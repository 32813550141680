// Forked from https://github.com/HenrikJoreteg/internal-nav-helper because we need some extra stuff
const findA = el => {
  if (!el) return null;
  return el.tagName === 'A' ? el : findA(el.parentElement);
};

const getLocalPathname = a =>
  a.href.indexOf(location.protocol + '//' + location.host) !== -1
    ? a.href.split(location.host, 2)[1]
    : null;

export default (onInternalNav, isInternavNav) => e => {
  if (e.button === 0 && !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
    const aTag = findA(e.target);
    if (
      aTag &&
      aTag.target !== '_blank' &&
      aTag.target !== '_external' &&
      !aTag.getAttribute('data-reload')
    ) {
      const url = getLocalPathname(aTag);
      if (url) {
        if (!isInternavNav || isInternavNav(url, e, aTag)) {
          e.preventDefault();
          onInternalNav(url, e, aTag);
        }
      }
    }
  }
};
