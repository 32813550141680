import React from 'react';
import PropTypes, { bool } from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';
import { chevronUp, chevronDown } from 'components/atoms/Icon/icons';
import Container from 'components/atoms/Container';
import Button, { LinkButton } from 'components/atoms/Button';
import MenuToggler from 'components/utilities/MenuToggler';
import { translate } from 'utils/translate';
import * as styles from './TopbarV2.scss';

const TopbarV2 = ({ children }) => (
  <div className={styles.root}>
    <Container wide className={styles.inner}>
      {children}
    </Container>
  </div>
);

TopbarV2.Button = ({
  className,
  children,
  onClick,
  href,
  icon,
  buttonClassName,
  loggedInFavorites,
  ariaLabel,
}) => (
  <Button
    transparent
    className={classnames(styles.button, className, {
      [styles.noIcon]: !icon && !loggedInFavorites,
    })}
    onClick={onClick}
    href={href}
    aria-label={ariaLabel}
  >
    {!!children && <span className={styles.buttonText}>{children}</span>}
    {!!icon && (
      <i className={classnames(styles.buttonIcon, buttonClassName)}>
        <Icon icon={icon} />
      </i>
    )}
  </Button>
);

TopbarV2.Menu = ({
  className,
  buttonClassName,
  label,
  items,
  href,
  loggedInFavorites,
}) =>
  loggedInFavorites ? (
    <MenuToggler
      closeOnOutsideClick
      closeOnEsc
      render={({ isOpen, clickOpenerProps, ref }) => (
        <div
          className={classnames(
            styles.menuRoot,
            styles.favoritesButton,
            {
              [styles.userMenuActive]: isOpen,
            },
            className,
          )}
        >
          {!!href && (
            <React.Fragment>
              <TopbarV2.Button
                href={href}
                className={classnames(styles.userFavorites, buttonClassName)}
                loggedInFavorites
              >
                {label}
              </TopbarV2.Button>
              <TopbarV2.Button
                icon={isOpen ? chevronUp : chevronDown}
                className={styles.userMenu}
                ariaLabel={
                  isOpen
                    ? translate('/menu/closeUserMenuAria')
                    : translate('/menu/openUserMenuAria')
                }
                {...clickOpenerProps}
              />
            </React.Fragment>
          )}
          {isOpen && (
            <ul className={classnames(styles.menu, styles.isOpen)} ref={ref}>
              {!!items &&
                items.map(({ linkText, action }) => (
                  <li key={linkText} className={styles.menuItem}>
                    <Button
                      transparent
                      onClick={action}
                      className={styles.menuLink}
                    >
                      {linkText}
                    </Button>
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
    />
  ) : (
    <MenuToggler
      closeOnOutsideClick
      closeOnEsc
      render={({ isOpen, clickOpenerProps, ref }) => (
        <div className={classnames(styles.menuRoot, className)}>
          {!href && (
            <TopbarV2.Button
              className={classnames(
                buttonClassName,
                isOpen && styles.activeTopMenu,
              )}
              icon={isOpen ? chevronUp : chevronDown}
              {...clickOpenerProps}
            >
              {label}
            </TopbarV2.Button>
          )}
          {!!href && (
            <React.Fragment>
              <TopbarV2.Button href={href} className={buttonClassName}>
                {label}
              </TopbarV2.Button>
              <TopbarV2.Button
                icon={isOpen ? chevronUp : chevronDown}
                className={buttonClassName}
                {...clickOpenerProps}
              />
            </React.Fragment>
          )}
          {isOpen && (
            <ul className={classnames(styles.menu, styles.isOpen)} ref={ref}>
              {!!items &&
                items.map(({ linkText, url, action }) => (
                  <li key={url || linkText} className={styles.menuItem}>
                    {!action ? (
                      <a
                        href={url}
                        className={styles.menuLink}
                        onClick={() => {
                          window.location.href = url;
                        }}
                      >
                        {linkText}
                      </a>
                    ) : (
                      <Button
                        transparent
                        onClick={action}
                        className={styles.menuLink}
                      >
                        {linkText}
                      </Button>
                    )}
                  </li>
                ))}
            </ul>
          )}
        </div>
      )}
    />
  );
TopbarV2.displayName = 'Topbar';
TopbarV2.propTypes = {};
TopbarV2.defaultProps = {};

export default TopbarV2;
