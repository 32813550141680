import { IS_BROWSER } from './environment';

const fallback = func => {
  setTimeout(func, 0);
};
export const raf =
  IS_BROWSER && self.requestAnimationFrame
    ? self.requestAnimationFrame
    : fallback;
export default raf;
