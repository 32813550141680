import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './ProductFilterSlider.scss';

const RangeSlider = ({
  className,
  onChange,
  value,
  label,
  minLabel,
  maxLabel,
  children,
  ...sliderProps
}) => {
  const [sliderVal, setSliderVal] = useState(0);
  const [mouseState, setMouseState] = useState(null);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const changeCallback = e => {
    setSliderVal(Number(e.target.value));
  };

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      onChange(sliderVal);
    }
  };
  const handleTouchEnd = () => {
    onChange(sliderVal);
  };

  useEffect(() => {
    if (mouseState === 'up') {
      onChange(sliderVal);
    }
  }, [mouseState]);

  return (
    <div className={classnames(styles.sliderWrapper, className)}>
      <div className={styles.label}>
        <span>{label || ' '}</span>
      </div>
      <div className={styles.rangeSlider}>
        <input
          type="range"
          value={sliderVal}
          {...sliderProps}
          onChange={changeCallback}
          onKeyUp={handleKeyUp}
          onMouseDown={() => setMouseState('down')}
          onMouseUp={() => setMouseState('up')}
          onTouchEnd={handleTouchEnd}
        />
        <div>
          <span>{minLabel}</span> <span> {maxLabel}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

RangeSlider.displayName = 'RangeSlider';
RangeSlider.propTypes = {};
RangeSlider.defaultProps = {};

export default RangeSlider;
