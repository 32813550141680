import React, { useRef, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useSize } from 'hooks/use-size';
import SVGMap from './SVGMap';
import Marker from './Marker';
import * as styles from './RegionMap.scss';

const MAP_INITIAL_WIDTH = 960;

const RegionMap = ({
  pages = [],
  highlightedPages = null,
  selectedPage = null,
  hoverPage = null,
  onSelect,
  onHover,
}) => {
  const mapRef = useRef();
  const [posFactor, setPosFactor] = useState(null);
  const size = useSize(mapRef, true, mapSize => {
    setPosFactor(mapSize ? mapSize.width / MAP_INITIAL_WIDTH : 1);
  });
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      ref={mapRef}
      className={styles.wrap}
    >
      <SVGMap className={styles.map} />
      {posFactor ? pages.map(page => (
        <Marker
          key={page.name}
          page={page}
          posFactor={posFactor}
          isHighlighted={highlightedPages.includes(page)}
          isSelected={selectedPage === page}
          isMapHover={isMouseOver || !!hoverPage}
          isHover={hoverPage === page}
          onHover={onHover}
          onSelect={onSelect}
        />
      )) : null}
    </div>
  );
};

export default RegionMap;
