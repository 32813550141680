import React from 'react';
import PropTypes from 'prop-types';
import { useColumnContext, COLUMN_MAIN } from 'components/molecules/PageGrid';
import getProductUrl from 'utils/getProductUrl';
import BlockWrapper from 'components/molecules/BlockWrapper';
import MediaSlider from 'components/molecules/MediaSlider';
import useSiteSettings from 'hooks/use-site-settings';

import * as styles from './TellusSliderContentBlock.scss';

// mock

const productWithImagesFilter = product => !!product.images.length;

const tellusProductToSliderItem = productPageLink => product => ({
  slideTitle: product.name,
  slideUrl: getProductUrl(product.url, productPageLink),
  slideImage: product.images[0].url,
});

const TellusSliderContentBlock = ({ block, productResponse }) => {
  const columnContext = useColumnContext();
  if (columnContext !== COLUMN_MAIN) {
    return null;
  }
  const { productPageLink } = useSiteSettings();
  const slides = productResponse.products
    .filter(productWithImagesFilter)
    .map(tellusProductToSliderItem(productPageLink));
  return (
    <BlockWrapper block={block}>
      <MediaSlider
        slides={slides}
        inlineContent
        height="max"
        fit="width"
        autoPlay={5000}
      />
    </BlockWrapper>
  );
};
TellusSliderContentBlock.displayName = 'TellusSliderContentBlock';
TellusSliderContentBlock.propTypes = {};
TellusSliderContentBlock.defaultProps = {};

export default TellusSliderContentBlock;
