import React from 'react';
import { connect } from 'redux-bundler-react';
import { appendQueryParams } from 'utils/bundle-helper';
import * as constants from 'utils/constants';

const defaultSearchResult = {
  totalResults: 0,
  pageNumber: 1,
  resultsPerPage: 10,
  count: 0,
  results: [],
};

const PageSearchResultContainer = ({
  pageData,
  children,
  queryObject,
  currentUrl,
}) => {
  // get searchTerm from the url.. and make sure to vet it properly
  const searchParam = constants.get('searchParam');
  const term = queryObject[searchParam] || null;

  // result or default
  const searchResult = {
    ...(pageData.searchResult || defaultSearchResult),
    searchParam,
    term,
  };

  // make pages
  const numberOfPages = Math.max(
    Math.ceil(searchResult.totalResults / searchResult.resultsPerPage),
    1,
  );
  searchResult.pages = Array(numberOfPages)
    .fill(0)
    .map((e, i) => {
      const number = i + 1;

      return {
        number,
        isCurrent: number === searchResult.pageNumber,
        href: appendQueryParams(currentUrl, {
          [constants.get('searchPageNumberParam')]: number,
        }).href,
      };
    });
  // do stuff to add more stuff here
  return children(searchResult);
};

export default connect(
  'selectPageData',
  'selectQueryObject',
  PageSearchResultContainer,
);
