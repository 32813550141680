import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Container, {
  getContainerProps,
} from 'components/atoms/Container/Container';
import { H2 } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
const Slider = lazy(() => import(/* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/Slider'));
const SlideTrack = lazy(() => import(/* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SlideTrack'));

import * as styles from './MyPageSectionActivitiesAndAttractions.scss';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

const MyPageSectionActivitiesAndAttractions = ({ items = [] }) => (
  <BreakpointContainer
    render={breakpoint => {
      if (breakpoint < BREAKPOINT_TABLET_PORTRAIT) {
        // WE NEED A SLIDER UP IN HERE
        return (
          <Suspense fallback={<LoadingIndicator />}>
            <Slider items={items}>
              <div className={styles.sliderTrack}>
                <SlideTrack
                  infinite
                  direction="horizontal"
                  snap="center"
                  dragTreshold={60}
                  height="item"
                  renderItem={({ item: article, getItemProps, isCurrent }) => (
                    <div
                      {...getContainerProps({
                        className: classnames(styles.item, {
                          [styles.currentItem]: isCurrent,
                        }),
                        noPadding: true,
                        ...getItemProps(),
                      })}
                    >
                      <a href={article.url} className={styles.link}>
                        <Image
                          src={article.imageUrl}
                          ratio={[16, 9]}
                          fit="cover"
                          className={styles.media}
                          cropDetails={article.cropDetails}
                        />
                        <div className={styles.body}>
                          <H2 alt center gutterBottom size={H2.sizes.heading4}>
                            {article.title}
                          </H2>
                          <Paragraph center size={Paragraph.sizes.heading6}>
                            {article.intro}
                          </Paragraph>
                        </div>
                      </a>
                    </div>
                  )}
                />
              </div>
            </Slider>
          </Suspense>
        );
      }
      return (
        <Container wide noPadding className={styles.list}>
          {items.map(article => (
            <a key={article.url} href={article.url} className={styles.link}>
              <Image
                src={article.imageUrl}
                ratio={[16, 9]}
                fit="cover"
                className={styles.media}
                cropDetails={article.cropDetails}
              />
              <H2 alt center className={styles.body}>
                {article.title}
              </H2>
            </a>
          ))}
        </Container>
      );
    }}
  />
);
MyPageSectionActivitiesAndAttractions.displayName =
  'MyPageSectionActivitiesAndAttractions';
MyPageSectionActivitiesAndAttractions.propTypes = {
  items: PropTypes.array,
};
MyPageSectionActivitiesAndAttractions.defaultProps = {};

export default MyPageSectionActivitiesAndAttractions;
