import { chevronRight, infoFill } from 'components/atoms/Icon/icons';
import Heading from 'components/atoms/Heading';

export const defaultConfig = {
  linksBlockIcon: chevronRight,
  footerLinksBlockIcon: chevronRight,
  footerHeadingSize: Heading.sizes.heading4,
  footerInfoIcon: infoFill,
  footerInvertSocialIcons: false,
  frontpageSliderFullHeightContent: true,
  teaserIcon: null,
  defaultMapZoomLevel: 12,
};
