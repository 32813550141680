import getProductDescription from './getProductDescription';
import getProductUrl from './getProductUrl';

const getTeaserForProduct = productPageUrl => product => ({
  id: product.id,
  myFavoritesId: product.myFavoritesId,
  title: product.name,
  intro: getProductDescription(product, true),
  url: getProductUrl(product.url, productPageUrl),
  published: product.published,
  address: product.address,
  geoLocation: product.geoLocation,
  place: product.place,
  bookingWebsite: product.bookingWebsite,
  website: product.contactInfo ? product.contactInfo.website : null,
  tripAdvisorId:
    product.tripAdvisorRating && product.tripAdvisorRating.tripAdvisorId,
  ...(product.images &&
    product.images.length && {
      imageUrl: product.images[0].url,
      imageText: product.images[0].copyright,
      cropDetails: product.images[0].cropDetails,
    }),
});
export default getTeaserForProduct;
