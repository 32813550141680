import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from 'components/layouts/DefaultLayout';
import FooterContainer from 'containers/organisms/FooterContainer';
import HeaderContainer from 'containers/organisms/HeaderContainer';
import HelmetContainer from 'containers/HelmetContainer';
import GTMPageViewTrackerContainer from 'containers/GTMPageViewTrackerContainer';
import NotificationsToastContainer from 'containers/molecules/NotificationsToastContainer';

import ErrorBoundary from 'packages/inmeta-epi-react/components/ErrorBoundary';

const FullscreenLayoutContainer = ({
  noSticky = true,
  hasEffects,
  children,
}) => {
  return (
    <DefaultLayout
      header={!hasEffects && <HeaderContainer noSticky={noSticky} mobileOnly />}
      footer={<FooterContainer />}
    >
      <HelmetContainer />
      <GTMPageViewTrackerContainer />
      <ErrorBoundary>{children}</ErrorBoundary>
      <NotificationsToastContainer max={3} />
    </DefaultLayout>
  );
};

export default FullscreenLayoutContainer;
