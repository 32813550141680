import { START, MIDDLE, END, AXIS_X, AXIS_Y } from './constants';
const xStyle = {
  [AXIS_X]: {
    [START]: { right: '100%' },
    [MIDDLE]: { left: '50%', transform: 'translateX(-50%)' },
    [END]: { left: '100%' },
  },
  [AXIS_Y]: {
    [START]: { left: '0' },
    [MIDDLE]: { left: '50%', transform: 'translateX(-50%)' },
    [END]: { right: '0' },
  },
};
const yStyle = {
  [AXIS_X]: {
    [START]: { top: '0' },
    [MIDDLE]: { top: '50%', transform: 'translateY(-50%)' },
    [END]: { bottom: '0' },
  },
  [AXIS_Y]: {
    [START]: { bottom: '100%' },
    [MIDDLE]: { top: '50%', transform: 'translateY(-50%)' },
    [END]: { top: '100%' },
  },
};

export default (zone, center = false) => {
  return zone
    ? {
        position: 'absolute',
        ...yStyle[zone.axis][zone.dy],
        ...xStyle[zone.axis][zone.dx],
        // adjust for centering the arrow on the axis
        ...(center &&
          (zone.axis === AXIS_X
            ? yStyle[zone.axis][MIDDLE]
            : xStyle[zone.axis][MIDDLE])),
      }
    : { position: 'absolute', top: 0, left: 0, visibility: 'hidden' };
};
