let phrases = {};

// Are you here looking for how we know which language keys exists?
// We find that out dynaically using `webpack/translation-finder-loader.js`
// It goes through all code and looks for calls like this: `translate('xxx')`
// and automatically makes that phrase available in the frontend code.

export const setPhrases = p => {
  phrases = p;
};

// Format phrase using curly braces, e.g. `format('foo{0} {1}', 'bar', 'baz')` => Outputs: foobar baz
export const format = (phrase, ...values) => {
  const regex = /{(\d+)}/g;
  if (process.env.NODE_ENV !== "production") {
    const placeholders = phrase.match(regex) || [];
    if (placeholders.length !== values.length) {
      const error = `The phrase "${phrase}" contains ${
        placeholders.length
      } placeholders
                     but ${
                       values.length
                     } placeholders was passed to translate(). The placeholders
                     passed in was: ${JSON.stringify(values)}`;
      throw new Error(error);
    }
  }
  return values.length === 0
    ? phrase
    : phrase.replace(
        regex,
        (match, index) =>
          typeof values[index] !== "undefined" ? values[index] : match
      );
};

export const translate = (key, ...values) => {
  if (!(key in phrases)) {
    return key;
  } else {
    return format(phrases[key], ...values);
  }
};
