import React from 'react';
import ReactDOM from 'react-dom';
import memoizeOne from 'memoize-one';

import observeRect from './observe-rect-wrapper';
import getZones from './getZones';
import getOptimalZone from './getOptimalZone';
import getPopoverStyle from './getPopoverStyle';
import getArrowStyle from './getArrowStyle';
import { presets, defaultRect, arrowDirections } from './constants';
import { IS_BROWSER } from 'utils/environment';

const containers = {
  body: 'body',
  parent: 'parent',
  viewport: 'viewport',
};

export default class Popover2 extends React.Component {
  constructor(props) {
    super(props);
    this.elRef = React.createRef();
    this.state = {
      bodyRect: defaultRect,
      anchorRect: defaultRect,
      containerRect: defaultRect,
      elRect: defaultRect,
    };

    // memoize stuff for better performance
    this.getZones = memoizeOne(getZones);
    this.getOptimalZone = memoizeOne(getOptimalZone);
    this.getZoneByName = memoizeOne(
      (zones, name) => zones.find(zone => zone.name === name) || zones[0],
    );
    this.getBodyOffset = memoizeOne((bodyRect, containerRect) => ({
      top: bodyRect.top - containerRect.top,
      left: bodyRect.left - containerRect.left,
    }));
  }
  componentDidMount() {
    this.addObservers();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.anchor !== this.props.anchor) {
      this.removeObservers();
      this.addObservers();
    }
  }
  componentWillUnmount() {
    this.removeObservers();
  }
  addObservers = () => {
    const { anchor, container = containers.viewport } = this.props;
    if (this.elRef.current && anchor) {
      let parent =
        container === containers.body
          ? document.body
          : container === containers.parent
          ? anchor.offsetParent || document.body
          : container === containers.viewport
          ? window
          : container;
      this.elObserver = observeRect(this.elRef.current, elRect => {
        this.setState({ elRect });
      });
      this.elObserver.observe();
      this.anchorObserver = observeRect(anchor, anchorRect => {
        this.setState({ anchorRect });
      });
      this.anchorObserver.observe();
      this.parentObserver = observeRect(parent, containerRect => {
        this.setState({ containerRect });
      });
      this.parentObserver.observe();
      this.bodyObserver = observeRect(document.body, bodyRect => {
        this.setState({ bodyRect });
      });
      this.bodyObserver.observe();
    }
  };
  removeObservers = () => {
    this.elObserver && this.elObserver.unobserve();
    this.anchorObserver && this.anchorObserver.unobserve();
    this.parentObserver && this.parentObserver.unobserve();
    this.bodyObserver && this.bodyObserver.unobserve();
  };

  render() {
    const {
      position,
      children,
      render,
      autoReposition = false,
      arrowSize = 0,
      centerArrow = false,
    } = this.props;
    const { elRect, anchorRect, containerRect, bodyRect } = this.state;
    const zones = this.getZones(anchorRect, containerRect);
    const zone =
      autoReposition && zones.length
        ? this.getOptimalZone(zones, elRect, position, arrowSize)
        : this.getZoneByName(zones, position);
    const bodyOffset = this.getBodyOffset(bodyRect, containerRect);

    // render to body ;)
    return IS_BROWSER
      ? ReactDOM.createPortal(
          (render || children)({
            ref: this.elRef,
            style: getPopoverStyle(zone, bodyOffset, arrowSize),
            arrowStyle: getArrowStyle(zone, centerArrow),
            arrowDirection: arrowDirections[zone.name[0]],
            zone: zone,
          }),
          document.body,
        )
      : null;
  }
}
Popover2.presets = presets;
Popover2.containers = containers;
