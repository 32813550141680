import React, { useEffect, useRef } from "react";
import { useSliderState } from "./Slider";
import useIntervalTimer from "./lib/use-interval-timer";
import { useFunction } from "./lib/use-function";
import { useVisibilityChange } from "./lib/use-visibility-change";

export const AutoPlay = ({
  interval = 10000,
  pause = false,
  pauseOnHover = false
}) => {
  const { actions, hover, index, inTransition } = useSliderState();
  const timer = useIntervalTimer();
  const timerRef = useRef(null);
  timerRef.current = timer;
  const shouldBePaused = pause || (pauseOnHover && hover);
  const shouldBePausedRef = useRef();
  shouldBePausedRef.current = shouldBePaused;
  const start = useFunction(() => {
    if (shouldBePausedRef.current) return;
    timerRef.current.start(actions.next, interval);
  });
  const resume = useFunction(() => {
    if (shouldBePausedRef.current) return;
    if (timerRef.current.isPaused()) {
      timerRef.current.resume();
    } else {
      start();
    }
  });

  useEffect(() => {
    if (shouldBePaused) {
      timerRef.current.pause();
    } else {
      resume();
    }
  }, [shouldBePaused, start, resume]);
  useVisibilityChange(isVisible =>
    isVisible ? resume() : timerRef.current.pause()
  );
  useEffect(() => {
    if (inTransition) {
      // stop current
      timerRef.current.stop();
    } else {
      // start new
      start();
    }
  }, [index, inTransition, start]);

  return null;
};

export default AutoPlay;
