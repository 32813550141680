import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './LoadingIndicatorBar.scss';

const LoadingIndicatorBar = () => (
  <div className={classnames(styles.loadingBar, styles.isLoading)} />
);
LoadingIndicatorBar.displayName = 'LoadingIndicatorBar';

export default LoadingIndicatorBar;
