import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import Icon from 'components/atoms/Icon';
import { spinner } from 'components/atoms/Icon/icons';
import Link from 'components/atoms/Link';
import { tellusApiPost } from 'server/api';

import * as styles from './TellusRegistrationForm.scss';

/*
recaptcha test-keys
Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
*/

class TellusRegistrationForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...props,
      isLoading: false,
      errors: {},
      submitError: false,
      recaptchaToken: null,
      terms: {
        termOfUsageAccept: false,
        termOfUsageAcceptSingleUsage: false,
        termsWidget: false,
      },

      // Set all formfields in state by default, for easier validation
      formData: {
        // hardcoded values
        technology: 'Feed',
        dbowner: 23,
        domain0: 'feed.simpleviewinc.no',
        domain0comment: 'Live',
        confirmation: 'https://www.visitoslo.no', // this is just a placeholder
        aa: 'Register',

        // dynamic values
        nameOfCompany: '',
        addressOfCompany: '',
        phoneNumer: '',
        orgNr: '',
        website: '',
        organisation: '',
        billingAddress: '',
        distribution_channel: '',
        goLiveDate: '',
        numberOfUsers: '',
        technical_name: '',
        technical_company: '',
        technical_email: '',
        technical_telephone: '',
        technical_mobile: '',
        ip0: '',
        ip0comment: '',
        ip1: '',
        ip1comment: '',
        projectContactName: '',
        projectContactMail: '',
        projectContactPhone: '',
        prContactName: '',
        prContactMail: '',
        prContactPhone: '',
      },
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.renderErrorLabel = this.renderErrorLabel.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.onTimeoutRecaptcha = this.onTimeoutRecaptcha.bind(this);
    this.validateFormData = this.validateFormData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
  }

  componentDidMount() {
    loadReCaptcha();
    if (this.captcha) {
      this.captcha.reset();
    }
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
    }
  }

  onTimeoutRecaptcha() {
    console.log('recaptcha timeout, resetting token');
    this.setState({
      recaptchaToken: null,
    });
  }

  handleFieldChange(field) {
    const { formData, errors } = this.state;
    let hasError = false;
    // const errors = {};

    const { value } = field.target;
    // Check empty field
    if (!value) {
      hasError = true;
    }

    // Check emails
    if (field.target.type === 'email') {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(value) === false) {
        hasError = true;
      }
    }

    formData[field.target.name] = value;

    if (hasError) {
      errors[field.target.name] = true;
    } else {
      delete errors[field.target.name];
    }

    this.setState({
      errors,
      formData,
    });
  }

  handleTermsChange(field) {
    const { checked, name } = field.target;
    const { terms } = this.state;
    terms[name] = !!checked;
    this.setState({
      terms,
    });
  }

  validateFormData() {
    // Check all fields in state
    const { formData, recaptchaToken } = this.state;

    if (recaptchaToken === null || recaptchaToken === '') {
      return false;
    }

    const errors = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] === '' || formData[key] === false) {
        errors[key] = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return false;
    }

    return true;
  }

  handleRecaptcha(recaptchaToken) {
    if (recaptchaToken && recaptchaToken.length > 0) {
      this.setState({
        recaptchaToken,
      });
    }
  }

  renderErrorLabel(field) {
    const { errors } = this.state;
    if (errors[field] === true) {
      return (
        <span className={styles.formFieldError}>
          Field is required/incorrect
        </span>
      );
    }
    return null;
  }

  handleSubmit(form) {
    const formValid = this.validateFormData();

    this.setState({
      isLoading: true,
      submitError: false,
    });
    if (formValid) {
      // Send formData to tellus
      return true;
    }

    this.setState({
      isLoading: false,
      submitError: true,
    });

    form.preventDefault();
  }

  render() {
    const { formData, terms, recaptchaToken, tellUsRegistrationUrl, confirmationPage } = this.state;
    let { siteKey } = this.state.recaptcha;
    const baseUrl = window.location.host;
    if (window.location.host.includes('dev.visitoslo') || window.location.host.includes('.azure.com')) { // Super ugly check to switch key if test/dev
      siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // TEST KEY FOR RECAPTCHA USE THIS IN DEV AND TEST
    }

    // console.log('regpage', tellUsRegistrationUrl.replace('http://', '//'));
    // console.log('confirm', `//${window.location.host + confirmationPage}`);

    const disabledButton =
      recaptchaToken === null ||
      terms.termOfUsageAccept === false ||
      terms.termOfUsageAcceptSingleUsage === false ||
      terms.termsWidget === false;

    return (
      <form action={tellUsRegistrationUrl.replace('http://', '//')} onSubmit={(e) => this.handleSubmit(e)} method="post">
        <input type="hidden" name="technology" value="Feed" />
        <input type="hidden" name="dbowner" value="23" />
        <input type="hidden" name="domain0" value="feed.tellus.no" />
        <input type="hidden" name="domain0comment" value="Live" />
        <input type="hidden" name="confirmation" value={`//${baseUrl + confirmationPage}`} />
        <input type="hidden" name="aa" value="Register" />

        <div className={classnames(styles.tellusform, styles.registrationForm)}>
          {/* Your organisation */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Your organisation
            </legend>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="nameOfCompany"
              >
                Name of company
              </span>
              <input
                name="nameOfCompany"
                type="text"
                id="nameOfCompany"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.nameOfCompany}

              />
              {this.renderErrorLabel('nameOfCompany')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="addressOfCompany"
              >
                Address
              </span>
              <input
                name="addressOfCompany"
                type="text"
                id="addressOfCompany"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.addressOfCompany}

              />
              {this.renderErrorLabel('addressOfCompany')}
            </div>
            <div className={styles.registrationForm}>
              <span className={styles.registrationFormLabel} htmlFor="phoneNumer">
                Phone number
              </span>
              <input
                name="phoneNumer"
                type="text"
                id="phoneNumer"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.phoneNumer}

              />
              {this.renderErrorLabel('phoneNumer')}
            </div>
            <div className={styles.registrationForm}>
              <span className={styles.registrationFormLabel} htmlFor="orgNr">
                Org.no. (VAT number)
              </span>
              <input
                name="orgNr"
                type="text"
                id="orgNr"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.orgNr}

              />
              {this.renderErrorLabel('orgNr')}
            </div>
            <div className={styles.registrationForm}>
              <span className={styles.registrationFormLabel} htmlFor="website">
                Website
              </span>
              <input
                name="website"
                type="text"
                id="website"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.website}

              />
              {this.renderErrorLabel('website')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="organisation"
              >
                Describe your organisation and business area and how it relates to
                our data
              </span>
              <textarea
                id="organisation"
                className={styles.formInput}
                name="organisation"
                onChange={this.handleFieldChange}
                value={formData.organisation}
                rows="3"
              />
              {this.renderErrorLabel('organisation')}
            </div>
          </fieldset>

          {/* Prices/Invoicing */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Prices/Invoicing
            </legend>
            <p className={classnames(styles.infoText, styles.registrationForm)}>
              A sign-up cost and a yearly cost apply (see Terms of use for prices)
              and will be billed upon signup by New Mind | TellUs. For additional
              help with implementation, New Mind | TellUs' technicians can be of
              assistance, charging by the hour.
            </p>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="billingAddress"
              >
                Billing address
              </span>
              <input
                name="billingAddress"
                type="text"
                id="billingAddress"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.billingAddress}

              />
              {this.renderErrorLabel('billingAddress')}
            </div>
          </fieldset>

          {/* Your application/site */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Your application/site
            </legend>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="distribution_channel"
              >
                Describe your application/site, with URL and the intended usage of
                the data
              </span>
              <textarea
                id="distribution_channel"
                className={styles.formInput}
                name="distribution_channel"
                onChange={this.handleFieldChange}
                value={formData.distribution_channel}
                rows="3"
              />
              {this.renderErrorLabel('distribution_channel')}
            </div>
            <div className={styles.registrationForm}>
              <span className={styles.registrationFormLabel} htmlFor="goLiveDate">
                When are you planning to go live with the new service based on
                VisitOSLO's data?
              </span>
              <textarea
                id="goLiveDate"
                className={styles.formInput}
                name="goLiveDate"
                onChange={this.handleFieldChange}
                value={formData.goLiveDate}
                rows="3"
              />
              {this.renderErrorLabel('goLiveDate')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="numberOfUsers"
              >
                Expected number of users/people reached with the new service.
              </span>
              <textarea
                id="numberOfUsers"
                className={styles.formInput}
                name="numberOfUsers"
                onChange={this.handleFieldChange}
                value={formData.numberOfUsers}
                rows="3"
              />
              {this.renderErrorLabel('numberOfUsers')}
            </div>
          </fieldset>

          {/* Technical contact */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Technical contact
            </legend>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="technical_name"
              >
                Name
              </span>
              <input
                name="technical_name"
                type="text"
                id="technical_name"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.technical_name}

              />
              {this.renderErrorLabel('technical_name')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="technical_company"
              >
                Company
              </span>
              <input
                name="technical_company"
                type="text"
                id="technical_company"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.technical_company}

              />
              {this.renderErrorLabel('technical_company')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="technical_email"
              >
                E-mail
              </span>
              <input
                name="technical_email"
                type="email"
                id="technical_email"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.technical_email}

              />
              {this.renderErrorLabel('technical_email')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="technical_telephone"
              >
                Telephone
              </span>
              <input
                name="technical_telephone"
                type="text"
                id="technical_telephone"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.technical_telephone}

              />
              {this.renderErrorLabel('technical_telephone')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="technical_mobile"
              >
                Mobile
              </span>
              <input
                name="technical_mobile"
                type="text"
                id="technical_mobile"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.technical_mobile}

              />
              {this.renderErrorLabel('technical_mobile')}
            </div>
          </fieldset>

          {/* IP Address */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              IP Address
            </legend>
            <p className={classnames(styles.infoText, styles.registrationForm)}>
              IP address that is going to use this licence (server external IP
              address)
            </p>
            <div className={styles.registrationForm}>
              <input
                name="ip0"
                type="text"
                id="ip0"
                className={classnames(styles.formInput, styles.ipInput)}
                onChange={this.handleFieldChange}
                value={formData.ip0}

              />
              <select
                name="ip0comment"
                id="ip0comment"
                className={styles.ipTypeInput}
                onChange={this.handleFieldChange}
                value={formData.ip0comment}

              >
                <option value="">-- Choose environment</option>
                <option value="Development">Development</option>
                <option value="Test">Test</option>
                <option value="Live">Live</option>
              </select>

              <div>
                <span className={styles.ipErrorLabel}>
                  {this.renderErrorLabel('ip0')}
                </span>
                {this.renderErrorLabel('ip0comment')}
              </div>
            </div>
            <div className={styles.registrationForm}>
              <input
                name="ip1"
                type="text"
                id="ip1"
                className={classnames(styles.formInput, styles.ipInput)}
                onChange={this.handleFieldChange}
                value={formData.ip1}

              />
              <select
                name="ip1comment"
                id="ip1comment"
                className={styles.ipTypeInput}
                onChange={this.handleFieldChange}
                value={formData.ip1comment}

              >
                <option value="">-- Choose environment</option>
                <option value="Development">Development</option>
                <option value="Test">Test</option>
                <option value="Live">Live</option>
              </select>

              <div>
                <span className={styles.ipErrorLabel}>
                  {this.renderErrorLabel('ip1')}
                </span>
                {this.renderErrorLabel('ip1comment')}
              </div>
            </div>
          </fieldset>

          {/* Project contact */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Project contact
            </legend>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="projectContactName"
              >
                Name
              </span>
              <input
                name="projectContactName"
                type="text"
                id="projectContactName"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.projectContactName}

              />
              {this.renderErrorLabel('projectContactName')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="projectContactMail"
              >
                E-mail
              </span>
              <input
                name="projectContactMail"
                type="email"
                id="projectContactMail"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.projectContactMail}

              />
              {this.renderErrorLabel('projectContactMail')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="projectContactPhone"
              >
                Phone number
              </span>
              <input
                name="projectContactPhone"
                type="text"
                id="projectContactPhone"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.projectContactPhone}

              />
              {this.renderErrorLabel('projectContactPhone')}
            </div>
          </fieldset>

          {/* PR contact */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              PR contact
            </legend>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="prContactName"
              >
                Name
              </span>
              <input
                name="prContactName"
                type="text"
                id="prContactName"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.prContactName}

              />
              {this.renderErrorLabel('prContactName')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="prContactMail"
              >
                E-mail
              </span>
              <input
                name="prContactMail"
                type="email"
                id="prContactMail"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.prContactMail}

              />
              {this.renderErrorLabel('prContactMail')}
            </div>
            <div className={styles.registrationForm}>
              <span
                className={styles.registrationFormLabel}
                htmlFor="prContactPhone"
              >
                Phone number
              </span>
              <input
                name="prContactPhone"
                type="text"
                id="prContactPhone"
                className={styles.formInput}
                onChange={this.handleFieldChange}
                value={formData.prContactPhone}

              />
              {this.renderErrorLabel('prContactPhone')}
            </div>
          </fieldset>

          {/* Terms of use */}
          <fieldset
            className={classnames(
              styles.registrationFormFieldset,
              styles.registrationForm,
            )}
          >
            <legend
              className={classnames(
                styles.registrationForm,
                styles.registrationFormLegend,
              )}
            >
              Terms of use
            </legend>
            <p className={classnames(styles.infoText, styles.registrationForm)}>
              You must read our terms and conditions before sending the form.
            </p>
            <div className={styles.registrationForm}>
              <Link href={this.state.termsOfUsePage} target="_blank">
                Terms of use
              </Link>
            </div>
            <div className={styles.registrationForm}>
              <label
                htmlFor="termOfUsageAccept"
                className={styles.termOfUsageLabel}
              >
                <input
                  id="termOfUsageAccept"
                  type="checkbox"
                  name="termOfUsageAccept"
                  className={styles.termsCheck}
                  onChange={this.handleTermsChange}
                  value={formData.termOfUsageAccept}

                />
                <span className="termsOfUsageText">
                  I have read and accept the terms of use and will use VisitOSLO's
                  content in accordance with the terms.
                </span>
              </label>
              <div>{this.renderErrorLabel('termOfUsageAccept')}</div>
            </div>
            <div className={styles.registrationForm}>
              <label
                htmlFor="termOfUsageAcceptSingleUsage"
                className={styles.termOfUsageLabel}
              >
                <input
                  id="termOfUsageAcceptSingleUsage"
                  type="checkbox"
                  name="termOfUsageAcceptSingleUsage"
                  className={styles.termsCheck}
                  onChange={this.handleTermsChange}
                  value={formData.termOfUsageAcceptSingleUsage}

                />
                <span className="termsOfUsageText">
                  I acknowledge that the licence is issued for a single
                  purpose/usage/application/web/URL and that no form of reuse or
                  redistribution of this data is permitted. Please contact us if
                  you are in doubt whether your planned usage is covered by an
                  existing agreement/licence.
                </span>
              </label>
              <div>{this.renderErrorLabel('termOfUsageAcceptSingleUsage')}</div>
            </div>
            <div className={styles.registrationForm}>
              <label htmlFor="termsWidget" className={styles.termOfUsageLabel}>
                <input
                  id="termsWidget"
                  type="checkbox"
                  name="termsWidget"
                  className={styles.termsCheck}
                  onChange={this.handleTermsChange}
                  value={formData.termsWidget}

                />
                <span className="termsOfUsageText">
                  The Widget agreement is effective from the date of application.
                  <br />
                  If the agreement is not terminated later than one (1) month
                  before the end of the contract period, the agreement is extended
                  by twelve (12) months with the same notice period.
                  <br />
                  Cancellation must be done in writing by email to{' '}
                  <a href="mailto: trine.avnung@simpleviewinc.com?subject=Termination of Widget Agreement">
                    trine.avnung@simpleviewinc.com
                  </a>
                  . Subject: Termination of Widget Agreement
                </span>
              </label>
              <div>{this.renderErrorLabel('termsWidget')}</div>
            </div>

            <div
              className={styles.registrationForm}
              style={{ marginTop: '20px' }}
            >
              <ReCaptcha
                ref={el => {
                  this.captcha = el;
                }}
                size="normal"
                data-theme="light"
                render="explicit"
                sitekey={siteKey}
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.handleRecaptcha}
                expiredCallback={this.onTimeoutRecaptcha}
              />
            </div>
          </fieldset>

          <button
            type="submit"
            className={styles.submitButton}
            disabled={disabledButton && !this.state.isLoading}
          >
            Register
          </button>

          {this.state.isLoading && (
            <span className={styles.loading}>
              <Icon icon={spinner} spin className={styles.icon} />
            </span>
          )}

          {this.state.submitError && (
            <div className={styles.submitError}>
              Noe gikk galt under sending av din forespørsel. Vennligst kontroller alle feltene, og prøv igjen.
            </div>
          )}
        </div>
      </form>
    );
  }
}

TellusRegistrationForm.displayName = 'TellusRegistrationForm';
TellusRegistrationForm.propTypes = {
  confirmationPage: PropTypes.string,
  tellUsRegistrationUrl: PropTypes.string,
};
TellusRegistrationForm.defaultProps = {};

export default TellusRegistrationForm;
