import React from "react";
import { connect } from "redux-bundler-react";
const forceArray = value => (value instanceof Array ? value : [value]);
const checks = {
  is: (value, breakpoint) =>
    !!forceArray(value).filter(value => value === breakpoint).length,
  not: (value, breakpoint) =>
    !forceArray(value).filter(value => value === breakpoint).length,
  gte: (value, breakpoint) => breakpoint >= value,
  gt: (value, breakpoint) => breakpoint > value,
  lt: (value, breakpoint) => breakpoint < value,
  lte: (value, breakpoint) => breakpoint <= value
};

const BreakpointContainer = props => {
  if (props.render) {
    return props.render(props.breakpoint);
  }
  if (
    !!Object.keys(checks).filter(
      type =>
        props[type] !== undefined && checks[type](props[type], props.breakpoint)
    ).length
  ) {
    return props.match();
  }
  return null;
};

export default connect("selectBreakpoint", BreakpointContainer);
