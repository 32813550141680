import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuToggler from 'components/utilities/MenuToggler';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { info, chevronLeft, chevronRight } from 'components/atoms/Icon/icons';
import { iconLookup } from 'utils/iconLookUp';
import * as styles from './NavigationV2.scss';

export const DesktopNavigationItem = ({
  label,
  url,
  isCurrent = false,
  children,
  className,
  linkClassName,
  renderChildren,
  setOpen,
  isOpen,
  setClose,
  menuLevel,
  menuColor,
}) => {
  const hasChildren = !!children || !!renderChildren;
  const firstMenuLevel = menuLevel === 0;
  const secondMenuLevel = menuLevel === 1;

  let style = {};
  if (menuColor) {
    style['--menuColorTheme'] = menuColor;
  }

  return (
    <div
      className={classnames(styles.item, className, {
        [styles.active]: isOpen,
        [styles.subItem]: menuLevel > 0,
        [styles.activeSubitem]: isOpen && hasChildren && secondMenuLevel,
        [styles.current]: isCurrent,
        [styles.hasChildren]: hasChildren,
        [styles.flexSpaceBetween]: hasChildren || firstMenuLevel,
      })}
      key={url || label}
      style={style}
    >
      {children ? (
        <>
          <Button
            className={classnames(styles.link, linkClassName, {
              [styles.activeUnderline]: isOpen && firstMenuLevel,
              [styles.hoverUnderline]: firstMenuLevel,
              [styles.subItemButton]: secondMenuLevel,
            })}
            style={style}
            onClick={isOpen ? setClose : setOpen}
          >
            <span>{label}</span>
            {menuLevel > 0 && (
              <Icon
                icon={isOpen ? chevronLeft : chevronRight}
                className={styles.chevrons}
              />
            )}
          </Button>

          {isOpen && children}
        </>
      ) : (
        <a
          href={url}
          className={classnames(styles.link, styles.alignLeft, linkClassName, {
            [styles.hoverUnderline]: firstMenuLevel,
            [styles.subItemButton]: secondMenuLevel,
          })}
        >
          {label}
        </a>
      )}
    </div>
  );
};
DesktopNavigationItem.propTypes = {
  label: PropTypes.any,
  url: PropTypes.string,
  isCurrent: PropTypes.bool,
  children: PropTypes.node,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  menuLevel: PropTypes.number,
  renderChildren: PropTypes.func,
  setOpen: PropTypes.func,
  setClose: PropTypes.func,
  isOpen: PropTypes.bool,
};
DesktopNavigationItem.defaultProps = {
  isCurrent: false,
  leftIcon: null,
  menuLevel: 0,
};

export const NavigationItemV2 = ({
  label,
  url,
  isCurrent = false,
  children,
  className,
  linkClassName,
  iconClassName,
  wrapClassName,
  leftIcon,
  leftIconProps,
  menuLevel,
  renderChildren,
  icon,
  noMenuIconChevron,
  menuColor,
}) => {
  const hasChildren = !!children || !!renderChildren;
  const shouldRender = !!url || !!hasChildren;
  if (!shouldRender) {
    return null;
  }

  return (
    <MenuToggler
      render={({
        clickOpenerProps,
        isOpen,
        ref,
        doClose,
        doOpen,
        doToggle,
      }) => (
        <div
          className={classnames(styles.item, className, {
            [styles.active]: isOpen && menuLevel > 1,
            [styles.current]: isCurrent,
            [styles.hasChildren]: hasChildren,
          })}
          style={{ backgroundColor: isOpen && menuLevel > 1 && menuColor }}
          key={url || label}
          ref={ref}
        >
          <span
            className={classnames(styles.wrap, wrapClassName, {
              [styles.alignChevron]: isOpen,
              [styles.regionChevron]: noMenuIconChevron,
            })}
          >
            {menuLevel <= 1 && !noMenuIconChevron && (
              <Icon
                name={iconLookup[icon] || info}
                className={styles.menuItemIcons}
              />
            )}
            {url ? (
              <a href={url} className={classnames(styles.link, linkClassName)}>
                {!!leftIcon && (
                  <Icon {...leftIconProps} offsetRight icon={leftIcon} />
                )}
                {label}
              </a>
            ) : (
              <span className={classnames(styles.link, linkClassName)}>
                {!!leftIcon && (
                  <Icon {...leftIconProps} offsetRight icon={leftIcon} />
                )}
                {label}
              </span>
            )}

            {!isOpen && (
              <span className={classnames(styles.wrap, wrapClassName)}>
                {hasChildren && (
                  <Button
                    transparent
                    className={classnames(styles.linkIcon, iconClassName)}
                    {...clickOpenerProps}
                    aria-label="Open menu"
                  >
                    <Icon icon={isOpen ? chevronLeft : chevronRight} />
                  </Button>
                )}
              </span>
            )}
          </span>

          {renderChildren
            ? renderChildren({ isOpen, doClose, doOpen, doToggle })
            : null}
          {!renderChildren && !!children && isOpen ? children : null}
        </div>
      )}
    />
  );
};
NavigationItemV2.propTypes = {
  label: PropTypes.any,
  url: PropTypes.string,
  isCurrent: PropTypes.bool,
  children: PropTypes.node,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  menuLevel: PropTypes.number,
  renderChildren: PropTypes.func,
};
NavigationItemV2.defaultProps = {
  isCurrent: false,
  leftIcon: null,
  menuLevel: 0,
};

export const NavigationV2 = ({
  direction = 'vertical',
  children,
  className,
  render,
  closeOnClickOutside,
}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const ref = useRef(null);

  useOnClickOutside(
    ref,
    () => setOpenIndex(null),
    closeOnClickOutside && openIndex == !null,
  );

  return (
    <div
      ref={ref}
      className={classnames(styles.navigation, className, {
        [styles.vertical]: direction === 'vertical',
        [styles.horizontal]: direction === 'horizontal',
      })}
    >
      {render ? render({ openIndex, setOpenIndex }) : children}
    </div>
  );
};
NavigationV2.displayName = 'NavigationV2';
NavigationV2.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  children: PropTypes.node,
  className: PropTypes.string,
  render: PropTypes.func,
  closeOnClickOutside: PropTypes.bool,
};
NavigationV2.defaultProps = {
  direction: 'vertical',
};

NavigationV2.Item = NavigationItemV2;
NavigationV2.DesktopItem = DesktopNavigationItem;

export default NavigationV2;
