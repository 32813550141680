let state = {
  rect: null,
  callbacks: [],
};

let run = () => {
  let width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  );
  let height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  state.rect = {
    top: 0,
    left: 0,
    width,
    height,
    right: width,
    bottom: width,
  };
  state.callbacks.forEach(cb => cb(state.rect));
};

export default cb => {
  return {
    observe() {
      let wasEmpty = state.callbacks.length === 0;
      if (!state.callbacks.includes(cb)) {
        state.callbacks.push(cb);
      }
      if (wasEmpty) {
        window.addEventListener('resize', run);
      }
      if (!state.rect) {
        run();
      } else {
        cb(state.rect);
      }
    },

    unobserve() {
      if (state) {
        // Remove the callback
        const index = state.callbacks.indexOf(cb);
        if (index > -1) state.callbacks.splice(index, 1);

        // Remove the node reference
        if (!state.callbacks.length) {
          window.removeEventListener('resize', run);
        }
      }
    },
  };
};
