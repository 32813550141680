import { useRef, useLayoutEffect, useState } from 'react';
import throttle from 'lodash.throttle';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY, height: window.innerHeight }
    : { x: position.left, y: position.top, height: position.height };
}

export function useScrollPosition(
  effect = () => {},
  dependencies,
  element,
  useWindow,
  wait = 100,
) {
  const position = useRef(getScrollPosition({ useWindow }));

  useLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleScroll = throttle(() => {
      const currPos = getScrollPosition({ element, useWindow });
      effect({ prevPos: position.current, currPos });
      position.current = currPos;
    }, wait);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, dependencies);
}

export const useIsInView = (mode = 'any', offset = 100, throttleTime = 100) => {
  if (!isBrowser) {
    return [false, currentElement];
  }

  if (
    ['top', 'bottom', 'left', 'right', 'any', 'all'].indexOf(
      mode.toLowerCase(),
    ) === -1
  ) {
    return [false, currentElement];
  }

  const [isInview, setIsInview] = useState(false);
  const currentElement = useRef(null);

  const handleScroll = throttle(() => {
    if (!currentElement.current) {
      setIsInview(false);
      return;
    }
    const inViewport = isInViewport(currentElement.current, offset);
    setIsInview(inViewport[mode]);
  }, throttleTime);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentElement.current]);

  return [isInview, currentElement];
};

const isInViewport = (element, offset) => {
  const bounding = element.getBoundingClientRect();

  const inViewport = {};
  inViewport.top =
    bounding.top + offset >= 0 && bounding.top - offset <= window.innerHeight;
  inViewport.left = bounding.left >= 0;
  inViewport.bottom =
    bounding.bottom - offset <= window.innerHeight &&
    bounding.bottom + offset >= 0;
  inViewport.right = bounding.right <= window.innerWidth;
  inViewport.any =
    inViewport.top || inViewport.left || inViewport.bottom || inViewport.right;
  inViewport.all =
    inViewport.top && inViewport.left && inViewport.bottom && inViewport.right;

  return inViewport;
};
