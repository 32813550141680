import React, { Suspense, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import {
  withColumnContext,
  COLUMN_MAIN,
  COLUMN_MAINLEFT,
  COLUMN_MAINRIGHT,
} from 'components/molecules/PageGrid';
import {
  propertyValueFor,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import BlockWrapper from 'components/molecules/BlockWrapper';
import getTeaserForProduct from 'utils/getTeaserForProduct';
import { getTeaserConfigFromViewType } from 'components/organisms/TeaserList';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import classnames from 'classnames';
import * as styles from './TellusContentBlock.scss';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
// refactor out to own component
import Button from 'components/atoms/Button';

const TeaserList = React.lazy(() =>
  import(
    /* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'
  ),
);

const TellusContentBlock = ({
  productResponse,
  categoryList,
  columnContext,
  page,
  block,
}) => {
  const [filterProductTypes, setFilterProductTypes] = useState([]);
  const allProducts = useMemo(
    () =>
      !!productResponse &&
      !!productResponse.products &&
      productResponse.products.length
        ? productResponse.products
        : [],
    [productResponse],
  );
  const productTypeList = useMemo(
    () =>
      allProducts
        .reduce((list, item) => {
          const mainCategory = categoryList.length
            ? categoryList[item.categoryList[0]]
            : 0;
          if (!mainCategory || list.includes(mainCategory)) return list;
          return [...list, mainCategory];
        }, [])
        .slice(0, 3),
    [allProducts],
  );
  const items = useMemo(() => {
    const allItems = filterProductTypes.length
      ? categoryList.length
        ? allProducts.filter(item =>
            filterProductTypes.includes(categoryList[item.categoryList[0]]),
          )
        : allProducts
      : allProducts;
    return allItems.map(
      getTeaserForProduct(propertyValueFor(page.productPageUrl)),
    );
  }, [allProducts, filterProductTypes, page.productPageUrl]);
  const itemsKey = useMemo(() => {
    return items.map(item => item.id).join('-');
  }, [items]);

  const toggleFilterProductType = useCallback(
    productType =>
      setFilterProductTypes(arr => {
        return arr.includes(productType)
          ? arr.filter(i => i !== productType)
          : [...arr, productType];
      }),
    [],
  );

  // have to move this down here because hooks doesnt work after an if statement
  if (
    !productResponse ||
    !productResponse.products ||
    !productResponse.products.length
  ) {
    return null;
  }

  let color = null;
  let hasWhiteFont = false;
  const style = {};
  if (block.colorTheme && propertyValueFor(block.colorTheme)) {
    let parsedColorTheme = propertyValueFor(block.colorTheme);
    parsedColorTheme = JSON.parse(parsedColorTheme);
    color = parsedColorTheme.Color;
    hasWhiteFont = parsedColorTheme.IsWhiteFont;
  }
  if (propertyValueFor(block.colorViewType) === 'colouredBlockBackground') {
    style.backgroundColor = color;
    if (hasWhiteFont) style.color = '#fff';
  }
  const blockBackground =
    propertyValueFor(block.colorViewType) === 'colouredBlockBackground';

  const hasColorTextBg =
    !!color &&
    propertyValueFor(block.colorViewType) === 'colouredTextBackground';

  const teaserListConfig = getTeaserConfigFromViewType(
    propertyValueFor(block.viewType),
    propertyValueFor(block.displayAsNews),
    columnContext,
  );

  // enable "filter buttons" for filtering used on startpage
  // refactor into own component (molecule -> organism?)
  const showFilter =
    !!propertyValueFor(block.filterContent) && !!productTypeList.length;
  const slidesAsWide = propertyValueFor(block.slidesAsWide);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BlockWrapper
        block={block}
        columnContext={columnContext}
        style={style}
        className={classnames(styles.root, {
          [styles.blockBackground]: blockBackground,
          [styles.colorTheme]: color,
          [styles.wide]: teaserListConfig.slider && !teaserListConfig.compact,
        })}
      >
        {block.ingress && (
          <PropertyFor
            property={block.ingress}
            className={styles.introduction}
            tagName="p"
          />
        )}
        {showFilter && productTypeList.length > 1 && (
          <div className="filterWrapper">
            {productTypeList.map(productType => (
              <Button
                key={productType}
                className={classnames(styles.filterButton, {
                  [styles.filterButtonActive]: filterProductTypes.includes(
                    productType,
                  ),
                })}
                onClick={() => toggleFilterProductType(productType)}
              >
                <span className={styles.indicator} />
                <span>{productType}</span>
              </Button>
            ))}
          </div>
        )}
        <TeaserList
          config={teaserListConfig}
          showDate={propertyValueFor(block.displayAsNews)}
          items={items}
          columnCount={propertyValueFor(block.singleColumn) ? 1 : 2}
          noBorder
          colorTheme={{ color: hasColorTextBg ? color : null, hasWhiteFont }}
          showFavoriteButton={
            columnContext === COLUMN_MAIN ||
            columnContext === COLUMN_MAINLEFT ||
            columnContext === COLUMN_MAINRIGHT
          }
          wideContainer={slidesAsWide}
          key={itemsKey}
        />
      </BlockWrapper>
    </Suspense>
  );
};
TellusContentBlock.displayName = 'TellusContentBlock';
TellusContentBlock.propTypes = {
  productResponse: PropTypes.object,
  categoryList: PropTypes.object,
  columnContext: PropTypes.string,
  page: PropTypes.object,
  block: PropTypes.shape({
    readMoreAsButton: CustomPropTypes.EpiserverValue,
  }),
};
TellusContentBlock.defaultProps = {};

export default connect('selectPage', withColumnContext(TellusContentBlock));
