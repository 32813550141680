import { urlToString, parseUrl } from '../../utils/url';
import { getSizes } from './ImageSizes';

const loadedImages = {};

export function hasOtherSizeLoaded(url, size) {
  return loadedImages[url] && loadedImages[url].indexOf(size) === -1;
}

export function registerLoaded(url, size) {
  if (!loadedImages[url]) {
    loadedImages[url] = [];
  }
  if (loadedImages[url].indexOf(size) === -1) {
    loadedImages[url].push(size);
  }
}

export function getBestCachedImageUrl(urlString) {
  const url = parseUrl(urlString);
  const sizes = getSizes();
  const loadedSizes = loadedImages[urlString];
  const bestSize = loadedSizes.reduce(
    (bestSize, sizeName) =>
      bestSize < sizes[sizeName] ? sizes[sizeName] : bestSize,
    0,
  );
  url.query.w = bestSize;
  url.query.h = bestSize;
  url.query.mode = 'max';
  return urlToString(url);
}

export default (url, size, sourceSet) =>
  new Promise((resolve, reject) => {
    const image = document.createElement('img');
    const sizes = getSizes();
    image.src = url;
    if (sourceSet) {
      image.srcset = sourceSet;
    }
    image.style.visibility = 'hidden';
    image.style.position = 'absolute';
    image.style.top = '0';
    image.style.left = '-9999px';
    if (size) {
      image.sizes = sizes[size] + 'px';
    }
    image.onload = () => {
      registerLoaded(url, size);
      document.body.removeChild(image);
      resolve();
    };
    image.onerror = () => {
      document.body.removeChild(image);
      reject();
    };

    document.body.appendChild(image);
  });
