import React from 'react';
import PropTypes from 'prop-types';

export class Ref extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null
    };
  }
  setRef = ref => {
    this.setState({ ref });
  };
  render() {
    return this.props.children([this.state.ref, this.setRef]);
  }
}

Ref.displayName = "Ref";
Ref.propTypes = {
    children: PropTypes.func.isRequired
};
Ref.defaultProps = {};


export default Ref