import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import {
  format,
  startOfDay,
  isPast,
  isBefore,
  isWithinInterval,
} from 'date-fns/esm';
import useCalendar from 'hooks/use-calendar';
import useSiteSettings from 'hooks/use-site-settings';
import { translate } from 'utils/translate';
import { IconButton } from 'components/atoms/Button';
import { chevronLeft, chevronRight } from 'components/atoms/Icon/icons';

import * as styles from './Calendar.scss';

const Calendar = ({
  locale,
  children,
  startDate,
  onSelect,
  onMonthChange,
  weekStartsOn = 1,
  showWeekNumber = false,
  getLinkForDate = null,
  currentlySelectedDate,
  dateRange,
}) => {
  const calendarEntries = useMemo(
    () =>
      React.Children.toArray(children).reduce(
        (dates, child) => ({
          ...dates,
          [format(startOfDay(new Date(child.props.date)), 't')]: {
            title: child.props.title,
            className: child.props.className,
            disabled: child.props.disabled,
            unselectable: child.props.unselectable,
            selected: child.props.selected,
          },
        }),
        {},
      ),
    [children],
  );
  const {
    formatOptions,
    currentMonth,
    weeks,
    nextMonth,
    prevMonth,
  } = useCalendar(
    locale,
    weekStartsOn,
    startDate,
    dateRange,
    onMonthChange,
    calendarEntries,
  );
  const [hoveredDate, setHoveredDate] = useState(null);
  const { colorSettings } = useSiteSettings();
  const eventColor = colorSettings.productColors['event'];
  let style = {};
  if (eventColor) {
    style['--siteColor'] = `${eventColor}33`;
  }
  return (
    <div className={classnames(styles.calendar, styles.eventCardsDesign)}>
      <div className={styles.navigationRow}>
        <IconButton
          className={styles.prevMonthButton}
          iconAfter={chevronLeft}
          onClick={prevMonth}
          type="button"
          aria-label={translate('/calendar/previousMonth')}
          title={translate('/calendar/previousMonth')}
        />
        <span className={styles.currentMonth}>
          {format(currentMonth, 'MMMM yyyy', formatOptions)}
        </span>
        <IconButton
          className={styles.nextMonthButton}
          iconAfter={chevronRight}
          onClick={nextMonth}
          type="button"
          aria-label={translate('/calendar/nextMonth')}
          title={translate('/calendar/nextMonth')}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.weekDaysRow}>
          {!!showWeekNumber && <span className={styles.weekDay}>&nbsp;</span>}
          {weeks.length &&
            weeks[0].days.map((day, i) => (
              <span key={i} className={styles.weekDay}>
                {format(day.date, 'EEEEEE', formatOptions)}
              </span>
            ))}
        </div>
        {weeks.map(week => (
          <div key={week.weekNumber} className={styles.weekRow}>
            {!!showWeekNumber && (
              <strong className={styles.weekNumber}>{week.weekNumber}</strong>
            )}
            {week.days.map(day => {
              const link = getLinkForDate && getLinkForDate(day.date);
              const dayProps = {
                title: day.title || format(day.date, 'PPP', formatOptions),
                disabled:
                  (!day.isInCurrentMonth && isBefore(day.date, currentMonth)) ||
                  day.disabled ||
                  day.unselectable ||
                  (isPast(day.date) && !day.isToday) ||
                  (isBefore(day.date, currentlySelectedDate) && !day.isToday),

                className: classnames(styles.day, day.className, {
                  [styles.defaultColor]: !eventColor,
                  [styles.currentDay]: day.isToday,
                  [styles.passedDay]:
                    (isPast(day.date) && !day.isToday) ||
                    isBefore(day.date, currentlySelectedDate),
                  [styles.dayInDateRange]:
                    day.isInDateRange && day.selected !== false,
                  [styles.dayInCurrentSelectionRange]:
                    currentlySelectedDate &&
                    hoveredDate &&
                    isWithinInterval(day.date, {
                      start: currentlySelectedDate,
                      end: hoveredDate,
                    }),
                  [styles.dayNotInCurrentMonth]: !day.isInCurrentMonth,
                  [styles.dayBeforeCurrentMonth]:
                    !day.isInCurrentMonth && isBefore(day.date, currentMonth),
                  [styles.disabledDay]: day.disabled,
                  [styles.unselectableDay]: day.unselectable,
                  [styles.selectedDay]:
                    day.selected || (currentlySelectedDate && day.selected),
                }),
              };
              return !link || day.disabled ? (
                <button
                  type="button"
                  key={day.date}
                  onClick={() => !day.unselectable && onSelect(day.date)}
                  {...dayProps}
                  style={style}
                  onMouseEnter={() => {
                    currentlySelectedDate &&
                    isBefore(currentlySelectedDate, day.date)
                      ? setHoveredDate(day.date)
                      : setHoveredDate(null);
                  }}
                  onMouseLeave={() => {
                    currentlySelectedDate && setHoveredDate(null);
                  }}
                >
                  {format(day.date, 'd', formatOptions)}
                </button>
              ) : (
                <a href={link} {...dayProps}>
                  {format(day.date, 'd', formatOptions)}
                </a>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
Calendar.weekStartsOn = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export default Calendar;
