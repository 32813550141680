import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import Icon from 'components/atoms/Icon';
import { info } from 'components/atoms/Icon/icons';
import { newShade } from 'utils/hex';
import { iconLookup } from 'utils/iconLookUp';
import * as styles from './ButtonRowBlock.scss';

const RowButton = ({ colorTheme, icon, name, remainingUrl, itemLink }) => {
  // solve color Theme
  const style = {};
  if (colorTheme) {
    const parsedColorTheme = JSON.parse(colorTheme);
    if (parsedColorTheme.Color) {
      style['--backgroundColor'] = parsedColorTheme.Color;
      style['--hoverBackgroundColor'] = newShade(
        parsedColorTheme.Color,
        parsedColorTheme.IsWhiteFont ? -10 : 10,
      );
    }
    if (parsedColorTheme.IsWhiteFont) {
      style['--color'] = '#fff';
    }
  }

  return (
    <a href={(itemLink || '') + (remainingUrl || '')} className={styles.Button}>
      <span className={styles.ButtonIcon} style={style}>
        <Icon name={iconLookup[icon] || info.name} />
      </span>
      <span className={styles.ButtonText}>{name}</span>
    </a>
  );
};

const ButtonRowBlock = ({ block = {}, buttons }) => {
  return (
    <BlockWrapper block={block} className={styles.ButtonRowBlock}>
      <div className={styles.wrapper}>
        {propertyValueFor(buttons).map(buttonProps => (
          <RowButton {...buttonProps} key={buttonProps.name} />
        ))}
      </div>
    </BlockWrapper>
  );
};

ButtonRowBlock.displayName = 'ButtonRowBlock';
ButtonRowBlock.propTypes = {
  block: PropTypes.shape({}),
  buttons: PropTypes.shape({}),
};
ButtonRowBlock.defaultProps = {};

export default ButtonRowBlock;
