import getCoveragePercentage from './getCoveragePercentage';
const getOrderedZones = (zones, preferred) => {
  const cardinal = preferred[0];
  return [...zones].sort((a, b) => {
    if (b.name === preferred) return 1;
    if (a.name === preferred) return -1;
    let bCardinal = b.name[0];
    if (bCardinal === cardinal) return 1;
    return -1;
  });
};
export default (
  zones,
  elRect,
  preferred,
  arrowSize,
  usePreferredFallback = false,
) => {
  const fittedZones = zones.map(zone => ({
    ...zone,
    coverage: zone.isWithin
      ? getCoveragePercentage(zone, elRect, arrowSize)
      : 0,
  }));
  const orderedZones = getOrderedZones(fittedZones, preferred);
  const preferredZone = orderedZones.find(zone => zone.name === preferred);
  // use preferred if hasRoom
  if (preferredZone.coverage === 100) {
    return preferredZone;
  }
  // use other than preffered if any of them has room.. try the opposit first
  const oppositZone = orderedZones.find(zone => zone.name === preferredZone.o);
  if (oppositZone.coverage === 100) {
    return oppositZone;
  }

  // if any other can fit the whole el
  const filteredZones = orderedZones.filter(zone => zone.coverage === 100);
  if (filteredZones.length) {
    return filteredZones[0];
  }
  // use preferred if none has room ? or use the one with most room?
  if (usePreferredFallback) {
    return preferredZone;
  }
  const sortedZones = orderedZones
    .slice()
    .sort((a, b) => b.coverage - a.coverage);
  return sortedZones[0];
};
