import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import { arrowCircled } from 'components/atoms/Icon/icons';
import Button, { IconButton } from 'components/atoms/Button';
import { TitleXL } from 'components/atoms/Heading';
import { translate } from 'utils/translate';
import { BREAKPOINT_TABLET_LANDSCAPE } from 'utils/breakpoint';
import BreakpointContainer from 'containers/BreakpointContainer';
import Toggle from 'components/utilities/Toggle';
import FocusLock from 'components/utilities/FocusLock';
import Modal from 'components/utilities/Modal';
import NoBodyScroll from 'components/utilities/NoBodyScroll';

import RegionMap from './RegionMap';
import ButtonFilter from './ButtonFilter';

import * as styles from './RegionMenu2.scss';

export const VARIANT_DESKTOP = 'VARIANT_DESKTOP';
export const VARIANT_MOBILE = 'VARIANT_MOBILE';

// use name as key (url varies on environment)

const menuItemShape = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  intro: PropTypes.string,
  image: PropTypes.string,
  imageAltText: PropTypes.string,
});
const menuPageShapeObject = {
  name: PropTypes.string,
  intro: PropTypes.string,
  menuItems: PropTypes.arrayOf(menuItemShape),
};
menuPageShapeObject.menuSubPages = PropTypes.arrayOf(
  PropTypes.shape(menuPageShapeObject),
);
const menuPageShape = PropTypes.shape(menuPageShapeObject);

const sortMenuPages = menuPages => {
  const [info, ...regions] = menuPages;
  const flattenedPages = regions.reduce(
    (ret, { menuSubPages, ...menuPage }) => [
      ...ret,
      menuPage,
      ...menuSubPages.map(({ menuSubPages: _, ...subPage }) => ({
        ...subPage,
        parent: menuPage.name,
      })),
    ],
    [],
  );
  return { info, flattenedPages };
};

const RegionMenu2 = ({
  menuPages,
  innerRef,
  megaMenu,
  variant = VARIANT_DESKTOP,
}) => {
  const btnRef = useRef();
  const { info, flattenedPages } = useMemo(() => sortMenuPages(menuPages), [
    menuPages,
  ]);
  const [hoverPageName, setHoverPageName] = useState(null);
  const [selectedRegionName, setSelectedRegionName] = useState(null);
  const [selectedAreaName, setSelectedAreaName] = useState(null);

  const selectedRegion = useMemo(
    () => flattenedPages.find(page => page.name === selectedRegionName),
    [flattenedPages, selectedRegionName],
  );
  const selectedArea = useMemo(
    () => flattenedPages.find(page => page.name === selectedAreaName),
    [flattenedPages, selectedAreaName],
  );
  const hoverPage = useMemo(
    () => flattenedPages.find(page => page.name === hoverPageName),
    [flattenedPages, hoverPageName],
  );

  const regions = useMemo(() => flattenedPages.filter(page => !page.parent), [
    flattenedPages,
  ]);
  const regionAreas = useMemo(
    () => flattenedPages.filter(page => page.parent === selectedRegionName),
    [flattenedPages, selectedRegionName],
  );
  const mapPages = useMemo(() => flattenedPages.filter(page => !!page.url), [
    flattenedPages,
  ]);

  const select = useCallback(page => {
    if (page.parent) {
      setSelectedRegionName(page.parent);
      setSelectedAreaName(page.name);
    } else {
      setSelectedRegionName(page.name);
      setSelectedAreaName(null);
    }
  }, []);

  const selectedMenuPage = selectedArea || selectedRegion;
  const hasMenuItems = !!(
    selectedMenuPage &&
    selectedMenuPage.menuItems &&
    selectedMenuPage.menuItems.length
  );

  // scroll to header on change, ref function vs useEffect:
  // we don't use ref function because it would be called again when switching between desktop and mobile

  const pageContentRef = useRef();
  useEffect(() => {
    if (!!selectedMenuPage && !!pageContentRef.current && !!innerRef?.current) {
      // scroll to pageContentRef.current on desktop
      const top = pageContentRef.current.offsetTop - 40;
      if (innerRef.current.scrollTo) {
        innerRef.current.scrollTo({ top, behavior: 'smooth' });
      } else {
        // for older browsers
        innerRef.current.scrolltop = top; // eslint-disable-line
      }
    }
  }, [selectedMenuPage]);

  if (variant === VARIANT_MOBILE) {
    return (
      <div className={styles.mobileVariant}>
        <div className={styles.filters}>
          <ButtonFilter
            title={translate('/regionMenu/filters/regionTitle')}
            links={regions}
            onSelect={select}
            selected={selectedRegion}
          />
          {regionAreas.length ? (
            <ButtonFilter
              title={translate('/regionMenu/filters/areaTitle')}
              links={regionAreas}
              onHover={setHoverPageName}
              onSelect={select}
              selected={selectedArea}
            />
          ) : null}
        </div>
        {selectedMenuPage ? (
          <div className={styles.pageContent}>
            <div>
              <h2 className={styles.title}>{selectedMenuPage.name}</h2>
              {selectedMenuPage.intro ? <p>{selectedMenuPage.intro}</p> : null}
            </div>
            {selectedMenuPage.url ? (
              <div className={styles.link}>
                <Button
                  as="a"
                  full
                  href={selectedMenuPage.url}
                  className={styles.button}
                  textAlign="left"
                >
                  <span className={styles.inner}>
                    {translate('/regionMenu/visitOurWebsite')}
                    <Icon icon={arrowCircled} size={['36px', '36px']} />
                  </span>
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      ref={innerRef}
      className={classnames(
        styles.regionMenu2,
        megaMenu && styles.megaMenuOffset,
      )}
    >
      <NoBodyScroll />
      <Container className={styles.info}>
        <TitleXL as="h1" underline="#98C2AD">
          {info.name}
        </TitleXL>
        <p className={styles.description}>{info.intro}</p>
      </Container>
      <BreakpointContainer
        render={breakpoint => (
          <React.Fragment>
            {breakpoint <= BREAKPOINT_TABLET_LANDSCAPE && (
              <Container className={styles.tablet}>
                <div className={styles.filtersHorizontal}>
                  <ButtonFilter
                    title={translate('/regionMenu/filters/regionTitle')}
                    links={regions}
                    onSelect={select}
                    selected={selectedRegion}
                  />
                  {regionAreas.length ? (
                    <ButtonFilter
                      title={translate('/regionMenu/filters/areaTitle')}
                      links={regionAreas}
                      onHover={setHoverPageName}
                      onSelect={select}
                      selected={selectedArea}
                    />
                  ) : null}
                </div>
              </Container>
            )}
            <Container
              wide={breakpoint > BREAKPOINT_TABLET_LANDSCAPE}
              className={styles.wrap}
            >
              {breakpoint > BREAKPOINT_TABLET_LANDSCAPE && (
                <div className={styles.aside}>
                  <div className={styles.filters}>
                    <ButtonFilter
                      title={translate('/regionMenu/filters/regionTitle')}
                      links={regions}
                      onSelect={select}
                      selected={selectedRegion}
                    />
                    {regionAreas.length ? (
                      <ButtonFilter
                        title={translate('/regionMenu/filters/areaTitle')}
                        links={regionAreas}
                        onHover={setHoverPageName}
                        onSelect={select}
                        selected={selectedArea}
                      />
                    ) : null}
                  </div>
                </div>
              )}
              <div className={styles.main}>
                <RegionMap
                  pages={mapPages}
                  highlightedPages={regionAreas || []}
                  selectedPage={selectedMenuPage}
                  hoverPage={hoverPage}
                  onSelect={select}
                  onHover={setHoverPageName}
                />
                {selectedMenuPage ? (
                  <div
                    className={styles.pageContent}
                    key={selectedMenuPage.name}
                    ref={pageContentRef}
                  >
                    <div className={styles.heading}>
                      <h2>{selectedMenuPage.name}</h2>
                      <p>{selectedMenuPage.intro}</p>
                    </div>
                    {selectedMenuPage.url ? (
                      <div className={styles.link}>
                        <Button
                          as="a"
                          full
                          href={selectedMenuPage.url}
                          className={styles.button}
                          textAlign="left"
                        >
                          <span className={styles.inner}>
                            {translate('/regionMenu/visitOurWebsite')}
                            <Icon icon={arrowCircled} size={['36px', '36px']} />
                          </span>
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {hasMenuItems ? (
                  <>
                    <div className={styles.separator}></div>
                    <div className={styles.articleList}>
                      {selectedMenuPage.menuItems.map(menuItem => (
                        <a
                          href={menuItem.url}
                          key={menuItem.name}
                          className={styles.article}
                        >
                          <Image
                            src={menuItem.image}
                            className={styles.img}
                            ratio={[16, 9]}
                            fit="cover"
                            lazy={false}
                          />
                          <div className={styles.text}>
                            <strong className={styles.name}>
                              {menuItem.name}
                            </strong>
                            <span className={styles.brandText}>
                              Visit {selectedMenuPage.name}{' '}
                              <Icon
                                icon={arrowCircled}
                                size={['18px', '18px']}
                              />
                            </span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </Container>
          </React.Fragment>
        )}
      />
    </div>
  );
};

RegionMenu2.displayName = 'RegionMenu2';
RegionMenu2.propTypes = {
  innerRef: PropTypes.any,
  menuPages: PropTypes.arrayOf(menuPageShape),
};
RegionMenu2.defaultProps = {
  menuPages: [],
};

export default RegionMenu2;
