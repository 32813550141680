import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import { spinner } from 'components/atoms/Icon/icons';
import * as styles from './LoadingIndicator.scss';

const styleFixed = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000,
};
const styleCentered = {
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
  alignContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const LoadingIndicator = ({
  text,
  spin = true,
  center = true,
  fixed = true,
  background = ''
}) => (
  <div
    style={{
      ...(!!fixed && styleFixed),
      ...(!!center && styleCentered),
      background
    }}
  >
    <div>
      <Icon icon={spinner} spin={spin} className={styles.icon} />
      {!!text && <Paragraph>{text}</Paragraph>}
    </div>
  </div>
);
LoadingIndicator.displayName = 'LoadingIndicator';
LoadingIndicator.propTypes = {};
LoadingIndicator.defaultProps = {};

export default LoadingIndicator;
