import { IS_BROWSER } from 'utils/environment';

// get the current scroll position that is defined as "active"
export const getCurrentScrollPosition = offset => {
  if (!IS_BROWSER) {
    return offset;
  }
  const scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;
  return scrollTop + Math.floor(window.innerHeight * offset);
};
// is the scrollPosition within the body of the element?
export const isElementActive = (el, scrollPosition) => {
  if (!IS_BROWSER || !el) return false;
  const elTop = el.offsetTop;
  const elBottom = elTop + el.offsetHeight;
  return elTop <= scrollPosition && elBottom > scrollPosition;
};

// get all elements that are active
export const getActiveElements = (elementObjects, offset) => {
  // get the current scroll
  const scrollPosition = getCurrentScrollPosition(offset);
  // filter out
  return elementObjects.filter(obj =>
    isElementActive(obj.getElement(), scrollPosition),
  );
};
