const items = {};
export const get = (key, ttl = 30000) => {
  return has(key, ttl) ? items[key].value : null;
};
export const set = (key, value) => {
  items[key] = { added: Date.now(), value };
  return value;
};
export const has = (key, ttl = false) => {
  return key in items && (!ttl || items[key].added + ttl >= Date.now());
};
