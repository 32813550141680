import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import { BREAKPOINT_TABLET_LANDSCAPE } from 'utils/breakpoint';

import BreakpointContainer from 'containers/BreakpointContainer';

import * as styles from './ImageRow.scss';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import BlockWrapper from 'components/molecules/BlockWrapper';

const ImageWrapper = ({ href, className, children }) => {
  return href ? (
    <Link href={href} isBlock className={className}>
      <figure>{children}</figure>
    </Link>
  ) : (
    <figure className={className}>{children}</figure>
  );
};

const ImageRow = ({ images, emphaziseBlock, fullWidth, block }) => {
  const imgs = images.map(
    img =>
      img.url && {
        src: img.url,
        alt: img.description,
        description: img.description,
        copy: img.credits,
        showCaption: true,
        href: img.link,
      },
  );
  const scaleImages = propertyValueFor(block.scaleWide);
  return (
    <div
      className={classnames(styles.imageRow, {
        [styles.oneImage]: imgs.length === 1,
        [styles.twoImages]: imgs.length === 2,
        [styles.emphazised]: emphaziseBlock,
        [styles.fullwidth]: fullWidth,
        [styles.threeImages]: imgs.length === 3,
        [styles.fourImages]: imgs.length === 4,
        [styles.scaleImages]: scaleImages,
      })}
    >
      <BreakpointContainer
        render={breakpoint => {
          if (breakpoint > BREAKPOINT_TABLET_LANDSCAPE) {
            return (
              <div className={styles.container}>
                {imgs.map(img => (
                  <ImageWrapper
                    key={img.src}
                    href={img.href}
                    className={styles.imageItem}
                  >
                    <Image
                      ratio={imgs.length === 1 ? [] : [1, 1]}
                      fit={imgs.length === 1 ? 'none' : 'contain'}
                      className={styles.image}
                      {...img}
                    />
                    {!!img.description && (
                      <figcaption className={styles.caption}>
                        <span>{img.description}</span>
                      </figcaption>
                    )}
                  </ImageWrapper>
                ))}
              </div>
            );
          }
          if (imgs.length === (3 || 4) && fullWidth) {
            const img = imgs[0];
            return (
              <div className={styles.container}>
                <div className={styles.innerContainer}>
                  <ImageWrapper href={img.href} className={styles.imageItem}>
                    <Image className={styles.image} {...img} />
                    {!!img.description && (
                      <figcaption className={styles.caption}>
                        <span>{img.description}</span>
                      </figcaption>
                    )}
                  </ImageWrapper>
                </div>
                <div className={styles.innerContainer}>
                  {imgs.map((image, i) => {
                    if (i > 0)
                      return (
                        <ImageWrapper
                          href={image.href}
                          className={styles.imageItem}
                          key={image.src}
                        >
                          <Image
                            className={styles.image}
                            {...image}
                            ratio={[1, 1]}
                            fit="cover"
                          />
                          {!!image.description && (
                            <figcaption className={styles.caption}>
                              <span>{image.description}</span>
                            </figcaption>
                          )}
                        </ImageWrapper>
                      );

                    return null;
                  })}
                </div>
              </div>
            );
          }
          if (imgs.length !== 1)
            return (
              <div className={styles.container}>
                {imgs.map(img => (
                  <ImageWrapper
                    href={img.href}
                    figure
                    key={img.src}
                    className={styles.imageItem}
                  >
                    <Image
                      className={styles.image}
                      {...img}
                      ratio={[1, 1]}
                      fit="contain"
                    />
                    {!!img.description && (
                      <figcaption className={styles.caption}>
                        <span>{img.description}</span>
                      </figcaption>
                    )}
                  </ImageWrapper>
                ))}
              </div>
            );
          return (
            <div className={styles.container}>
              {imgs.map(img => (
                <ImageWrapper
                  href={img.href}
                  key={img.src}
                  className={styles.imageItem}
                >
                  <Image className={styles.image} {...img} />
                  {!!img.description && (
                    <figcaption className={styles.caption}>
                      <span>{img.description}</span>
                    </figcaption>
                  )}
                </ImageWrapper>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

ImageRow.displayName = 'ImageRow';
ImageRow.propTypes = {
  images: PropTypes.any,
  emphaziseBlock: PropTypes.any,
  fullWidth: PropTypes.any,
  block: PropTypes.shape({
    titleText: PropTypes.any,
    ingress: PropTypes.any,
  }),
};
ImageRow.defaultProps = {};

export default ImageRow;
