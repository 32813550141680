import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const useIsSticky = ref => {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    setIsSticky(window.scrollY >= ref.current.offsetTop);
    const handler = () => {
      setIsSticky(window.scrollY >= ref.current.offsetTop);
    };
    window.addEventListener('scroll', handler, false);
    return () => {
      window.removeEventListener('scroll', handler, false);
    };
  }, []);
  return isSticky;
};

const Sticky = ({
  children,
  placeholderClassName,
  outerClassName,
  innerClassName,
  stickyClassName,
}) => {
  const wrapperRef = useRef();
  const isSticky = useIsSticky(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      className={classnames(outerClassName, { [stickyClassName]: isSticky })}
    >
      {isSticky && (
        <div style={{ visibility: 'hidden' }} className={placeholderClassName}>
          {children}
        </div>
      )}
      <div className={innerClassName}>{children}</div>
    </div>
  );
};
Sticky.displayName = 'Sticky';
Sticky.propTypes = {
  children: PropTypes.node,
  placeholderClassName: PropTypes.string,
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  stickyClassName: PropTypes.string,
};
Sticky.defaultProps = {};

export default Sticky;
