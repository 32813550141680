import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Base from 'components/atoms/Base';
import { isString } from 'utils/bundle-helper';
import * as styles from './Block.scss';

const ratioStyles = {
  '1:1': [1, 1],
  '3:2': [3, 2],
  '4:3': [4, 3],
  '5:3': [5, 3],
  '6:3': [6, 3],
  '16:9': [16, 9],
  '12:5': [12, 5],
  '2:1': [2, 1],
  '28:11': [28, 11],
  '12:3': [12,3],
};

export const getRatioStyle = (ratio = null) => {
  const [x, y] = isString(ratio) ? ratioStyles[ratio] || [] : ratio;
  return x && y
    ? {
        paddingTop: `${(100 / x) * y}%`,
      }
    : null;
};

export const ratioPropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.oneOf(Object.keys(ratioStyles)),
]);

const Block = ({
  as = 'div',
  ratio = [0, 0],
  contain = false,
  children,
  className,
  ...rest
}) => {
  const ratioStyle = useMemo(() => getRatioStyle(ratio), [ratio]);

  return (
    <Base
      as={as}
      className={classnames(className, styles.block, contain && styles.contain)}
      {...rest}
    >
      {!!ratioStyle && <span className={styles.ratioBase} style={ratioStyle} />}
      {children}
    </Base>
  );
};
Block.displayName = 'Block';
Block.propTypes = {
  ...Base.propTypes,
  contain: PropTypes.bool,
  ratio: ratioPropType,
};
Block.defaultProps = {
  contain: false,
};
Block.ratios = ratioStyles;

export default Block;
