import { useEffect, useRef, useMemo } from "react";
import ResizeObserver from "resize-observer-polyfill";

export const useResizeObserver = (ref, callback, active = true) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        //console.log("resized");
        callbackRef.current(entries[0].contentRect);
      }),
    []
  );

  useEffect(() => {
    if (active && ref.current) {
      const el = ref.current;
      resizeObserver.observe(el);

      return () => {
        resizeObserver.unobserve(el);
      };
    }
  }, [ref, active, resizeObserver]);
};
