import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import getLocale from 'utils/getLocale';
import Button from 'components/atoms/Button/Button';
import Calendar from 'components/utilities/Calendar';
import CalendarEntry from 'components/utilities/Calendar/CalendarEntry';
import { translate } from 'utils/translate';
import {
  addDays,
  startOfWeek,
  endOfWeek,
  isAfter,
  parseISO,
  addWeeks,
} from 'date-fns/esm';
import { getFormattedDate } from 'utils/dateTime';
import { appendQueryParams } from 'utils/bundle-helper';
import * as constants from 'utils/constants';

import * as styles from './EventListDateFilter.scss';

const CalendarSelector = ({
  initialFrom,
  initialTo,
  getHref,
  url,
  getIsSelected,
  onSetDatesBtnClick,
  urlFromDate,
  urlToDate,
}) => {
  const locale = getLocale();
  const [fromDate, setFromDate] = useState(initialFrom);
  const [toDate, setToDate] = useState(initialTo);
  const [isSelectingDate, setIsSelectingDate] = useState(false);
  const [dateRange, setDateRange] = useState(() => ({
    from: fromDate,
    to: toDate,
  }));

  useEffect(() => {
    if (isAfter(fromDate, toDate)) {
      setFromDate(toDate);
    }
  }, [toDate]);

  useEffect(() => {
    if (isAfter(fromDate, toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate]);

  useEffect(() => {
    setFromDate(initialFrom);
    setToDate(initialTo);
  }, [initialFrom, initialTo]);

  useEffect(() => {
    setDateRange({ from: fromDate, to: toDate });
  }, [fromDate, toDate]);

  useEffect(() => {
    setIsSelectingDate(false);
  }, [urlFromDate, urlToDate]);

  const customDatesInUrl = getIsSelected(fromDate, toDate);

  const handleDateSelect = value => {
    if (!isSelectingDate) {
      setFromDate(value);
      setIsSelectingDate(true);
    } else {
      setToDate(value);
      setIsSelectingDate(false);
    }
  };

  // Enables correct setting of single dates with one click, and date ranges with two clicks
  const setSingleDate =
    isSelectingDate &&
    (dateRange.from !== initialFrom || dateRange.to !== initialTo);

  const constructResetUrl = () => {
    const queryParams = new URLSearchParams(url.query);

    // Remove the date params
    queryParams.delete('FromDate');
    queryParams.delete('ToDate');

    // Construct a new URL
    return `${url.path}?${queryParams}`;
  };

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        startDate={new Date(fromDate)}
        locale={locale}
        isEventListPage
        onSelect={handleDateSelect}
        dateRange={!isSelectingDate && dateRange}
        currentlySelectedDate={isSelectingDate && fromDate}
      >
        <CalendarEntry
          date={new Date(!!dateRange ? dateRange.from : fromDate)}
          selected={
            customDatesInUrl ||
            dateRange.from !== initialFrom ||
            (!!dateRange && isSelectingDate)
          }
        />
        <CalendarEntry
          date={
            new Date(!isSelectingDate && (!!dateRange ? dateRange.to : toDate))
          }
          selected={
            customDatesInUrl ||
            dateRange.to !== initialTo ||
            (!!dateRange && isSelectingDate)
          }
        />
      </Calendar>
      <Button
        className={classnames(styles.searchByDateButton, {
          [styles.active]: fromDate !== initialFrom || toDate !== initialTo,
        })}
        palette="secondary"
        onClick={onSetDatesBtnClick}
        href={setSingleDate ? getHref(fromDate) : getHref(fromDate, toDate)}
        disabled={fromDate === initialFrom && toDate === initialTo}
      >
        {translate('/pages/eventListPage/useSelectedDates')}
      </Button>
      <Button
        className={classnames(styles.resetCalendarButton, {
          [styles.active]: !!url.query.FromDate,
        })}
        palette="secondary"
        href={constructResetUrl()}
        onClick={onSetDatesBtnClick}
        disabled={!url.query.FromDate}
      >
        {translate('/pages/eventListPage/resetCalendar')}
      </Button>
    </div>
  );
};

const getHref = (url, fromDate, toDate) =>
  appendQueryParams(url, {
    [constants.get('fromDate')]: getFormattedDate(fromDate, 'yyyy-MM-dd'),
    [constants.get('toDate')]: getFormattedDate(
      toDate || fromDate,
      'yyyy-MM-dd',
    ),
  }).href;

const getIsSelected = (urlFromDate, urlToDate, fromDate, toDate) => {
  let isSelected = false;
  if (
    getFormattedDate(fromDate, 'yyyy-MM-dd') === urlFromDate &&
    getFormattedDate(toDate || fromDate, 'yyyy-MM-dd') === urlToDate
  ) {
    isSelected = true;
  }
  return isSelected;
};

const EventListDateFilter = React.memo(
  ({ url, initialNumberOfDays = 2, compact = false, onSetDatesBtnClick }) => {

    const now = new Date();
    const nextWeek = addWeeks(now, 1);
    const urlFromDate = url.query[constants.get('fromDate')];
    const urlToDate = url.query[constants.get('toDate')];
    const initialFromDate = (urlFromDate && parseISO(urlFromDate)) || now;

    let numberOfDays = parseInt(initialNumberOfDays, 10); // don't count today
    if (numberOfDays < 0) {
      numberOfDays = 0;
    }
    const initialToDate =
      (urlToDate && parseISO(urlToDate)) ||
      addDays(initialFromDate, numberOfDays);

    return (
      <div
        className={classnames(
          compact && styles.compact,
          styles.eventCardsDesign,
        )}
      >
        <div className={styles.shortcutButtons}>
          <Button
            className={classnames(styles.shortcut, {
              [styles.selected]: getIsSelected(urlFromDate, urlToDate, now),
            })}
            palette="secondary"
            href={getHref(url, now)}
            onClick={onSetDatesBtnClick}
            full={compact}
            data-no-scrolltop
          >
            {translate('/pages/eventListPage/today')}
          </Button>
          <Button
            className={classnames(styles.shortcut, {
              [styles.selected]: getIsSelected(
                urlFromDate,
                urlToDate,
                addDays(now, 1),
              ),
            })}
            palette="secondary"
            href={getHref(url, addDays(now, 1))}
            onClick={onSetDatesBtnClick}
            full={compact}
            data-no-scrolltop
          >
            {translate('/pages/eventListPage/tomorrow')}
          </Button>
          <Button
            className={classnames(styles.shortcut, {
              [styles.selected]: getIsSelected(
                urlFromDate,
                urlToDate,
                startOfWeek(nextWeek, { weekStartsOn: 1 }),
                endOfWeek(nextWeek, { weekStartsOn: 1 }),
              ),
            })}
            palette="secondary"
            href={getHref(
              url,
              startOfWeek(nextWeek, { weekStartsOn: 1 }),
              endOfWeek(nextWeek, { weekStartsOn: 1 }),
            )}
            onClick={onSetDatesBtnClick}
            full={compact}
            data-no-scrolltop
          >
            {translate('/pages/eventListPage/nextWeek')}
          </Button>
        </div>
        <CalendarSelector
          getHref={(from, to) => getHref(url, from, to)}
          url={url}
          initialFrom={new Date(initialFromDate)}
          initialTo={new Date(initialToDate)}
          urlFromDate={urlFromDate}
          urlToDate={urlToDate}
          getIsSelected={(from, to) =>
            getIsSelected(urlFromDate, urlToDate, from, to)
          }
          onSetDatesBtnClick={onSetDatesBtnClick}
        />
      </div>
    );
  },
);

EventListDateFilter.displayName = 'EventListFilter';
EventListDateFilter.propTypes = {
  onSetDatesBtnClick: PropTypes.func,
};
EventListDateFilter.defaultProps = {};

export default EventListDateFilter;
