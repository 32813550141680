import {useEffect} from 'react';

let instanceCount = 0;


export const useNoBodyScroll = () => {
    useEffect(() => {
        const htmlElement = !!document && document.querySelector('html');
        if(htmlElement) {
            if(instanceCount === 0) {
                instanceCount++;
                htmlElement.style.overflow = 'hidden';
            }
            return () => {
                instanceCount--;
                if(instanceCount < 1) {
                    htmlElement.style.overflow = 'auto';
                }
            }
        }
    }, []);
}