import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import MediaSlider from 'components/molecules/MediaSlider';
import * as styles from './SliderBlock.scss';

const SliderBlock = ({ block }) => {
  const slides = propertyValueFor(block.sliderImages);
  if (slides && slides.length) {
    return (
      <BlockWrapper block={block} className={styles.SliderBlock}>
        <MediaSlider
          autoPlay={4000}
          slides={slides}
          ratio={[2, 1]}
          inlineContent
          buttonsOutside
        />
      </BlockWrapper>
    );
  }
  return null;
};

SliderBlock.displayName = 'SliderBlock';
SliderBlock.propTypes = {};
SliderBlock.defaultProps = {};

export default SliderBlock;
