import React from 'react';
import PropTypes from 'prop-types';

import Heading, { H2 } from 'components/atoms/Heading';
import TeaserList, { teaserConfig } from 'components/organisms/TeaserList';
import getProductUrl from 'utils/getProductUrl';
import { translate } from 'utils/translate';
import useSiteSettings from 'hooks/use-site-settings';

import * as styles from './RelatedProducts.scss';

const RelatedProducts = ({ products, title }) => {
  const { productPageLink } = useSiteSettings();
  return (
    <div className={styles.root}>
      <H2 size={Heading.sizes.heading2} gutterBottom>
        {title || translate(`/product/relatedProductsTitle/seeAlso`)}
      </H2>
      <TeaserList
        config={teaserConfig.imageTitleAndIntro}
        columnCount={3}
        items={products.map(p => ({
          ...p,
          url:
            (p.hasOwnProperty('productType') && p.productType !== '') ||
            p.myFavoritesId.startsWith('tlp')
              ? getProductUrl(p.url, productPageLink)
              : p.url,
        }))}
        showFavoriteButton
      />
    </div>
  );
};
RelatedProducts.displayName = 'RelatedProducts';
RelatedProducts.propTypes = {};
RelatedProducts.defaultProps = {};

export default RelatedProducts;
