import React from 'react';
import PropTypes from 'prop-types';

const API_URL = 'https://integrator.simpleviewinc.no/import/TIcketSystemWrapper2/';

class TicketAvailabilityFetcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      ticketData: {},
      error: null,
    };
  }

  getUrl = () => {
    const { products, channel } = this.props;
    const productKeyList = products.map(product => product.key).join(',');
    return `${API_URL}?productkeylist=${productKeyList}&channel=${channel}`;
  };

  componentDidUpdate(prevProps) {
    if (this.props.products !== prevProps.products) {
      // If the products have changed, fetch new ticket data
      this.fetchTicketData();
    }
  }

  fetchTicketData() {
    // do the fetching
    const lastFetch = (this.lastFetch = Date.now());
    const bookableProducts = this.props.products.filter(
      product => !!product.eventDate,
    );
    if (bookableProducts.length) {
      this.setState({ isLoading: true });
      fetch(this.getUrl())
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong ...');
        })
        .then(data => {
          if (lastFetch === this.lastFetch) {
            const ticketData = bookableProducts.reduce(
              (ticketData, product, i) => {
                return {
                  ...ticketData,
                  // We used product-id here, to map products to ticket-data, but each product will always have the same id as the event it
                  // is connected to, so this will just be overridden in the object.
                  // Use key instead, since products and ticket-data will always be in the same order.
                  [i]: data.events[i],
                };
              },
              {},
            );
            this.setState({ ticketData, error: null, isLoading: false });
          }
        })
        .catch(error =>
          this.setState({ ticketData: {}, error, isLoading: false }),
        );
    } else {
      this.setState({ ticketData: {}, error: null, isLoading: false });
    }
  }

  componentDidMount() {
    this.fetchTicketData();
  }
  // expand with static getDerivedStateFromProps or similar for fetching new data based on new props

  render() {
    // expand with actions like reload or similar if needed
    return this.props.children({ ...this.state });
  }
}
TicketAvailabilityFetcher.displayName = 'TicketAvailabilityFetcher';
TicketAvailabilityFetcher.propTypes = {
  products: PropTypes.array,
  channel: PropTypes.string.isRequired,
};
TicketAvailabilityFetcher.defaultProps = {};

export default TicketAvailabilityFetcher;
