import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CustomPropTypes } from 'packages/inmeta-epi-react/utils/epi-react';

import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import Button, { LinkButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { minus, plus } from 'components/atoms/Icon/icons';
import * as styles from './Accordion.scss';

const AccordionItem = ({ heading, body }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <dt className={styles.itemHeading}>
        <LinkButton transparent onClick={() => setOpen(!open)}>
          {heading}
          <Icon
            role="presentation"
            icon={open ? minus : plus}
            className={styles.itemHeadingIcon}
          />
        </LinkButton>
      </dt>
      <dd className={classnames(styles.itemBody, { [styles.open]: open })}>
        <PropertyFor property={body} />
      </dd>
    </React.Fragment>
  );
};

AccordionItem.propTypes = {
  heading: PropTypes.string,
  body: CustomPropTypes.EpiserverValue,
};

export const Accordion = ({ items }) => (
  <dl className={styles.root}>
    {items.map((item, i) => (
      <AccordionItem key={item.title + i.toString()} {...item} />
    ))}
  </dl>
);

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      body: CustomPropTypes.EpiserverValue,
    }),
  ),
};
Accordion.defaultProps = {};

export default Accordion;
