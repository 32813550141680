import React from 'react';
import { connect } from 'redux-bundler-react';
import Footer from 'components/organisms/Footer';
import ForcePropertyFor from 'packages/inmeta-epi-react/components/ForcePropertyFor';

export default connect(
  'selectFooterSettings',
  ({ footerSettings }) => (
    <Footer
      linkBlocks={
        (footerSettings.footerLinkBlocks &&
          footerSettings.footerLinkBlocks.items) ||
        []
      }
      socialHeading={footerSettings.footerSocialHeading}
      socialBlocks={
        (footerSettings.footerSocialBlocks &&
          footerSettings.footerSocialBlocks.items) ||
        []
      }
      rightContentHeading={footerSettings.footerRightTextHeading}
      rightContent={<ForcePropertyFor $c={footerSettings.footerRightText} />}
      showTouristIcon={
        !footerSettings.hideTouristIcon ||
        footerSettings.hideTouristIcon !== 'enable'
      }
      footerBottomImage={
        footerSettings.footerBottomImage
          ? footerSettings.footerBottomImage
          : null
      }
      footerLeftImage={
        footerSettings.footerLeftImage
          ? footerSettings.footerLeftImage
          : null
      }
    />
  ),
);
