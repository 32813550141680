import React from 'react';
import { connect } from 'redux-bundler-react';
import Container from 'components/atoms/Container';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';

export default connect(
  'selectPageData',
  'selectBreakpoint',
  ({ pageData, breakpoint }) =>
    pageData.breadCrumbs && breakpoint > BREAKPOINT_PHONE ? (
      <Container>
        <Breadcrumbs crumbs={pageData.breadCrumbs} />
      </Container>
    ) : null,
);
