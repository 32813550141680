import { createSelector } from 'redux-bundler';
import dlv from 'dlv';
import { on } from 'packages/inmeta-epi-react/GlobalEvents';
import * as cache from '../utils/page-cache';
import { parseUrl, urlToString, cleanPath } from '../utils/bundle-helper';
import { EVENT_POP, EVENT_REPLACE, EVENT_LOAD } from './create-url-bundle';
import navHelper from '../utils/internal-nav-helper';

export const PAGE_FETCH_START = 'PAGE_FETCH_START';
export const PAGE_FETCH_ERROR = 'PAGE_FETCH_ERROR';
export const PAGE_FETCH_SUCCESS = 'PAGE_FETCH_SUCCESS';
export const PAGE_FETCH_SLOW = 'PAGE_FETCH_SLOW';
export const PAGE_CACHE_HIT = 'PAGE_CACHE_HIT';

export const createPageDataFromModel = model => {
  const url = parseUrl(model.url, EVENT_LOAD);
  const data = {
    ...model,
    url,
  };
  const page = {
    isLoading: false,
    isLoadingSlow: false,
    lastFetched: Date.now(),
    data,
    url,
  };
  cache.set(page.url.href, data);
  return page;
};

const defaults = {
  enableCache: true,
  cache: 1200000, // cache for 2 minutes
  slow: 300, // mark as slow after 300ms
};

const componentNameCacheBlackList = ['pages/MyPage'];
const isBlackListed = componentName =>
  componentNameCacheBlackList.indexOf(componentName) > -1;

// const menuMapFn = currentPath => item => ({
//   ...item,
//   isCurrent: !!item.url && startsWith(currentPath, item.url),
//   children: item.children.map(menuMapFn(currentPath)),
// });

export default opts => {
  const config = Object.assign({}, defaults, opts);
  let lastFetch = Date.now();

  // selectors
  const selectPageRaw = state => state.page;
  const selectPageData = createSelector(
    selectPageRaw,
    page => page.data,
  );
  const selectPage = createSelector(
    selectPageData,
    data => data.currentPage,
  );
  const selectPageUrl = createSelector(
    selectPageData,
    data => data.url,
  );
  const selectRelatedProducts = createSelector(
    selectPageData,
    data => data.relatedProducts,
  );
  const selectPageComponent = createSelector(
    selectPageData,
    data => data.Component,
  );
  const selectIsPageLoading = createSelector(
    selectPageRaw,
    page => page.isLoading,
  );
  const selectIsPageLoadingSlow = createSelector(
    selectPageRaw,
    page => page.isLoadingSlow,
  );
  const selectMeta = createSelector(
    selectPageData,
    data => data.meta,
  );
  const selectEventResponse = createSelector(
    selectPageData,
    data =>
      data.eventResponse || {
        events: [],
        count: 0,
      },
  );

  // actions
  // TODO: handle url.cosmetic
  const doFetchPage = (url, forceReload = false) => ({
    store,
    dispatch,
    pageFetch,
  }) => {
    //
    const cachedData =
      config.enableCache &&
      !forceReload &&
      config.cache &&
      cache.get(urlToString(url), url.event !== EVENT_POP && config.cache);
    let timeout;
    let completed = false;

    if (cachedData && !isBlackListed(cachedData.componentName)) {
      dispatch({ type: PAGE_CACHE_HIT, url, page: cachedData });
    } else if (url.cosmetic) {
      dispatch({
        type: PAGE_FETCH_SUCCESS,
        url,
        page: {
          ...store.selectPageData(),
          url,
        },
      });
    } else {
      // eslint-disable-next-line no-multi-assign
      const timestamp = (lastFetch = Date.now());
      dispatch({ type: PAGE_FETCH_START, url });
      timeout = setTimeout(() => {
        if (timestamp === lastFetch && !completed) {
          dispatch({ type: PAGE_FETCH_SLOW });
        }
      }, config.slow);

      pageFetch(urlToString(url, true))
        .then(response => {
          const data = { ...response, url };
          completed = true;
          clearTimeout(timeout);
          if (config.cache && !isBlackListed(data.componentName)) {
            cache.set(urlToString(url), data);
          }
          if (timestamp === lastFetch) {
            dispatch({ type: PAGE_FETCH_SUCCESS, url, page: data });
          }
        })
        .catch(error => {
          dispatch({ type: PAGE_FETCH_ERROR, payload: { url, error } });
        });
    }
  };
  const doSetPage = (url, page) => ({
    type: PAGE_FETCH_SUCCESS,
    url,
    page,
  });
  const doReplacePage = url => ({ store, dispatch }) => {
    const page = store.selectPageData();
    page.url = url;
    dispatch({
      type: PAGE_FETCH_SUCCESS,
      page: cache.set(url.href, page),
      url,
    });
  };
  const doReloadPage = () => ({ store }) => {
    const url = store.selectPageUrl();
    store.doFetchPage(url, true);
  };
  const doGoToStartpage = () => ({ store }) => {
    const startPages = dlv(
      window,
      'INITIAL_DATA.siteSettings.siteStartpages',
      {},
    );
    const currentPath = dlv(store.selectPageData(), 'url.path');
    // find the current startPage
    const currentStartPagePath = getStartpagePathForUrl(
      currentPath,
      startPages,
    );
    if (currentPath === currentStartPagePath) {
      store.doReloadPage();
    } else {
      store.doUpdateUrl(currentStartPagePath);
    }
  };

  return {
    // the name becomes the reducer name in the resulting state
    name: 'page',
    // optional init method is ran after store is created and passed the store object.
    init: store => {
      if (!dlv(window, 'INITIAL_DATA.model.pageIsInEditMode')) {
        on('click', navHelper(handleInternalNav(store), isInternalNav(store)));
      }
    },
    // the Redux reducer function
    getReducer: () => (
      state = {
        isLoading: false,
        isLoadingSlow: false,
        lastFetched: Date.now(),
        data: {},
        url: parseUrl('/'),
      },
      action,
    ) => {
      if (action.type === PAGE_FETCH_START) {
        return {
          ...state,
          url: action.url,
          isLoading: true,
          isLoadingSlow: false,
        };
      }
      if (action.type === PAGE_FETCH_SLOW) {
        return {
          ...state,
          isLoadingSlow: true,
        };
      }
      if (action.type === PAGE_FETCH_SUCCESS) {
        return {
          url: action.url,
          data: action.page,
          lastFetched: Date.now(),
          isLoading: false,
          isLoadingSlow: false,
        };
      }

      // a cache hit differs from a PAGE_FETCH_SUCCESS in that we don't want to use a cached user object!
      if (action.type === PAGE_CACHE_HIT) {
        return {
          url: action.url,
          data: {
            ...action.page,
            user: state.user,
          },
          lastFetched: Date.now(),
          isLoading: false,
          isLoadingSlow: false,
        };
      }
      if (action.type === PAGE_FETCH_ERROR) {
        return {
          ...state,
          isLoading: false,
          isLoadingSlow: false,
          url: action.url,
          componentName: 'pages/NotFound404Page',
        };
        // loadFailure: payload.pathname // add something here to inform the user
      }
      return state;
    },
    // anything that starts with `select` is treated as a selector
    selectPageRaw,
    selectPageData,
    selectPage,
    selectPageUrl,
    selectPageComponent,
    selectRelatedProducts,
    selectIsPageLoading,
    selectIsPageLoadingSlow,
    selectMeta,
    selectEventResponse,

    // the actions
    doFetchPage,
    doSetPage,
    doReplacePage,
    doReloadPage,
    doGoToStartpage,

    // react to new urls
    reactUrlChanged: createSelector(
      'selectUrl',
      'selectPageRaw',
      // eslint-disable-next-line consistent-return
      (url, pageRaw) => {
        // console.log(url, pageRaw.url);
        if (!pageRaw.url || url.href !== pageRaw.url.href) {
          return {
            actionCreator:
              url.event === EVENT_REPLACE ? 'doReplacePage' : 'doFetchPage',
            args: [url],
          };
        }
      },
    ),
  };
};

// checker for proper theme/siteskin handeling BEFORE navigation (whitelist/blacklist)
const isInternalNav = store => (href, e, aTag) => {
  if (aTag.getAttribute('data-force-refresh')) {
    return false;
  }
  // get list of startPages
  const startPages = dlv(
    window,
    'INITIAL_DATA.siteSettings.siteStartpages',
    {},
  );
  // make sure that the link is for the same language
  const languageLink = getLanguageLinkForUrl(href, store.selectSiteLanguages());
  // return false if we couldn't retrieve the language, or the link is for a language that isn't the current language
  if (!languageLink || !languageLink.isCurrentLanguage) {
    return false;
  }

  // find the current startPage
  const currentStartPagePath = getStartpagePathForUrl(
    dlv(store.selectPageData(), 'url.path'),
    startPages,
  );
  // find the next startPage
  const nextStartPagePath = getStartpagePathForUrl(href, startPages);

  // return true if current and next are equal and nextStartPagePath !== null
  return currentStartPagePath === nextStartPagePath && !!nextStartPagePath;
};

// url AND startPages as arguments.. because it's more flexible that way
const getStartpagePathForUrl = (url, startPages) =>
  Object.keys(startPages).reduce((finalPath, startPagePath) => {
    const fixedStartPagePath = cleanPath(startPagePath);
    if (
      url.indexOf(fixedStartPagePath) === 0 &&
      (!finalPath ||
        fixedStartPagePath.split('/').length > finalPath.split('/').length)
    ) {
      return fixedStartPagePath;
    }
    return finalPath;
  }, null);

const getLanguageLinkForUrl = (url, languageLinks) =>
  languageLinks
    .filter(link => url.indexOf(link.url) === 0)
    .reduce(
      (final, link) =>
        !!final && final.url.split('/').length > link.url.split('/').length
          ? final
          : link,
      null,
    );

const handleInternalNav = store => (url, e, aTag) => {
  const parsedUrl = parseUrl(
    url,
    'push',
    !!aTag.getAttribute('data-cosmetic'),
    !!aTag.getAttribute('data-no-scrolltop'),
  );
  if (aTag.getAttribute('data-replace')) {
    store.doReplaceUrl(parsedUrl);
  } else {
    store.doUpdateUrl(parsedUrl);
  }
};
