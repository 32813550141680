import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { heartFilled, heartOpen } from 'components/atoms/Icon/icons';
import { translate } from 'utils/translate';
import classnames from 'classnames';
import Sparkles from '../../atoms/Sparkles';
import * as styles from './FavouriteButton.scss';
import VisuallyHidden from 'components/atoms/VisuallyHidden';

class FavouriteButton extends React.Component {
  init = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isFavourite: props.isFavourite,
    };
  }

  handleChange = e => {
    e.stopPropagation();
    if (this.props.onChange) {
      this.props.onChange();
    } else {
      this.setState(state => ({ isFavourite: !state.isFavourite }));
    }
  };

  shouldComponentUpdate(newProps, newState) {
    return (
      newProps.isFavourite !== this.props.isFavourite ||
      newState.isFavourite !== this.state.isFavourite
    );
  }

  componentDidMount() {
    this.init = true;
  }

  render() {
    const { onChange, isShort, className, compact, colorTheme } = this.props;
    const isFavourite = onChange
      ? this.props.isFavourite
      : this.state.isFavourite;
    const saveText = isShort
      ? translate('/favoriteButton/add_short')
      : translate('/favoriteButton/add');

    const style = colorTheme && colorTheme.hasWhiteFont ? { color: '#fff' } : {};

    return (
      <Button
        onClick={this.handleChange}
        noPadding
        transparent={!compact}
        className={classnames(className, { [styles.compact]: compact })}
        style={style}
      >
        <span
          className={classnames(styles.icon, {
            [styles.isChecked]: isFavourite,
          })}
        >
          <i className={styles.checked}>
            <Icon icon={heartFilled} />
          </i>
          <i className={styles.unchecked}>
            <Icon icon={heartOpen} />
          </i>
          {isFavourite && this.init && <Sparkles decorator />}
        </span>
        {compact ? (
          <VisuallyHidden>
            {isFavourite ? (
              <span className={styles.savedText}>
                {translate('/favoriteButton/saved')}
              </span>
            ) : (
              saveText
            )}
          </VisuallyHidden>
        ) : isFavourite ? (
          <span className={styles.savedText}>
            {translate('/favoriteButton/saved')}
          </span>
        ) : (
          saveText
        )}
      </Button>
    );
  }
}
FavouriteButton.displayName = 'FavouriteButton';
FavouriteButton.propTypes = {
  isFavourite: PropTypes.bool,
  onChange: PropTypes.func,
  isShort: PropTypes.bool,
  compact: PropTypes.bool,
  className: PropTypes.string,
  colorTheme: PropTypes.object,
};
FavouriteButton.defaultProps = {
  isFavourite: false,
  compact: false,
  isShort: false,
  colorTheme: { color: null, hasWhiteFont: false },
};

export default FavouriteButton;
