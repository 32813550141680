import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import { connect } from 'redux-bundler-react';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import LocalFavoritesImporter from 'components/organisms/LocalFavoritesImporter';
import ShareButton from 'components/organisms/ShareButton';
import MyPageSection from 'components/organisms/MyPageSection';
import ProfileHeader from 'components/molecules/ProfileHeader';
import ProfileBio from 'components/molecules/ProfileBio';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

import { translate } from 'utils/translate';

const MyPageView = React.lazy(() =>
  import(
    /* webpackChunkName: "mypage" */ /* webpackPreload: true */ 'components/organisms/MyPageView'
  ),
);
// TODO: Anonymous => MyPageContainer

function Loading(props) {
  if (props.error) {
    // console.log(props.error);
    // When the loader has errored
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time...{' '}
        <button type="button" onClick={props.retry}>
          Retry
        </button>
      </div>
    );
  }
  if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div>Loading...</div>;
  }
  // When the loader has just started
  return null;
}
const LoadableProfileEditor = Loadable({
  loader: () => import('components/organisms/ProfileEditor'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
  render(loaded, props) {
    // console.log(loaded, props);
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

const transformTheme = theme => ({
  image: propertyValueFor(theme.themeImage),
  fontColor: propertyValueFor(theme.themeFontColor),
  id: propertyValueFor(theme.blockId),
});

class MyPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editHeader: false,
      headerTitle:
        propertyValueFor(props.pageData.currentPage.myPageHeading) || '',
      headerTheme: transformTheme(
        props.pageData.selectedTheme ||
          props.pageData.availableThemes.map(transformTheme)[0],
      ),
      bio: propertyValueFor(props.pageData.currentPage.bio),
    };
  }

  handleHeaderSave = headerData => {
    this.setState({
      editHeader: false,
      headerTitle: headerData.title,
      headerTheme: headerData.theme,
    });
    this.props.doSetMyPageTitle(headerData.title);
    this.props.doSetMyPageTheme(headerData.theme.id);
  };

  handleBioSave = bio => {
    this.props.doSetMyPageBio(bio);
  };

  handleClearFavorites = () => {
    this.props.doClearFavorites();
    this.props.doReloadPage();
  };

  handleLocalFavoritesImporterClose = () => {
    this.props.doClearUnimportedFavorites();
    this.props.doReloadPage();
  };

  render() {
    const {
      pageData,
      pageData: { currentPage },
      url,
      unimportedFavorites,
      facebookAccessToken,
    } = this.props;
    const { editHeader, headerTitle, headerTheme, bio } = this.state;
    const themeList = pageData.availableThemes.map(transformTheme);

    const profile = {
      title: headerTitle,
      currentTheme: headerTheme,
      name: propertyValueFor(currentPage.name),
      bio: propertyValueFor(currentPage.bio),
      profileImage: `${propertyValueFor(
        currentPage.externalProfilePictureUrl,
      )}&access_token=${facebookAccessToken}`,
    };
    const userCanEditPage = pageData.allowPageEdit;
    const isHeaderEditMode = userCanEditPage && editHeader;
    return (
      <Suspense fallback={<LoadingIndicator />}>
        <MyPageView
          showProfileHeader
          productPageUrl={propertyValueFor(currentPage.productPageUrl)}
          favoriteItems={pageData.myFavoriteItems}
          onClearFavorites={userCanEditPage ? this.handleClearFavorites : null}
          profileHeader={
            isHeaderEditMode ? (
              <Container relative wide noPadding>
                <LoadableProfileEditor
                  themeList={themeList}
                  title={profile.title}
                  placeholder={translate('/pages/myPage/titlePlaceholder')}
                  currentTheme={profile.currentTheme}
                  onSave={this.handleHeaderSave}
                />
              </Container>
            ) : (
              <Container relative wide noPadding>
                <ProfileHeader
                  title={
                    <ProfileHeader.Title>{profile.title}</ProfileHeader.Title>
                  }
                  currentTheme={profile.currentTheme}
                  editButton={
                    userCanEditPage && (
                      <Button
                        rounded
                        onClick={() =>
                          this.setState({
                            editHeader: true,
                          })
                        }
                      >
                        {translate('/pages/myPage/customizeButton')}
                      </Button>
                    )
                  }
                />
              </Container>
            )
          }
          profileBioSection={
            <MyPageSection color="#FCEDED" style={{ paddingTop: '40px' }}>
              <Container centerText style={{ marginBottom: '40px' }}>
                <ShareButton title={profile.title} url={url.href} />
              </Container>
              <Container>
                <ProfileBio
                  name={profile.name}
                  image={profile.profileImage}
                  bio={bio}
                  onSave={this.handleBioSave}
                  canEdit={userCanEditPage}
                />
              </Container>
            </MyPageSection>
          }
        >
          {!!unimportedFavorites.length && (
            <LocalFavoritesImporter
              unimportedFavorites={unimportedFavorites}
              onClose={this.handleLocalFavoritesImporterClose}
            />
          )}
        </MyPageView>
      </Suspense>
    );
  }
}
MyPage.displayName = 'MyPage';
MyPage.propTypes = {
  doClearFavorites: PropTypes.func,
  doSetMyPageBio: PropTypes.func,
  doSetMyPageTitle: PropTypes.func,
  doSetMyPageTheme: PropTypes.func,
  doReloadPage: PropTypes.func,
  doClearUnimportedFavorites: PropTypes.func,
  pageData: PropTypes.object,
  url: PropTypes.object,
  unimportedFavorites: PropTypes.array,
  facebookAccessToken: PropTypes.string,
};
MyPage.defaultProps = {};

export default connect(
  'selectFacebookAccessToken',
  'selectPageData',
  'selectUnimportedFavorites',
  'doSetMyPageTitle',
  'doSetMyPageTheme',
  'doSetMyPageBio',
  'doClearFavorites',
  'doReloadPage',
  'doClearUnimportedFavorites',
  MyPage,
);
