import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FullscreenLayoutContainer from 'containers/layouts/FullscreenLayoutContainer';
import HeroHeader from 'components/molecules/HeroHeader';
import { Paragraph } from 'components/atoms/Text';
import { useScrollPosition } from 'hooks/use-is-in-view';
import isInEditMode from 'packages/inmeta-epi-react/utils/is-in-edit-mode';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import {
  CustomPropTypes,
  propertyValueFor,
} from 'packages/inmeta-epi-react/utils/epi-react';
import Sticky from 'components/utilities/Sticky';
import CurrentSectionIndicator from 'components/molecules/CurrentSectionIndicator';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
import Container from 'components/atoms/Container';
import StoryChapterBlock from 'episerver/blocks/StoryChapterBlock';
import BreadcrumbsContainer from 'containers/molecules/BreadcrumbsContainer';
import * as styles from './StoryPage.scss';

const StoryPage = props => {
  const {
    image,
    heading,
    introduction,
    mainBody,
    noMainImageEffects,
    chapters,
  } = props;

  const mainImage = {
    src: propertyValueFor(image).url,
    alt: propertyValueFor(image).alternativeText,
    description: propertyValueFor(image).description,
    copy: propertyValueFor(image).credits,
  };
  const [showMain, setShowMain] = useState(null);

  const storyChapters = propertyValueFor(chapters);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y > 0;
      setShowMain(isShow);
    },
    null,
    null,
    true,
  );

  return (
    <FullscreenLayoutContainer
      hasEffects={!propertyValueFor(noMainImageEffects)}
    >
      <HeroHeader
        image={mainImage}
        title={heading}
        hide={showMain && !isInEditMode()}
        hasEffects={!propertyValueFor(noMainImageEffects)}
      />
      <Container
        full
        noPadding
        className={classnames(styles.container, {
          [styles.hidden]:
            !propertyValueFor(noMainImageEffects) &&
            !showMain &&
            !isInEditMode(),
          [styles.editMode]: isInEditMode(),
        })}
      >
        {storyChapters && storyChapters.items.length > 0 && (
          <BreakpointContainer
            gte={BREAKPOINT_TABLET_PORTRAIT}
            match={() => (
              <Sticky
                stickyClassName={styles.isStickyNav}
                innerClassName={styles.nav}
                placeholderClassName={styles.nav}
              >
                <nav>
                  <CurrentSectionIndicator
                    horizontal="left"
                    vertical="top"
                    secondaryColor="white"
                    primaryColor="#7b7b7b"
                    textColor="#000"
                    isAlt
                  />
                </nav>
              </Sticky>
            )}
          />
        )}
        <div
          className={classnames(styles.BreadcrumbsContainer, {
            [styles.alt]: !propertyValueFor(noMainImageEffects),
          })}
        >
          <BreadcrumbsContainer />
        </div>
        <PropertyFor
          property={introduction}
          className={styles.introduction}
          component={
            !!introduction && typeof introduction.$c === 'string' && Paragraph
          }
          componentProps={{ size: Paragraph.sizes.large }}
        />
        <PropertyFor
          property={mainBody}
          className={classnames(styles.mainBody)}
        />

        {storyChapters &&
          storyChapters.items.length > 0 &&
          storyChapters.items.map(c => (
            <StoryChapterBlock
              className={classnames(styles.mainBody, styles.mainBodyChapter)}
              block={c.block}
              key={propertyValueFor(c.block.title)}
            />
          ))}
      </Container>
    </FullscreenLayoutContainer>
  );
};
StoryPage.displayName = 'StoryPage';
StoryPage.propTypes = {
  heading: CustomPropTypes.EpiserverValue,
  mainBody: CustomPropTypes.EpiserverValue,
  introduction: CustomPropTypes.EpiserverValue,
  image: PropTypes.object,
  noMainImageEffects: CustomPropTypes.EpiserverValue,
  chapters: CustomPropTypes.EpiserverValue,
};
StoryPage.defaultProps = {};

// StoryPage = React.memo(StoryPage);

export default StoryPage;
