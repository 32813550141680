import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/atoms/Button';
import { magnifyer } from 'components/atoms/Icon/icons';
import { translate } from 'utils/translate';
import * as constants from 'utils/constants';

const SearchForm = ({
  action,
  className,
  buttonClassName,
  inputClassName,
  placeholder,
  showSearchButton = false,
  doUpdateUrl = () => {},
}) => {
  const inputRef = React.useRef(null);
  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault();

      doUpdateUrl(
        `${action}${action.includes('?') ? '&' : '?'}${inputRef.current.name}=${
          inputRef.current.value
        }`,
      );
    },
    [action],
  );
  return (
    <form
      method="get"
      action={action}
      onSubmit={handleSubmit}
      className={className}
      role="search"
    >
      <input
        ref={inputRef}
        type="search"
        className={inputClassName}
        placeholder={placeholder}
        name={constants.get('searchParam')}
        aria-label={translate('/search/searchFieldLabel')}
      />
      {showSearchButton && (
        <IconButton
          className={buttonClassName}
          iconAfter={magnifyer}
          type="submit"
          aria-label={translate('/search/searchButton')}
        />
      )}
    </form>
  );
};
SearchForm.displayName = 'SearchForm';
SearchForm.propTypes = {};
SearchForm.defaultProps = {};

export default SearchForm;
