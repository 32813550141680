import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ActiveElementIndicator from 'components/utilities/MainElementDetector/ActiveElementIndicator';
import * as styles from './CurrentSectionIndicator.scss';

const CurrentSectionIndicator = ({
  vertical = 'center',
  horizontal = 'right',
  direction = 'vertical',
  primaryColor = '#AF2070',
  secondaryColor = 'white',
  isAlt = false,
}) => {
  const activeColor = isAlt ? secondaryColor : primaryColor;
  const inactiveColor = isAlt ? primaryColor : secondaryColor;
  return (
    <ActiveElementIndicator
      render={({ elements, current }) => (
        <ul
          className={classnames(
            styles.root,
            styles[`${vertical}_${horizontal}`] || styles.center_center,
            {
              [styles.isAlt]: isAlt,
            },
          )}
        >
          {elements.map((obj, i) => (
            <li
              key={i}
              className={classnames(styles.item, {
                [styles.vertical]: direction === 'vertical',
                [styles.horizontal]: direction == 'horizontal',
              })}
              onClick={() =>
                obj.getElement().scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                })
              }
            >
              <span
                className={styles.dot}
                style={{
                  backgroundColor:
                    obj === current ? activeColor : inactiveColor,
                  borderColor: primaryColor,
                }}
              />
              <span
                data-title={obj.data.title}
                className={classnames(styles.info, {
                  [styles.infoActive]: obj === current,
                })}
                style={{ color: isAlt ? '#000' : primaryColor }}
              >
                {obj.data.title}
              </span>
            </li>
          ))}
        </ul>
      )}
    />
  );
};
CurrentSectionIndicator.displayName = 'CurrentSectionIndicator';
CurrentSectionIndicator.propTypes = {};
CurrentSectionIndicator.defaultProps = {};

export default CurrentSectionIndicator;
