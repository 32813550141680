import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Block, { ratioPropType } from 'components/atoms/Block/Block';
import Ref from 'components/utilities/Ref';
import { translate } from 'utils/translate';
import Popover2 from 'components/utilities/Popover2';
import Toggle from 'components/utilities/Toggle';
import Icon from '../Icon';
import { copyright } from '../Icon/icons';
import * as styles from './Image.scss';
import ConditionalWrap from 'components/utilities/ConditionalWrap';

/* presets:
Teaser 680 x 340 3:2
ProductSliderImage 720x480 3:2
ProductSliderThumbnails 200x100 2:1
ProductImage 960x640 3:2
WallImage 480x360 4:3
*/

const cropActionMapping = {
  Teaser: 'Small',
  WallImage: 'Thumbnail',
  limitWidth: 'Large',
  ProductImage: 'Large',
  ProductSliderImage: 'Large',
};

const getTypeFromSrc = src => {
  if (src.match(/^.*wsimgs.*$/)) {
    return 'tellus';
  }
  if (src.match(/^.*contentassets.*/)) {
    return 'epi';
  }
  if (src.match(/^.*globalassets.*/)) {
    return 'epi';
  }
  if (src.match(/^\/link.*/)) {
    return 'epi';
  }
  return 'unknown';
};

const getCropType = (type, cropDetails) => {
  const cropMap = cropActionMapping[type];
  const cropValue = cropDetails.find(crop => {
    return crop.name === cropMap;
  });

  if (cropValue !== undefined && cropValue.imageResizerCropValue) {
    return cropValue.imageResizerCropValue;
  }

  return null;
};

const srcResolvers = {
  tellus: (src, preset, cropDetails) => {
    if (cropDetails) {
      const crop = getCropType(preset, cropDetails);
      if (crop) {
        return `${src}[${preset}][${crop}]`;
      }
    }
    return `${src}[${preset}]`;
  },
  epi: (src, preset, cropDetails) => `${src}?preset=${preset}`,
};

const getImageProps = (src, ratio = [], cropDetails) => {
  // console.log('src', src, cropDetails);
  if (!src) return {};

  if (src.split('.').pop() === 'gif') {
    return { src };
  }

  const type = getTypeFromSrc(src);
  const ratioString = ratio.join(':');
  if (type !== 'unknown') {
    const srcResolver = srcResolvers[type];
    if (ratioString === '2:1' || ratioString === '16:9') {
      return {
        src: srcResolver(src, 'Teaser', cropDetails),
        // src: srcResolver(src, 'ProductImage'),
      };
    }
    if (ratioString === '4:3') {
      return {
        src: srcResolver(src, 'WallImage', cropDetails),
      };
    }
    if (ratioString === '28:11') {
      return {
        src: srcResolver(src, 'limitWidth', cropDetails),
      };
    }
    if (ratioString === '3:2') {
      return {
        src: srcResolver(src, 'ProductImage', cropDetails),
        srcSet: [
          `${srcResolver(src, 'ProductImage', cropDetails)} 960w`,
          `${srcResolver(src, 'ProductSliderImage', cropDetails)} 720w`,
          `${srcResolver(src, 'Teaser', cropDetails)} 680w`,
          `${srcResolver(src, 'WallImage', cropDetails)} 480w`,
        ].join(', '),
      };
    }
    if (ratioString === '6:4') {
      return {
        src: srcResolver(src, 'Teaser', cropDetails),
      };
    }
    if (ratioString === '6:3') {
      return {
        src: srcResolver(src, 'GalleryItem', cropDetails),
      };
    }
    if (ratioString === '12:5') {
      return {
        src: srcResolver(src, 'GalleryItemWide', cropDetails),
      };
    }
  }

  return { src };
};

const fitMap = {
  contain: styles.contain,
  cover: styles.cover,
  'scale-down': styles.scaleDown,
  none: null,
  width: styles.fullWidth,
};
const fitValues = Object.keys(fitMap);
const defaultRatioFit = 'contain';

const Image = React.memo(
  ({
    src,
    alt,
    title,
    copy,
    description,
    ratio,
    className,
    style,
    fit,
    draggable,
    copyClassName,
    isInvisible,
    cropDetails = [],
    lazy,
  }) => {
    const fitClassName = fitMap[ratio && !fit ? defaultRatioFit : fit];
    const imageProps = getImageProps(src, ratio, cropDetails);

    return (
      <ConditionalWrap
        condition={!!lazy}
        wrap={children => (
          <LazyLoad
            height={lazy.height}
            offset={lazy.offset}
            resize={lazy.resize}
            once
          >
            {children}
          </LazyLoad>
        )}
      >
        <Block
          as="figure"
          ratio={ratio}
          contain={!!ratio}
          className={classnames(styles.base, className, {
            [styles.invisible]: isInvisible,
          })}
          style={style}
        >
          <img
            className={classnames(styles.image, fitClassName)}
            alt={alt || ''}
            draggable={draggable}
            name={title}
            {...imageProps}
          />
          {(copy || description || title) && (
            <Ref>
              {([currentRef, setRef]) => (
                <Toggle
                  render={({ on, setOn, setOff }) => (
                    <React.Fragment>
                      <span
                        ref={setRef}
                        onMouseEnter={setOn}
                        onMouseLeave={setOff}
                        className={classnames(styles.copyButton, copyClassName)}
                      >
                        <Icon icon={copyright} />
                      </span>
                      {on && (
                        <Popover2
                          anchor={currentRef}
                          position={Popover2.presets.NNE}
                          arrowSize={5}
                        >
                          {({ style, ref, arrowStyle, arrowDirection }) => (
                            <figcaption
                              className={styles.copy}
                              style={style}
                              ref={ref}
                            >
                              {!!description && <p>{description}</p>}
                              {!!title && <p>{title}</p>}
                              {!!copy && (
                                <p>
                                  {translate(
                                    '/product/photo/photographer',
                                    copy,
                                  )}
                                </p>
                              )}
                              <span
                                className={classnames(
                                  styles.arrow,
                                  styles[`arrow--${arrowDirection}`],
                                )}
                                style={arrowStyle}
                              />
                            </figcaption>
                          )}
                        </Popover2>
                      )}
                    </React.Fragment>
                  )}
                />
              )}
            </Ref>
          )}
        </Block>
      </ConditionalWrap>
    );
  },
);

Image.displayName = 'Image';
Image.propTypes = {
  src: PropTypes.string,
  // TODO srcSet: srcSetShape,
  alt: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  description: PropTypes.string,
  ratio: ratioPropType,
  className: PropTypes.string,
  fit: PropTypes.oneOf(fitValues),
  style: PropTypes.object,
  draggable: PropTypes.bool,
  cropDetails: PropTypes.array,
  copyClassName: PropTypes.string,
  isInvisible: PropTypes.bool,
  lazy: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
Image.defaultProps = {
  alt: '',
  lazy: {
    offset: 100,
    height: 200,
    resize: false,
  },
};

export default Image;
