import React from "react";
import DefaultLayoutContainer from "containers/layouts/DefaultLayoutContainer";
import PageView from "components/organisms/PageView";

const SubStartPage = page => (
  <DefaultLayoutContainer>
    <PageView
      useFullscreenSlider
      slider={{ captionSettings: { fullHeight: true } }}
      {...page}
    />
  </DefaultLayoutContainer>
);

SubStartPage.displayName = "SubStartPage";
SubStartPage.propTypes = {};
SubStartPage.defaultProps = {};

export default SubStartPage;
