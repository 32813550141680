export const START = 0;
export const MIDDLE = 1;
export const END = 2;

export const AXIS_X = 0;
export const AXIS_Y = 1;

export const arrowDirections = {
  N: 'down',
  S: 'up',
  W: 'right',
  E: 'left',
};

export const defaultRect = {
  top: 0,
  left: 0,
  right: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

/*
      →       ←
      NNW  N  NNE
↓ WNW             ENE ↓
    W   ANCHOR    E
↑ WSW             ESE ↑
      SSW  S  SSE
      →       ←
*/

export const zones = [
  {
    name: 'NNW',
    ax: START,
    ay: START,
    dx: START,
    dy: END,
    o: 'SSW',
    axis: AXIS_Y,
  },
  {
    name: 'N',
    ax: MIDDLE,
    ay: START,
    dx: MIDDLE,
    dy: END,
    o: 'S',
    axis: AXIS_Y,
  },
  {
    name: 'NNE',
    ax: END,
    ay: START,
    dx: END,
    dy: END,
    o: 'SSE',
    axis: AXIS_Y,
  },
  {
    name: 'ENE',
    ax: END,
    ay: START,
    dx: START,
    dy: START,
    o: 'WNW',
    axis: AXIS_X,
  },
  {
    name: 'E',
    ax: END,
    ay: MIDDLE,
    dx: START,
    dy: MIDDLE,
    o: 'W',
    axis: AXIS_X,
  },
  {
    name: 'ESE',
    ax: END,
    ay: END,
    dx: START,
    dy: END,
    o: 'WSW',
    axis: AXIS_X,
  },
  {
    name: 'SSE',
    ax: END,
    ay: END,
    dx: END,
    dy: START,
    o: 'NNE',
    axis: AXIS_Y,
  },
  {
    name: 'S',
    ax: MIDDLE,
    ay: END,
    dx: MIDDLE,
    dy: START,
    o: 'N',
    axis: AXIS_Y,
  },
  {
    name: 'SSW',
    ax: START,
    ay: END,
    dx: START,
    dy: START,
    o: 'NNW',
    axis: AXIS_Y,
  },
  {
    name: 'WSW',
    ax: START,
    ay: END,
    dx: END,
    dy: END,
    o: 'ESE',
    axis: AXIS_X,
  },
  {
    name: 'W',
    ax: START,
    ay: MIDDLE,
    dx: END,
    dy: MIDDLE,
    o: 'E',
    axis: AXIS_X,
  },
  {
    name: 'WNW',
    ax: START,
    ay: START,
    dx: END,
    dy: START,
    o: 'ENE',
    axis: AXIS_X,
  },
];
export const presets = zones.reduce(
  (presets, item) => ({ ...presets, [item.name]: item.name }),
  {},
);
