import PropTypes from 'prop-types';

const CalendarEntry = () => null;
CalendarEntry.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  unselectable: PropTypes.bool,
  selected: PropTypes.bool,
};
CalendarEntry.defaultProps = {
  disabled: false,
  unselectable: false,
  selected: false,
};

export default CalendarEntry;
