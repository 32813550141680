import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'components/atoms/Image';
import Block from 'components/atoms/Block';
import { Hero } from 'components/atoms/Heading';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';
import * as styles from './ProfileHeader.scss';

const ProfileHeader = ({ currentTheme, title, editButton, imageSelector }) => (
  <BreakpointContainer
    render={breakpoint => (
      <Block
        className={styles.root}
        ratio={breakpoint <= BREAKPOINT_PHONE ? [3, 2] : [2, 1]}
        style={{ backgroundImage: `url(${currentTheme.image})` }}
      >
        <div className={styles.imageSelectorSlot}>
          {!!imageSelector && imageSelector}
        </div>
        <div className={styles.buttonSlot}>{!!editButton && editButton}</div>
        <div className={styles.titleSlot}>{!!title && title}</div>
      </Block>
    )}
  />
);
ProfileHeader.displayName = 'ProfileHeader';
ProfileHeader.propTypes = {
  title: PropTypes.node,
  editButton: PropTypes.node,
  imageSelector: PropTypes.node,
  currentTheme: PropTypes.object.isRequired,
};
ProfileHeader.defaultProps = {};
ProfileHeader.Title = ({ children, className, ...rest }) => (
  <Hero className={classnames(styles.title, className)} {...rest}>
    {children}
  </Hero>
);

export default ProfileHeader;
