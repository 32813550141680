import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Heading, { H2 } from 'components/atoms/Heading';
import MainElementDetector from 'components/utilities/MainElementDetector';
import BodyBackgroundColor from 'components/utilities/BodyBackgroundColor';
import * as styles from './MyPageSection.scss';

const MyPageSection = ({ title, titleBackground, color, children, style }) => (
  <MainElementDetector
    data={{
      title,
    }}
    render={({ isCurrent }) => (
      <section className={styles.root} style={style}>
        {!!title && (
          <div
            className={classnames({
              [styles.titleWithBackground]: !!titleBackground,
              [styles.title]: !titleBackground,
            })}
            style={
              !!titleBackground
                ? {
                    backgroundImage: `url(${titleBackground})`,
                  }
                : {}
            }
          >
            <H2
              alt
              size={Heading.sizes.hero}
              center
              className={styles.titleHeading}
            >
              {title}
            </H2>
          </div>
        )}
        {children}
        {isCurrent && !!color && <BodyBackgroundColor color={color} />}
      </section>
    )}
  />
);
MyPageSection.displayName = 'MyPageSection';
MyPageSection.propTypes = {
  title: PropTypes.string,
  titleBackground: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};
MyPageSection.defaultProps = {
  style: {},
};

export default MyPageSection;
