import React, { lazy, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forceCheck } from 'react-lazyload';
import Heading, { H2 } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import FavouriteButtonContainer from 'containers/molecules/FavouriteButtonContainer';
import BreakpointContainer from 'containers/BreakpointContainer';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_PHONE,
  BREAKPOINT_TABLET_LANDSCAPE,
  BREAKPOINT_TABLET_PORTRAIT,
} from 'utils/breakpoint';
import { COLUMN_MAIN, useColumnContext } from 'components/molecules/PageGrid';
import {
  Teaser,
  MODE_TEASER,
  MODE_FLAG,
  MODE_LINK,
} from 'components/molecules/Teaser';
import TeaserLink from 'components/molecules/TeaserLink';
import Time from 'components/atoms/Time';
import { translate } from 'utils/translate';

import Icon from 'components/atoms/Icon';
import {
  chevronLeft,
  chevronRight,
  signpost,
  www,
} from 'components/atoms/Icon/icons';
import Link from 'components/atoms/Link';
import Block from 'components/atoms/Block';
import AutoPlay from 'packages/react-spring-slider/AutoPlay';
import arrayChunk from 'utils/arrayChunk';
import TripAdvisorWidget from '../TripAdvisorWidget';
import * as styles from './TeaserList.scss';
import SliderState from 'packages/react-spring-slider/SliderState';
import { LinkButton } from 'components/atoms/Button';

const Slider = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/Slider'
  ),
);
const SlideTrack = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SlideTrack'
  ),
);

export const teaserConfig = {
  onlyLink: { mode: MODE_LINK },
  onlyLinkCompact: { mode: MODE_LINK, compact: true },
  imageAndTitle: { mode: MODE_TEASER, title: true, image: true },
  introAndTitle: { mode: MODE_TEASER, intro: true, title: true },
  teaserNews: {
    mode: MODE_TEASER,
    intro: true,
    title: true,
    image: true,
    showDate: true,
  },
  imageTitleAndIntro: {
    mode: MODE_TEASER,
    title: true,
    image: true,
    intro: true,
  },
  imageTitleAndIntroForListView: {
    mode: MODE_FLAG,
    title: true,
    image: true,
    intro: true,
  },
  imageTitleIntroAndDetailsForListView: {
    mode: MODE_FLAG,
    title: true,
    image: true,
    intro: true,
    details: true,
  },
  imageAndTitleAsSlides: {
    mode: MODE_TEASER,
    title: true,
    image: true,
    slider: true,
    extraTall: true,
  },
  imageAndTitleAsSlidesCompact: {
    mode: MODE_TEASER,
    title: true,
    image: true,
    slider: true,
    compact: true,
  },
  imageTitleAndIntroAsSlides: {
    mode: MODE_TEASER,
    title: true,
    image: true,
    intro: true,
    slider: true,
    extraTall: true,
  },
};

export const getTeaserConfigFromViewType = (
  viewType,
  isNews = false,
  columnContext = COLUMN_MAIN,
) => {
  if (columnContext !== COLUMN_MAIN && !isNews) {
    return viewType === 'imageTitleAndIntroForListView'
      ? teaserConfig.imageTitleAndIntro
      : teaserConfig[viewType];
  }
  if (columnContext !== COLUMN_MAIN && isNews) {
    return teaserConfig.teaserNews;
  }
  return isNews
    ? teaserConfig.imageTitleAndIntroForListView ||
        teaserConfig.imageTitleIntroAndDetailsForListView
    : teaserConfig[viewType] || teaserConfig.imageTitleAndIntro;
};

const TeaserList = ({
  title,
  items = [],
  className = '',
  config = teaserConfig.imageTitleAndIntro,
  showDate = false,
  showFavoriteButton = false,
  columnCount = 2,
  showMunicipality,
  alt,
  culture,
  noBorder = false,
  colorTheme = { color: null, hasWhiteFont: false },
  wideContainer = false,
  megaMenuTeaser = false,
}) => {
  // force check lazyload
  forceCheck();
  /* {console.log(className)} */

  const columnContext = useColumnContext();
  const isMain = columnContext === COLUMN_MAIN;

  const isAlt = alt !== undefined ? alt : false;

  if (config.slider) {
    return (
      <BreakpointContainer
        render={breakpoint => {
          const sliderItems = useMemo(
            () =>
              arrayChunk(
                items.map(item => ({ ...item })),
                breakpoint > BREAKPOINT_PHONE
                  ? wideContainer
                    ? breakpoint < BREAKPOINT_DESKTOP
                      ? 3
                      : 4
                    : 2
                  : 1,
              ),
            [items],
          );
          return (
            <Slider items={sliderItems}>
              <div
                className={classnames(styles.sliderContainer, {
                  [styles.mainColumnWidth]: isMain,
                  [styles.sliderCompact]: config.compact,
                  [styles.tallContainer]: wideContainer,
                  [styles.extraTallContainer]: config.extraTall,
                })}
              >
                <div
                  className={classnames(styles.slider, {
                    [styles.wideContainer]: wideContainer,
                  })}
                >
                  <div className={styles.sliderItems}>
                    <SlideTrack
                      dragTreshold={100}
                      swipe={breakpoint > BREAKPOINT_PHONE && items.length > 2}
                      direction="horizontal"
                      height="min"
                      renderItem={({
                        item: chunk,
                        getItemProps,
                        isCurrent,
                      }) => (
                        <div
                          {...getItemProps()}
                          className={classnames(styles.sliderItem, {
                            [styles.currentSliderItem]: isCurrent,
                          })}
                          key={`chunk_${chunk[0].url}`}
                        >
                          {chunk.map(item => (
                            <>
                              <Teaser
                                key={item.url}
                                mode={config.mode}
                                href={item.url}
                                alt={isAlt}
                                target={item.linkTarget}
                                columnCount={0}
                                color={colorTheme.color}
                                invert={colorTheme.hasWhiteFont}
                                image={
                                  config.image &&
                                  !!item.imageUrl && {
                                    src: config.compact
                                      ? item.imageUrl
                                      : `${item.imageUrl}?preset=TeaserPortrait`,
                                    title: item.imageTitle
                                      ? item.imageTitle
                                      : null,
                                    photographer: item.imagePhotographer
                                      ? item.imagePhotographer
                                      : null,
                                    cropDetails: item.cropDetails,
                                    fit: config.compact ? 'contain' : null,
                                    ratio: config.compact ? [6, 4] : [6, 8],
                                  }
                                }
                                className={classnames(styles.item, {
                                  [styles.alt]: !isMain,
                                  [styles.noBorder]: noBorder,
                                  [styles.startPageVO]: wideContainer,
                                })}
                                description={item.title}
                                renderAfterLink={
                                  <React.Fragment>
                                    {((showMunicipality &&
                                      item.address &&
                                      item.address.postalName) ||
                                      item.bookingWebsite ||
                                      item.place) && (
                                      <div className={styles.detailsWrapper}>
                                        {showMunicipality &&
                                          item.address &&
                                          item.address.postalName && (
                                            <Paragraph
                                              className={styles.detail}
                                            >
                                              <Icon
                                                icon={signpost}
                                                size={['1.3em', '1.3em']}
                                              />
                                              {item.address.postalName}
                                            </Paragraph>
                                          )}
                                        {
                                          // TODO readd when SimpleView updated their API to enable localisation
                                          /* config.intro !showMunicipality &&item.place && (
                                <Paragraph className={styles.detail}>
                                  <Icon icon={signpost} size={['1.3em', '1.3em']} />
                                  {item.place}
                                </Paragraph>
                              ) */
                                        }
                                        {config.details && item.website && (
                                          <Paragraph className={styles.detail}>
                                            <Link href={item.website}>
                                              <Icon
                                                icon={www}
                                                size={['1.3em', '1.3em']}
                                              />
                                              {translate(
                                                'pages/productListPage/website',
                                              )}
                                            </Link>
                                          </Paragraph>
                                        )}
                                      </div>
                                    )}
                                    {config.details && item.tripAdvisorId && (
                                      <TripAdvisorWidget
                                        className={
                                          styles.tripAdvisorRatingWidget
                                        }
                                        type="rating"
                                        id={item.tripAdvisorId}
                                        culture={culture}
                                      />
                                    )}
                                    {showFavoriteButton &&
                                      item.myFavoritesId && (
                                        <FavouriteButtonContainer
                                          compact={config.image}
                                          identifier={item.myFavoritesId}
                                          className={
                                            styles.sliderFavouriteButton
                                          }
                                        />
                                      )}
                                  </React.Fragment>
                                }
                              >
                                <div className={styles.sliderItemText}>
                                  {config.title && (
                                    <Teaser.Title
                                      hasWhiteFont={colorTheme.hasWhiteFont}
                                    >
                                      {item.title}
                                    </Teaser.Title>
                                  )}
                                  {showDate && item.published && (
                                    <Teaser.SmallText>
                                      <Time date={item.published} />
                                    </Teaser.SmallText>
                                  )}
                                  {showDate &&
                                    !item.published &&
                                    item.fromTime && (
                                      <Teaser.SmallText>
                                        <Time
                                          date={item.fromTime}
                                          format="EEEE, d. MMMM"
                                        />
                                      </Teaser.SmallText>
                                    )}
                                  {config.intro && (
                                    <Teaser.Intro truncate={item.title}>
                                      {item.intro}
                                    </Teaser.Intro>
                                  )}
                                </div>
                              </Teaser>
                            </>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                  {breakpoint > BREAKPOINT_PHONE ? (
                    <SliderState>
                      {({ isLast, isFirst, actions }) => (
                        <React.Fragment>
                          <LinkButton
                            className={classnames(
                              {
                                [styles.rl0]: wideContainer,
                              },
                              styles.sliderNavButton,
                              styles.prev,
                            )}
                            onClick={actions.prev}
                            disabled={isFirst}
                            aria-label={translate(
                              '/product/sliderButtonPrevAria',
                            )}
                          >
                            <Icon
                              icon={chevronLeft}
                              role="presentation"
                              size={['1.4em', '1.4em']}
                            />
                          </LinkButton>
                          <LinkButton
                            className={classnames(
                              {
                                [styles.rl0]: wideContainer,
                              },
                              styles.sliderNavButton,
                              styles.next,
                            )}
                            onClick={actions.next}
                            disabled={isLast}
                            aria-label={translate(
                              '/product/sliderButtonNextAria',
                            )}
                          >
                            <Icon
                              icon={chevronRight}
                              role="presentation"
                              size={['1.4em', '1.4em']}
                            />
                          </LinkButton>
                        </React.Fragment>
                      )}
                    </SliderState>
                  ) : (
                    <SliderState>
                      {({ isLast, isFirst, actions }) => (
                        <React.Fragment>
                          <LinkButton
                            className={classnames(
                              styles.sliderNavButton,
                              styles.compact,
                              styles.prev,
                            )}
                            onClick={actions.prev}
                            disabled={isFirst}
                            aria-label={translate(
                              '/product/sliderButtonPrevAria',
                            )}
                          >
                            <Icon
                              icon={chevronLeft}
                              role="presentation"
                              size={['1.2em', '1.2em']}
                            />
                          </LinkButton>
                          <LinkButton
                            className={classnames(
                              styles.sliderNavButton,
                              styles.compact,
                              styles.next,
                            )}
                            onClick={actions.next}
                            disabled={isLast}
                            aria-label={translate(
                              '/product/sliderButtonNextAria',
                            )}
                          >
                            <Icon
                              icon={chevronRight}
                              role="presentation"
                              size={['1.2em', '1.2em']}
                            />
                          </LinkButton>
                        </React.Fragment>
                      )}
                    </SliderState>
                  )}
                </div>
              </div>
            </Slider>
          );
        }}
      />
    );
  }
  if (config.mode === MODE_LINK) {
    return (
      <React.Fragment>
        {!!title && (
          <H2
            bold
            size={Heading.sizes.normal}
            className={classnames(styles.listHeading, {
              [styles.listHeadingAlt]: isAlt,
            })}
          >
            {title}
          </H2>
        )}
        <ul
          className={classnames(styles.list, className, {
            [styles.compact]: config.compact,
          })}
        >
          {items &&
            items.map(t => (
              <li key={t.url}>
                <TeaserLink
                  href={t.url}
                  className={classnames(styles.listItem, {
                    [styles.listItemWhite]: colorTheme.hasWhiteFont,
                  })}
                  alt={isAlt}
                  target={t.linkTarget}
                  icon={config.compact ? null : undefined}
                >
                  {t.title}
                </TeaserLink>
              </li>
            ))}
        </ul>
      </React.Fragment>
    );
  }

  let imageRatio = null;
  if (columnCount === 1) imageRatio = [3, 2];
  else if (config.details) imageRatio = [2, 1];
  else if (colorTheme.color) imageRatio = [6, 4];
  return (
    <BreakpointContainer
      render={breakpoint => {
        const isMobile = breakpoint === BREAKPOINT_PHONE;
        return (
          <div
            className={classnames(
              styles.root,
              {
                [styles.flag]: !isMobile && config.mode === MODE_FLAG,
                [styles.grid]: isMobile || config.mode !== MODE_FLAG,
                [styles.c2]: !isMobile && isMain && columnCount === 2,
                [styles.c3]: !isMobile && isMain && columnCount === 3,
                [styles.inverted]: colorTheme.hasWhiteFont,
              },
              className,
            )}
          >
            {items.length <= 0 && !megaMenuTeaser && (
              <div className={styles.emptyList}>
                {translate('/pages/productListPage/emptyList')}
              </div>
            )}
            {items.map(t => (
              <Teaser
                key={t.url}
                mode={config.mode}
                href={t.url}
                alt={isAlt}
                target={t.linkTarget}
                megaMenuTeaser={!!megaMenuTeaser}
                color={colorTheme.color}
                invert={colorTheme.hasWhiteFont}
                image={
                  config.image &&
                  !!t.imageUrl && {
                    src: t.imageUrl,
                    title: t.imageTitle ? t.imageTitle : null,
                    photographer: t.imagePhotographer
                      ? t.imagePhotographer
                      : null,
                    cropDetails: t.cropDetails,
                    ratio: imageRatio,
                  }
                }
                className={classnames(styles.item, {
                  [styles.alt]: !isMain,
                  [styles.noBorder]: noBorder,
                })}
                description={t.title}
                renderAfterLink={
                  <React.Fragment>
                    {((showMunicipality && t.address && t.address.postalName) ||
                      t.bookingWebsite ||
                      t.place) && (
                      <div className={styles.detailsWrapper}>
                        {showMunicipality && t.address && t.address.postalName && (
                          <Paragraph className={styles.detail}>
                            <Icon icon={signpost} size={['1.3em', '1.3em']} />
                            {t.address.postalName}
                          </Paragraph>
                        )}
                        {
                          // TODO readd when SimpleView updated their API to enable localisation
                          /* config.intro !showMunicipality && t.place && (
                          <Paragraph className={styles.detail}>
                            <Icon icon={signpost} size={['1.3em', '1.3em']} />
                            {t.place}
                          </Paragraph>
                        ) */
                        }
                        {config.details && t.website && (
                          <Paragraph className={styles.detail}>
                            <Link href={t.website}>
                              <Icon icon={www} size={['1.3em', '1.3em']} />
                              {translate('/pages/productListPage/website')}
                            </Link>
                          </Paragraph>
                        )}
                      </div>
                    )}
                    {config.details && t.tripAdvisorId && (
                      <TripAdvisorWidget
                        className={styles.tripAdvisorRatingWidget}
                        type="rating"
                        id={t.tripAdvisorId}
                        culture={culture}
                      />
                    )}
                    {showFavoriteButton && t.myFavoritesId && (
                      <FavouriteButtonContainer
                        compact={config.image}
                        identifier={t.myFavoritesId}
                        colorTheme={colorTheme}
                      />
                    )}
                  </React.Fragment>
                }
              >
                {config.title && (
                  <Teaser.Title
                    hasWhiteFont={colorTheme.hasWhiteFont}
                    className={className}
                  >
                    {t.title}
                  </Teaser.Title>
                )}
                {showDate && t.published && (
                  <Teaser.SmallText>
                    <Time date={t.published} />
                  </Teaser.SmallText>
                )}
                {showDate && !t.published && t.fromTime && (
                  <Teaser.SmallText>
                    <Time date={t.fromTime} format="EEEE, d. MMMM" />
                  </Teaser.SmallText>
                )}
                {config.intro && (
                  <Teaser.Intro truncate={t.title}>{t.intro}</Teaser.Intro>
                )}
              </Teaser>
            ))}
          </div>
        );
      }}
    />
  );
};
TeaserList.displayName = 'TeaserList';
TeaserList.propTypes = {
  title: PropTypes.string,
  showFavoriteButton: PropTypes.bool,
  items: PropTypes.array,
  className: PropTypes.string,
  config: PropTypes.object,
  showDate: PropTypes.bool,
  columnCount: PropTypes.number,
  showMunicipality: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  alt: PropTypes.bool,
  culture: PropTypes.string,
  noBorder: PropTypes.bool,
  colorTheme: PropTypes.shape({
    color: PropTypes.string,
    hasWhiteFont: PropTypes.bool,
  }),
  wideContainer: PropTypes.bool,
  megaMenuTeaser: PropTypes.bool,
};
TeaserList.defaultProps = {
  showMunicipality: false,
};
export default TeaserList;
