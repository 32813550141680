export const createCache = (defaultTTL = 3000) => {
  const items = {};
  const get = (key, ttl = defaultTTL) =>
    has(key, ttl) ? items[key].value : null;
  const set = (key, value) => {
    // console.log('set', key, value);
    items[key] = { added: Date.now(), value };
    return value;
  };
  const has = (key, ttl = false) =>
    key in items && (!ttl || items[key].added + ttl >= Date.now());
  const list = () => items;
  return {
    get,
    set,
    has,
    list,
  };
};
