import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import {
  propertyValueFor,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import {
  facebookLogo,
  flickrLogo,
  foursquareLogo,
  googlePlusLogo,
  instagramLogo,
  pinterestLogo,
  tiktokLogo,
  twitterLogo,
  weiboLogo,
  youtubeLogo,
} from 'components/atoms/Icon/icons';
import Icon from 'components/atoms/Icon';
import * as styles from './SocialMediaLinkBlock.scss';

const logos = {
  facebook: facebookLogo,
  tikTok: tiktokLogo,
  twitter: twitterLogo,
  instagram: instagramLogo,
  googleplus: googlePlusLogo,
  pinterest: pinterestLogo,
  flickr: flickrLogo,
  youtube: youtubeLogo,
  foursquare: foursquareLogo,
  weibo: weiboLogo,
};


const SocialMediaLinkBlock = ({ block }) => (
  <Link
    href={propertyValueFor(block.url)}
    className={classnames(styles.root, {
      [styles.invert]: propertyValueFor(block.invertImage),
    })}
    description={propertyValueFor(block.mediaType)}
    aria-label={propertyValueFor(block.mediaType)}
    title={propertyValueFor(block.mediaType)}
    target="_blank"
    rel="roopener noreferrer"
  >
    <Icon
      icon={logos[propertyValueFor(block.mediaType)]}
      size={['1.7em', '1.7em']}
    />
  </Link>
);

SocialMediaLinkBlock.displayName = 'SocialMediaLinkBlock';
SocialMediaLinkBlock.propTypes = {
  block: PropTypes.shape({
    url: CustomPropTypes.EpiserverValue,
    mediaType: CustomPropTypes.EpiserverValue,
  }),
};
SocialMediaLinkBlock.defaultProps = {};

export default SocialMediaLinkBlock;
