import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { resolveComponent } from '../utils/component-registry';
import {
  editorAttributesFor,
  expandPlaceholders,
  addHTMLStringAsNodes,
} from '../utils/epi-react';
import isInEditMode from '../utils/is-in-edit-mode';

/*
type Props = {
  property: Property<any>;
  tagName?: string;
  className?: string;
  component?: React.ComponentClass<any> | React.StatelessComponent<any>;
  componentProps?: any;
  args?: {[key: string]: (string|number) };
  // If we should render a simple value (string eg) by setting inner HTML through
  // React. This is useful for properties where they enter HTML
  dangerouslySetInnerHTML?: boolean;
}

type State = {
  property?: Property<any>;
  tagName?: string;
  className?: string;
};
*/
class PropertyFor extends Component {
  componentDidMount() {
    if (isInEditMode()) {
      const node = ReactDOM.findDOMNode(this);
      const component = this;
      const currentProperty = this.props.property;
      const hasComponent = !!(
        this.props.component ||
        (currentProperty &&
          currentProperty.$c &&
          currentProperty.$c.componentName)
      );

      if (hasComponent) {
        Object.defineProperty(node, 'innerHTML', {
          get() {
            let html = '';
            for (let i = 0; i < node.childNodes.length; i++) {
              const child = node.childNodes[i];
              if (child instanceof Element) {
                html += child.innerHTML;
              }
            }
            return html;
          },
          set(value) {
            // Because Epi wants to replace empty content with a space, probably to make it take up some space
            if (value === '&nbsp;') {
              return;
            }
            // component.innerHTML = value;
            let content = null;
            try {
              content = hasComponent ? JSON.parse(value) : value;

              // If content is null, it means that the content was a complex type and that it has now been
              // removed. But we still want to use the same component to render it since otherwise React
              // will throw away the DOM node
              if (content === null) {
                content = {
                  componentName: component.props.property.$c.componentName,
                };
              }
              const property = Object.assign({}, component.state.property, {
                $c: content,
              });
              component.setState({ property });
            } catch (e) {
              // this is most likely html content..
              addHTMLStringAsNodes(node, value);
              console.log('Unable to parse complex value as json.');
            }
          },
        });
      }
    }
  }

  render() {
    const {
      property,
      tagName = 'div',
      className,
      component,
      dangerouslySetInnerHTML,
      args,
      componentProps,
    } = this.props;
    const name = property && property._name;
    const content = property ? property.$c : null;

    // don't render anything if there's no content and we're not in edit mode
    if (content === null && isInEditMode()) {
      return null;
    }

    if (component || (content && content.componentName)) {
      const Component = component || resolveComponent(content.componentName);
      const actualContent =
        typeof content === 'string' ? { children: content } : content;

      return Component.supportsPropertyForIntegration ? (
        <Component
          tagName={tagName}
          className={className}
          propertyName={name}
          {...this.props.componentProps}
          {...actualContent}
        />
      ) : (
        React.createElement(
          tagName,
          editorAttributesFor(name, className),
          <Component {...componentProps} {...actualContent} />,
        )
      );
    }
    const actualContent =
      typeof content === 'string' && args
        ? expandPlaceholders(content, args)
        : content;
    const props = editorAttributesFor(name, className);
    if (dangerouslySetInnerHTML) {
      // We use dangerouslySetInnerHTML here because placeholders might expand to HTML like links etc
      props.dangerouslySetInnerHTML = { __html: actualContent };
      return React.createElement(tagName, props);
    } 
      return React.createElement(tagName, props, [actualContent || '']);
    
  }
}

export default PropertyFor;
