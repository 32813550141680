import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/atoms/Icon';
import { ticket, ticket2 } from 'components/atoms/Icon/icons';
import { BookingButton, LinkButton } from 'components/atoms/Button/Button';
import { translate } from 'utils/translate';
import * as styles from './EventTicketInfo.scss';

export const TICKETSTATUS_AVAILABLE = 'TICKETSTATUS_AVAILABLE';
export const TICKETSTATUS_CANCELLED = 'TICKETSTATUS_CANCELLED';
export const TICKETSTATUS_SOLD_OUT = 'TICKETSTATUS_SOLD_OUT';
export const TICKETSTATUS_FREE_ENTRANCE = 'TICKETSTATUS_FREE_ENTRANCE';
export const TICKETSTATUS_FREE_AND_BOOKABLE = 'TICKETSTATUS_FREE_AND_BOOKABLE';
export const TICKETSTATUS_ONLY_WEBSITE = 'TICKETSTATUS_ONLY_WEBSITE';
export const TICKETSTATUS_ON_THE_SPOT = 'TICKETSTATUS_ON_THE_SPOT';
export const TICKETSTATUS_NO_INFO = 'TICKETSTATUS_NO_INFO';

const getStatusForProduct = (product, data) => {
  if (data && data.id) {
    if (data.seats_avail && data.seats_avail === 'True')
      return TICKETSTATUS_AVAILABLE;
    if (data.canceled && data.canceled === 'True')
      return TICKETSTATUS_CANCELLED;
    if (data.sold_out && data.sold_out === 'True') return TICKETSTATUS_SOLD_OUT;
  }
  if (product.isFree) {
    if (product.bookingWebsite || (data && data.bookingurl)) {
      return TICKETSTATUS_FREE_AND_BOOKABLE;
    } else {
      return TICKETSTATUS_FREE_ENTRANCE;
    }
  }
  if (product.bookingWebsite && !product.ticketsOnLocation) {
    return TICKETSTATUS_ONLY_WEBSITE;
  }
  if (!product.bookingWebsite && product.ticketsOnLocation) {
    return TICKETSTATUS_ON_THE_SPOT;
  }
  return TICKETSTATUS_NO_INFO;
};

const TicketIcon = () => (
  <span className={styles.icon}>
    <Icon icon={ticket} />
  </span>
);

const EventTicketInfo = ({
  externalData,
  product,
  alt,
  isLoading,
  eventCardView,
  error,
}) => {
  if (isLoading) {
    return 'Loading...';
  }
  const status = getStatusForProduct(product, externalData);

  switch (eventCardView) {
    case true:
      switch (status) {
        case TICKETSTATUS_ONLY_WEBSITE:
        case TICKETSTATUS_AVAILABLE:
          return (
            <React.Fragment>
              {product.isFree && (
                <span className={styles.statusAboveButton}>
                  <Icon icon={ticket2} />
                  {translate('/ticketInfo/free')}
                </span>
              )}
              <BookingButton
                href={product.bookingWebsite || externalData.bookingurl}
                className={classnames(styles.eventStatus, styles.bookingButton)}
                target="_blank"
                full
              >
                {translate('/ticketInfo/findTickets')}
              </BookingButton>
            </React.Fragment>
          );
        case TICKETSTATUS_ON_THE_SPOT:
          return (
            <div className={styles.eventStatus}>
              {translate('/ticketInfo/ticketOnTheSpot')}
            </div>
          );
        case TICKETSTATUS_FREE_ENTRANCE:
          return (
            <div className={styles.eventStatus}>
              {translate('/ticketInfo/free')}
            </div>
          );
        case TICKETSTATUS_FREE_AND_BOOKABLE:
          return (
            <React.Fragment>
              <span className={styles.statusAboveButton}>
                <Icon icon={ticket2} />
                {translate('/ticketInfo/free')}
              </span>
              <BookingButton
                href={product.bookingWebsite || externalData.bookingurl}
                className={classnames(styles.eventStatus, styles.bookingButton)}
                target="_blank"
                full
              >
                {translate('/ticketInfo/findTickets')}
              </BookingButton>
            </React.Fragment>
          );
        case TICKETSTATUS_SOLD_OUT:
          return (
            <div
              className={classnames(
                styles.eventStatus,
                styles.cancelledOrSoldOut,
              )}
            >
              {translate('/ticketInfo/soldOut')}
            </div>
          );
        case TICKETSTATUS_CANCELLED:
          return (
            <div
              className={classnames(
                styles.eventStatus,
                styles.cancelledOrSoldOut,
              )}
            >
              {translate('/ticketInfo/cancelled')}
            </div>
          );
        default:
          return (
            <div className={styles.eventStatus}>
              {translate('/ticketInfo/infoNotAvailable')}
            </div>
          );
      }

    default:
      switch (status) {
        case TICKETSTATUS_AVAILABLE:
          return (
            <React.Fragment>
              {product.isFree && (
                <span className={styles.bookableAndFree}>
                  <TicketIcon />
                  <span> {translate('/ticketInfo/free')}</span>
                </span>
              )}
              <span className={styles.withBookingButton}>
                <TicketIcon />
                <span> {translate('/ticketInfo/ticketsAvailable')}</span>
              </span>
              <BookingButton
                href={externalData.bookingurl}
                target="_blank"
                full
              >
                {translate('/ticketInfo/findTickets')}
              </BookingButton>
            </React.Fragment>
          );
        case TICKETSTATUS_CANCELLED:
          return (
            <span>
              <TicketIcon />
              <span> {translate('/ticketInfo/cancelled')}</span>
            </span>
          );
        case TICKETSTATUS_SOLD_OUT:
          return (
            <span>
              <TicketIcon />
              <span className={styles.soldOut}>
                {translate('/ticketInfo/soldOut')}
              </span>
            </span>
          );
        case TICKETSTATUS_ONLY_WEBSITE:
          return (
            <React.Fragment>
              {product.isFree && (
                <span className={styles.bookableAndFree}>
                  <TicketIcon />
                  <span> {translate('/ticketInfo/free')}</span>
                </span>
              )}
              {alt ? (
                <BookingButton
                  href={product.bookingWebsite}
                  target="_blank"
                  full
                >
                  {translate('/ticketInfo/findTickets')}
                </BookingButton>
              ) : (
                <LinkButton href={product.bookingWebsite} target="_blank" full>
                  {translate('/ticketInfo/findTickets')}
                </LinkButton>
              )}
            </React.Fragment>
          );
        case TICKETSTATUS_FREE_ENTRANCE:
        case TICKETSTATUS_FREE_AND_BOOKABLE:
          return (
            <span>
              <TicketIcon />
              <span> {translate('/ticketInfo/free')}</span>
            </span>
          );
        case TICKETSTATUS_ON_THE_SPOT:
          return (
            <span>
              <TicketIcon />
              <span> {translate('/ticketInfo/ticketOnTheSpot')}</span>
            </span>
          );
        default:
          return (
            <span>
              <TicketIcon />
              <span> {translate('/ticketInfo/infoNotAvailable')}</span>
            </span>
          );
      }
  }
};
EventTicketInfo.displayName = 'EventTicketInfo';
EventTicketInfo.propTypes = {
  alt: PropTypes.bool,
  eventCardView: PropTypes.bool,
};
EventTicketInfo.defaultProps = {
  alt: false,
};

export default EventTicketInfo;
