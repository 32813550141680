import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Container from 'components/atoms/Container';
import { H2, H3 } from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import { ColumnContext, COLUMN_FOOTER } from 'components/molecules/PageGrid';
import { useThemeConfig } from 'hooks/use-theme-config';
import classnames from 'classnames';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import * as styles from './Footer.scss';
import BreakpointContainer from 'containers/BreakpointContainer';

const SocialMediaLinkBlock = React.lazy(() => import(/* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'episerver/blocks/SocialMediaLinkBlock'));
const LinksBlock = React.lazy(() => import(/* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'episerver/blocks/LinksBlock'));

const Footer = ({
  linkBlocks,
  socialHeading,
  socialBlocks,
  rightContent,
  rightContentHeading,
  showTouristIcon,
  footerBottomImage,
  footerLeftImage,
  smallView = false,
}) => {
  const themeConfig = useThemeConfig();
  return (
    <ColumnContext.Provider value={COLUMN_FOOTER}>
      <footer
        className={classnames(styles.footer, {
          [styles.noPaddingBottom]: !!footerBottomImage,
        })}
      >
        <BreakpointContainer
          render={breakpoint => {

            breakpoint <= 2 ? (smallView = true) : (smallView = false);
            return (
              <>
              {(smallView && footerLeftImage) && (
                <div className={classnames(styles.gridItem, styles.leftImageContainerSmallView)}>
                  <img alt="Footerimage" src={footerLeftImage} />
                </div>
              )}
        <Container className={classnames(styles.container, styles.footerWidth)}>
          <Suspense fallback={<LoadingIndicator />}>
            <div className={styles.sectionWide}>
                {(!smallView && footerLeftImage) && (
                  <div className={classnames(styles.gridItem, styles.footerLeftImage)}>
                    <img alt="Footerimage" src={footerLeftImage} />
                  </div>
                )}
              {linkBlocks.map(linkBlock => (
                <div className={styles.gridItem} key={linkBlock.blockId}>
                  <LinksBlock
                    block={linkBlock.block}
                    icon={themeConfig.footerLinksBlockIcon}
                  />
                </div>
              ))}
              <div className={styles.section}>
            {!!rightContentHeading && (
              <H2
                alt
                size={themeConfig.footerHeadingSize}
                gutterBottom
                className={styles.rightContentHeading}
              >
                {showTouristIcon && (
                  <Icon
                    icon={themeConfig.footerInfoIcon}
                    className={styles.rightContentIcon}
                  />
                )}
                {rightContentHeading}
              </H2>
            )}
            <article className={styles.rightContent}>{rightContent}</article>
            </div>
        </div>
          </Suspense>
        </Container>
        </>
            );
        }}
        />
        <Container className={styles.socialMediaContainer}>
        <Suspense fallback={<LoadingIndicator />}>
        {!!socialBlocks.length && (
    <div className={styles.socialsGridItem}>
      <H2
        alt
        size={themeConfig.footerHeadingSize}
        className={styles.socialHeading}
        gutterBottom
      >
        {socialHeading}
      </H2>
      {socialBlocks.map(socialBlock => {
        const { block } = socialBlock;
        block.invertImage = {
          $c: themeConfig.footerInvertSocialIcons,
        };

        return (
          <SocialMediaLinkBlock
            block={block}
            key={socialBlock.blockId}
          />
        );
      })}
    </div>
  )}
  </Suspense>
  </Container>
  {/* {footerBottomImage && (
    <div className={styles.footerBottomImage}>
      <img alt="Footerimage" src={footerBottomImage} />
    </div>
  )} */}
      </footer>
    </ColumnContext.Provider>
  );
};
Footer.displayName = 'Footer';
Footer.propTypes = {
  rightContent: PropTypes.any,
  rightContentHeading: PropTypes.string,
  linkBlocks: PropTypes.array,
  socialBlocks: PropTypes.any,
  socialHeading: PropTypes.string,
  showTouristIcon: PropTypes.bool,
  footerBottomImage: PropTypes.any,
  footerLeftImage: PropTypes.any,
  smallView: PropTypes.bool,
};
Footer.defaultProps = {};

export default Footer;
