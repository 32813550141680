export default function throttle(fn, delay) {
  let timeout;
  return function() {
    let ctx = this;
    let args = arguments;
    // because of how React synthetic events are handeled, we need to call e.persist() to not loose the event in async env
    !!args.length &&
      !!args[0].persist &&
      typeof args[0].persist === "function" &&
      args[0].persist();
    if (!timeout) {
      timeout = window.setTimeout(() => {
        timeout = null;
        fn.apply(ctx, args);
        // execute at a rate of 15fps
      }, 66);
    }
  };
}
