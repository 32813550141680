import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from 'components/atoms/Button';
import { chevronDown, chevronUp } from 'components/atoms/Icon/icons';
import Toggle from 'components/utilities/Toggle';

import * as styles from './InlineDropdown.scss';

const InlineDropdown = ({
  label,
  children,
  className,
  toggleable = true,
  open = false,
  onChange,
}) => (
  <Toggle
    on={open}
    onChange={onChange}
    render={({ on, toggle }) => (
      <div className={className}>
        <IconButton
          transparent
          full
          onClick={toggleable ? toggle : null}
          iconAfter={!!toggleable && (on ? chevronUp : chevronDown)}
          textAlign="left"
          disabled={!toggleable}
          className={toggleable ? styles.headerButton : ''}
        >
          {label}
        </IconButton>
        {on && children}
      </div>
    )}
  />
);

InlineDropdown.displayName = 'InlineDropdown';
InlineDropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  toggleable: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
InlineDropdown.defaultProps = {};

export default InlineDropdown;
