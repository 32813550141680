import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './TripAdvisorWidget.scss';

import Iframe from 'components/utilities/Iframe';
let id = 0;
const simpleId = {
  next: () => {
    id = id + 1;
    return id;
  },
};

const tripAdvisorWidgetUrls = {
  detail: (id, lang) =>
    `https://www.tripadvisor.com/WidgetEmbed-cdspropertydetail?display=true&partnerId=564F0E59F14942E3BBE414D78E5D55CF&lang=${lang}&locationId=${id}`,
  rating: (id, lang) =>
    `https://www.tripadvisor.com/WidgetEmbed-cdsLicensedRating?display=true&partnerId=564F0E59F14942E3BBE414D78E5D55CF&lang=${lang}&locationId=${id}`,
  summary: (id, lang) =>
    `https://www.tripadvisor.com/WidgetEmbed-cdspropertysummary?display=true&partnerId=564F0E59F14942E3BBE414D78E5D55CF&lang=${lang}&locationId=${id}`,
};
class TripAdvisorWidget extends React.Component {
  constructor(props) {
    super(props);
    this.id = `ta-widget-${props.type}-${props.id}-${simpleId.next()}`;
    this.messageHandler = this.getMessageHandler(this.id);
    this.state = {};
  }
  getMessageHandler = id => e => {
    if (
      e.origin === 'https://www.tripadvisor.com' &&
      e.data.widgetData &&
      e.data.frameName === id
    ) {
      // console.log("is widgetData for this iframe");
      this.setState({
        height: e.data.widgetData.height,
        width: e.data.widgetData.width,
      });
    }
  };
  componentDidMount() {
    window.addEventListener('message', this.messageHandler, false);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  render() {
    const style = this.state.height ? { height: this.state.height } : null;
    const { id, type, ...rest } = this.props;
    const src = tripAdvisorWidgetUrls[type](
      id,
      this.props.culture
        ? this.props.culture === 'en'
          ? 'en_UK'
          : this.props.culture
        : 'en_UK',
    ); // TODO: fix language string
    return <Iframe {...rest} src={src} name={this.id} style={style} />;
  }
}
TripAdvisorWidget.displayName = 'TripAdvisorWidget';

TripAdvisorWidget.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};
TripAdvisorWidget.defaultProps = {};

export default TripAdvisorWidget;
