import * as iconCollection from 'components/atoms/Icon/icons';
const reqSvgs = require.context('assets/svgs', false, /\.svg$/);

export const icomoonIcons = iconCollection;

export const svgIcons = reqSvgs
  .keys()
  .map(path => path?.replace('./', '')?.replace('.svg', ''));

export const iconLookup = reqSvgs
  .keys()
  .concat(Object.keys(iconCollection))
  .reduce((images, path) => {
    const dict = images;
    const name = path?.replace('./', '')?.replace('.svg', '');
    if (name) dict[name] = name;
    return images;
  }, {});
