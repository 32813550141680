export const CATEGORY_ACCOMMODATION = 'Accommodation'; // Overnatting
export const CATEGORY_INSPIRATION = 'Inspiration'; // Inspirasjon: Sider (artikler o.a.), videoer
export const CATEGORY_EVENT = 'Event'; // Arrangement: Produkter med hovedtype Arrangement
export const CATEGORY_LOCATION = 'Location'; // Se og Gjøre: Produkter med hovedtype Attraksjoner, Aktiviteter eller Forretninger-Service
export const CATEGORY_FOODANDDRINK = 'FoodAndDrink'; // Mat & Drikke: Produkter med hovedtype Servering
export const CATEGORY_OTHER = 'Other'; // Alt annet (nyttige lenker)
export const CATEGORY_DEFAULT = CATEGORY_OTHER;

export const categoryMap = {
  EpiserverItem: CATEGORY_INSPIRATION,
  Unknown: CATEGORY_OTHER,
  Accommodation: CATEGORY_ACCOMMODATION,
  Event: CATEGORY_EVENT,
  Attraction: CATEGORY_LOCATION,
  Activity: CATEGORY_LOCATION,
  FoodAndDrink: CATEGORY_FOODANDDRINK,
  Entertainment: CATEGORY_OTHER,
  Facility: CATEGORY_LOCATION,
  Shopping: CATEGORY_LOCATION,
  Service: CATEGORY_OTHER,
  Transport: CATEGORY_OTHER,
  Organisation: CATEGORY_OTHER,
  Conference: CATEGORY_OTHER,
  TravelTrade: CATEGORY_OTHER,
  Property: CATEGORY_OTHER,
  Brochure: CATEGORY_OTHER,
  Location: CATEGORY_LOCATION,
  EducationAndTraining: CATEGORY_OTHER,
  Artist: CATEGORY_OTHER,
  RealEstate: CATEGORY_OTHER,
  PackageTrip: CATEGORY_OTHER,
  Hire: CATEGORY_OTHER,
  Production: CATEGORY_OTHER,
  Project: CATEGORY_OTHER,
  Gastronomy: CATEGORY_OTHER,
  News: CATEGORY_OTHER, // This is called General Category in the metadata, but VisitOslo wants to call it News.
  FilmProduction: CATEGORY_OTHER,
  EpiserverSeeAndDo: CATEGORY_LOCATION,
  EpiserverInspiration: CATEGORY_INSPIRATION,
  EpiserverUsefulLinks: CATEGORY_OTHER,
};
