import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import {
  propertyValueFor,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import GallerySlider from 'components/molecules/GallerySlider/GallerySlider';
import * as styles from './GalleryBlock.scss';

const GalleryBlock = ({ block, items }) => {
  const wide = propertyValueFor(block.wideView);
  if (items && items.length) {
    return (
      <BlockWrapper block={block} className={styles.SliderBlock}>
        <GallerySlider
          wide={wide}
          autoPlay={8000}
          slides={items}
          ratio={wide ? [12, 5] : [6, 3]}
        />
      </BlockWrapper>
    );
  }
  return null;
};

GalleryBlock.displayName = 'GalleryBlock';
GalleryBlock.propTypes = {
  block: PropTypes.shape({
    wideView: CustomPropTypes.EpiserverValue,
    galleryItems: CustomPropTypes.EpiserverValue,
  }),
  items: PropTypes.array,
};
GalleryBlock.defaultProps = {};

export default GalleryBlock;
