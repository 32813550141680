import React from "react";
import PropTypes from "prop-types";
import SkipToMainContent from "components/atoms/SkipToMainContent/SkipToMainContent";
import CookieBanner from "components/organisms/CookieBanner";

const DefaultLayout = ({ header, breadcrumbs, footer, children }) => (
  <React.Fragment>
    <SkipToMainContent targetId="main-content" />
    {header}
    {breadcrumbs}
    {children}
    {footer}
    <CookieBanner/>
  </React.Fragment>
);
DefaultLayout.displayName = "DefaultLayout";
DefaultLayout.propTypes = {
  header: PropTypes.node,
  breadcrumbs: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node
};

export default DefaultLayout;
