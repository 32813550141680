import React from "react";
import { resolveComponent } from "../../utils/component-registry";
import { editorAttributesFor } from "../../utils/epi-react";
import Image from "../ImageFallback";
// import Image from "components/atoms/Image";
import { getSizes } from "../ImageFallback/ImageSizes";

function renderHtmlTag(item, i) {
  // allow null value
  if (item) {
    if (item.tag) {
      const attr = Object.assign({ key: i }, item.attr);
      // because there's so much html with "contenteditable" attributes that React throws errors at (and we don't really want the user to edit content)
      delete attr.contenteditable;

      if (item.tag === "img") {
        const props = Object.assign({}, attr);
        let size = "huge";
        if (
          props["width"] &&
          parseInt(props["width"], 10) <= getSizes().large
        ) {
          size = "large";
        }
        const style = {};
        if (props.width || props.height) {
          if (props.width) {
            delete props.width;
          }
          if (props.height) {
            style["maxHeight"] = props.height;
            delete props.height;
          }
        }
        // console.log("image with props", props);
        return <Image {...props} size={size} style={style} />;
      }
      return React.createElement(
        item.tag,
        attr,
        item.children && item.children.map(renderHtmlTag)
      );
    } else if (item.componentName) {
      const Component = resolveComponent(item.componentName);
      const props = Object.assign({ key: i }, item);
      return React.createElement(Component, props);
    } else {
      return item;
    }
  }
  return null;
}

const Html = ({ tagName, className, html, propertyName }) =>
  React.createElement(
    tagName,
    editorAttributesFor(propertyName, className),
    html && html.map(renderHtmlTag)
  );

Html.supportsPropertyForIntegration = true;

export default Html;
