import React from "react";
import DefaultLayoutContaniner from "containers/layouts/DefaultLayoutContainer";
import {Title} from "components/atoms/Heading";
import Container from "components/atoms/Container";
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import { translate } from 'utils/translate';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';


import * as styles from './NotFound404Page.scss';

const NotFound404Page = page => {
  // console.log(page);
  return (
    <DefaultLayoutContainerV2>
      <Container>
        {page.name.$c &&
          <Title>
            {page.name.$c}
          </Title>
        }
        {page.mainBody &&
          <PropertyFor property={page.mainBody} />
        }
        <div className={styles.ingres}>
          <p>{translate('/pages/notFound/youRequested')}: <span className={styles.attemptedPath}>{page.url.href}</span></p>
        </div>
        {page.secondBody &&
          <PropertyFor property={page.secondBody} />
        }
      </Container>
    </DefaultLayoutContainerV2>
  );
};
NotFound404Page.displayName = "NotFound404Page";
NotFound404Page.propTypes = {};
NotFound404Page.defaultProps = {};

export default NotFound404Page;
