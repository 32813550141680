import { nb, enGB, fr, es, de, it } from 'date-fns/locale';
const locales = {
  no: nb,
  en: enGB,
  fr,
  es,
  de,
  it,
};

const defaultLocale = locales.en;

const getLocale = locale => locales[locale || global.LOCALE] || defaultLocale;

export default getLocale;
