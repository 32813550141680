import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import { useTransition } from 'react-spring';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';
import Button from 'components/atoms/Button';
import Timer from 'components/utilities/Timer';

import * as styles from './NotificationsToastContainer.scss';

const NotificationShape = PropTypes.shape({
  icon: PropTypes.any,
  header: PropTypes.string,
  body: PropTypes.node,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  button: PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
  uuid: PropTypes.string.isRequired,
});

const Notification = ({ notification, onDismiss }) => (
  <Timer
    callback={() => onDismiss(notification)}
    delay={notification.delay}
    autostart={!!notification.delay}
    render={({ pause, resume, stop }) => {
      const { header, body, link, button } = notification;
      return (
        <div onMouseEnter={pause} onMouseLeave={resume} className={styles.item}>
          {(!!header || !!body) && (
            <span>
              {!!header && <h1>{header}</h1>}
              {!!body && body}
            </span>
          )}
          {!!link && (
            <Button
              href={link.href}
              onClick={() => onDismiss(notification)}
              className={styles.link}
              transparent
            >
              {link.label}
            </Button>
          )}
          {!!button && (
            <Button
              onClick={() => {
                stop();
                onDismiss(notification);
                button.action();
              }}
              transparent
              className={styles.button}
            >
              {button.label}
            </Button>
          )}
        </div>
      );
    }}
  />
);
Notification.displayName = 'Notification';
Notification.propTypes = {
  notification: NotificationShape.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const NotificationsToastContainer = ({
  breakpoint,
  notifications,
  doDismissNotification,
  max = Infinity,
}) => {
  const items = useMemo(() => notifications.slice(0, max), [
    notifications,
    max,
  ]);
  const transitions = useTransition(items, item => item.uuid, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, pointerEvents: 'none' },
  });
  return (
    <div
      className={
        breakpoint <= BREAKPOINT_PHONE ? styles.mobile : styles.desktop
      }
    >
      {transitions.map(({ key, item, props }) => (
        <div style={props} key={key}>
          <Notification notification={item} onDismiss={doDismissNotification} />
        </div>
      ))}
    </div>
  );
};
NotificationsToastContainer.propTypes = {
  breakpoint: PropTypes.number,
  notifications: PropTypes.array,
  doDismissNotification: PropTypes.func,
  max: PropTypes.number,
};

export default connect(
  'selectBreakpoint',
  'selectNotifications',
  'doDismissNotification',
  NotificationsToastContainer,
);
