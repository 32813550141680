import React from "react";
import { getElements, getCurrent, emitter } from "./core";

class ActiveElementIndicator extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      elements: [],
      current: null
    };
  }
  componentDidMount() {
    emitter.on("change", this.onChange);
    this.onChange();
  }
  componentWillUnmount() {
    emitter.off("change", this.onChange);
  }
  onChange = () => {
    this.setState({
      elements: getElements(),
      current: getCurrent()
    });
  };

  render() {
    return this.props.render({
      elements: this.state.elements,
      current: this.state.current
    });
  }
}

export default ActiveElementIndicator;
