import React from "react";
import PropTypes from "prop-types";

class Toggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      on: this.props.on || false
    };
  }
  handleToggle = () => {
    this.setState(state => ({
      on: !state.on
    }));
  };
  handleSetOn = () => {
    this.setState({ on: true });
  };
  handleSetOff = () => {
    this.setState({ on: false });
  };
  render() {
    return this.props.render({
      on: this.state.on,
      toggle: this.handleToggle,
      setOn: this.handleSetOn,
      setOff: this.handleSetOff
    });
  }
}
Toggle.displayName = "Toggle";
Toggle.propTypes = {
  render: PropTypes.func.isRequired,
  on: PropTypes.bool
};
Toggle.defaultProps = {};

export default Toggle;
