import React from 'react';
import { withColumnContext } from 'components/molecules/PageGrid';
import PropTypes from 'prop-types';

import ScriptSandbox from 'components/utilities/ScriptSandbox';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import Image from 'components/atoms/Image';
import VideoBlock from 'episerver/blocks/VideoBlock';
import Modal from 'components/utilities/Modal';
import { youtubeIdFrom } from 'utils/utils';

import * as styles from './AdBlock.scss';

const VideoAd = props => {
  const { video } = props;
  return (
    <div>
      {props.on && (
        <Modal>
          <button className={styles.closeModal} onClick={() => props.close}>
            Close
          </button>
          <VideoBlock
            block={{ video }}
            videoId={youtubeIdFrom(propertyValueFor(video))}
          />
        </Modal>
      )}
    </div>
  );
};

class AdBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false,
    };
  }
  render() {
    const { block, as: Tag = 'div' } = this.props;
    const image = propertyValueFor(block.image);
    const imageLink = propertyValueFor(block.imageLink);
    const imageAlt = propertyValueFor(block.imageAlt);
    const imageHeight = propertyValueFor(block.imageHeight);
    const imageWidth = propertyValueFor(block.imageWidth);
    const video = propertyValueFor(block.video);
    const videoLinkName = propertyValueFor(block.videoLinkName);
    const openInNewWindow = propertyValueFor(block.openInNewWindow);
    const script = propertyValueFor(block.script);

    return (
      <Tag
        className={classnames(styles.adBlock, {
          [styles.noImage]: !image,
        })}
        type={this.state.on ? 'button' : null}
        onClick={
          this.state.on ? () => this.setState({ on: !this.state.on }) : null
        }
      >
        {video && (image || imageAlt) && (
          <button
            onClick={video ? () => this.setState({ on: !this.state.on }) : null}
            style={imageWidth ? { maxWidth: `${imageWidth}px` } : null}
          >
            <Image src={image} alt={imageAlt} />
          </button>
        )}
        {!video && imageLink && (image || imageAlt) && (
          <Link
            href={imageLink ? imageLink : null}
            target={imageLink && openInNewWindow ? '_blank' : null}
            style={imageWidth ? { maxWidth: `${imageWidth}px` } : null}
          >
            <Image src={image} alt={imageAlt} />
          </Link>
        )}
        {!video && !imageLink && (image || imageAlt) && (
          <div style={imageWidth ? { maxWidth: `${imageWidth}px` } : null}>
            <Image src={image} alt={imageAlt} />
          </div>
        )}
        {video && (
          <Link
            target="_blank"
            href={video}
            onClick={() => this.setState({ on: false })}
          >
            {videoLinkName ? videoLinkName : `Video`}
          </Link>
        )}
        {video && (
          <VideoAd
            video={block.video}
            imageReference={image}
            videoLinkName={videoLinkName}
            on={this.state.on}
            close={() => this.setState({ on: false })}
          />
        )}
        {script && (
          <ScriptSandbox
            content={`<script async charset="utf-8">${script}</script>`}
          />
        )}
      </Tag>
    );
  }
}

AdBlock.displayName = 'AdBlock';
AdBlock.propTypes = {
  block: PropTypes.any,
  blockId: PropTypes.number,
  componentName: PropTypes.string,
};
AdBlock.defaultProps = {};

export default withColumnContext(AdBlock);
