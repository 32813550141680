import React from 'react';
import PropTypes from 'prop-types';
import dlv from 'dlv';

import Link from 'components/atoms/Link';
import Container from 'components/atoms/Container';
import Heading, { H2 } from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import { Paragraph } from 'components/atoms/Text';
import { trimIntro } from 'components/molecules/Teaser';
import { getFormattedDate } from 'utils/dateTime';

import { translate } from 'utils/translate';

import * as styles from './MyPageSectionUpcomingEvents.scss';

const EventItem = ({ eventItem }) => (
  <Paragraph
    size={Paragraph.sizes.small}
    gutterBottom={false}
    className={styles.eventInfo}
  >
    {getFormattedDate(eventItem.fromDate, 'dd. MMM yyyy')}
    {!!eventItem.fromTime && getFormattedDate(eventItem.fromTime, ' p')}
  </Paragraph>
);

const MyPageSectionUpcomingEvents = ({ items }) => (
  <React.Fragment>
    {items.slice(0, 1).map(article => {
      const eventItem = dlv(article, 'eventItems.0', null);
      return (
        <Container noPadding key={article.url}>
          <div className={styles.mainEvent}>
            <Link key={article.url} href={article.url} className={styles.link}>
              <Image
                src={article.imageUrl}
                ratio={[16, 9]}
                fit="cover"
                className={styles.image}
                cropDetails={article.cropDetails}
              />
              <div className={styles.body}>
                <div>
                  <H2 alt bold size={Heading.sizes.heading3}>
                    {article.title}
                  </H2>
                  <Paragraph>{article.intro}</Paragraph>
                </div>
                {!!eventItem && <EventItem eventItem={eventItem} />}
              </div>
            </Link>
          </div>
        </Container>
      );
    })}
    <Container>
      <div className={styles.eventGrid}>
        {items.slice(1).map(article => (
          <div className={styles.item}>
            <Link key={article.url} href={article.url} className={styles.link}>
              <H2 alt bold gutterBottom size={Heading.sizes.heading6}>
                {article.title}
              </H2>
              <Paragraph size={Paragraph.sizes.small}>
                {trimIntro(article.intro, article.title)}
              </Paragraph>
              {article.eventItems.slice(0, 2).map(eventItem => (
                <EventItem
                  eventItem={eventItem}
                  key={`${eventItem.fromDate}_${eventItem.fromTime}`}
                />
              ))}
            </Link>
            {article.eventItems.length > 2 && (
              <Paragraph
                size={Paragraph.sizes.small}
                gutterBottom={false}
                className={styles.moreDates}
              >
                <Link href={article.url}>
                  {translate('/pages/myPage/sections/events/moreDates')}
                </Link>
              </Paragraph>
            )}
          </div>
        ))}
      </div>
    </Container>
  </React.Fragment>
);

MyPageSectionUpcomingEvents.displayName = 'MyPageSectionUpcomingEvents';
MyPageSectionUpcomingEvents.propTypes = {};
MyPageSectionUpcomingEvents.defaultProps = {};

export default MyPageSectionUpcomingEvents;
