import React, {
  useState,
  useEffect,
  Suspense,
  lazy,
  useRef,
  useMemo,
} from 'react';
import { connect } from 'redux-bundler-react';
import classnames from 'classnames';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import useSiteSettings from 'hooks/use-site-settings';
import PageView from 'components/organisms/PageView';
import EventListDateFilter from 'components/organisms/EventListDateFilter';
import PageFiltersContainer from 'containers/organisms/PageFiltersContainer';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
import Toggle from 'components/utilities/Toggle';
import FocusLock from 'components/utilities/FocusLock';
import Modal from 'components/utilities/Modal';
import Button, { IconButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import {
  calendar,
  filter,
  plus,
  chevronLeft,
  chevronRight,
} from 'components/atoms/Icon/icons';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { isUrlForSamePage, appendQueryParams } from 'utils/bundle-helper';
import { translate } from 'utils/translate';
import { getFormattedDate, getDateFromISO } from 'utils/dateTime';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';

import * as styles from './EventListPage.scss';

const ProductFilter = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'components/organisms/ProductFilter'
  ),
);
const EventList = lazy(() =>
  import(
    /* webpackChunkName: "eventlist" */ /* webpackPreload: true */ 'components/organisms/EventList'
  ),
);

const maxPaginationAmount = 20;
const mobilePaginationAmount = 10;
const initialPageNumber = 1;
const EventListPage = ({
  doUpdateUrl = () => {},
  pageData: { categories, eventResponse, url },
  useWideTemplate,
  ...page
}) => {
  const initialNumberOfDays = propertyValueFor(page.numberOfDays);
  const { colorSettings } = useSiteSettings();
  const eventColor = colorSettings.productColors.event;
  const btnRef = useRef(null);
  const showAreaTag = propertyValueFor(page.showArea);
  const isWideTemplate = propertyValueFor(useWideTemplate);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 980);
  const eventListRef = useRef(null);
  const [paginationIndex, setPaginationIndex] = useState(0);

  const style = {};
  if (eventColor) {
    style['--wrapperColor'] = `${eventColor}66`;
  }

  useEffect(() => {
    if (categories && categories.length > 0) {
      const key = 'CategoryIds';
      const newDefaultActiveCategory = {};
      categories.forEach(category => {
        if (category.useDefaultFilter === true) {
          if (!newDefaultActiveCategory[key]) {
            newDefaultActiveCategory[key] = '';
          }
          if (newDefaultActiveCategory[key] !== '') {
            newDefaultActiveCategory[key] += ',';
          }
          newDefaultActiveCategory[key] += category.tellusCategoryIds.join(',');
        }
      });
      if (!page.url.query[key])
        doUpdateUrl(appendQueryParams(page.url, newDefaultActiveCategory));
    }
  }, [categories]);

  const getPaginationAmount = () =>
    isMobile ? mobilePaginationAmount : maxPaginationAmount;

  const getTimeStamp = event => {
    const { fromDate, fromTime } = event;
    const date = getDateFromISO(fromDate, fromTime);
    const formattedDate = getFormattedDate(date, 't');
    return formattedDate;
  };

  const uniqueEvents = useMemo(
    () =>
      eventResponse.events
        .filter(
          (event, index, self) =>
            index ===
            self.findIndex(
              response =>
                response.id === event.id &&
                response.title === event.title &&
                response.fromTime === event.fromTime &&
                response.fromDate === event.fromDate,
            ),
        )
        .sort((a, b) => getTimeStamp(a).localeCompare(getTimeStamp(b))),
    [eventResponse.events],
  );

  const handlePageClick = pageNumber => {
    const newPaginationIndex = (pageNumber - 1) * getPaginationAmount();
    setPaginationIndex(newPaginationIndex);

    // Optionally, handle scrolling
    if (eventListRef.current) {
      setTimeout(() => {
        eventListRef.current.scrollIntoView({ block: 'start' });
      }, 10);
    }
  };

  const eventsOnCurrentPage = useMemo(() => {
    const startIndex = paginationIndex;
    const endIndex = startIndex + getPaginationAmount();
    return uniqueEvents.slice(startIndex, endIndex);
  }, [paginationIndex, uniqueEvents, getPaginationAmount]);

  const totalEvents = uniqueEvents.length;
  const isUniqueEventsArray = totalEvents > 0;

  const currentPage = Math.floor(paginationIndex / getPaginationAmount()) + 1;
  const totalPages = Math.ceil(totalEvents / getPaginationAmount());

  const buttonRange = isMobile ? 5 : 10;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 980);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handlePageClick(initialPageNumber);
    };
  }, [initialPageNumber]);

  let startPage = Math.max(1, currentPage - Math.floor(buttonRange / 2));
  const endPage = Math.min(totalPages, startPage + buttonRange - 1);

  if (totalPages - startPage < buttonRange) {
    startPage = Math.max(1, totalPages - buttonRange + 1);
  }

  const handlePreviousBatchClick = () => {
    const newStartPage = Math.max(1, startPage - buttonRange);
    handlePageClick(newStartPage);
  };

  const handleNextBatchClick = () => {
    const newStartPage = Math.min(totalPages, endPage + 1);
    handlePageClick(newStartPage);
  };

  const groupFilters = (
    groups = {},
    type,
    filters,
    toggleable,
    dropdown = false,
  ) => {
    const filterGroup = groups;
    if (!filters || !type) {
      return null;
    }
    const group = {
      filterType: type,
      filters,
      toggleable: toggleable || filters.length > 10,
      dropdown,
    };
    if (filterGroup[type]) {
      filterGroup[type].push(group);
    } else {
      filterGroup[type] = group;
    }
    return filterGroup;
  };

  return (
    <DefaultLayoutContainerV2>
      <PageView {...page} showShareButtons isV2design color={eventColor}>
        <div ref={eventListRef} className={styles.eventCalendarWrapper}>
          <BreakpointContainer
            render={breakpoint => {
              const compact = breakpoint <= BREAKPOINT_TABLET_PORTRAIT;
              return (
                <div
                  className={classnames(styles.dateAndfilters, {
                    [styles.compact]: compact,
                  })}
                >
                  {compact && (
                    <div className={styles.compactSelectButtons}>
                      <Toggle
                        on={false}
                        render={({ on, setOn, setOff }) => (
                          <React.Fragment>
                            <IconButton
                              transparent
                              textAlign="left"
                              className={styles.button}
                              iconAfter={calendar}
                              onClick={() => {
                                setOn();
                                setTimeout(() => {
                                  if (btnRef.current) btnRef.current.focus();
                                }, 100);
                              }}
                            >
                              {translate('/pages/eventListPage/searchByDate')}
                            </IconButton>

                            {on && (
                              <FocusLock>
                                <Modal
                                  fullscreen
                                  scrollLock
                                  closeBtn={
                                    <Button
                                      ref={btnRef}
                                      onClick={setOff}
                                      transparent
                                      className={styles.closeFilter}
                                    >
                                      {`${translate(
                                        '/global/close',
                                      )} ${translate(
                                        '/pages/eventListPage/calendar',
                                      ).toLowerCase()}`}
                                      <Icon icon={plus} />
                                    </Button>
                                  }
                                >
                                  <Modal.Body
                                    className={styles.fullscreenCalendar}
                                    style={{
                                      background: `${eventColor}66`,
                                    }}
                                  >
                                    <div className={styles.calendar}>
                                      <EventListDateFilter
                                        url={page.url}
                                        compact={compact}
                                        initialNumberOfDays={
                                          initialNumberOfDays
                                        }
                                      />
                                    </div>
                                  </Modal.Body>
                                </Modal>
                              </FocusLock>
                            )}
                          </React.Fragment>
                        )}
                      />

                      <PageFiltersContainer>
                        {(showFilters, showLocationButton, filters, cats) => {
                          let filterGroups = {};
                          if (cats && cats.length > 1)
                            filterGroups = groupFilters(
                              filterGroups,
                              'CategoryIds',
                              cats.sort(
                                (a, b) =>
                                  b.useDefaultFilter - a.useDefaultFilter,
                              ),
                            );

                          if (filters?.length > 0)
                            filters.forEach(area => {
                              filterGroups = groupFilters(
                                filterGroups,
                                area.filterType,
                                area.filters,
                              );
                            });

                          return (
                            showFilters && (
                              <Toggle
                                on={false}
                                render={({ on, setOn, setOff }) => (
                                  <React.Fragment>
                                    <IconButton
                                      transparent
                                      textAlign="left"
                                      className={styles.button}
                                      iconAfter={filter}
                                      onClick={() => {
                                        setOn();
                                        setTimeout(() => {
                                          if (btnRef.current)
                                            btnRef.current.focus();
                                        }, 100);
                                      }}
                                    >
                                      {translate(
                                        '/pages/eventListPage/filters',
                                      )}
                                    </IconButton>

                                    {on && (
                                      <FocusLock>
                                        <Modal
                                          fullscreen
                                          scrollLock
                                          closeBtn={
                                            <Button
                                              ref={btnRef}
                                              onClick={setOff}
                                              transparent
                                              className={styles.closeFilter}
                                            >
                                              {translate(
                                                '/pages/productListPage/closeFilter',
                                              )}
                                              <Icon icon={plus} />
                                            </Button>
                                          }
                                        >
                                          <Modal.Body
                                            className={styles.fullscreenFilter}
                                            style={{
                                              background: `${eventColor}66`,
                                            }}
                                          >
                                            <Suspense
                                              fallback={<LoadingIndicator />}
                                            >
                                              <ProductFilter
                                                url={page.url}
                                                className={styles.filter}
                                                filterPage="Event"
                                                filterGroup={filterGroups}
                                                color={eventColor}
                                                compact={compact}
                                                onSetFiltersBtnClick={() =>
                                                  setOff(btnRef)
                                                }
                                              />
                                            </Suspense>
                                          </Modal.Body>
                                        </Modal>
                                      </FocusLock>
                                    )}
                                  </React.Fragment>
                                )}
                              />
                            )
                          );
                        }}
                      </PageFiltersContainer>
                    </div>
                  )}
                  {!compact && (
                    <React.Fragment>
                      <h3>{translate('/pages/eventListPage/searchByDate')}:</h3>
                      <EventListDateFilter
                        url={page.url}
                        compact={compact}
                        initialNumberOfDays={initialNumberOfDays}
                      />
                    </React.Fragment>
                  )}
                  {!compact && (
                    <React.Fragment>
                      <h3>{translate('/pages/eventListPage/filters')}:</h3>
                      <div className={styles.filtersWrapper}>
                        <PageFiltersContainer>
                          {(showFilters, showLocationButton, filters, cats) => {
                            let filterGroups = {};
                            if (cats && cats.length > 1)
                              filterGroups = groupFilters(
                                filterGroups,
                                'CategoryIds',
                                cats.sort(
                                  (a, b) =>
                                    b.useDefaultFilter - a.useDefaultFilter,
                                ),
                              );

                            filters.forEach(area => {
                              filterGroups = groupFilters(
                                filterGroups,
                                area.filterType,
                                area.filters,
                              );
                            });

                            return (
                              showFilters &&
                              filterGroups !== null && (
                                <React.Fragment>
                                  <Suspense fallback={<LoadingIndicator />}>
                                    <ProductFilter
                                      dropdown={false}
                                      url={page.url}
                                      className={styles.filter}
                                      filterPage="Event"
                                      filterGroup={filterGroups}
                                      color={eventColor}
                                    />
                                  </Suspense>
                                </React.Fragment>
                              )
                            );
                          }}
                        </PageFiltersContainer>
                      </div>
                    </React.Fragment>
                  )}
                  {!!page.isLoading &&
                    isUrlForSamePage(page.url, page.nextUrl) && (
                      <LoadingIndicator background="rgba(255,255,255,0.4)" />
                    )}
                </div>
              );
            }}
          />
          <>
            <div className={styles.eventlistPaginationWrapper}>
              <div>
                {isUniqueEventsArray ? (
                  <Suspense fallback={<LoadingIndicator />}>
                    <EventList
                      products={eventsOnCurrentPage}
                      productPageUrl={page.url.path}
                      groupBy="day"
                      dateVariant={isWideTemplate ? 'dateTime' : 'time'}
                      eventColor={eventColor}
                      showAreaTag={showAreaTag}
                    />
                  </Suspense>
                ) : (
                  <p>No events to display.</p>
                )}
              </div>
              <div
                className={styles.paginationContainer}
                aria-label="Pagination"
              >
                {!isMobile && startPage > 1 && (
                  <button
                    type="button"
                    className={styles.paginationButton}
                    onClick={handlePreviousBatchClick}
                    aria-label="Previous batch of pages"
                  >
                    <Icon icon={chevronLeft} />
                  </button>
                )}
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <button
                    type="button"
                    className={classnames({
                      [styles.selectedButton]:
                        currentPage === startPage + index,
                      [styles.paginationButton]:
                        currentPage !== startPage + index,
                    })}
                    aria-label={`Go to page ${startPage + index}`}
                    key={startPage + index}
                    onClick={() => handlePageClick(startPage + index)}
                    disabled={currentPage === startPage + index}
                  >
                    {startPage + index}
                  </button>
                ))}
                {!isMobile && endPage < totalPages && (
                  <button
                    type="button"
                    className={styles.paginationButton}
                    onClick={handleNextBatchClick}
                    aria-label="Next batch of pages"
                  >
                    <Icon icon={chevronRight} />
                  </button>
                )}
              </div>
            </div>
          </>
        </div>
      </PageView>
    </DefaultLayoutContainerV2>
  );
};
EventListPage.displayName = 'EventListPage';
EventListPage.propTypes = {};
EventListPage.defaultProps = {};

export default connect('selectPageData', 'doUpdateUrl', EventListPage);
