import React, { useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Block, { ratioPropType } from 'components/atoms/Block/Block';
import Image from 'components/atoms/Image';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import Icon from 'components/atoms/Icon';

import { chevronLeft, chevronRight } from 'components/atoms/Icon/icons';

import { LinkButton } from 'components/atoms/Button';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';
import * as styles from './GallerySlider.scss';
const Slider = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/Slider'
  ),
);
const SlideTrack = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SlideTrack'
  ),
);
const FadeTrack = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/FadeTrack'
  ),
);
const AutoPlay = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/AutoPlay'
  ),
);
const SliderState = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SliderState'
  ),
);

const ThumbNailNavigation = ({
  onSelect,
  items = [],
  selectedItem = { index: 0 },
}) => (
  <Suspense fallback={<LoadingIndicator />}>
    <Slider items={items}>
      <BreakpointContainer
        render={breakpoint => (
          <div className={styles.navContainer}>
            <div className={styles.nav}>
              <div className={styles.navItems}>
                <SlideTrack
                  dragTreshold={100}
                  swipe={breakpoint > BREAKPOINT_PHONE && items.length > 2}
                  direction="horizontal"
                  height="min"
                  renderItem={({ item, getItemProps }) => (
                    <div
                      className={classnames(styles.navItem, {
                        [styles.activeNavItem]:
                          item.index === selectedItem.index,
                      })}
                      {...getItemProps()}
                    >
                      <LinkButton
                        className={styles.navItemButton}
                        onClick={() => onSelect(item)}
                        key={`item_${item.image}`}
                      >
                        <Image
                          src={`${item.image}?preset=GalleryThumbnail`}
                          fit="cover"
                          cropDetails={item.cropDetails || []}
                          lazy={{ height: 151 }}
                        />
                      </LinkButton>
                    </div>
                  )}
                />
              </div>

              {items.length > 3 && breakpoint > BREAKPOINT_PHONE && (
                <SliderState>
                  {({ isFirst, actions, index, count }) => (
                    <React.Fragment>
                      <LinkButton
                        className={classnames(styles.navButton, styles.prev)}
                        onClick={actions.prev}
                        disabled={isFirst}
                      >
                        <Icon
                          icon={chevronLeft}
                          role="presentation"
                          size={['1.4em', '1.4em']}
                        />
                      </LinkButton>
                      <LinkButton
                        className={classnames(styles.navButton, styles.next)}
                        onClick={actions.next}
                        disabled={index === count - 2}
                      >
                        <Icon
                          icon={chevronRight}
                          role="presentation"
                          size={['1.4em', '1.4em']}
                        />
                      </LinkButton>
                    </React.Fragment>
                  )}
                </SliderState>
              )}
            </div>
          </div>
        )}
      />
    </Slider>
  </Suspense>
);
ThumbNailNavigation.propTypes = {
  onSelect: PropTypes.func,
  items: PropTypes.array,
  selectedItem: PropTypes.object,
};

const GallerySlider = React.memo(
  ({ slides, className, autoPlay, ratio, wide, onlySlider }) => {
    const [hasNavigated, setHasNavigated] = useState(false);
    const items = slides.map((s, i) => ({
      ...s,
      id: s.image + i.toString(),
      index: i,
    }));
    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Slider items={items}>
          <div
            className={classnames(styles.root, className, {
              [styles.wide]: wide,
            })}
          >
            {!onlySlider && (
              <Block ratio={ratio || [3, 2]} className={styles.main}>
              {!hasNavigated && autoPlay && <AutoPlay interval={autoPlay} />}
              <FadeTrack
                className={styles.mainTrack}
                renderItem={({ item, getItemProps }) => (
                  <div
                    {...getItemProps()}
                    className={classnames(styles.sliderItem, styles.mainItem)}
                  >
                    <Image
                      ratio={ratio || [3, 2]}
                      fit={ratio ? 'cover' : 'none'}
                      cropDetails={item.cropDetails || []}
                      src={item.image}
                      draggable={false}
                      copy={item.imageFileCopyright}
                      description={item.imageFileDescription}
                      alt={item.altText}
                    />
                  </div>
                )}
              />
            </Block>
            )} 
            {items.length > 1 && (
              <SliderState>
                {({ actions, index }) => (
                  <ThumbNailNavigation
                    items={items}
                    selectedItem={items.find(item => item.index === index)}
                    onSelect={item => {
                      setHasNavigated(true);
                      actions.goTo(item.index);
                    }}
                  />
                )}
              </SliderState>
            )}
          </div>
        </Slider>
      </Suspense>
    );
  },
);

GallerySlider.displayName = 'GallerySlider';
GallerySlider.propTypes = {
  slides: PropTypes.array.isRequired,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /** className for the root node */
  className: PropTypes.string,
  ratio: ratioPropType,
  wide: PropTypes.bool,
};
GallerySlider.defaultProps = {};

export default GallerySlider;
