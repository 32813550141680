import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sticky from 'components/utilities/Sticky';
import {
  heartOpen,
  chevronUp,
  chevronDown,
  language,
  cross,
  magnifyer,
} from 'components/atoms/Icon/icons';
import Container from 'components/atoms/Container';
import Button, { IconButton } from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import NavigationV2 from 'components/molecules/NavigationV2';
import TopbarV2 from 'components/organisms/TopbarV2';
import RegionMenu2 from 'components/organisms/RegionMenu2';
import MenuToggler from 'components/utilities/MenuToggler';
import ConditionalWrap from 'components/utilities/ConditionalWrap';
import { translate } from 'utils/translate';
import { BREAKPOINT_TABLET_LANDSCAPE } from 'utils/breakpoint';
import { newShade } from 'utils/hex';

// search
import SearchFormContainerV2 from 'containers/molecules/SearchFormContainerV2';
import TeaserList, { teaserConfig } from '../TeaserList';
import * as styles from './DesktopHeaderV2.scss';

const HeaderPropTypes = {
  logo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  professionalsMenu: PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.array,
  }),
  languageLinks: PropTypes.array,
  quickSearchText: PropTypes.string,
  userMenu: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
    url: PropTypes.string,
    logoutAction: PropTypes.func,
  }),
  menuItems: PropTypes.array,
  regionMenu: PropTypes.object,
  isSticky: PropTypes.bool,
  searchPageLink: PropTypes.any,
};

const { megaMenu } = INITIAL_DATA;

const DesktopHeaderV2 = ({
  logo,
  professionalsMenu,
  languageLinks,
  quickSearchText,
  searchPageLink,
  userMenu,
  menuItems,
  regionMenu,
  isSticky,
  breakpoint,
}) => {
  const desktopDown = breakpoint <= BREAKPOINT_TABLET_LANDSCAPE;

  // states
  const [searchActive, setSearchActive] = useState(false);
  const [viewLanguages, setViewLanguages] = useState(false);
  const [viewFavorites, setViewFavorites] = useState(false);

  const hideLang = () => setViewLanguages(false);
  const hideSearch = () => setSearchActive(false);

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  searchActive
    ? (document.body.style = 'position:fixed;width:100%')
    : (document.body.style.removeProperty('position'),
      document.body.style.removeProperty('width'));

  viewLanguages
    ? (document.body.style = 'position:fixed;width:100%')
    : (document.body.style.removeProperty('position'),
      document.body.style.removeProperty('width'));

  viewFavorites
    ? (document.body.style = 'position:fixed;width:100%')
    : (document.body.style.removeProperty('position'),
      document.body.style.removeProperty('width'));

  function resetStates() {
    setViewLanguages(false);
    setSearchActive(false);
    setViewFavorites(false);
  }

  return (
    <div className={styles.root}>
      <TopbarV2>
        <MenuToggler
          closeOnOutsideClick
          render={({ isOpen, clickOpenerProps, ref }) => (
            <React.Fragment>
              <TopbarV2.Button
                {...clickOpenerProps}
                className={classnames(styles.regionMenuButton, {
                  [styles.isOpen]: isOpen,
                })}
                buttonClassName={styles.regionMenuButtonIcon}
                icon={isOpen ? chevronUp : chevronDown}
              >
                {translate('/regionMenu/exploreTheRegion')}
              </TopbarV2.Button>
              {isOpen && (
                <RegionMenu2 {...regionMenu} innerRef={ref} megaMenu />
              )}
            </React.Fragment>
          )}
        />
        {!!professionalsMenu && !!professionalsMenu.label && (
          <TopbarV2.Menu
            buttonClassName={styles.professionalsMenuButton}
            label={professionalsMenu.label}
            items={professionalsMenu.items}
          />
        )}
      </TopbarV2>
      <ConditionalWrap
        condition={!!isSticky}
        wrap={children => (
          <Sticky
            stickyClassName={styles.isSticky}
            innerClassName={styles.stickyInner}
            placeholderClassName={styles.hiddenOnScroll}
            outerClassName={styles.stickyHeaderWrapper}
          >
            {children}
          </Sticky>
        )}
      >
        <header className={styles.header}>
          <Container wide className={styles.container}>
            {!!logo.link && !!logo.desktopImage && (
              <a href={logo.link} className={styles.logoWrapper}>
                <img
                  src={logo.desktopImage}
                  className={styles.logo}
                  alt={translate('/menu/MenuLogoAlt')}
                />
              </a>
            )}

            <nav className={styles.navigation}>
              <Container noPadding className={styles.navigationContainer}>
                <NavigationV2
                  direction="horizontal"
                  render={({ openIndex, setOpenIndex }) => (
                    <>
                      {megaMenu.menuItems.slice(0, 6).map((item, itemIndex) => {
                        const featuredItem = item.featured
                          ? item.featured.filter(item => !!item.url)
                          : [];

                        const subItems = item.children
                          ? item.children.filter(item => !!item.url)
                          : [];

                        const menuColorTheme =
                          item.colorTheme && JSON.parse(item.colorTheme);

                        const color = menuColorTheme
                          ? menuColorTheme.Color
                          : '#daeef4';

                        const style = {};
                        if (color) {
                          style['--darkerMenuColorTheme'] = newShade(
                            color,
                            -15,
                          );
                        }

                        return (
                          <React.Fragment key={item.url || item.name}>
                            <NavigationV2.DesktopItem
                              label={item.name}
                              url={item.url}
                              isCurrent={item.isCurrent}
                              menuLevel={0}
                              menuColor={color}
                              isOpen={itemIndex === openIndex}
                              setOpen={() => setOpenIndex(itemIndex)}
                              setClose={() => setOpenIndex(null)}
                            >
                              {!!subItems.length && (
                                <div className={styles.fullWidthMenu}>
                                  <NavigationV2
                                    closeOnClickOutside
                                    direction="vertical"
                                    className={classnames(
                                      styles.subMenu,
                                      styles.firstMenuLevel,
                                    )}
                                    render={({
                                      openIndex: subOpenIndex,
                                      setOpenIndex: setSubOpenIndex,
                                    }) => (
                                      <>
                                        <div className={styles.subMenuOuter}>
                                          <a
                                            href={item.url ? item.url : null}
                                            className={classnames(
                                              styles.link,
                                              styles.subMenuTitle,
                                            )}
                                            style={{
                                              borderColor:
                                                style['--darkerMenuColorTheme'],
                                            }}
                                          >
                                            {item.name}
                                          </a>
                                          <div
                                            className={styles.secondLevelItems}
                                          >
                                            {subItems.map(
                                              (subItem, subItemIndex) => {
                                                const featuredSubItem = subItem.featured
                                                  ? subItem.featured.filter(
                                                      subItem =>
                                                        !!subItem.url &&
                                                        !!subItem.imageUrl,
                                                    )
                                                  : [];

                                                const subSubItems = subItem.children
                                                  ? subItem.children.filter(
                                                      item => !!item.url,
                                                    )
                                                  : [];

                                                subOpenIndex !== null
                                                  ? setSubMenuOpen(true)
                                                  : setSubMenuOpen(false);

                                                return (
                                                  <NavigationV2.DesktopItem
                                                    className={styles.subItem}
                                                    linkClassName={
                                                      styles.subItemButton
                                                    }
                                                    key={subItem.url}
                                                    label={subItem.name}
                                                    url={subItem.url}
                                                    featured={subItem}
                                                    isCurrent={
                                                      subItem.isCurrent
                                                    }
                                                    menuLevel={1}
                                                    menuColor={color}
                                                    isOpen={
                                                      subItemIndex ===
                                                      subOpenIndex
                                                    }
                                                    setOpen={() =>
                                                      setSubOpenIndex(
                                                        subItemIndex,
                                                      )
                                                    }
                                                    setClose={() =>
                                                      setSubOpenIndex(null)
                                                    }
                                                  >
                                                    {subSubItems.length && (
                                                      <NavigationV2
                                                        direction="vertical"
                                                        className={classnames(
                                                          styles.subMenu,
                                                        )}
                                                      >
                                                        <div
                                                          className={
                                                            styles.subSubMenuWrapper
                                                          }
                                                        >
                                                          <a
                                                            href={
                                                              subItem.url
                                                                ? subItem.url
                                                                : null
                                                            }
                                                            className={classnames(
                                                              styles.link,
                                                              styles.subMenuTitle,
                                                              styles.subsubMenuTitle,
                                                            )}
                                                            style={{
                                                              borderColor:
                                                                style[
                                                                  '--darkerMenuColorTheme'
                                                                ],
                                                            }}
                                                          >
                                                            {subItem.name}
                                                          </a>
                                                          <div
                                                            className={
                                                              styles.subsubWrapper
                                                            }
                                                          >
                                                            {subSubItems.map(
                                                              subSubItem => (
                                                                <NavigationV2.DesktopItem
                                                                  className={
                                                                    styles.subItem
                                                                  }
                                                                  key={
                                                                    subSubItem.url
                                                                  }
                                                                  label={
                                                                    subSubItem.name
                                                                  }
                                                                  url={
                                                                    subSubItem.url
                                                                  }
                                                                  isCurrent={
                                                                    subSubItem.isCurrent
                                                                  }
                                                                  menuLevel={2}
                                                                  menuColor={
                                                                    color
                                                                  }
                                                                />
                                                              ),
                                                            )}
                                                          </div>
                                                        </div>

                                                        {!!featuredSubItem.length &&
                                                          subMenuOpen && (
                                                            <div
                                                              className={
                                                                styles.featuredContainer
                                                              }
                                                            >
                                                              <h2
                                                                className={
                                                                  styles.featuredTitle
                                                                }
                                                              >
                                                                {
                                                                  subItem.featuredTitle
                                                                }
                                                              </h2>

                                                              <TeaserList
                                                                className="featuredTitleText"
                                                                columnCount={2}
                                                                showFavoriteButton
                                                                megaMenuTeaser
                                                                config={
                                                                  teaserConfig.imageAndTitle
                                                                }
                                                                items={featuredSubItem.map(
                                                                  subItem => ({
                                                                    url:
                                                                      subItem.url,
                                                                    title:
                                                                      subItem.name,
                                                                    myFavoritesId:
                                                                      subItem.myFavoritesId,
                                                                    imageUrl:
                                                                      subItem.imageUrl,
                                                                  }),
                                                                )}
                                                              />
                                                            </div>
                                                          )}
                                                      </NavigationV2>
                                                    )}
                                                  </NavigationV2.DesktopItem>
                                                );
                                              },
                                            )}
                                          </div>
                                        </div>

                                        {!!featuredItem.length && !subMenuOpen && (
                                          <div
                                            className={styles.featuredContainer}
                                          >
                                            <h2
                                              className={styles.featuredTitle}
                                            >
                                              {item.featuredTitle}
                                            </h2>

                                            <TeaserList
                                              className="featuredTitleText"
                                              columnCount={2}
                                              showFavoriteButton
                                              megaMenuTeaser
                                              config={
                                                teaserConfig.imageAndTitle
                                              }
                                              items={featuredItem.map(item => ({
                                                url: item.url,
                                                title: item.name,
                                                myFavoritesId:
                                                  item.myFavoritesId,
                                                imageUrl: item.imageUrl,
                                              }))}
                                            />
                                          </div>
                                        )}

                                        <IconButton
                                          iconAfter={cross}
                                          className={styles.exitButton}
                                          onClick={() => setOpenIndex(null)}
                                          aria-label={translate(
                                            '/menu/closeMenuAria',
                                          )}
                                        ></IconButton>
                                      </>
                                    )}
                                  ></NavigationV2>
                                </div>
                              )}
                            </NavigationV2.DesktopItem>
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                ></NavigationV2>
              </Container>
            </nav>

            <div className={styles.langSearchContainer}>
              {!!languageLinks && (
                <Button
                  transparent
                  className={classnames(styles.menuIconButton, {
                    [styles.activeUnderline]: viewLanguages,
                  })}
                  aria-label={translate('/menu/lang')}
                  onClick={() => {
                    resetStates();
                    setViewLanguages(!viewLanguages);
                  }}
                >
                  <div className={styles.languageList}>
                    <Icon icon={language} className={styles.languageIcon} />
                    <span className={styles.rightMenuSpan}>
                      {
                        languageLinks.find(
                          element => element.isCurrentLanguage === true,
                        ).name
                      }
                    </span>
                  </div>
                </Button>
              )}

              {viewLanguages && (
                <div className={styles.languageWrapper}>
                  <div>
                    <h2>{translate('/menu/lang')}</h2>

                    <div className={styles.languageInnerWrapper}>
                      {languageLinks.map(lang => (
                        <div key={lang.url}>
                          <Button
                            href={lang.url}
                            className={classnames({
                              [styles.currentLanguage]: lang.isCurrentLanguage,
                            })}
                            transparent
                          >
                            {lang.name}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {userMenu.isLoggedIn ? (
                <TopbarV2.Menu
                  className={styles.myPageButton}
                  loggedInFavorites
                  items={[
                    {
                      linkText: translate('/menu/logOut'),
                      action: userMenu.logoutAction,
                    },
                  ]}
                  href={userMenu.url}
                  label={
                    <React.Fragment>
                      <Image
                        src={userMenu.user.profilePictureUrl}
                        ratio={[1, 1]}
                        className={classnames(styles.avatar, {
                          [styles.noButtontext]: desktopDown,
                        })}
                        fit="cover"
                      />
                      {translate('/menu/myFavorites')}
                    </React.Fragment>
                  }
                />
              ) : (
                <Button
                  as="a"
                  href={userMenu.url}
                  transparent
                  className={classnames(
                    styles.favorites,
                    styles.menuIconButton,
                    {
                      [styles.activeUnderline]: viewFavorites,
                    },
                  )}
                  aria-label={translate('/menu/myFavorites')}
                  onClick={() => {
                    resetStates();
                    setViewFavorites(!viewFavorites);
                  }}
                >
                  <Icon icon={heartOpen} className={styles.favoritesIconOpen} />

                  {!desktopDown && (
                    <span className={styles.rightMenuSpan}>
                      {translate('/menu/myFavorites')}
                    </span>
                  )}
                </Button>
              )}

              <Button
                transparent
                className={classnames(styles.menuIconButton, {
                  [styles.activeUnderline]: searchActive,
                })}
                aria-label={translate('/search/searchButton')}
                onClick={() => {
                  resetStates();
                  setSearchActive(!searchActive);
                }}
              >
                <div
                  className={classnames(
                    styles.iconWrapper,
                    styles.searchButton,
                  )}
                >
                  <Icon icon={magnifyer} className={styles.searchIcon} />
                </div>
              </Button>

              {searchActive && (
                <div className={styles.searchWrapper}>
                  <Icon
                    icon={magnifyer}
                    className={styles.searchIconAbsolute}
                  />
                  <SearchFormContainerV2
                    action={searchPageLink}
                    className={styles.searchForm}
                    inputClassName={styles.searchFormInput}
                    placeholder={quickSearchText}
                    showSecondarySearchButton
                    isDesktopMenu
                  />
                </div>
              )}
            </div>
          </Container>
        </header>
      </ConditionalWrap>
    </div>
  );
};

DesktopHeaderV2.displayName = 'DesktopHeaderV2';
DesktopHeaderV2.propTypes = HeaderPropTypes;

export default DesktopHeaderV2;
