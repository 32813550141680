import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { editorAttributesFor } from 'packages/inmeta-epi-react/utils/epi-react';
import isInEditMode from 'packages/inmeta-epi-react/utils/is-in-edit-mode';
import styles from './Text.scss';
import fontSizes from './TextSizes.scss';

// usage pattern <Text size={Text.sizes.heading1} as="p" center truncate gutterBottom caps />
// a variant should only have:
// * font-family
// * font-size
// * font weight (only bold)
// * caps
// * center
// It should not include
// * colors
// * margins
// * paddings
// * line-height
// * text-alignment

const Text = React.forwardRef(
  (
    {
      as = 'span',
      tagName,
      size,
      alt,
      center,
      bold,
      emphasized,
      caps,
      gutterBottom,
      truncate,
      breakWord,
      className,
      underline,
      propertyName,
      children,
      ...props
    },
    ref,
  ) => {
    // no size == use whatever font-size is the current size
    const Element = as; //  || tagName;
    // we don't render if no children ...
    return !isInEditMode() && !children ? null : (
      <Element
        ref={ref}
        {...editorAttributesFor(
          propertyName,
          classnames(
            styles.root,
            size,
            {
              [styles.truncate]: truncate,
              [styles.breakWord]: breakWord,
              [styles.caps]: caps,
              [styles.center]: center,
              [styles.alternateFont]: alt,
              [styles.bold]: bold,
              [styles.emphasized]: emphasized,
              [styles.gutterBottom]: gutterBottom,
            },
            className,
          ),
        )}
        {...props}
      >
        {children}
        {!!underline && (
          <span
            className={styles.underline}
            style={{ borderBottomColor: underline }}
          ></span>
        )}
      </Element>
    );
  },
);
Text.displayName = 'Text';
Text.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tagName: PropTypes.string,
  className: PropTypes.string,
  truncate: PropTypes.bool,
  breakWord: PropTypes.bool,
  caps: PropTypes.bool,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  alt: PropTypes.bool,
  emphasized: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(fontSizes)),
  underline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
Text.defaultProps = {
  truncate: false,
  breakWord: false,
  caps: false,
  bold: false,
  center: false,
  gutterBottom: false,
};
Text.sizes = fontSizes;
Text.supportsPropertyForIntegration = true;

export const createTextVariation = (displayName, fn) => {
  const Component = React.forwardRef(fn);
  Component.displayName = displayName;
  Component.sizes = fontSizes;
  Component.supportsPropertyForIntegration = true;
  return Component;
};

export const Paragraph = createTextVariation('Paragraph', (props, ref) => (
  <Text as="p" size={Text.sizes.normal} gutterBottom {...props} ref={ref} />
));
export const TinyText = createTextVariation('TinyText', (props, ref) => (
  <Text size={Text.sizes.tiny} {...props} ref={ref} />
));
export const SmallText = createTextVariation('SmallText', (props, ref) => (
  <Text size={Text.sizes.small} {...props} ref={ref} />
));
export const LargeText = createTextVariation('LargeText', (props, ref) => (
  <Text size={Text.sizes.large} {...props} ref={ref} />
));

export default Text;
