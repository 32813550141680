import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

export const transformTheme = theme => ({
  image: propertyValueFor(theme.themeImage),
  fontColor: propertyValueFor(theme.themeFontColor),
  title: propertyValueFor(theme.title),
  id: propertyValueFor(theme.blockId),
});

export const youtubeIdFrom = url => {
  if (url && url.indexOf('v=') > 0) {
    let videoId = url.split('v=')[1];
    const ampersandPosition = videoId.length ? videoId.indexOf('&') : 0;
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }
  return null;
};

export const vimeoIdFrom = url => {
  if (url) {
    let videoId = url.split('com/')[1];
    if (videoId && videoId.indexOf('&') !== -1) {
      videoId = videoId.substring(0, videoId.indexOf('&'));
    }
    return videoId;
  }
  return null;
};

export const getVideoPlatform = url => {
  const platform = url.split(new RegExp('(vimeo|youtube|youtu)'));
  // console.log(platform);
  return platform.filter(str => {
    switch (str) {
      case 'vimeo':
        return 'vimeo';
      case 'youtube':
        return 'youtube';
      case 'youtu':
        return 'youtube';
      default:
        return null;
    }
  })[0];
};

const vimeoCache = {};
export const getVimeoVideoData = url => {
  const id = vimeoIdFrom(url);
  if (vimeoCache[id]) {
    return Promise.resolve(vimeoCache[id]);
  }
  return fetch(`https://vimeo.com/api/v2/video/${id}.json`)
    .then(response => response.json())
    .then(data => {
      vimeoCache[id] = {
        url,
        thumbnail_url: data && data[0] && data[0].thumbnail_medium,
      };
      return vimeoCache[id];
    });
};

export const getYoutubeVideoData = url => {
  const youtubeId = youtubeIdFrom(url);
  if (!!youtubeId) {
    return Promise.resolve({
      url,
      thumbnail_url: `https://img.youtube.com/vi/${youtubeId}/0.jpg`,
    });
  } else {
    return null;
  }
};

export const getVideoData = url => {
  const platform = getVideoPlatform(url);
  switch (platform) {
    case 'vimeo':
      return getVimeoVideoData(url);
    case 'youtube':
      return getYoutubeVideoData(url);
    default:
      return null;
  }
};

export const getVideoId = url => {
  const platform = getVideoPlatform(url);
  switch (platform) {
    case 'vimeo':
      return vimeoIdFrom(url);
    case 'youtube':
      return youtubeIdFrom(url);
    default:
      return null;
  }
};

export const median = values => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

export const generateRandomInteger = (min, max) =>
  Math.floor(min + Math.random() * (max + 1 - min));

export const getLocaleByCulture = culture => {
  let locale = culture;
  // If norwegian culture (no), return norwegian locale (nb)
  if (locale === 'no') {
    locale = 'nb';
  }
  return locale;
};

export const stripHtml = html => html.replace(/(<([^>]+)>)/gi, '');
