import React from "react";
import PropTypes from "prop-types";

const propTypes = {};
const defaultProps = {};

import { addScrollElement } from "./core";

class MainElementDetector extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isActive: false
    };
  }
  componentDidMount() {
    this.removeScrollElement = addScrollElement(
      this.getRef,
      this.onChange,
      this.props.data
    );
  }
  componentWillUnmount() {
    this.removeScrollElement && this.removeScrollElement();
  }
  onChange = isCurrent => {
    this.setState({
      isCurrent
    });
  };
  getRef = () => {
    return this.ref;
  };
  setRef = ref => {
    this.ref = ref;
  };
  render() {
    return React.cloneElement(
      this.props.render({
        isCurrent: this.state.isCurrent
      }),
      {
        ref: this.setRef
      }
    );
  }
}
MainElementDetector.displayName = "MainElementDetector";
MainElementDetector.propTypes = propTypes;
MainElementDetector.defaultProps = defaultProps;

export default MainElementDetector;
