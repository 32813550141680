import React from 'react';
import PropTypes from 'prop-types';
import Button, { IconButton } from 'components/atoms/Button';
import { magnifyer } from 'components/atoms/Icon/icons';
import { translate } from 'utils/translate';
import * as constants from 'utils/constants';
import * as styles from './SearchFormV2.scss';
import classnames from 'classnames';

const SearchFormV2 = ({
  action,
  className,
  inputClassName,
  placeholder,
  showSearchButton = false,
  showSecondarySearchButton = false,
  isDesktopMenu = false,
  doUpdateUrl = () => {},
}) => {
  const inputRef = React.useRef(null);
  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault();

      doUpdateUrl(
        `${action}${action.includes('?') ? '&' : '?'}${inputRef.current.name}=${
          inputRef.current.value
        }`,
      );
    },
    [action],
  );
  return (
    <form
      method="get"
      action={action}
      onSubmit={handleSubmit}
      className={className}
      role="search"
    >
      <input
        ref={inputRef}
        type="search"
        className={inputClassName}
        placeholder={placeholder}
        name={constants.get('searchParam')}
        aria-label={translate('/search/searchFieldLabel')}
      />
      {showSearchButton && (
        <IconButton
          className={styles.inputMagnifyingGlass}
          iconAfter={magnifyer}
          type="submit"
          aria-label={translate('/search/searchButton')}
        />
      )}
      {showSecondarySearchButton && (
        <Button
          className={classnames(styles.secondarySearchButton, {
            [styles.desktopSearchButton]: isDesktopMenu,
          })}
          type="submit"
          aria-label={translate('/search/searchButton')}
        >
          {translate('/search/searchButton')}
        </Button>
      )}
    </form>
  );
};
SearchFormV2.displayName = 'SearchFormV2';
SearchFormV2.propTypes = {};
SearchFormV2.defaultProps = {};

export default SearchFormV2;
