import React from 'react';
import classnames from 'classnames';
import Image from 'components/atoms/Image';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';

import BreakpointContainer from 'containers/BreakpointContainer';

import * as styles from './FeatureImage.scss';
import ConditionalWrap from 'components/utilities/ConditionalWrap';

const FeatureImage = props => {
  const { url, description, title, credits, size, showCaption } = props;
  if (!url) {
    return null;
  }

  const imageSize = size ? size.toLowerCase() : '';

  const image = {
    src: url,
    alt: description,
    description,
    title,
    copy: credits,
  };

  return (
    <BreakpointContainer
      render={breakpoint => {
        if (breakpoint > BREAKPOINT_PHONE) {
          if (showCaption && description)
            return (
              <div>
                <figure
                  className={classnames(styles.featureImage, {
                    [styles.featureImageLarge]:
                      imageSize === 'large' || imageSize === '',
                    [styles.featureImageMedium]: imageSize === 'medium',
                    [styles.featureImageSmall]: imageSize === 'small',
                  })}
                >
                  <Image {...image} />
                  <figcaption className={styles.caption}>
                    {description}
                  </figcaption>
                </figure>
              </div>
            );
          return (
            <Image
              className={classnames(styles.featureImage, {
                [styles.featureImageLarge]:
                  imageSize === 'large' || imageSize === '',
                [styles.featureImageMedium]: imageSize === 'medium',
                [styles.featureImageSmall]: imageSize === 'small',
              })}
              {...image}
            />
          );
        }
        return (
          <ConditionalWrap
            condition={showCaption && description}
            wrap={children => (
              <figure>
                {children}
                <figcaption className={styles.caption}>
                  {description}
                </figcaption>
              </figure>
            )}
          >
            <Image
              className={classnames(styles.featureImage, {
                [styles.featureImageLarge]:
                  imageSize === 'large' ||
                  imageSize === '' ||
                  imageSize === 'medium',
                // [styles.featureImageMedium]: imageSize === 'medium',
                [styles.featureImageSmall]: imageSize === 'small',
              })}
              {...image}
            />
          </ConditionalWrap>
        );
      }}
    />
  );
};

FeatureImage.displayName = 'FeatureImage';
FeatureImage.defaultProps = {};

export default FeatureImage;
