import { parseISO, set, format, formatISO } from 'date-fns/esm';
import getLocale from 'utils/getLocale';

export const getDateFromISO = (date, time) => {
  if (!date) return null;
  let dateTime = parseISO(date);
  if (time) {
    const parsedTime = parseISO(time);
    dateTime = set(dateTime, {
      hours: parsedTime.getHours(),
      minutes: parsedTime.getMinutes(),
      seconds: parsedTime.getSeconds(),
    });
  }
  return dateTime;
};
export const getFormattedDate = (date, dateFormat, locale) =>
  format(date instanceof Date ? date : getDateFromISO(date), dateFormat, {
    locale: getLocale(locale),
  });