import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import Iframe from 'components/utilities/Iframe';
import * as styles from './FormsBlock.scss';

const FormsBlock = ({ block }) => {
  const iframeLink = propertyValueFor(block.iframeLink);
  return (
    <BlockWrapper className={styles.iframeWrapper} block={block}>
      <Iframe src={iframeLink} autoresize />
    </BlockWrapper>
  );
};
FormsBlock.displayName = 'IframeBlock';
FormsBlock.propTypes = {
  block: PropTypes.any,
};
FormsBlock.defaultProps = {};

export default FormsBlock;
