import { START, MIDDLE, END, AXIS_Y, AXIS_X } from './constants';
const transforms = {
  [START]: '0%',
  [MIDDLE]: '-50%',
  [END]: '-100%',
};
const signMap = {
  [START]: '+',
  [END]: '-',
};
const getTransform = (zone, arrowSize) => {
  if (zone.axis === AXIS_X) {
    return `translate(calc(${transforms[zone.dx]} ${
      signMap[zone.dx]
    } ${arrowSize}px), ${transforms[zone.dy]})`;
  }
  return `translate(${transforms[zone.dx]}, calc(${transforms[zone.dy]} ${
    signMap[zone.dy]
  } ${arrowSize}px)`;
};
// `calc(transform +/- ${arrowSize}px)`;
export default (zone, bodyOffset, arrowSize = 0) => {
  return zone && bodyOffset
    ? {
        position: 'absolute',
        top: bodyOffset.top * -1 + zone.y,
        left: bodyOffset.left * -1 + zone.x,
        transform: getTransform(zone, arrowSize),
      }
    : { position: 'absolute', top: 0, left: 0, visibility: 'hidden' };
};
