export function currentUrl() {
  return parseUrl(window.location);
}

export function parseUrl(url) {
  let path = "";
  let query = {};
  let hiddenQuery = {};

  if (typeof url === "string") {
    const parts = url.split("?");
    path = parts[0];
    if (path.indexOf("http") === 0) {
      path = path.replace(/https?:\/\/[^\/]+/, "");
      if (path === "") {
        path = "/";
      }
    }
    query = searchToObject(parts[1]);
  } else if (isLocation(url)) {
    path = url.pathname;
    query = searchToObject(url.search.split("?").pop());
  } else if (isUrlObject(url)) {
    // Url is already parsed but might not contain `query` and `hiddenQuery`
    path = url.path;
    query = url.query || {};
    hiddenQuery = url.hiddenQuery || {};
  }

  return {
    path,
    query,
    hiddenQuery
  };
}

// We cannot use `url instanceof Location` because location objects in IE
// are not instances of that constructor
function isLocation(url) {
  return "hash" in url && "pathname" in url && "origin" in url;
}

export function urlToString(url, includeHiddenQuery = false) {
  if (typeof url === "string") {
    return urlToString(parseUrl(url));
  } else {
    let q = queryToString(url.query);
    if (includeHiddenQuery && url.hiddenQuery) {
      const hiddenQ = queryToString(url.hiddenQuery);
      q = (q === "" ? hiddenQ : q + "&" + hiddenQ).replace(/&$/, "");
    }
    return cleanPath(url.path) + (q === "" ? "" : "?" + q);
  }
}

export function areEqual(url1, url2, compareHiddenQuery = false) {
  let urlObject1 = null;
  let urlObject2 = null;
  if (typeof url1 === "string") {
    urlObject1 = parseUrl(url1);
  } else {
    urlObject1 = url1;
  }
  if (typeof url2 === "string") {
    urlObject2 = parseUrl(url2);
  } else {
    urlObject2 = url2;
  }
  if (urlObject1.path !== urlObject2.path) {
    return false;
  }
  if (queryToString(urlObject1.query) !== queryToString(urlObject2.query)) {
    return false;
  }
  if (
    compareHiddenQuery &&
    queryToString(urlObject1.hiddenQuery) !==
      queryToString(urlObject2.hiddenQuery)
  ) {
    return false;
  }
  return true;
}

const validUrlKeys = ["path", "query", "hiddenQuery"];
export function isUrlObject(url) {
  if (!url || typeof url !== "object") {
    return false;
  }
  if ("path" in url && typeof url.path === "string") {
    const otherKeys = Object.keys(url).filter(
      key => validUrlKeys.indexOf(key) === -1
    );
    if (otherKeys.length === 0) {
      let validKeyTypes = true;
      if (url.query && typeof url.query !== "object") {
        validKeyTypes = false;
      }
      if (url.hiddenQuery && typeof url.hiddenQuery !== "object") {
        validKeyTypes = false;
      }
      return validKeyTypes;
    } else {
      return false;
    }
  }
  return false;
}

export function pathCombine(first, ...rest) {
  let result = first;
  for (let i = 0; i < rest.length; i++) {
    if (result[result.length - 1] !== "/") {
      result += "/";
    }
    const current = rest[i][0] === "/" ? rest[i].substr(1) : rest[i];
    if (current) {
      result += current;
    }
  }
  return result;
}

function queryToString(query) {
  if (!query) {
    return "";
  }
  let queryString = "";
  const keys = Object.keys(query).sort();
  keys.forEach(k => {
    if (Array.isArray(query[k])) {
      const queryParams = query[k].slice();
      queryParams.sort();
      queryParams.forEach(v => {
        queryString += createQueryKeyValuePair(k, v);
      });
    } else {
      queryString += createQueryKeyValuePair(k, query[k]);
    }
  });
  return queryString.replace(/&$/, "");
}

function createQueryKeyValuePair(key, value) {
  if (value === null || value === undefined) {
    return encodeURIComponent(key) + "&";
  } else {
    return encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
  }
}

function searchToObject(search) {
  const query = {};
  if (search) {
    search.split("&").forEach(q => {
      const [key, value] = q.split("=");
      if (key in query) {
        if (!Array.isArray(query[key])) {
          query[key] = [query[key]];
        }
        query[key].push(decodeURIComponent(value));
      } else {
        query[key] = decodeURIComponent(value);
      }
    });
  }
  return query;
}

function cleanPath(path) {
  path = path
    .replace(/\/+$/, "") // Remove trailing slash
    .toLowerCase();

  return path === "" ? "/" : path; // Don't want to strip the root
}
