import React, { Suspense, useMemo, useState, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import classnames from 'classnames';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { teaserConfig } from 'components/organisms/TeaserList';
import { getFormattedDate } from 'utils/dateTime';
import { translate } from 'utils/translate';
import {
  withColumnContext,
  COLUMN_MAIN,
  COLUMN_MAINLEFT,
  COLUMN_MAINRIGHT,
} from 'components/molecules/PageGrid';
import * as styles from './TellusEventListBlock.scss';
import { flatten } from 'lodash';
import Button from 'components/atoms/Button';

const TeaserList = React.lazy(() =>
  import(
    /* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'
  ),
);
const EventList = React.lazy(() =>
  import(
    /* webpackChunkName: "eventlist" */ /* webpackPreload: true */ 'components/organisms/EventList'
  ),
);

const TellusEventListBlock = ({
  block,
  page,
  productResponse,
  columnContext,
}) => {
  if (
    !productResponse ||
    !productResponse.events ||
    !productResponse.events.length
  ) {
    return null;
  }
  const eventListViewType = propertyValueFor(block.eventListViewType);
  switch (eventListViewType) {
    case 'imageAndTitleAsSlides':
    case 'imageAndTitleAsSlidesCompact':
    case 'imageTitleAndIntroAsSlides':
      const teaserListConfig = teaserConfig[eventListViewType];
      let color = null;
      let hasWhiteFont = false;
      const style = {};
      if (block.colorTheme && propertyValueFor(block.colorTheme)) {
        let parsedColorTheme = propertyValueFor(block.colorTheme);
        parsedColorTheme = JSON.parse(parsedColorTheme);
        color = parsedColorTheme.Color;
        hasWhiteFont = parsedColorTheme.IsWhiteFont;
      }
      if (propertyValueFor(block.colorViewType) === 'colouredBlockBackground') {
        style.backgroundColor = color;
        if (hasWhiteFont) style.color = '#fff';
      }
      const blockBackground =
        propertyValueFor(block.colorViewType) === 'colouredBlockBackground';

      const hasColorTextBg =
        !!color &&
        propertyValueFor(block.colorViewType) === 'colouredTextBackground';
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <BlockWrapper
            block={block}
            columnContext={columnContext}
            style={style}
            className={classnames(styles.root, {
              [styles.blockBackground]: blockBackground,
              [styles.colorTheme]: color,
              [styles.wide]:
                teaserListConfig.slider && !teaserListConfig.compact,
            })}
          >
            {block.ingress && (
              <PropertyFor
                property={block.ingress}
                className={styles.introduction}
                tagName="p"
              />
            )}
            <EventList
              groupBy="day"
              products={productResponse.events}
              productPageUrl={propertyValueFor(page.productPageUrl)}
              render={groupedProducts => {
                const availableFilterDates = useMemo(() => {
                  // find today and tomorrow
                  const todayDate = new Date();
                  const tomorrowDate = new Date(todayDate);
                  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
                  return [
                    {
                      date: getFormattedDate(todayDate, 'PPPP'),
                      label: translate('/pages/eventListPage/today'),
                    },
                    {
                      date: getFormattedDate(tomorrowDate, 'PPPP'),
                      label: translate('/pages/eventListPage/tomorrow'),
                    },
                  ];
                }, [groupedProducts]);

                const [filterDates, setFilterDates] = useState([]);
                const toggleFilterDates = useCallback(
                  date =>
                    setFilterDates(arr => {
                      return arr.includes(date)
                        ? arr.filter(i => i !== date)
                        : [...arr, date];
                    }),
                  [],
                );
                // if no filter, flatten
                const sortedProducts = useMemo(() => {
                  if (!filterDates.length) {
                    return flatten(
                      groupedProducts.map(group => group.products),
                    );
                  }
                  return flatten(
                    groupedProducts
                      .filter(group => filterDates.includes(group.title))
                      .map(group => group.products),
                  );
                }, [filterDates, groupedProducts]);
                const itemsKey = useMemo(
                  () => sortedProducts.map(product => product.id).join('-'),
                  [sortedProducts],
                );
                return (
                  <>
                    {!!availableFilterDates.length && (
                      <div className="filterWrapper">
                        {availableFilterDates.map(filterDate => (
                          <Button
                            key={filterDate.date}
                            className={classnames(styles.filterButton, {
                              [styles.filterButtonActive]: filterDates.includes(
                                filterDate.date,
                              ),
                            })}
                            onClick={() => toggleFilterDates(filterDate.date)}
                          >
                            <span className={styles.indicator} />
                            <span>{filterDate.label}</span>
                          </Button>
                        ))}
                      </div>
                    )}
                    <TeaserList
                      config={teaserListConfig}
                      showDate
                      items={sortedProducts}
                      columnCount={propertyValueFor(block.singleColumn) ? 1 : 2}
                      noBorder
                      colorTheme={{
                        color: hasColorTextBg ? color : null,
                        hasWhiteFont,
                      }}
                      showFavoriteButton={
                        columnContext === COLUMN_MAIN ||
                        columnContext === COLUMN_MAINLEFT ||
                        columnContext === COLUMN_MAINRIGHT
                      }
                      wideContainer
                      key={itemsKey}
                    />
                  </>
                );
              }}
            />
          </BlockWrapper>
        </Suspense>
      );
    case 'full':
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <BlockWrapper block={block} columnContext={columnContext}>
            <EventList
              products={productResponse.events}
              productPageUrl={propertyValueFor(page.productPageUrl)}
              render={sortedProducts => (
                <TeaserList
                  items={sortedProducts}
                  config={teaserConfig.imageTitleAndIntroForListView}
                  showDate
                />
              )}
            />
          </BlockWrapper>
        </Suspense>
      );
    case 'compact':
    default:
      return (
        <Suspense fallback={<LoadingIndicator />}>
          <BlockWrapper block={block} columnContext={columnContext}>
            <EventList
              products={productResponse.events}
              productPageUrl={propertyValueFor(page.productPageUrl)}
              groupBy="day"
              dayFormat="eeee, dd. MMMM"
              dateVariant="dateTime"
              render={groupedProducts => (
                <React.Fragment>
                  {groupedProducts.map(group => (
                    <TeaserList
                      title={group.title}
                      items={group.products}
                      config={teaserConfig.onlyLink}
                      key={group.title}
                      alt={false}
                    />
                  ))}
                </React.Fragment>
              )}
            />
          </BlockWrapper>
        </Suspense>
      );
  }
};
TellusEventListBlock.displayName = 'TellusEventListBlock';
TellusEventListBlock.propTypes = {};
TellusEventListBlock.defaultProps = {};

export default connect('selectPage', withColumnContext(TellusEventListBlock));
