import { createSelector } from 'redux-bundler';
import { translate } from 'utils/translate';

// action types
const USER_SET = 'USER_SET';
const USER_FAVORITES_SET = 'USER_FAVORITES_SET';
const USER_FAVORITES_ADD = 'USER_FAVORITES_ADD';
const USER_FAVORITES_REMOVE = 'USER_FAVORITES_REMOVE';
const USER_FAVORITES_CLEAR = 'USER_FAVORITES_CLEAR';
const USER_RESET = 'USER_RESET';
const FAVORITES_SET = 'FAVORITES_SET';
const SET_UNIMPORTED_FAVORITES = 'SET_UNIMPORTED_FAVORITES';

// other const
export const USERNAME_ANONYMOUS = 'anonymous';
export const getUnimportedFavorites = (
  localFavorites = [],
  serverFavorites = [],
) =>
  localFavorites.filter(
    localId => !serverFavorites.find(serverId => serverId === localId),
  );
const initialData = {
  data: {
    userName: USERNAME_ANONYMOUS,
    name: null,
    profilePictureUrl: null,
    favorites: [],
    myFavoritesPageUrl: null,
  },
  unimportedFavorites: [],
};
// user bundle
export default {
  name: 'user',
  getReducer: () => {
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case USER_RESET:
          return {
            ...initialData,
            data: {
              ...initialData.data,
              ...(!!payload && payload),
            },
          };
        case USER_SET:
          return { ...state, data: payload };
        case USER_FAVORITES_SET:
          return { ...state, data: { ...state.data, favorites: payload } };
        case USER_FAVORITES_ADD:
          return {
            ...state,
            // only unique favoriteIds
            data: {
              ...state.data,
              favorites: [...state.data.favorites, payload.uuid].filter(
                (v, i, a) => a.indexOf(v) === i,
              ),
            },
          };
        case USER_FAVORITES_REMOVE:
          return {
            ...state,
            data: {
              ...state.data,
              favorites: state.data.favorites.filter(id => id !== payload.uuid),
            },
          };
        case USER_FAVORITES_CLEAR:
          return { ...state, data: { ...state.data, favorites: [] } };
        case SET_UNIMPORTED_FAVORITES:
          return {
            ...state,
            unimportedFavorites: payload,
          };
        default:
          return state;
      }
    };
  },
  selectUserRaw: state => state.user,
  selectUser: createSelector(
    'selectUserRaw',
    raw => raw.data,
  ),
  selectUserIsLoggedIn: createSelector(
    'selectUser',
    user => user.userName !== USERNAME_ANONYMOUS,
  ),
  selectUserPageUrl: createSelector(
    'selectUser',
    user => user.url,
  ),
  selectUserFavorites: createSelector(
    'selectUser',
    user => user.favorites,
  ),
  selectUnimportedFavorites: createSelector(
    'selectUserRaw',
    raw => raw.unimportedFavorites,
  ),
  doUpdateUserData: userData => ({ dispatch, localMyPageApi }) => {
    dispatch({
      type: USER_SET,
      payload: {
        ...userData,
        ...(userData.userName === USERNAME_ANONYMOUS && {
          favorites: localMyPageApi.getFavorites(),
        }),
      },
    });
  },
  doToggleFavorite: uuid => ({ store }) => {
    const favorites = store.selectUserFavorites();
    if (favorites.includes(uuid)) {
      store.doRemoveFavorite(uuid);
    } else {
      store.doAddFavorite(uuid);
    }
  },
  doAddFavorite: uuid => ({ store, dispatch, myPageApi, localMyPageApi }) => {
    // call service with new favourite
    dispatch({
      type: USER_FAVORITES_ADD,
      payload: { uuid },
    });
    (store.selectUserIsLoggedIn() ? myPageApi : localMyPageApi).addFavorite(
      uuid,
    );
    const user = store.selectUser();
    // if the notifications module is loaded, now is a good time to add a notification about the new favurite
    if (store.doAddNotification) {
      store.doAddNotification({
        delay: 6000,
        body: translate('/notifications/savedFavorite/body'),
        link: {
          label: translate('/notifications/savedFavorite/linkLabel'),
          href: user.myFavoritesPageUrl,
        },
      });
    }
  },
  doRemoveFavorite: uuid => ({
    store,
    dispatch,
    myPageApi,
    localMyPageApi,
  }) => {
    // call service with removable favourite
    dispatch({ type: USER_FAVORITES_REMOVE, payload: { uuid } });
    (store.selectUserIsLoggedIn() ? myPageApi : localMyPageApi).removeFavorite(
      uuid,
    );
  },
  doClearFavorites: () => ({ store, dispatch, myPageApi, localMyPageApi }) => {
    // call service to clear favourites
    dispatch({ type: USER_FAVORITES_CLEAR });
    (store.selectUserIsLoggedIn()
      ? myPageApi
      : localMyPageApi
    ).clearFavorites();
  },
  doSetFavorites: favorites => ({
    type: FAVORITES_SET,
    payload: favorites,
  }),

  // mypage stuff, just mapping api things without changing the store ?
  // TODO: combine calls for setting bio, title and theme into a setProfile or similar (could fake this in frontend, but better to have a proper api endpoint for it)
  doSetMyPageBio: bio => ({ myPageApi }) => {
    myPageApi.setBio(bio);
  },
  doSetMyPageTheme: themeId => ({ myPageApi }) => {
    myPageApi.setTheme(themeId);
  },
  doSetMyPageTitle: title => ({ myPageApi }) => {
    myPageApi.setTitle(title);
  },
  doClearUnimportedFavorites: () => ({ dispatch }) => {
    dispatch({
      type: SET_UNIMPORTED_FAVORITES,
      payload: [],
    });
  },
  doHandleFacebookLogin: (
    { profile, tokenDetail },
    localFavoritesData = [],
  ) => ({ myPageApi, store, dispatch, localMyPageApi }) =>
    myPageApi.setAccessToken(tokenDetail).then(() =>
      myPageApi.initMyPageForUser(profile).then(userData => {
        store.doUpdateUrl(userData.myFavoritesPageUrl);
        const unimportedFavorites = getUnimportedFavorites(
          localMyPageApi.getFavorites(),
          userData.favorites,
        );
        dispatch({
          type: SET_UNIMPORTED_FAVORITES,
          payload: unimportedFavorites
            .map(id =>
              localFavoritesData.find(data => data.myFavoritesId === id),
            )
            .filter(Boolean),
        });
        // resolve();
      }),
    ),
  doLogOut: () => ({ myPageApi, store }) => {
    myPageApi.logOutUser().then(() => {
      store.doResetUser();
      store.doGoToStartpage();
    });
  },
  doResetUser: () => ({ localMyPageApi, dispatch, store }) => {
    dispatch({
      type: USER_RESET,
      payload: {
        favorites: localMyPageApi.getFavorites(),
        myFavoritesPageUrl: store.selectKnownUrls().myFavoritesPage,
      },
    });
  },
};
