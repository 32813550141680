// Are we rendering on the server or are we in the browser?
export const isBrowser = typeof document !== 'undefined';
// Detection explained: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
export const isTouch =
  (isBrowser && 'ontouchstart' in window) ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

// browser sniffing
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isIE = /MSIE|Trident/.test(navigator.userAgent);
export const isEdge = /Edge/.test(navigator.userAgent);
export const isFF = /Firefox/.test(navigator.userAgent);
export const isChrome = /Chrome/.test(navigator.userAgent);

// has external SVG use() support?
export const externalSvgUseSupported = !isIE && !isEdge && !isFF;

export function isLandscape() {
  return isBrowser && window.screen.width > window.screen.height;
}
export function isPortrait() {
  return isBrowser && !isLandscape();
}

function currentStyleProperty(
  name,
  element = isBrowser ? document.body : null,
) {
  if (!isBrowser) {
    return '';
  }
  const nameCap = name.charAt(0).toUpperCase() + name.slice(1);
  let transform;
  [
    name,
    `webkit${nameCap}`,
    `webkit-${name}`,
    `Webkit${nameCap}`,
    `Moz${nameCap}`,
    `O${nameCap}`,
    `ms${nameCap}`,
  ].every(e => {
    if (typeof element.style[e] !== 'undefined') {
      transform = e;
      return false;
    }
    return true;
  });
  return transform;
}

export const transformProperty = currentStyleProperty('transform');
export const transitionProperty = currentStyleProperty('transition');

const transitions = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd',
};
export const transitionEndEvent = (() => {
  if (!isBrowser) {
    return transitions.transition;
  }

  const el = document.createElement('div');

  return transitions[
    Object.keys(transitions).find(t => !!el.style[t]) || 'transition'
  ];
})();
