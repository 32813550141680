import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button';
import { useSiteSettings } from 'hooks/use-site-settings';
import { useCookie } from 'hooks/use-cookie';
import * as styles from './CookieBanner.scss';

// markup based on https://techservicesillinois.github.io/accessibility/examples/notice/index.html
const CookieBanner = props => {
    const {cookieSettings} = useSiteSettings();
    const [cookieBannerDismissed, setCookieBannerDismissed] = useCookie("cookie-information-dismissed", !cookieSettings || !cookieSettings.cookieDisclaimerEnabled);
    
    if(cookieBannerDismissed) return null;

    return (
        <div className={styles.CookieBanner} role="dialog" aria-modal="false" aria-labelledby="cookiebanner-title" aria-describedby="cookiebanner-message">
            <div className={styles.CookieBannerBody}>
                <h2 id="cookiebanner-title"  className={styles.CookieBannerTitle}>{cookieSettings.cookiePopupHeader}</h2>
                <p id="cookiebanner-message" className={styles.CookieBannerMessage}>{cookieSettings.cookiePopupMainMessage} <a href={cookieSettings.cookiePopupDetailsPage}>{cookieSettings.cookiePopupDetailsButtonText}</a></p>
            </div>
            <div>
                <Button onClick={() => setCookieBannerDismissed("true", 365)} className={styles.CookieBannerCloseButton}>{cookieSettings.cookiePopupButtonText}</Button>
            </div>
        </div>
    );
};
CookieBanner.displayName = "CookieBanner";
CookieBanner.propTypes = {};
CookieBanner.defaultProps = {};


export default CookieBanner