export default (itemRect, parentRect) => {
  const top = itemRect.top - parentRect.top,
    left = itemRect.left - parentRect.left,
    right = left + itemRect.width,
    bottom = top + itemRect.height;

  return {
    top,
    right,
    bottom,
    left,
    x: left,
    y: top,
    width: itemRect.width,
    height: itemRect.height,
  };
};
