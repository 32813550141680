import React from 'react';
import PropTypes from 'prop-types';
import {
  propertyValueFor,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import classnames from 'classnames';
import BlockWrapper from 'components/molecules/BlockWrapper';
import * as styles from './EditorBlock.scss';

const EditorBlock = React.memo(({ block }) => {
  const { bordered, colorTheme, content, isFullwidth } = block;
  const style = {};

  if (colorTheme && propertyValueFor(colorTheme)) {
    let parsedColorTheme = propertyValueFor(colorTheme);
    parsedColorTheme = JSON.parse(parsedColorTheme);

    const { Color, IsWhiteFont } = parsedColorTheme;
    if (!propertyValueFor(bordered)) {
      style.backgroundColor = Color;
      if (IsWhiteFont) style.color = '#fff';
    } else style.borderColor = Color;
  }
  return (
    <BlockWrapper
      block={block}
      className={classnames(styles.root, {
        [styles.bordered]: propertyValueFor(bordered),
        [styles.padded]:
          propertyValueFor(bordered) || propertyValueFor(colorTheme),
        [styles.fullWidth]: propertyValueFor(isFullwidth),
        [styles.colored]: propertyValueFor(colorTheme),
      })}
      style={style}
    >
      <PropertyFor property={content} />
    </BlockWrapper>
  );
});

EditorBlock.displayName = 'EditorBlock';
EditorBlock.propTypes = {
  block: PropTypes.shape({
    title: CustomPropTypes.EpiserverValue,
    content: CustomPropTypes.EpiserverValue,
    hideTitle: CustomPropTypes.EpiserverValue,
    bordered: CustomPropTypes.EpiserverValue,
    colorTheme: CustomPropTypes.EpiserverValue,
    isFullwidth: CustomPropTypes.EpiserverValue,
  }),
};
EditorBlock.defaultProps = {};

export default EditorBlock;
