export const positions = {
  Oslo: { x: 380+19, y: 218+19 },
  // greater oslo
  'Asker og Bærum': { x: 357+9, y: 253+22 },
  Follo: { x: 422+9, y: 331 },
  'Drøbak og Oscarsbord': { x: 391+9, y: 323+22 },
  Romerike: { x: 486+9, y: 120+22 },
  // vestfold
  Holmestrand: { x: 311+9, y: 410+22 },
  Horten: { x: 334+9, y: 469+22 },
  Sandefjord: { x: 302+9, y: 542+22 },
  'Tønsberg og Færder': { x: 315+9, y: 516+22 },
  Larvik: { x: 281+9, y: 567+22 },
  // Østfold
  Halden: { x: 530+9, y: 520+22 },
  'Indre Østfold': { x: 491+9, y: 369+22 },
  Moss: { x: 420+9, y: 430+22 },
  Sarpsborg: { x: 503+9, y: 463+22 },
  // Fredrikstad og Hvaler
  'Fredrikstad & Hvaler': { x: 458+9, y: 511+22 },
};
