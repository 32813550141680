import React from 'react';
import PropTypes from 'prop-types';
import dlv from 'dlv';
import ErrorBoundary from '../components/ErrorBoundary';
import LoadingIndicatorBar from 'components/utilities/LoadingIndicatorBar';
import { connect } from 'redux-bundler-react';
import { resolveComponent } from '../utils/component-registry';

const CurrentPage = ({ pageRaw = {} }) => {
  const { url, data, isLoading, isLoadingSlow } = pageRaw;
  // console.log(url, data, pageRaw);
  const key = dlv(data, 'url.path', '') + dlv(data, 'url.query.tlp', '');
  // console.log(key, data.url.path, data.url.query.tlp || '');
  // const PageComponent = isLoadingSlow
  //   ? resolveComponent('pages/LoadingPage')
  //   : resolveComponent(data.componentName);
  const PageComponent = resolveComponent(data.componentName);
  return PageComponent ? (
    <ErrorBoundary key={key}>
      <PageComponent
        {...data.currentPage}
        isLoading={isLoading}
        isLoadingSlow={isLoadingSlow}
        url={data.url}
        nextUrl={url}
      />
      {!!isLoading && <LoadingIndicatorBar />}
      {/* TODO: HANDLE LOADING SLOW
      !!isLoadingSlow && (
        <div style={{ position: 'fixed', top: 0, left: 0 }}>
          <LoadingIndicator />
        </div>
      ) */}
    </ErrorBoundary>
  ) : null;
};
CurrentPage.propTypes = {};
CurrentPage.defaultProps = {};

export default connect(
  'selectPageRaw',
  CurrentPage,
);
