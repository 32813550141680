import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import { Paragraph } from 'components/atoms/Text';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import BlockWrapperTitle from './BlockWrapperTitle';

import * as styles from './BlockWrapper.scss';

const BlockWrapper = ({ block, children, columnContext, className, style}) => {
  const readMoreLink = propertyValueFor(
    block.readMoreLink || block.eventListPage,
  );
  const readMoreText = propertyValueFor(
    block.readMoreText || block.eventListPageTitle,
  );
  const target = block.readMoreLinkTarget;
  const showReadMore = !!readMoreLink && !!readMoreText;

  const colorTheme = block.colorTheme
    ? propertyValueFor(block.colorTheme)
    : null;
  let isWhiteFont = false;
  if (colorTheme) {
    const parsedColorTheme = JSON.parse(colorTheme);
    isWhiteFont = parsedColorTheme && parsedColorTheme.IsWhiteFont;
  }

  const useReadMoreButton = propertyValueFor(block.readMoreAsButton);
  
  return (
    <section className={classnames(styles.root, className)} style={style}>
      {block.title && !propertyValueFor(block.hideTitle) && (
        <BlockWrapperTitle columnContext={columnContext} style={style}>
          {block.title}
        </BlockWrapperTitle>
      )}
      {children}
      
      {showReadMore && (
        <Paragraph className={styles.more}>
          <Link
            href={readMoreLink}
            className={classnames(styles.moreLink, {
              [styles.moreLinkWhite]: isWhiteFont,
              [styles.readMoreButton]: useReadMoreButton,
            })}
            target={target}
          >
            {readMoreText}
          </Link>
        </Paragraph>
      )}
    </section>
  );
};
BlockWrapper.displayName = 'BlockWrapper';
BlockWrapper.propTypes = {
  block: PropTypes.object,
  blockId: PropTypes.number,
  children: PropTypes.node,
  columnContext: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
BlockWrapper.defaultProps = {};

export default BlockWrapper;
