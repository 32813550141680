import { localStorageData } from 'utils/bundle-helper';

const mypageApiBaseURL = '/api/MyFavoritesApi/';
const ANONYMOUS_STORAGE_KEY = 'anonymous.favorites';

function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('promise timeout'));
    }, ms);
    promise.then(
      res => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      err => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });
}

const localCacheData = {};
const localCache = {
  get: (id, maxTTL) => {
    const entry = localCacheData[id];
    const now = Date.now();
    if (
      entry &&
      (!maxTTL || entry.added + maxTTL <= now) &&
      (!entry.expires || entry.expires > now)
    ) {
      return entry.entry;
    }
    return null;
  },
  raw: id => localCacheData[id],
  add: (id, data, TTL = false) => {
    const now = Date.now();
    localCache[id] = { data, added: now, expires: TTL && now + TTL };
    return data;
  },
};

export const apiFetch = (
  urlPath,
  method = 'get',
  data, // here would be a great place to select it from your store // url to fetch // can have "get", "post", "delete" and "put"
  timeout = 10000,
) =>
  // and pass it along with the fetch. Then none of your individual
  // action creators need to worry about this.
  timeoutPromise(
    timeout,
    fetch(urlPath, {
      method,
      credentials: 'include',
      timeout: 15000,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Client-Version': '999.0.0.1528393077517',
      },
      ...(data && { body: JSON.stringify(data) }),
    }).then(res => res.json()),
  );
export const apiPost = (url, data, timeout) =>
  apiFetch(url, 'post', data, timeout);
export const apiGet = (url, timeout) => apiFetch(url, 'get', null, timeout);

// myPageApi
export const myPageApi = {
  getFavouriteItemsFromIds: (favoriteIds = []) =>
    favoriteIds.length
      ? apiGet(
          `${mypageApiBaseURL}GetSpecificFavorites?language=${
            window.LOCALE
          }&ids=${favoriteIds.join(',')}`,
          30000,
        )
      : new Promise(resolve => resolve([])),
  addFavorite: favoriteId =>
    apiPost(`${mypageApiBaseURL}AddFavorite?id=${favoriteId}`),
  addFavorites: favoriteIds =>
    favoriteIds.length
      ? apiPost(`${mypageApiBaseURL}AddFavorites?ids=${favoriteIds.join(',')}`)
      : new Promise(resolve => resolve([])),
  removeFavorite: favoriteId =>
    apiPost(`${mypageApiBaseURL}DeleteFavorite?id=${favoriteId}`),
  clearFavorites: () => apiPost(`${mypageApiBaseURL}ClearFavorites`),
  setTheme: themeId =>
    apiPost(`${mypageApiBaseURL}SetTheme?themeBlockId=${themeId}`), // TODO: Refactor backend to get bio from body/data of Post call...
  setBio: bio =>
    apiPost(`${mypageApiBaseURL}SetBio?bio=${encodeURIComponent(bio)}`),
  setTitle: title =>
    apiPost(`${mypageApiBaseURL}SetTitle?title=${encodeURIComponent(title)}`),
  setAccessToken: response =>
    apiPost(`${mypageApiBaseURL}SetAccessToken`, JSON.stringify(response)),
  initMyPageForUser: response =>
    apiPost(`${mypageApiBaseURL}InitMyPageForUser`, JSON.stringify(response)),
  logOutUser: () => apiGet(`${mypageApiBaseURL}LogOutOfMyPage`),
};

// local api
const getLocalFavorites = () =>
  localStorageData.getItem(ANONYMOUS_STORAGE_KEY) || [];
export const localMyPageApi = {
  getFavorites: getLocalFavorites,
  addFavorite: favoriteId =>
    localStorageData.setItem(
      ANONYMOUS_STORAGE_KEY,
      // only unique favoriteIds
      [...getLocalFavorites(), favoriteId].filter(
        (v, i, a) => a.indexOf(v) === i,
      ),
    ),
  removeFavorite: favoriteId =>
    localStorageData.setItem(
      ANONYMOUS_STORAGE_KEY,
      getLocalFavorites().filter(id => id !== favoriteId),
    ),
  clearFavorites: () => localStorageData.setItem(ANONYMOUS_STORAGE_KEY, []),
};

// Tellus API
// Post Tellus registration form
export const tellusApiPost = (url, data) =>
  fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
    .then(res => res)
    .catch(err => {
      throw err;
    });

export const enturApiGet = url =>
  fetch(url, {
    method: 'GET',
  })
    .then(res => res.json())
    .catch(err => {
      throw err;
    });
