import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import { Paragraph } from 'components/atoms/Text';
import Container from 'components/atoms/Container';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';

import * as styles from './MyPageSectionInspiration.scss';

const MyPageSectionInspiration = ({ items = [] }) => (
  <BreakpointContainer
    render={breakpoint => {
      const headingSize =
        breakpoint < BREAKPOINT_TABLET_PORTRAIT
          ? H2.sizes.heading3
          : H2.sizes.heading2;
      return (
        <Container>
          {items.map(article => (
            <a key={article.url} href={article.url} className={styles.link}>
              <div className={styles.media}>
                <Image src={article.imageUrl} ratio={[16, 9]} fit="cover" cropDetails={article.cropDetails} />
              </div>
              <div className={styles.body}>
                <H2 alt size={headingSize} gutterBottom>
                  {article.title}
                </H2>
                <Paragraph>{article.intro}</Paragraph>
              </div>
            </a>
          ))}
        </Container>
      );
    }}
  />
);
MyPageSectionInspiration.displayName = 'MyPageSectionInspiration';
MyPageSectionInspiration.propTypes = {
  items: PropTypes.array,
};
MyPageSectionInspiration.defaultProps = {};

export default MyPageSectionInspiration;
