import PropTypes from 'prop-types';
import isInEditMode from './is-in-edit-mode';

// Replace a part of a string with a React element.
export const replaceStringWithElement = (
  text, // : string,
  match, // : string | RegExp,
  replace, // : (match: string, index: number) => React.ReactElement<T>
) => {
  const regex =
    match instanceof RegExp ? match : new RegExp(`(${match})`, 'gi');
  // Apply `replace` to all odd elements
  return text
    .split(regex)
    .map((entry, index) => (index % 2 ? replace(entry, index) : entry));
};

export const propertyValueFor = (property, args) => {
  let value = property ? property.$c : null;
  if (typeof value === 'string' && args) {
    value = expandPlaceholders(value, args);
  }
  return value;
};

export const editorAttributesFor = (propertyName, className) => {
  if (isInEditMode() && propertyName) {
    return {
      className: `${className ? `${className} ` : ''}epi-editContainer`,
      'data-epi-property-name': propertyName,
      'data-epi-use-mvc': 'True',
    };
  }
  return { className };
};

const placeholderReplaceRegex = /({[^}]+})/g;

export const expandPlaceholders = (value, args) =>
  value &&
  value.replace(placeholderReplaceRegex, ph => {
    const key = ph.substr(1, ph.length - 2).trim();
    if (key in args) {
      const val = args[key];
      if (val == null) {
        return '';
      }
      if (typeof val === 'number') {
        return val.toString();
      }
      return val;
    }
    console.error('Could not find placeholder', key, 'in', args);
    return ph;
  });

export const addHTMLStringAsNodes = (node, html) => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  // clear node
  while (node.firstChild) {
    node.removeChild(node.firstChild);
  }
  // move nodes from temp to node
  while (temp.firstChild) {
    node.appendChild(temp.firstChild);
  }
};

export const fixReplacedScriptsInString = (string = '') =>
  string.replace(/REPLACE_WITH_SCRIPT/g, 'script');

export const CustomPropTypes = {
  EpiserverValue: PropTypes.shape({
    $c: PropTypes.any,
  }),
};
