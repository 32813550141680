import Heading from 'components/atoms/Heading';
import { chevronDoubleRight } from 'components/atoms/Icon/icons';
import { defaultConfig } from './defaultConfig';

export const config = {
  ...defaultConfig,
  linksBlockIcon: chevronDoubleRight,
  footerLinksBlockIcon: chevronDoubleRight,
  frontpageSliderFullHeightContent: true,
  footerHeadingSize: Heading.sizes.heading4,
  teaserIcon: chevronDoubleRight,
  defaultMapZoomLevel: 9,
};
