import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import { useSpring, animated } from 'react-spring';

import { useIsInView } from 'hooks/use-is-in-view';
import Image from 'components/atoms/Image';
import isInEditMode from 'packages/inmeta-epi-react/utils/is-in-edit-mode';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';

import * as styles from './HighlightImage.scss';

const AnimatedImage = animated(Image);
const HighlightImage = ({ image, breakpoint }) => {
  const [inView, elementRef] = useIsInView(
    'all',
    breakpoint > BREAKPOINT_PHONE ? 250 : 100,
  );

  const img = {
    src: image.imageLink,
    alt: image.description,
    description: image.description,
    copy: image.credits,
  };

  const wrapperTransition = useSpring({
    to: inView
      ? [
          {
            // height: '100vh',
            // position: 'fixed',
            // width: '100vw',
            display: 'inline-block',
          },
          { opacity: 1 },
        ]
      : [
          { opacity: 0 },
          {
            // height: '1vh',
            // position: 'absolute',
            // width: '1vw',
            display: 'none',
          },
        ],
    config: { duration: 750 },
    immediate: key => key !== 'opacity',
  });

  return isInEditMode() ? (
    <Image {...img} fit="width" />
  ) : (
    <div className={styles.wrapper} ref={elementRef}>
      <AnimatedImage
        {...img}
        className={styles.highlightImage}
        style={wrapperTransition}
        copyClassName={styles.copyButton}
        fit="contain"
      />
    </div>
  );
};
HighlightImage.displayName = 'HighlightImage';
HighlightImage.propTypes = {
  image: PropTypes.object.isRequired,
  breakpoint: PropTypes.number,
};
HighlightImage.defaultProps = {};
export default connect('selectBreakpoint', HighlightImage);
