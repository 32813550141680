import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useColumnContext } from 'components/molecules/PageGrid';
import BlockWrapper from 'components/molecules/BlockWrapper';
import EventList from 'components/organisms/EventList';
import { teaserConfig } from 'components/organisms/TeaserList';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

const TeaserList = React.lazy(() => import(/* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'));

const WhatsInTheWeekendBlock = ({
  block,
  fridayProducts = {},
  saturdayProducts = {},
  sundayProducts = {},
}) => {
  const columnContext = useColumnContext();
  const removeEventDuplicates = eventArray =>
    (eventArray || []).filter(
      (event, index, self) =>
        index === self.findIndex(e => e.title === event.title),
    );
  const getEvents = () => {
    fridayProducts.events = removeEventDuplicates(fridayProducts.events);
    saturdayProducts.events = removeEventDuplicates(saturdayProducts.events);
    sundayProducts.events = removeEventDuplicates(sundayProducts.events);
    return [fridayProducts, saturdayProducts, sundayProducts]
      .filter(day => !!day.events)
      .flatMap(day => day.events);
  };
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BlockWrapper block={block} columnContext={columnContext}>
        <EventList
          products={getEvents()}
          groupBy="day"
          dayFormat="eeee, dd. MMMM"
          dateVariant="dateTime"
          render={groupedProducts => (
            <React.Fragment>
              {groupedProducts.map(group => (
                <TeaserList
                  title={group.title}
                  items={group.products}
                  config={teaserConfig.onlyLink}
                  key={group.id}
                  alt={false}
                />
              ))}
            </React.Fragment>
          )}
        />
      </BlockWrapper>
    </Suspense>
  );
};
WhatsInTheWeekendBlock.displayName = 'WhatsInTheWeekendBlock';
WhatsInTheWeekendBlock.propTypes = {};
WhatsInTheWeekendBlock.defaultProps = {};

export default WhatsInTheWeekendBlock;
