import React from 'react';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import useThemeConfig from 'hooks/use-theme-config';
import * as styles from './TeaserLink.scss';

export const TeaserLink = ({
  href,
  children,
  className,
  icon,
  description,
  alt = false,
  target
}) => {
  const themeConfig = useThemeConfig();
  const _icon = icon !== undefined ? icon : themeConfig.linksBlockIcon;
  return (
    <Link
      href={href}
      className={classnames(styles.teaserLink, alt && styles.alt, className)}
      description={description}
      target={target}
    >
      {children}
      {!!_icon && <Icon icon={_icon} className={styles.icon} />}
    </Link>
  );
};
TeaserLink.displayName = 'TeaserLink';
TeaserLink.propTypes = {};
TeaserLink.defaultProps = {};

export default TeaserLink;
