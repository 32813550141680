import React, {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

import { Hero, H3 } from 'components/atoms/Heading';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import Button, { SocialButton } from 'components/atoms/Button';
import { Login } from 'react-facebook/module';
import Container from 'components/atoms/Container';
import MyPageView from 'components/organisms/MyPageView';
import ProfileHeader from 'components/molecules/ProfileHeader';
import Icon from 'components/atoms/Icon';
import { spinner } from 'components/atoms/Icon/icons';

import { myPageApi } from 'server/api';

import { useFetch } from 'hooks/use-fetch';
import { transformTheme } from 'utils/utils';
import { translate } from 'utils/translate';
import { IS_BROWSER } from 'utils/environment';
import { Paragraph } from 'components/atoms/Text';
import useSiteSettings from 'hooks/use-site-settings';

const FacebookLoginButton = ({ children, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleComplete = useCallback(
    data => {
      setIsLoading(true);
      onCompleted(data).catch(() => {
        setError(true);
        setIsLoading(false);
      });
    },
    [onCompleted],
  );
  useEffect(() => {
    if (error) console.log(error);
  }, [error]);
  return (
    <React.Fragment>
      {!!error && <Paragraph>Det har oppstått en feil, prøv igjen</Paragraph>}
      {!isLoading && (
        <Login
          scope="email,public_profile"
          onCompleted={handleComplete}
          onError={setError}
        >
          {({ loading, handleClick }) => (
            <SocialButton
              brand="facebook"
              onClick={handleClick}
              disabled={loading || isLoading}
            >
              {children}
              {(loading || isLoading) && <Icon icon={spinner} spin />}
            </SocialButton>
          )}
        </Login>
      )}
      {isLoading && (
        <LoadingIndicator
          fixed={false}
          text={translate('/pages/myPage/loggingIn')}
        />
      )}
    </React.Fragment>
  );
};

const MyPageContainer = ({
  pageData,
  doClearFavorites,
  userFavorites,
  doHandleFacebookLogin,
}) => {
  const themes = propertyValueFor(pageData.currentPage.themes).items;
  const { culture, siteSkin } = useSiteSettings();
  const currentTheme = transformTheme(themes[0]);
  const { fetch, isLoading, data, error } = useFetch(
    myPageApi.getFavouriteItemsFromIds,
  );
  const handleFetch = useCallback(() => {
    fetch(userFavorites);
  }, [fetch, userFavorites]);
  const handleFacebookLogin = useCallback(
    facebookCredentials =>
      doHandleFacebookLogin(
        { profile: { ...facebookCredentials.profile, language: culture }, tokenDetail: facebookCredentials.tokenDetail },
        data,
      ),
    [data],
  );

  useLayoutEffect(() => {
    if (IS_BROWSER) {
      handleFetch();
    }
  }, [handleFetch]);
  useEffect(() => {
    if (error) {
      // do something with the error
      console.error('Error loading favoriteItems for the ids', error);
    }
  }, [error]);

  return (
    <MyPageView
      productPageUrl={propertyValueFor(pageData.currentPage.productPageUrl)}
      favoriteItems={data || []}
      onClearFavorites={doClearFavorites}
      profileHeader={
        <Container relative wide noPadding>
          <ProfileHeader
            title={
              <ProfileHeader.Title>{currentTheme.title}</ProfileHeader.Title>
            }
            currentTheme={currentTheme}
          />
        </Container>
      }
    >
      <Container style={{ padding: '3.5em 15px', textAlign: 'center' }}>
        {!!data && (
          <React.Fragment>
            <Hero gutterBottom>
              {translate('/pages/myPage/anonymous/infoTitle')}
            </Hero>
            <H3 alt gutterBottom>
              {translate('/pages/myPage/anonymous/infoText')}
            </H3>
            <p>
              <FacebookLoginButton onCompleted={handleFacebookLogin}>
                {translate('/pages/myPage/facebookButton/signIn')}
              </FacebookLoginButton>
            </p>
          </React.Fragment>
        )}
        {(!data || isLoading) && (
          <LoadingIndicator
            fixed={false}
            text={translate('/pages/myPage/loadingFavorites')}
          />
        )}
        {error && (
          <React.Fragment>
            <p>{translate('/pages/myPage/unableToLoad')}</p>
            <p>
              <Button onClick={handleFetch}>
                {translate('/pages/myPage/tryAgain')}
              </Button>
            </p>
          </React.Fragment>
        )}
      </Container>
    </MyPageView>
  );
};
MyPageContainer.displayName = 'MyPageContainer';
MyPageContainer.propTypes = {
  pageData: PropTypes.object,
  userFavorites: PropTypes.array,
  doHandleFacebookLogin: PropTypes.func,
  doClearFavorites: PropTypes.func,
};
MyPageContainer.defaultProps = {};

export default connect(
  'selectPageData',
  'selectUserFavorites',
  'doClearFavorites',
  'doHandleFacebookLogin',
  MyPageContainer,
);
