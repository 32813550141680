import { useRef, useLayoutEffect } from "react";

export const useValueChanged = (value, callback) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const lastValueRef = useRef(value);
  useLayoutEffect(() => {
    if (callbackRef.current && value !== lastValueRef.current) {
      callbackRef.current(value, lastValueRef.current);
    }
    lastValueRef.current = value;
  }, [value]);
};
