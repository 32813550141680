import React from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import {
  propertyValueFor,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import GallerySlider from 'components/molecules/GallerySlider/GallerySlider';
import * as styles from './AdvancedSliderBlock.scss';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import Button from 'components/atoms/Button';
import classnames from 'classnames';

// TODO: Header & ingress, all styling, tellus content filtering
// bottom button to expand the related content
const AdvancedSliderBlock = ({ block, items }) => {
  const wide = propertyValueFor(block.wideView);
  console.log(propertyValueFor(block.ingress));
  if (items && items.length) {
    return (
      <BlockWrapper block={block} className={styles.SliderBlock}>
        {block.ingress && (
          <PropertyFor
              property={block.ingress}
              className={styles.introduction}
            />
        )}  
        <GallerySlider
          className={[styles.GallerySlider, styles.noBefore, styles.navWide]}
          wide={wide}
          autoPlay={false}
          slides={items}
          ratio={wide ? [12, 5] : [6, 3]}
          noThumbNail={false}
          onlySlider={true}
        />
        <Button className={classnames(styles.readMoreButton, styles.filterButton)}>{"What the locals suggest"}</Button>
      </BlockWrapper>
    );
  }
  return null;
};

AdvancedSliderBlock.displayName = 'AdvancedSliderBlock';
AdvancedSliderBlock.propTypes = {
  block: PropTypes.shape({
    wideView: CustomPropTypes.EpiserverValue,
    galleryItems: CustomPropTypes.EpiserverValue,
    titleText: PropTypes.string,
    ingress: PropTypes.string,
  }),
  items: PropTypes.array,
};
AdvancedSliderBlock.defaultProps = {};

export default AdvancedSliderBlock;
