import { useEffect } from 'react';

export default function useScriptInject(script) {
  if (!script) return;
  useEffect(() => {
    if (!script) return;

    const scriptNode = document.createElement('script');
    const regex = /src="(.*)"/;
    const srcMatch = script.match(regex);
    const src = srcMatch[1];

    if (src) {
      scriptNode.src = src;
      document.body.appendChild(scriptNode);
    }
    return;
  }, [script]);

  return;
}
