import { createSelector } from "redux-bundler";
import { startsWith } from "utils/bundle-helper";

export const MAINMENU_SET = "MAINMENU_SET";

const menuMapFn = currentPath => item => ({
  ...item,
  isCurrent: !!item.url && startsWith(currentPath, item.url),
  children: item.children.map(menuMapFn(currentPath))
});

let lastUrl = "";

export default {
  name: "mainMenu",
  getReducer: () => {
    const initialData = {
      menuHierarchy: {
        menuItems: []
      }
    };

    return (state = initialData, action) => {
      if (action.type === MAINMENU_SET) {
        return action.mainMenu;
      }

      return state;
    };
  },
  selectMainMenu: state => state.mainMenu,
  selectMainMenuItems: createSelector(
    "selectMainMenu",
    mainMenu => mainMenu.menuHierarchy.menuItems
  ),

  reactUrlOrManinMenuChanged: createSelector(
    "selectUrl",
    "selectMainMenu",
    (url, mainMenu) => {
      if (url.path !== lastUrl.path) {
        lastUrl = url;
        return {
          type: MAINMENU_SET,
          mainMenu: {
            menuHierarchy: {
              menuItems: mainMenu.menuHierarchy.menuItems.map(
                menuMapFn(url.path)
              )
            }
          }
        };
      }
    }
  )
};
