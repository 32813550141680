import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/use-on-click-outside';
import useKeyPress, { KEY_ESC } from 'hooks/use-key-press';

const MenuToggler = ({
  render,
  initialOpen = false,
  closeOnOutsideClick = false,
  closeOnEsc = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const openStateRef = useRef(initialOpen);
  const menuRef = useRef(null);
  const propsRef = useRef(props);
  propsRef.current = props;

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleToggle = useCallback(() => {
    setIsOpen(open => !open);
  }, []);

  useEffect(() => {
    if (isOpen !== openStateRef.current) {
      openStateRef.current = isOpen;
      if (propsRef.current.onChange) {
        propsRef.current.onChange(isOpen);
      }
      if (isOpen && propsRef.current.onOpen) {
        propsRef.current.onOpen();
      }

      if (!isOpen && propsRef.current.onClose) {
        propsRef.current.onClose();
      }
    }
  }, [isOpen]);
  useOnClickOutside(menuRef, handleClose, closeOnOutsideClick && isOpen);
  useKeyPress(KEY_ESC, handleClose, closeOnEsc && isOpen);

  return render({
    isOpen,
    ref: menuRef,
    hoverOpenerProps: {
      ref: menuRef,
      onMouseEnter: handleOpen,
      onMouseLeave: handleClose,
    },
    hoverSubMenu: {
      ref: menuRef,
      onMouseEnter: handleOpen,
      onMouseLeave: handleClose,
    },
    clickOpenerProps: {
      ref: menuRef,
      onClick: handleToggle,
    },
    focusOpenerProps: {
      ref: menuRef,
      onFocus: handleOpen,
      onBlur: handleClose,
    },
    doClose: handleClose,
    doOpen: handleOpen,
    doToggle: handleToggle,
  });
};
MenuToggler.displayName = 'MenuToggler';
MenuToggler.propTypes = {};
MenuToggler.defaultProps = {};

export default MenuToggler;
