export const fakeColorOpacity = (hex, opacity) => {
  console.log(hex, opacity);
  if (!hex || !opacity) return '';
  const r = Math.floor(
    parseInt(hex.slice(1, 3), 16) * (1 - opacity) + 0xff * opacity,
  );
  const g = Math.floor(
    parseInt(hex.slice(3, 5), 16) * (1 - opacity) + 0xff * opacity,
  );
  const b = Math.floor(
    parseInt(hex.slice(5, 7), 16) * (1 - opacity) + 0xff * opacity,
  );

  console.log(`${((r << 16) | (g << 8) | b).toString(16)}`)
  // eslint-disable-next-line no-bitwise
  return `#${((r << 16) | (g << 8) | b).toString(16)}`;
};
