import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import BlockWrapper from 'components/molecules/BlockWrapper';
import {
  CustomPropTypes,
  propertyValueFor,
} from 'packages/inmeta-epi-react/utils/epi-react';
import { withColumnContext } from 'components/molecules/PageGrid';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import * as styles from './VideoBlock.scss';

const VideoPlayer = React.lazy(() =>
  import(
    /* webpackChunkName: "video" */ /* webpackPreload: true */ 'components/utilities/VideoPlayer'
  ),
);

const VideoBlock = ({ block, columnContext, ratio }) => (
  <BlockWrapper
    block={block}
    columnContext={columnContext}
    className={styles.videoBlockWrapper}
  >
    <Suspense fallback={<LoadingIndicator />}>
      <VideoPlayer url={propertyValueFor(block.video)} ratio={ratio} />
    </Suspense>
  </BlockWrapper>
);
VideoBlock.displayName = 'VideoBlock';
VideoBlock.propTypes = {
  block: PropTypes.shape({
    video: CustomPropTypes.EpiserverValue,
  }),
  ratio: PropTypes.any,
  columnContext: PropTypes.any,
};
VideoBlock.defaultProps = {
  ratio: [16, 9],
};

export default withColumnContext(VideoBlock);
