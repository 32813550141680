export const copyToClipboard = (str, onSuccess) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute'; // Display none does not let you copy the elements content, so hide it offscreen.
  el.style.left = '-9999px';
  el.style.height = '0';
  el.style.width = '0';
  el.style['z-index'] = '-1';
  document.body.appendChild(el);

  // Check for active selection.
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;

  // Copy content of our textarea then delete it.
  el.select();
  const res = document.execCommand('copy');
  if (res && onSuccess) onSuccess();
  document.body.removeChild(el);

  // Restore selection if user had selected something.
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
