import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  lazy,
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import supercluster from 'points-cluster';
import Icon from 'components/atoms/Icon';
import Block, { ratioPropType } from 'components/atoms/Block/Block';
import Button, { LinkButton } from 'components/atoms/Button';
import Teaser from 'components/molecules/Teaser';
import { mapMarker, cross } from 'components/atoms/Icon/icons';
import { translate } from 'utils/translate';
import { useSize } from 'hooks/use-size';
import useSiteSettings from 'hooks/use-site-settings';

import Ref from 'components/utilities/Ref';
import Popover2 from 'components/utilities/Popover2';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import * as styles from './TeaserMap.scss';
import Heading, { H2 } from 'components/atoms/Heading';
const GoogleMapReact = lazy(() =>
  import(
    /* webpackChunkName: "googleMap" */ /* webpackPreload: true */ 'google-map-react'
  ),
);

const staticOptions = {
  panControl: false,
  mapTypeControl: true,
  streetViewControl: true,
  scrollwheel: false,
};

// mocked
const Marker = ({
  showInfo,
  teaser,
  onInfoClose,
  favoriteMap,
  readmoreLink,
  fromGmapPage,
}) => {
  let readmore = readmoreLink;
  if (fromGmapPage) {
    readmore = teaser.url;
  }
  return (
    <Ref>
      {([currentRef, setRef]) => (
        <div className={styles.marker} ref={setRef}>
          <Icon
            icon={mapMarker}
            className={classnames(
              styles.markerIcon,
              favoriteMap && styles.favorite,
            )}
          />
          {!!showInfo && (
            <Popover2
              anchor={currentRef}
              position={Popover2.presets.N}
              autoReposition
              arrowSize={10}
              container="viewport"
            >
              {({ style, ref, arrowStyle, arrowDirection }) => (
                <div style={style} ref={ref} className={styles.markerInfo}>
                  <Button
                    transparent
                    onClick={onInfoClose}
                    className={styles.closeButton}
                  >
                    <Icon icon={cross} />
                  </Button>
                  <Teaser
                    href={readmore}
                    description={teaser.title}
                    className={styles.infoTeaser}
                  >
                    <Teaser.Title>{teaser.title}</Teaser.Title>
                    <Teaser.Intro truncate={teaser.title} gutterBottom={false}>
                      {teaser.intro}
                    </Teaser.Intro>
                    {readmore && (
                      <Teaser.Readmore>
                        {translate('/blocks/newsletterPageListing/readMore')}
                      </Teaser.Readmore>
                    )}
                  </Teaser>
                  <span
                    className={classnames(
                      styles.arrow,
                      styles[`arrow--${arrowDirection}`],
                    )}
                    style={arrowStyle}
                  />
                </div>
              )}
            </Popover2>
          )}
        </div>
      )}
    </Ref>
  );
};

const ClusterMarker = ({ points, favoriteMap }) => (
  <div className={styles.clusterMarker}>
    <Icon
      icon={mapMarker}
      className={classnames(styles.markerIcon, favoriteMap && styles.favorite)}
    />
    <span className={styles.points}>{points}</span>
  </div>
);

const getCenter = items => {
  const { length } = items;
  if (length) {
    return items.reduce(
      (center, item) => ({
        lat: center.lat + item.lat / length,
        lng: center.lng + item.lng / length,
      }),
      { lat: 0, lng: 0 },
    );
  }
  return null;
};
const noop = () => {};
const STATIC_MAP_MAX_SIZE = 640;
const StaticGoogleMap = ({
  center,
  zoom = 14,
  markers,
  onClick = noop,
  apiKey,
  staticMapImage,
}) => {
  const wrapperRef = useRef();
  const { height, width } = useSize(wrapperRef, true);
  const scaled = useMemo(() => {
    const scaleMultiplier = STATIC_MAP_MAX_SIZE / Math.max(height, width);
    return {
      width: Math.floor(width * scaleMultiplier),
      height: Math.floor(height * scaleMultiplier),
    };
  }, [height, width]);
  const src = useMemo(
    () => staticMapImage || 
      `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat},${center.lng}&zoom=${zoom}&size=${scaled.width}x${scaled.height}&key=${apiKey}`,
    [scaled, apiKey, zoom, center, staticMapImage],
  );
  return (
    <div
      onClick={onClick}
      className={styles.wrap}
      style={{ cursor: 'pointer' }}
      ref={wrapperRef}
    >
      <div className={styles.staticMapMessage}>
        {translate('/map/clickToViewMarkers')}
      </div>
      {!!src && <img src={src} style={{ width, height }} />}
    </div>
  );
};

const TeaserMap = ({
  items,
  center,
  defaultCenter,
  zoom,
  clusterRadius,
  ratio,
  showList,
  favoriteMap,
  route,
  readmoreLink,
  fromGmapPage,
}) => {
  const {
    googleMapAPIKey = 'AIzaSyC1eTHEgXXATIUbJJikr9-vAZhQnpLkT04',
    displayMapStaticImage = true,
    staticMapImage,
  } = useSiteSettings();
  const [staticMap, setStaticMap] = useState(
    fromGmapPage ? false : displayMapStaticImage,
  );
  // transform teaser items into objects with lat, lng
  const geoItems = useMemo(
    () =>
      items
        .filter(item => item.geoLocation)
        .map(item => ({
          ...item,
          lat: item.geoLocation.latitude,
          lng: item.geoLocation.longitude,
        })),
    [items],
  );
  const [mapOptions, setMapOptions] = useState(() => ({
    center: center || getCenter(geoItems) || defaultCenter || null,
    zoom: zoom || 12,
    bounds: null,
  }));
  const [clusters, setClusters] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  /* log mount and unmount
  useEffect(() => {
    console.log('map mounted');
    return () => console.log('map unmounted');
  }, []);
*/
  // create new clusters when mapOptions or items change (but only when bounds are present)
  useEffect(() => {
    if (mapOptions.bounds) {
      const makeClusters = supercluster(geoItems, {
        minZoom: 0,
        maxZoom: 15,
        radius: clusterRadius,
      });
      setClusters(
        makeClusters(mapOptions).map(({ wx, wy, numPoints, points }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: points[0].url,
          points,
        })),
      );
    }
  }, [mapOptions, items]);

  const handleMapChange = ({ center, zoom, bounds }) => {
    setMapOptions({ center, zoom, bounds });
  };
  const handleMarkerSelect = item => {
    setStaticMap(false);
    setSelectedMarker(item.url);
    setMapOptions(state => ({
      ...state,
      center: {
        lat: item.geoLocation.latitude,
        lng: item.geoLocation.longitude,
      },
      zoom: 16,
    }));
  };

  const handleClusterMarkerSelect = ({ lat, lng }) => {
    setSelectedMarker(null);
    setMapOptions(state => ({
      ...state,
      center: { lat, lng },
      zoom: state.zoom + 1,
    }));
  };
  const handleChildClick = (key, childProps) => {
    if (childProps.teaser) {
      // this is a teaser
      handleMarkerSelect(childProps.teaser);
    } else if (childProps.points) {
      // this is a cluster marker
      handleClusterMarkerSelect(childProps);
    }
    // else: do nothing
  };
  const handleInfoClose = () => {
    setSelectedMarker(null);
  };

  const handleApiLoaded = (map, maps) => {
    if (route && route.url) {
      const KML = new maps.KmlLayer({
        url: route.url,
      });
      KML.setMap(map);
    }
  };

  return (
    <React.Fragment>
      <Block ratio={ratio} contain className={styles.TeaserMap}>
        {staticMap ? (
          <StaticGoogleMap
            zoom={mapOptions.zoom}
            center={mapOptions.center}
            apiKey={googleMapAPIKey}
            staticMapImage={staticMapImage}
            onClick={() => setStaticMap(false)}
          />
        ) : (
          <div className={styles.wrap}>
            <Suspense fallback={<LoadingIndicator />}>
              <GoogleMapReact
                zoom={mapOptions.zoom}
                center={mapOptions.center}
                options={staticOptions}
                onChange={handleMapChange}
                onChildClick={handleChildClick}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{
                  key: googleMapAPIKey,
                }}
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              >
                {clusters.map(item =>
                  item.numPoints === 1 ? (
                    <Marker
                      key={item.id}
                      lat={item.lat}
                      lng={item.lng}
                      id={item.id}
                      teaser={item.points[0]}
                      showInfo={item.id === selectedMarker}
                      onInfoClose={handleInfoClose}
                      favoriteMap={favoriteMap}
                      readmoreLink={readmoreLink}
                      fromGmapPage={fromGmapPage}
                    />
                  ) : (
                    <ClusterMarker
                      key={item.id}
                      id={item.id}
                      lat={item.lat}
                      lng={item.lng}
                      points={item.numPoints}
                      favoriteMap={favoriteMap}
                    />
                  ),
                )}
              </GoogleMapReact>
            </Suspense>
          </div>
        )}
      </Block>
      {!!showList && items.length > 0 && (
        <div className={styles.list}>
          <H2 alt size={Heading.sizes.heading2}>
            {translate('/pages/productListPage/locations')}
          </H2>
          <ul>
            {items
              .sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map(item => (
                <li>
                  <LinkButton
                    key={item.id}
                    className={styles.listItem}
                    onClick={() => handleMarkerSelect(item)}
                    title={item.intro}
                  >
                    {item.title}
                  </LinkButton>
                </li>
              ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

TeaserMap.displayName = 'TeaserMap';
TeaserMap.propTypes = {
  showList: PropTypes.bool,
  items: PropTypes.array,
  clusterRadius: PropTypes.number,
  fitBounds: PropTypes.bool,
  zoom: PropTypes.number,
  /* center lat lng */
  center: PropTypes.any,
  ratio: ratioPropType,
  favoriteMap: PropTypes.bool,
};
TeaserMap.defaultProps = {
  showList: false,
  items: [],
  clusterRadius: 60,
  zoom: 12,
  ratio: [16, 9],
  fitBounds: false,
  favoriteMap: false,
  defaultCenter: {
    lat: 59.8938364,
    lng: 10.715078,
  },
};

export default TeaserMap;
