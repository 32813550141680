import React from 'react';
import { connect } from 'redux-bundler-react';
import Helmet from 'react-helmet-async';
import useSiteSettings from 'hooks/use-site-settings';

const HelmetContainer = ({ pageData: { meta } }) => {
  const {
    googleSiteVerificationKey = '',
    logoAndFaviconSettings,
  } = useSiteSettings();

  return (
    <Helmet
      link={
        logoAndFaviconSettings && [
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: logoAndFaviconSettings.faviconSmall,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: logoAndFaviconSettings.faviconMedium,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '72x72',
            href: logoAndFaviconSettings.faviconLarge,
          },
          {
            rel: 'icon',
            type: 'image/gif',
            sizes: '114x114',
            href: logoAndFaviconSettings.faviconHuge,
          },
          {
            rel: 'apple-touch-icon',
            type: 'image/png',
            sizes: '180x180',
            href: logoAndFaviconSettings.appleTouchIcon,
          },
          {
            rel: 'apple-touch-icon-precomposed',
            type: 'image/png',
            sizes: '180x180',
            href: logoAndFaviconSettings.appleTouchIcon,
          },
        ]
      }
    >
      <title>{(meta && meta.title) || ''}</title>
      <meta
        name="google-site-verification"
        content={googleSiteVerificationKey}
      />
      {Object.keys((meta && meta.elements) || {}).map(name => (
        <meta name={name} content={meta.elements[name]} key={name} />
      ))}
      {/* TODO: add theme-color and similar stuff */}
    </Helmet>
  );
};

export default connect(
  'selectPageData',
  HelmetContainer,
);
