import React from 'react';
import { connect } from 'redux-bundler-react';

const PageEventsContainer = ({ eventResponse, children }) =>
  children({
    events: eventResponse.events,
  });

export default connect(
  'selectEventResponse',
  PageEventsContainer,
);
