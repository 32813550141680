import mitt from 'mitt';
import { getActiveElements } from './utils';
import throttle from 'packages/inmeta-epi-react/utils/throttle';

export const emitter = mitt();

const settings = {
  offset: 0.45,
  throttle: 100,
};
let elements = [];
let current = null;

const calculate = force => {
  const active = getActiveElements(elements, settings.offset);
  const newCurrent = active[active.length - 1];
  if (!force && newCurrent === current) return;
  current = newCurrent;
  emitter.emit('change');
  elements.forEach(obj => {
    obj.onChange(obj === current);
  });
};
const handleScroll = throttle(() => calculate(), settings.throttle);

// accepts a function (for getting the element) and an onChange handler
// because we don't want to add/remove elements on every ref change, we use a function in stead
export const addScrollElement = (getElement, onChange, data) => {
  // create the element object
  const obj = {
    getElement,
    onChange,
    data,
  };
  // add to elements
  elements.push(obj);
  // trigger handleScroll to handle new colors
  calculate(true);
  // if this is the only element, we have to add the event listener
  if (elements.length === 1) {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
  }
  // return a remove function for the element object
  return () => {
    // filter out our element object
    elements = elements.filter(el => el !== obj);
    // trigger handleScroll to handle new colors
    calculate(true);
    // if there are no elements left, remove the listener
    if (elements.length === 0) {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }
  };
};

export const getElements = () => elements;
export const getCurrent = () => current;
export const setSetting = (key, value) => {
  settings[key] = value;
  emitter.emit('settings', settings);
};
export const getSetting = (key, def) => (key in settings ? settings[key] : def);
