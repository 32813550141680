import React, { lazy, Suspense } from 'react';
import { connect } from 'redux-bundler-react';

import { translate } from 'utils/translate';
import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';
const Product = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'components/organisms/Product'
  ),
);
import RelatedProducts from 'components/organisms/RelatedProducts';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';


const ProductPresentationPage = ({
  pageData: { product, hierarchicalFacilityList },
  productPageLink,
  breakpoint,
  hideEnturWidget,
  hideShortDescription,
  hidePriceInformation,
  ...page
}) => (
  <DefaultLayoutContainerV2>
    <PageView
      isOneCol
      isV2design
      renderAfter={
        <React.Fragment>
          {!!(
            product &&
            product.relatedProducts &&
            product.relatedProducts.length
          ) && <RelatedProducts products={product.relatedProducts} />}
          {!!(
            product &&
            product.relatedArticles &&
            product.relatedArticles.length
          ) && (
            <RelatedProducts
              products={product.relatedArticles}
              title={translate(`/product/relatedProductsTitle/readMore`)}
            />
          )}
        </React.Fragment>
      }
      {...page}
    >
      {product && product.id ? (
        <Suspense fallback={<LoadingIndicator />}>
          <Product
            product={{...product, hierarchicalFacilityList}}
            url={page.url}
            breakpoint={breakpoint}
            hideEnturWidget={propertyValueFor(hideEnturWidget)}
            hideShortDescription={propertyValueFor(hideShortDescription)}
            hidePriceInformation={propertyValueFor(hidePriceInformation)}
          />
        </Suspense>
      ) : (
        <div>Not found</div>
      )}
    </PageView>
  </DefaultLayoutContainerV2>
);
ProductPresentationPage.displayName = 'ProductPresentationPage';
ProductPresentationPage.propTypes = {};
ProductPresentationPage.defaultProps = {};

export default connect(
  'selectPageData',
  'selectBreakpoint',
  ProductPresentationPage,
);
