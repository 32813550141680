import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Toggle from 'components/utilities/Toggle';
import Button from 'components/atoms/Button';
import { H3 } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import Modal from 'components/utilities/Modal';
import { translate } from 'utils/translate';
import { copyToClipboard } from 'utils/copyToClipboard';

import Icon from 'components/atoms/Icon';
import {
  facebookLogo,
  twitterLogo,
  envelope,
  link,
} from 'components/atoms/Icon/icons';

import * as styles from './ShareButton.scss';

const windowOpen = url =>
  window.open(
    url,
    '_blank',
    'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700,height=500',
  );

const ShareButton = ({ url, title, label, children, noMargin, noPadding }) => {
  const [copyActive, setCopyActive] = useState(false);
  const actions = useMemo(
    () => ({
      facebook: () =>
        windowOpen(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url,
          )}`,
        ),
      twitter: () =>
        windowOpen(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            `${title} ${url}`,
          )}`,
        ),
      mail: `mailto:?subject=${encodeURIComponent(
        title,
      )}&body=${encodeURIComponent(url)}`,
      clipboard: () => {
        copyToClipboard(url, () => setCopyActive(true));
      },
    }),
    [url, title],
  );
  useEffect(() => {
    let timeout;
    if (copyActive) {
      timeout = setTimeout(() => {
        setCopyActive(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [copyActive]);

  return (
    <Toggle
      on={false}
      render={({ on, toggle, setOff }) => (
        <span>
          <Button
            transparent
            onClick={toggle}
            noMargin={noMargin}
            noPadding={noPadding}
          >
            {label || children || (
              <React.Fragment>
                <span style={{ margin: '0 10px 0 0', fontSize: 16 }}>
                  <Icon icon={facebookLogo} />
                </span>
                <span style={{ margin: '0 10px', fontSize: 18 }}>
                  <Icon icon={twitterLogo} />
                </span>
                <span style={{ margin: '0 10px' }}>
                  {translate('/sharing/shareTitle')}
                </span>
              </React.Fragment>
            )}
          </Button>
          {on && (
            <Modal light shadow maxWidth="400px" onClose={setOff}>
              <Modal.Body>
                <H3>{translate('/sharing/shareTitle')}</H3>
                <Paragraph>
                  {translate('/sharing/shareParagraph')}
                  <br />
                  {title}
                </Paragraph>
                <Button
                  className={classnames(styles.shareButton, styles.odd)}
                  onClick={actions.facebook}
                >
                  <i className={styles.shareButtonIcon}>
                    <Icon icon={facebookLogo} />
                  </i>{' '}
                  Facebook
                </Button>

                <Button
                  className={classnames(styles.shareButton, styles.even)}
                  onClick={actions.twitter}
                >
                  <i className={styles.shareButtonIcon}>
                    <Icon icon={twitterLogo} />
                  </i>{' '}
                  Twitter
                </Button>
                <a
                  className={classnames(styles.shareButton, styles.odd)}
                  href={actions.mail}
                >
                  <i className={styles.shareButtonIcon}>
                    <Icon icon={envelope} />
                  </i>{' '}
                  Mail
                </a>
                <Button
                  className={classnames(styles.shareButton, styles.even)}
                  onClick={actions.clipboard}
                >
                  <i className={styles.shareButtonIcon}>
                    <Icon icon={link} />
                  </i>{' '}
                  {translate('/sharing/copyLink')}
                  {copyActive && (
                    <span className={styles.copyFeedback}>
                      {translate('/sharing/copiedLink')}
                    </span>
                  )}
                </Button>
              </Modal.Body>
            </Modal>
          )}
        </span>
      )}
    />
  );
};

ShareButton.displayName = 'ShareButton';
ShareButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  noMargin: PropTypes.bool,
  noPadding: PropTypes.bool,
};
ShareButton.defaultProps = {
  noMargin: false,
  noPadding: false,
};

export default ShareButton;
