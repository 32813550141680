import React, { Suspense, lazy } from 'react';
import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';
import PageFiltersContainer from 'containers/organisms/PageFiltersContainer';
import TeaserMap from 'components/molecules/TeaserMap';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import getTeaserForProduct from 'utils/getTeaserForProduct';
import { isUrlForSamePage } from 'utils/bundle-helper';
import useSiteSettings from 'hooks/use-site-settings';
import { Paragraph } from 'components/atoms/Text';
import { translate } from 'utils/translate';
import LocationButton from 'components/molecules/LocationButton';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';

import * as styles from './GmapPage.scss';

const PageProductsContainer = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'containers/organisms/PageProductsContainer'
  ),
);
const ProductFilter = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'components/organisms/ProductFilter'
  ),
);

const GmapPage = page => {
  const { productPageLink } = useSiteSettings();
  const geolocationOK = !!navigator.geolocation;

  return (
    <DefaultLayoutContainerV2>
      <PageView isOneCol {...page} showShareButtons>
        <PageFiltersContainer>
          {(showFilters, showLocationButton, filters) =>
            showFilters &&
            !!filters.length && (
              <div className={styles.filterWrapper}>
                {filters.map(filter => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <ProductFilter
                      key={filter.name}
                      url={page.url}
                      toggleable={false}
                      open
                      className={styles.filter}
                      {...filter}
                    />
                  </Suspense>
                ))}
                {!!page.isLoading &&
                  isUrlForSamePage(page.url, page.nextUrl) && (
                    <LoadingIndicator background="rgba(255,255,255,0.4)" />
                  )}
              </div>
            )
          }
        </PageFiltersContainer>

        {geolocationOK && (
          <div className={styles.locationButton}>
            <LocationButton
              title={translate('/pages/productListPage/locationButtonText')}
              url={page.url}
            />
          </div>
        )}

        <Suspense fallback={<LoadingIndicator />}>
          <PageProductsContainer>
            {productResponse => {
              const items = productResponse.products.map(
                getTeaserForProduct(productPageLink),
              );

              return (
                <React.Fragment>
                  {productResponse.tooManyProductsToRetrieve && (
                    <Paragraph className={styles.tooManyProductsToRetrieve}>
                      {translate('/pages/gmapPage/tooManyProducts')}
                    </Paragraph>
                  )}
                  <TeaserMap
                    ratio={[16, 9]}
                    items={items}
                    defaultCenter={{
                      lat: propertyValueFor(page.defaultLatitude) * 1,
                      lng: propertyValueFor(page.defaultLongitude) * 1,
                    }}
                    zoom={propertyValueFor(page.defaultZoomLevel)}
                    showList
                    fromGmapPage
                  />
                </React.Fragment>
              );
            }}
          </PageProductsContainer>
        </Suspense>
      </PageView>
    </DefaultLayoutContainerV2>
  );
};
GmapPage.displayName = 'GmapPage';
GmapPage.propTypes = {};
GmapPage.defaultProps = {};

export default GmapPage;
