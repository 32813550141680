import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon, { iconPropType } from 'components/atoms/Icon';
import { externalLink, facebookLogo } from 'components/atoms/Icon/icons';
import Text from 'components/atoms/Text';
import * as styles from './Button.scss';

const Button = React.forwardRef(
  (
    {
      as,
      children,
      label,
      onClick,
      href,
      size,
      className,
      disabled,
      transparent,
      rounded,
      ghost,
      noMargin,
      noPadding,
      bordered,
      full,
      palette = 'primary',
      type = 'button',
      ...rest
    },
    ref,
  ) => {
    const computedClassName = classnames(
      styles.button,
      className,
      size,
      !transparent && (Button.palettes[palette] || Button.palettes.primary),
      {
        [styles.disabled]: disabled,
        [styles.transparent]: transparent,
        [styles.rounded]: rounded,
        [styles.ghost]: ghost,
        [styles.noMargin]: noMargin,
        [styles.noPadding]: noPadding,
        [styles.full]: full,
        [styles.bordered]: bordered,
      },
    );
    const Element = as ? as : href ? 'a' : 'button';
    const props = {
      as: Element,
      className: computedClassName,
      onClick,
      ref,
      ...(Element === 'button' && {
        type,
        disabled,
      }),
      ...(Element === 'a' && {
        href: !disabled ? href : null,
      }),
      ...rest,
    };
    return <Text {...props}>{label || children}</Text>;
  },
);
Button.displayName = 'Button';
Button.sizes = {
  small: Text.sizes.small,
  normal: Text.sizes.normal,
  large: Text.sizes.large,
};
Button.palettes = {
  primary: styles.primaryPalette,
  secondary: styles.secondaryPalette,
  tertiary: styles.tertiaryPalette,
};
Button.propTypes = {
  /** Adds a class to the button */
  className: PropTypes.string,
  style: PropTypes.object,
  /** onClick */
  onClick: PropTypes.func,
  href: PropTypes.string,
  /** children (anything) */
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  palette: PropTypes.string,
  transparent: PropTypes.bool,
  rounded: PropTypes.bool,
  ghost: PropTypes.bool,
  bordered: PropTypes.bool,
  noMargin: PropTypes.bool,
  noPadding: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(Button.sizes)),
  full: PropTypes.bool,
};
Button.defaultProps = {
  disabled: false,
  size: Button.sizes.normal,
};

export const IconButton = React.forwardRef(
  (
    {
      className,
      iconBefore,
      iconAfter,
      textAlign = 'center',
      children,
      ...props
    },
    ref,
  ) => (
    <Button
      className={classnames(className, styles.iconButton)}
      {...props}
      ref={ref}
    >
      {!!iconBefore && <Icon icon={iconBefore} className={styles.iconBefore} />}
      {!!children && (
        <span
          className={classnames(styles.iconButtonText, {
            [styles.iconButtonTextLeft]: textAlign === 'left',
            [styles.iconButtonTextRight]: textAlign === 'right',
            [styles.iconButtonHasIconAfter]: !!iconAfter,
            [styles.iconButtonHasIconBefore]: !!iconBefore,
          })}
        >
          {children}
        </span>
      )}
      {!!iconAfter && <Icon icon={iconAfter} className={styles.iconAfter} />}
    </Button>
  ),
);
IconButton.propTypes = {
  ...Button.propTypes,
  position: PropTypes.oneOf(['before', 'after']),
  icon: iconPropType,
};

export const BookingButton = ({ className, secondary, ...props }) => (
  <Button
    className={classnames(styles.bookingButton, className, {
      [styles.bookingButtonInverted]: secondary,
    })}
    {...props}
  />
);
export const FavoriteButton = ({ className, ...props }) => (
  <Button className={classnames(styles.favoriteButton, className)} {...props} />
);
export const LinkButton = ({ className, ...props }) => (
  <Button className={classnames(styles.linkButton, className)} {...props} />
);

export const SocialButton = ({ brand, className, children, ...props }) => (
  <Button
    size={Button.sizes.large}
    className={classnames(
      styles.socialButton,
      styles[`brand-${brand}`],
      className,
    )}
    {...props}
  >
    <span className={styles.wrap}>
      <span className={styles.icon}>
        <Icon icon={facebookLogo} />
      </span>
      <span className={styles.text}>{children}</span>
    </span>
  </Button>
);

export default Button;
