import React from "react";
import PropTypes from "prop-types";
import * as styles from "./Sparkles.scss";

const Sparkles = props => {
  return <i className={styles.sparkles} />;
};
Sparkles.displayName = "Sparkles";
Sparkles.propTypes = {};
Sparkles.defaultProps = {};

export default Sparkles;
