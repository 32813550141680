let constants = {};

export const set = c => {
  constants = c;
};

export const list = () => constants;

export const get = key => {
  if (!(key in constants)) {
    return key;
  }
  return constants[key];
};
