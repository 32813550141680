import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/atoms/Button';
import useLockBodyScroll from 'hooks/use-lock-body-scroll.js';
import classnames from 'classnames';
import * as styles from './Modal.scss';

const Modal = React.forwardRef(
  (
    {
      light,
      shadow,
      maxWidth,
      fullscreen,
      onClose,
      children,
      closeBtn,
      position,
      scrollLock,
      className,
    },
    ref,
  ) => {
    if (scrollLock) useLockBodyScroll();
    return (
      <div
        className={classnames(
          styles.root,
          {
            [styles.isLight]: light,
            [styles.hasShadow]: shadow,
            [styles.positionTop]: position === 'top',
            [styles.positionCenter]: position === 'center',
            [styles.positionBottom]: position === 'bottom',
            [styles.isFullscreen]: fullscreen,
          },
          className,
        )}
      >
        <div className={styles.overlay} />
        <div className={styles.modalWrapper}>
          <div
            className={styles.modal}
            style={{ maxWidth: maxWidth || 'auto' }}
          >
            {!!closeBtn
              ? closeBtn
              : onClose && (
                  <Button
                    transparent
                    onClick={onClose}
                    className={styles.closeButton}
                    ref={ref}
                  >
                    ×
                  </Button>
                )}
            {children}
          </div>
        </div>
      </div>
    );
  },
);
Modal.displayName = 'Modal';
Modal.propTypes = {
  position: PropTypes.oneOf(['top', 'center', 'bottom']),
  light: PropTypes.bool,
  shadow: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any,
  fullscreen: PropTypes.bool,
  closeBtn: PropTypes.object,
  scrollLock: PropTypes.bool,
  className: PropTypes.string,
};
Modal.defaultProps = {
  position: 'center',
  fullscreen: false,
  scrollLock: false,
};

Modal.Body = ({ children, className, ...props }) => (
  <div className={classnames(className, styles.modalBody)} {...props}>
    {children}
  </div>
);
Modal.Body.displayName = 'ModalBody';
Modal.Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
Modal.Body.defaultProps = {};

export default Modal;
