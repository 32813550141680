import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';

import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';
import TellusRegistrationForm from 'components/organisms/TellusRegistrationForm';

import * as styles from './TellusRegistrationPage.scss';

const TellusRegistrationPage = ({ pageData, ...page }) => (
  <DefaultLayoutContainer>
    <PageView {...page}>
      <TellusRegistrationForm
        confirmationPage={propertyValueFor(page.confirmationPage)}
        tellUsRegistrationUrl={propertyValueFor(page.tellUsRegistrationUrl)}
        termsOfUsePage={propertyValueFor(page.termsOfUsePage)}
        pageData={pageData}
        recaptcha={{
          siteKey: pageData.googleRecaptchaPublicKey,
          privateKey: pageData.googleRecaptchaPrivateKey,
        }}
      />
    </PageView>
  </DefaultLayoutContainer>
);

TellusRegistrationPage.displayName = 'TellusRegistrationPage';
TellusRegistrationPage.propTypes = {};
TellusRegistrationPage.defaultProps = {};

export default connect(
  'selectPageData',
  TellusRegistrationPage,
);
