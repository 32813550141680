export const getCookie = (key, defaultValue = null) => {
    const value = document.cookie.split("; ").reduce((value, currentCookie) => {
       const item = currentCookie.split("=");
       const storedKey = item[0];
       const storedValue = item[1];
       return key === storedKey 
         ? decodeURIComponent(storedValue)
         : value;
    }, "");
    return value === "" ? defaultValue : value;
}
export const setCookie = (key, value, numberOfDays, path = "/") => {
    const now = new Date();
    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + (numberOfDays * 60 * 60 * 24 * 1000));
    document.cookie = `${key}=${value || ""}; expires=${now.toUTCString()}; path=${path}`;
};
export const deleteCookie = (key, path) => {
    document.cookie = `${key}=${value || ""}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
};