import React from "react";
import PropTypes from "prop-types";

import DefaultLayoutContainer from "containers/layouts/DefaultLayoutContainer";
import Container from "components/atoms/Container";
import LoadingIndicator from "components/utilities/LoadingIndicator";

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';

const LoadingPage = props => (
  <DefaultLayoutContainerV2 noBreadcrumbs>
    <Container style={{ minHeight: "50vh", transform: "translate(0)" }}>
      <LoadingIndicator />
    </Container>
  </DefaultLayoutContainerV2>
);
LoadingPage.displayName = "LoadingPage";
LoadingPage.propTypes = {};
LoadingPage.defaultProps = {};

export default LoadingPage;
