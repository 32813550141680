export const ACTION_NEXT = "ACTION_NEXT";
export const ACTION_PREV = "ACTION_PREV";
export const ACTION_GOTO = "ACTION_GOTO";
export const ACTION_SET_ITEMS = "ACTION_SET_ITEMS";
export const ACTION_TRANSITION_START = "ACTION_TRANSITION_START";
export const ACTION_TRANSITION_END = "ACTION_TRANSITION_END";
export const ACTION_RESET = "ACTION_RESET";
export const ACTION_HOVER_START = "ACTION_HOVER_START";
export const ACTION_HOVER_END = "ACTION_HOVER_END";

const getIndex = (newIndex, count) => {
  if (count < 1) {
    return 0;
  }
  if (newIndex >= count) {
    return 0;
  }
  if (newIndex < 0) {
    return count - 1;
  }
  return newIndex;
};

export const getInitialState = ({
  initialIndex = 0,
  hover = false,
  items = []
}) => ({
  index: initialIndex,
  hover,
  inTransition: false,
  increment: true,
  items
});

export const reducer = (state, action) => {
  // console.log(state, action);
  switch (action.type) {
    case ACTION_NEXT:
      if (state.inTransition) {
        return state;
      }
      return {
        ...state,
        index: getIndex(state.index + 1, state.items.length),
        increment: true,
        inTransition: false
      };
    case ACTION_PREV:
      if (state.inTransition) {
        return state;
      }
      return {
        ...state,
        index: getIndex(state.index - 1, state.items.length),
        increment: false,
        inTransition: false
      };
    case ACTION_GOTO:
      if (state.inTransition) {
        return state;
      }
      let goToIndex = getIndex(action.index, state.items.length);
      return {
        ...state,
        index: goToIndex,
        increment: goToIndex > state.index,
        inTransition: false
      };
    case ACTION_SET_ITEMS:
      return { ...state, items: action.items };
    case ACTION_TRANSITION_START:
      return { ...state, inTransition: true };
    case ACTION_TRANSITION_END:
      let index =
        action.index !== undefined
          ? getIndex(action.index, state.items.length)
          : state.index;
      return {
        ...state,
        inTransition: false,
        index,
        increment:
          action.increment !== undefined ? action.increment : state.increment
      };
    case ACTION_HOVER_START:
      return { ...state, hover: true };
    case ACTION_HOVER_END:
      return { ...state, hover: false };
    case ACTION_RESET:
      return getInitialState({
        initialIndex: action.initialIndex,
        items: state.items,
        hover: state.hover
      });
    default:
      return state;
  }
};
