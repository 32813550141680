import { useEffect } from 'react';
import { isBrowser } from 'packages/inmeta-epi-react/utils/device';
export default function useOnClickOutside(ref, handler, listen = true) {
  useEffect(() => {
    if (isBrowser && ref.current && listen) {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        event.stopPropagation();
        setTimeout(() => {
          handler();
        }, 100);
      };

      document.addEventListener('click', listener);

      return () => {
        document.removeEventListener('click', listener);
      };
    }
  }, [ref, handler, listen]);
}
