import React from 'react';
import PropTypes from 'prop-types';

import { H2 } from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import { Paragraph } from 'components/atoms/Text';
import Container from 'components/atoms/Container';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';

import * as styles from './MyPageSectionFoodAndDrink.scss';

const MyPageSectionFoodAndDrink = ({ items = [] }) => (
  <BreakpointContainer
    render={breakpoint => {
      const [headingSize, paragraphSize] =
        breakpoint < BREAKPOINT_TABLET_PORTRAIT
          ? [H2.sizes.heading6, Paragraph.sizes.small]
          : [H2.sizes.heading2, Paragraph.sizes.heading6];
      return (
        <Container>
          <div className={styles.root}>
            {items.map((article, i) => (
              <div key={article.url} className={styles.item}>
                <div className={styles.body}>
                  <a href={article.url} className={styles.link}>
                    <H2 alt center size={headingSize} className={styles.title}>
                      {article.title}
                    </H2>
                    <Paragraph size={paragraphSize} className={styles.street}>
                      {article.address.street || ' '}
                    </Paragraph>
                    <Paragraph size={paragraphSize} className={styles.zip}>
                      {[
                        article.address.postalCode,
                        article.address.postalName,
                      ].join(' ')}
                    </Paragraph>
                  </a>
                  <Paragraph size={paragraphSize}>
                    {article.phone || ' '}
                  </Paragraph>
                </div>
                <Image src={article.imageUrl} ratio={[16, 9]} fit="cover" cropDetails={article.cropDetails} />
              </div>
            ))}
          </div>
        </Container>
      );
    }}
  />
);
MyPageSectionFoodAndDrink.displayName = 'MyPageSectionFoodAndDrink';
MyPageSectionFoodAndDrink.propTypes = {};
MyPageSectionFoodAndDrink.defaultProps = {};

export default MyPageSectionFoodAndDrink;
