import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import classnames from 'classnames';

import { appendQueryParams } from 'utils/bundle-helper';
import Link from 'components/atoms/Link';

import Icon from 'components/atoms/Icon';
import { spinner } from 'components/atoms/Icon/icons';

import * as styles from './LocationButton.scss';

const LocationButton = ({ doUpdateUrl, title, url }) => {
  const [fetching, setFetching] = useState(false);

  const hasGeo = ['latitude', 'longitude'].some(substring =>
    url.href.includes(substring),
  );

  const defaultUrl = appendQueryParams(
    url,
    {
      latitude: '',
      longitude: '',
      distance: '',
    },
    true,
  ).href;

  return (
    <Link
      className={classnames(styles.geoButton, {
        [styles.selected]: hasGeo,
      })}
      href={defaultUrl}
      data-no-scrolltop
      onClick={e => {
        e.preventDefault();
        if (!hasGeo) {
          setFetching(true);

          navigator.geolocation.getCurrentPosition(position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const newUrl = appendQueryParams(
              url,
              {
                latitude: pos.lat,
                longitude: pos.lng,
                distance: '500',
              },
              true,
            ).href;
            setFetching(false);
            doUpdateUrl(newUrl);
          });
        }
      }}
    >
      {!fetching && <span>{title}</span>}
      {fetching && <Icon icon={spinner} spin className={styles.icon} />}
    </Link>
  );
};
LocationButton.displayName = 'LocationButton';
LocationButton.propTypes = {
  doUpdateUrl: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.object,
};
LocationButton.defaultProps = {};

export default connect('doUpdateUrl', LocationButton);
