import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  withColumnContext,
  COLUMN_MAIN,
  COLUMN_MAINLEFT,
  COLUMN_MAINRIGHT,
} from 'components/molecules/PageGrid';
import {
  CustomPropTypes,
  propertyValueFor,
} from 'packages/inmeta-epi-react/utils/epi-react';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { getTeaserConfigFromViewType } from 'components/organisms/TeaserList';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import classnames from 'classnames';
import * as styles from './PageListBlock.scss';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';

const TeaserList = React.lazy(() =>
  import(
    /* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'
  ),
);

const PageListBlock = React.memo(({ block, teaserItems, columnContext }) => {
  if (!teaserItems.length) {
    return null;
  }
  let color = null;
  let hasWhiteFont = false;
  const style = {};
  if (block.colorTheme && propertyValueFor(block.colorTheme)) {
    let parsedColorTheme = propertyValueFor(block.colorTheme);
    parsedColorTheme = JSON.parse(parsedColorTheme);
    color = parsedColorTheme.Color;
    hasWhiteFont = parsedColorTheme.IsWhiteFont;
  }
  if (propertyValueFor(block.colorViewType) === 'colouredBlockBackground') {
    style.backgroundColor = color;
    if (hasWhiteFont) style.color = '#fff';
  }
  const blockBackground =
    propertyValueFor(block.colorViewType) === 'colouredBlockBackground';

  const hasColorTextBg =
    !!color &&
    propertyValueFor(block.colorViewType) === 'colouredTextBackground';

  const teaserListConfig = getTeaserConfigFromViewType(
    propertyValueFor(block.viewType),
  );

  const slidesAsWide = propertyValueFor(block.slidesAsWide);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BlockWrapper
        block={block}
        columnContext={columnContext}
        style={style}
        className={classnames(styles.root, {
          [styles.blockBackground]: blockBackground,
          [styles.colorTheme]: color,
          [styles.wide]: teaserListConfig.slider && !teaserListConfig.compact,
        })}
      >
        {block.ingress && (
          <PropertyFor
            property={block.ingress}
            className={styles.introduction}
            tagName="p"
          />
        )}
        <TeaserList
          config={teaserListConfig}
          showDate={propertyValueFor(block.displayAsNews)}
          items={teaserItems}
          noBorder
          columnCount={propertyValueFor(block.singleColumn) ? 1 : 2}
          colorTheme={{ color: hasColorTextBg ? color : null, hasWhiteFont }}
          showFavoriteButton={
            columnContext === COLUMN_MAIN ||
            columnContext === COLUMN_MAINLEFT ||
            columnContext === COLUMN_MAINRIGHT
          }
          wideContainer={slidesAsWide}
        />
      </BlockWrapper>
    </Suspense>
  );
});
PageListBlock.displayName = 'PageListBlock';
PageListBlock.propTypes = {
  block: PropTypes.shape({
    title: CustomPropTypes.EpiserverValue,
    viewType: CustomPropTypes.EpiserverValue,
    openInNewWindow: CustomPropTypes.EpiserverValue,
    readMoreLink: CustomPropTypes.EpiserverValue,
    readMoreText: CustomPropTypes.EpiserverValue,
    colorTheme: CustomPropTypes.EpiserverValue,
    colorViewType: CustomPropTypes.EpiserverValue,
    displayAsNews: CustomPropTypes.EpiserverValue,
    singleColumn: CustomPropTypes.EpiserverValue,
    readMoreAsButton: CustomPropTypes.EpiserverValue,
  }),
  teaserItems: PropTypes.array,
  columnContext: PropTypes.string,
};
PageListBlock.defaultProps = {
  block: {},
  teaserItems: [],
};

export default withColumnContext(PageListBlock);
