import React, {lazy, Suspense} from 'react';

import Container from 'components/atoms/Container';
const TeaserMap = lazy(() => import(/* webpackChunkName: "teaserMap" */ 'components/molecules/TeaserMap'));
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
import { useThemeConfig } from 'hooks/use-theme-config';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

import * as styles from './MyPageSectionMap.scss';

const MyPageSectionMap = ({ items = [] }) => {
  const themeConfig = useThemeConfig();

  return (
    <BreakpointContainer
      render={breakpoint => (
        <Container full noPadding>
          <Suspense fallback={LoadingIndicator}>
          <TeaserMap
            items={items.filter(item => !!item.geoLocation)}
            clusterRadius={60}
            ratio={breakpoint >= BREAKPOINT_TABLET_PORTRAIT ? [16, 9] : [1, 1]}
            favoriteMap
            fromGmapPage
            zoom={themeConfig.defaultMapZoomLevel}
          />
          </Suspense>
        </Container>
      )}
    />
  );
};

MyPageSectionMap.displayName = 'MyPageSectionMap';
MyPageSectionMap.propTypes = {};
MyPageSectionMap.defaultProps = {};

export default MyPageSectionMap;
