import './instrument';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import './utils/component-registry';
import { render } from 'react-dom';
import { FacebookProvider } from 'react-facebook/module';
import Site from 'packages/inmeta-epi-react/components/Site';
import { setEditMode } from 'packages/inmeta-epi-react/utils/is-in-edit-mode';
import { setPhrases } from 'utils/translate';
import { getBrowserBreakpoint } from 'utils/breakpoint';
import * as constants from 'utils/constants';
import { HelmetProvider } from 'react-helmet-async';
import SiteSettingsContext from 'context/SiteSettings';
import { parseUrl } from 'utils/bundle-helper';
import { USERNAME_ANONYMOUS } from './state/user';
import { localMyPageApi } from './server/api';
import getStore from './state';
import { createPageProductsDataFromProductResponse } from './state/page-products';
import { createPageDataFromModel } from './state/create-page-bundle';
import { EVENT_LOAD } from './state/create-url-bundle';

// import icons to make webpack create the sprite
const svgModules = require.context('./assets/svgs', false, /\.svg$/);
svgModules.keys().forEach(svgModules);

// because of how extraxt-text-plugin works, this file has to be loaded using require - BUT IT IS BROKEN ATM
require('./styles/app.scss');
const breakpoint = getBrowserBreakpoint();
const initialData = { ...(window.INITIAL_DATA || {}) };
setEditMode(initialData.pageIsInEditMode);
window.LOCALE = initialData.culture;
setPhrases(initialData.languageStrings);
constants.set(initialData.constantStrings);
const url = parseUrl(window.INITIAL_DATA.model.url, EVENT_LOAD);
const settings = {
  ...initialData.siteSettings,
  ...initialData.themeSettings,
  professionalsMenu: initialData.professionalsMenu,
  culture: initialData.culture,
  regionMenu: initialData.regionMenu,
};
const {userData, productResponse, ...pageModel} = initialData.model;
const store = getStore({
  breakpoint,
  page: createPageDataFromModel(pageModel),
  pageProducts: {
    isFetching: false,
    data: createPageProductsDataFromProductResponse(
      url.path,
      productResponse,
    ),
  },
  user: {
    data: {
      ...userData,
      ...(userData.userName === USERNAME_ANONYMOUS && {
        favorites: localMyPageApi.getFavorites(),
      }),
    },
    unimportedFavorites: [],
  },
  mainMenu: initialData.mainMenu,
  settings,
});
const root = document.getElementById('root');
// !!! THIS IS A WORKAROUND TO MAKE SURE STUFF LIKE DIFFERENCES IN GUESSED BREAKPOINT AND ACTUAL BREAKPOINT DOESN'T CREATE HAVOC
// !!! Because we don't always render the same things on server and client, the hydrate/render reconciliation breaks stuff.. a lot..
// !!! See https://github.com/facebook/react/issues/10591
root.innerHTML = '';
// TODO: Add language to FacebookProvider (remember correct format)
render(
  <SiteSettingsContext.Provider value={settings}>
    <HelmetProvider>
      <FacebookProvider
        lang={initialData.culture}
        appId={settings.facbookAppId || '277434839740389'}
      >
        <Site store={store} />
      </FacebookProvider>
    </HelmetProvider>
  </SiteSettingsContext.Provider>,
  root,
);
