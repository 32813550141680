import React, { lazy, Suspense } from 'react';
import classnames from 'classnames';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';
import PageEventsContainer from 'containers/organisms/PageEventsContainer';
import EventListDateFilter from 'components/organisms/EventListDateFilter';
import PageFiltersContainer from 'containers/organisms/PageFiltersContainer';
const ProductFilter = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'components/organisms/ProductFilter'
  ),
);
import PageOtherListPagesContainer from 'containers/organisms/PageOtherListPagesContainer';
import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import { calendar, marker } from 'components/atoms/Icon/icons';
import InlineDropdown from 'components/molecules/InlineDropdown';
import ConditionalWrap from 'components/utilities/ConditionalWrap';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { isUrlForSamePage } from 'utils/bundle-helper';
import { translate } from 'utils/translate';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';

import * as styles from './ConferenceCalendarPage.scss';

const ConferenceCalendarPage = ({ useWideTemplate, ...page }) => (
  <DefaultLayoutContainerV2>
    <PageView {...page} isWideMain showShareButtons>
      <div className={styles.filters}>
        <EventListDateFilter url={page.url} />
        <PageOtherListPagesContainer>
          {({ otherListPages }) => (
            <InlineDropdown
              label={
                otherListPages.links[0].isCurrentpage
                  ? translate('/pages/eventListPage/allCategories')
                  : otherListPages.name
              }
              className={classnames(styles.categorySelect, styles.filter)}
              toggleable
            >
              <ul className={styles.list}>
                {otherListPages.links.map((link, i) => (
                  <li className={styles.item} key={link.url}>
                    <Link href={link.url}>
                      <ConditionalWrap
                        condition={link.isCurrentpage}
                        wrap={children => <strong>{children}</strong>}
                      >
                        {i === 0
                          ? translate('/pages/eventListPage/allCategories')
                          : link.name}
                      </ConditionalWrap>
                    </Link>
                  </li>
                ))}
              </ul>
            </InlineDropdown>
          )}
        </PageOtherListPagesContainer>
        <PageFiltersContainer>
          {(showFilters, showLocationButton, filters) => (
            <React.Fragment>
              {showFilters &&
                filters.map(filter => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <ProductFilter
                      url={page.url}
                      {...filter}
                      className={styles.filter}
                    />
                  </Suspense>
                ))}
            </React.Fragment>
          )}
        </PageFiltersContainer>
        {!!page.isLoading && isUrlForSamePage(page.url, page.nextUrl) && (
          <LoadingIndicator background="rgba(255,255,255,0.4)" />
        )}
      </div>
      <PageEventsContainer>
        {pageEvents => {
          const groupedEvents = pageEvents.events
            .filter(event => {
              if (!page.query) {
                return event;
              }
              return event;
            })
            .reduce((accumulator, item) => {
              const fromYear = new Date(item.fromDate).getFullYear();
              // const toYear = new Date(item.toDate).getFullYear();
              const year = fromYear;
              if (accumulator[year]) {
                accumulator[year].push(item);
              } else {
                accumulator[year] = [item];
              }
              return accumulator;
            }, []);
          // console.log(productResponse);
          // console.log(groupedEvents);
          return (
            <div className={styles.tableWrapper}>
              {groupedEvents.map(events => {
                return (
                  <React.Fragment>
                    <h3 className={styles.heading}>
                      {new Date(events[0].fromDate).getFullYear()}
                    </h3>
                    <table>
                      <thead>
                        <tr>
                          <th>{translate('/pages/conferenceCalendar/date')}</th>
                          <th className="el-event-name">
                            {translate('/pages/conferenceCalendar/event')}
                          </th>
                          {propertyValueFor(page.showHost) && (
                            <th className={styles.hiddenAtPhone}>
                              {translate('/pages/conferenceCalendar/organizer')}
                            </th>
                          )}
                          {(propertyValueFor(page.showSubCategoryType1) ||
                            propertyValueFor(page.showSubCategoryType2)) && (
                            <th className={styles.hiddenAtPhone}>
                              {translate('/pages/conferenceCalendar/type')}
                            </th>
                          )}
                          {propertyValueFor(page.showCapacity) && (
                            <th className={styles.hiddenAtPhone}>
                              {translate(
                                '/pages/conferenceCalendar/noOfDelegates',
                              )}
                            </th>
                          )}
                          {propertyValueFor(page.showLocation) && (
                            <th className={styles.hiddenAtPhone}>
                              {translate('/pages/conferenceCalendar/place')}
                            </th>
                          )}
                          {propertyValueFor(page.showTechHost) && (
                            <th className={styles.hiddenAtPhone}>
                              {translate(
                                '/pages/conferenceCalendar/technicalHost',
                              )}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {events.map(product => (
                          <tr>
                            <td
                              className={classnames(styles.cell, styles.date)}
                            >
                              {product.fromDate && product.toDate && (
                                <span
                                  className={classnames(
                                    styles.cellIcon,
                                    styles.hiddenAtPhone,
                                  )}
                                >
                                  <Icon
                                    icon={calendar}
                                    className={styles.icon}
                                  />
                                </span>
                              )}
                              {product.fromDate && product.toDate && (
                                <div className={styles.cellText}>
                                  <time dateTime={new Date(product.fromDate)}>
                                    {`${(
                                      '0' + new Date(product.fromDate).getDate()
                                    ).slice(-2)}.${`0${new Date(
                                      product.fromDate,
                                    ).getMonth() + 1}`.slice(-2)}.`}
                                  </time>
                                  <span> - </span>
                                  <time dateTime={new Date(product.toDate)}>
                                    {`${(
                                      '0' + new Date(product.toDate).getDate()
                                    ).slice(-2)}.${(
                                      '0' +
                                      (new Date(product.toDate).getMonth() + 1)
                                    ).slice(-2)}.`}
                                  </time>
                                </div>
                              )}
                            </td>
                            <td
                              className={classnames(styles.cell, styles.title)}
                            >
                              {product.title && (
                                <Link
                                  href={product.url.slice(1)}
                                  className={styles.cellText}
                                >
                                  {product.title}
                                </Link>
                              )}
                            </td>
                            {propertyValueFor(page.showHost) && (
                              <td
                                className={classnames(
                                  styles.cell,
                                  styles.organiser,
                                  styles.hiddenAtPhone,
                                )}
                              >
                                {product.organiser && (
                                  <p className={styles.cellText}>
                                    {product.organiser}
                                  </p>
                                )}
                                {product.organiserEmail && (
                                  <Link
                                    href={`mailto:${product.organiserEmail}`}
                                    className={styles.cellText}
                                  >
                                    e-mail
                                  </Link>
                                )}
                              </td>
                            )}

                            {(propertyValueFor(page.showSubCategoryType1) ||
                              propertyValueFor(page.showSubCategoryType2)) && (
                              <td
                                className={classnames(
                                  styles.category,
                                  styles.cell,
                                  styles.hiddenAtPhone,
                                )}
                              >
                                {product.category && (
                                  <p className={styles.cellText}>
                                    {product.category}
                                  </p>
                                )}
                              </td>
                            )}

                            {propertyValueFor(page.showCapacity) && (
                              <td
                                className={classnames(
                                  styles.cell,
                                  styles.hiddenAtPhone,
                                )}
                              >
                                <p className={styles.cellText}>
                                  {product.maxAttendees > 0
                                    ? product.maxAttendees
                                    : '-'}
                                </p>
                              </td>
                            )}

                            {propertyValueFor(page.showLocation) && (
                              <td
                                className={classnames(
                                  styles.cell,
                                  styles.hiddenAtPhone,
                                )}
                              >
                                {product.location && (
                                  <span className={styles.cellIcon}>
                                    <Icon
                                      icon={marker}
                                      className={styles.icon}
                                    />
                                  </span>
                                )}
                                {product.location && (
                                  <span className={styles.cellText}>
                                    {product.location}
                                  </span>
                                )}
                              </td>
                            )}
                            {propertyValueFor(page.showTechHost) && (
                              <td
                                className={classnames(
                                  styles.cell,
                                  styles.hiddenAtPhone,
                                )}
                              >
                                {product.techHost && (
                                  <Link
                                    href={product.url.slice(1)}
                                    className={styles.cellText}
                                  >
                                    {product.techHost.name &&
                                      product.techHost.name}
                                  </Link>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </PageEventsContainer>
    </PageView>
  </DefaultLayoutContainerV2>
);
ConferenceCalendarPage.displayName = 'ConferenceCalendarPage';
ConferenceCalendarPage.propTypes = {};
ConferenceCalendarPage.defaultProps = {};

export default ConferenceCalendarPage;
