import React from 'react';
import { connect } from 'redux-bundler-react';
import {
  BREAKPOINT_TABLET_LANDSCAPE,
  BREAKPOINT_TABLET_PORTRAIT,
} from 'utils/breakpoint';
import MobileHeaderV2 from 'components/organisms/MobileHeaderV2';
import DesktopHeaderV2 from 'components/organisms/DesktopHeaderV2';
import DesktopHeader from 'components/organisms/DesktopHeader';
import MobileHeader from 'components/organisms/MobileHeader';

export default connect(
  'selectSettingsRaw',
  'selectPageData',
  'selectBreakpoint',
  'selectUser',
  'selectUserIsLoggedIn',
  'selectMainMenuItems',
  'selectRegionMenu',
  'doLogOut',
  ({
    settingsRaw: {
      professionalsMenu,
      logoAndFaviconSettings,
      quickSearchText,
      searchPage,
      enableStickyNavigation,
    },
    pageData: { languageLinks, url },
    breakpoint,
    user,
    userIsLoggedIn,
    mainMenuItems,
    regionMenu,
    noSticky = false,
    doLogOut,
    mobileOnly,
  }) => {

    const useDesktop = INITIAL_DATA.megaMenu ? DesktopHeaderV2 : DesktopHeader;
    const useMobile = INITIAL_DATA.megaMenu ? MobileHeaderV2 : MobileHeader;

    const HeaderComponentV2 =
      !mobileOnly && breakpoint > BREAKPOINT_TABLET_PORTRAIT
        ? useDesktop
        : useMobile;

    return (
      <HeaderComponentV2
        isSticky={!noSticky && enableStickyNavigation}
        menuItems={mainMenuItems}
        professionalsMenu={{
          label: professionalsMenu.professionalsMenuName,
          items: professionalsMenu.professionalsMenuLinks,
        }}
        languageLinks={languageLinks}
        quickSearchText={quickSearchText}
        searchPageLink={searchPage}
        logo={{
          link: logoAndFaviconSettings.logoLink,
          desktopImage: logoAndFaviconSettings.logoDesktop,
          mobileImage: logoAndFaviconSettings.logoMobile,
        }}
        secondaryLogo={{
          link: logoAndFaviconSettings.secondaryLogoLink,
          desktopImage: logoAndFaviconSettings.secondaryLogoDesktop,
          mobileImage: logoAndFaviconSettings.secondaryLogoMobile,
        }}
        userMenu={{
          logoutAction: doLogOut,
          url: user.myFavoritesPageUrl,
          isLoggedIn: userIsLoggedIn,
          user,
        }}
        regionMenu={regionMenu}
        key={url.href}
        breakpoint={breakpoint}
      />
    );
  },
);
