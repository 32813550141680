import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'utils/dateTime';

const Time = ({ date, format }) => (
  <time dateTime={getFormattedDate(date, 'yyyy-MM-dd')}>
    {getFormattedDate(date, format)}
  </time>
);

Time.displayName = 'Time';
Time.propTypes = {
  date: PropTypes.any,
  format: PropTypes.string,
};
Time.defaultProps = {
  format: 'P',
};

export default Time;
