import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/utilities/Modal';
import { H2, H3 } from 'components/atoms/Heading';
import Text, { Paragraph } from 'components/atoms/Text';
import Button, { FavoriteButton } from 'components/atoms/Button';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { IS_BROWSER } from 'utils/environment';
import { myPageApi } from 'server/api';
import { useFetch } from 'hooks/use-fetch';

import * as styles from './LocalFavoritesImporter.scss';

const LocalFavoritesImporter = React.memo(
  ({ unimportedFavorites = [], onClose }) => {
    const { fetch, data, error, isLoading } = useFetch(myPageApi.addFavorites);
    const showModal = useMemo(() => IS_BROWSER, []);
    const handleSave = useCallback(() => {
      // handle save
      fetch(unimportedFavorites.map(favorite => favorite.myFavoritesId));
    }, [unimportedFavorites]);
    if (!showModal) return null;
    const showFavoriteList = !data;
    const showSavedScreen = !!data;
    const showErrorMessage = !!error && !isLoading;
    const showButtons = !data && !isLoading;
    return (
      <Modal shadow maxWidth="800px" position="top">
        <Modal.Body>
          <H2 alt className={styles.title}>
            Importer favoritter
          </H2>
          <div style={{ transform: 'translate(0)' }}>
            {isLoading && (
              <LoadingIndicator
                text="Lagrer favoritter"
                background="rgba(255,255,255,0.8)"
              />
            )}
            {showSavedScreen && (
              <div style={{ padding: '3rem 0' }}>
                <Paragraph>Favorittene er lagret!</Paragraph>
                <FavoriteButton onClick={onClose}>Lukk</FavoriteButton>
              </div>
            )}
            {showFavoriteList && (
              <React.Fragment>
                <Paragraph>
                  Du har følgende favoritter lagret i cookies
                  (informasjonskapsler), ønsker du å lagre dem?
                </Paragraph>
                <ul className={styles.list}>
                  {unimportedFavorites.map(favorite => (
                    <li key={favorite.myFavoritesId}>
                      <H3 size={Text.sizes.heading4} alt>
                        {favorite.title}
                      </H3>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )}

            {showErrorMessage && (
              <Paragraph>
                Det oppstod en feil ved lagring av favoritter. Prøv igjen
              </Paragraph>
            )}
            {showButtons && (
              <div className={styles.controls}>
                <Button transparent onClick={onClose}>
                  Avbryt
                </Button>{' '}
                <FavoriteButton onClick={handleSave}>
                  Importer favoritter
                </FavoriteButton>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  },
);
LocalFavoritesImporter.displayName = 'LocalFavoritesImporter';
LocalFavoritesImporter.propTypes = {
  unimportedFavorites: PropTypes.array,
  onClose: PropTypes.func,
};
LocalFavoritesImporter.defaultProps = {
  unimportedFavorites: [],
};

export default LocalFavoritesImporter;
