import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { withColumnContext, COLUMN_MAIN } from "components/molecules/PageGrid";
import {
  CustomPropTypes,
  propertyValueFor
} from "packages/inmeta-epi-react/utils/epi-react";

import BlockWrapper from "components/molecules/BlockWrapper";
import { getTeaserConfigFromViewType } from 'components/organisms/TeaserList';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

const TeaserList = React.lazy(() => import(/* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'));

import * as styles from "./TeaserListBlock.scss";

const TeaserListBlock = ({ block, teaserItems }) => {
  if (!props.teaserItems.length) {
    return null;
  }
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BlockWrapper block={props.block} columnContext={props.columnContext}>
        <TeaserList
          config={getTeaserConfigFromViewType(
            propertyValueFor(props.block.viewType)
          )}
          showDate={propertyValueFor(props.block.displayAsNews)}
          items={props.teaserItems}
          className={styles.root}
          showFavoriteButton={props.columnContext === COLUMN_MAIN}
        />
      </BlockWrapper>
    </Suspense>
  );
};
TeaserListBlock.displayName = "TeaserListBlock";
TeaserListBlock.propTypes = {
  block: PropTypes.shape({
    title: CustomPropTypes.EpiserverValue,
    viewType: CustomPropTypes.EpiserverValue,
    openInNewWindow: CustomPropTypes.EpiserverValue,
    readMoreLink: CustomPropTypes.EpiserverValue,
    readMoreText: CustomPropTypes.EpiserverValue
  }),
  teaserItems: PropTypes.array
};
TeaserListBlock.defaultProps = {
  block: {},
  teaserItems: []
};

export default withColumnContext(TeaserListBlock);
