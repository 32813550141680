import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { editorAttributesFor } from 'packages/inmeta-epi-react/utils/epi-react';

// tagName is swallowed here (because we don't want it rendered to the component)
const Base = React.forwardRef(
  (
    { as: Component = 'div', className, propertyName, tagName, ...rest },
    ref,
  ) => (
    <Component
      ref={ref}
      {...editorAttributesFor(propertyName, classnames(className))}
      {...rest}
    />
  ),
);
Base.displayName = 'Base';
Base.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
Base.defaultProps = {
  as: 'span',
};
Base.supportsPropertyForIntegration = true;

export default Base;
