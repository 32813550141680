import React from 'react';
import { connect } from 'redux-bundler-react';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';
import MobileHeader from 'components/organisms/MobileHeader';
import DesktopHeader from 'components/organisms/DesktopHeader';

export default connect(
  'selectSettingsRaw',
  'selectPageData',
  'selectBreakpoint',
  'selectUser',
  'selectUserIsLoggedIn',
  'selectMainMenuItems',
  'selectRegionMenu',
  'doLogOut',
  ({
    settingsRaw: {
      professionalsMenu,
      logoAndFaviconSettings,
      quickSearchText,
      searchPage,
      enableStickyNavigation,
    },
    pageData: { languageLinks, url },
    breakpoint,
    user,
    userIsLoggedIn,
    mainMenuItems,
    regionMenu,
    noSticky = false,
    doLogOut,
    mobileOnly,
  }) => {
  
    const HeaderComponent =
      !mobileOnly && breakpoint !== BREAKPOINT_PHONE
        ? DesktopHeader
        : MobileHeader;
    return (
      <HeaderComponent
        isSticky={!noSticky && enableStickyNavigation}
        menuItems={mainMenuItems}
        professionalsMenu={{
          label: professionalsMenu.professionalsMenuName,
          items: professionalsMenu.professionalsMenuLinks,
        }}
        languageLinks={languageLinks}
        quickSearchText={quickSearchText}
        searchPageLink={searchPage}
        logo={{
          link: logoAndFaviconSettings.logoLink,
          desktopImage: logoAndFaviconSettings.logoDesktop,
          mobileImage: logoAndFaviconSettings.logoMobile,
        }}
        secondaryLogo={{
          link: logoAndFaviconSettings.secondaryLogoLink,
          desktopImage: logoAndFaviconSettings.secondaryLogoDesktop,
          mobileImage: logoAndFaviconSettings.secondaryLogoMobile,
        }}
        userMenu={{
          logoutAction: doLogOut,
          url: user.myFavoritesPageUrl,
          isLoggedIn: userIsLoggedIn,
          user,
        }}
        regionMenu={regionMenu}
        key={url.href}
      />
    );
  },
);
