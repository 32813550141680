import React from "react";
import PageView from "components/organisms/PageView";
import DefaultLayoutContainerV2 from "containers/layouts/DefaultLayoutContainerV2";

const SectionPageV2 = page => {
  return (
    <DefaultLayoutContainerV2>
      <PageView
            useFullscreenSlider
            useStartPageLayout
            noBreadcrumbs
            isV2design
        {...page}
      />
    </DefaultLayoutContainerV2>
  );
};
SectionPageV2.displayName = "SectionPageV2";
SectionPageV2.propTypes = {};
SectionPageV2.defaultProps = {};

export default SectionPageV2;
