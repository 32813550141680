import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Image from 'components/atoms/Image';
import { H2 } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import Button from 'components/atoms/Button';

import { translate } from 'utils/translate';
import { nl2br } from 'utils/string';

import * as styles from './ProfileBio.scss';

class ProfileBio extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editMode: false,
      bio: this.props.bio,
    };
  }
  componentWillReceiveProps(newProps) {
    if (newProps.bio !== this.props.bio) {
      this.setState({
        bio: newProps.bio,
      });
    }
  }
  enableEditMode = () => {
    this.setState({ editMode: true });
  };
  handleChange = event => {
    this.setState({ bio: event.target.value });
  };
  handleSave = () => {
    this.setState({ editMode: false });
    this.props.onSave && this.props.onSave(this.state.bio);
  };
  render() {
    const { image, name, bio, canEdit } = this.props;
    return (
      <div
        className={classnames(styles.root, {
          [styles.noImage]: !image,
        })}
      >
        {!!image && (
          <Image
            src={image}
            ratio={[1, 1]}
            className={styles.image}
            fit="cover"
          />
        )}
        <H2 className={styles.name}>{name}</H2>
        {!this.state.editMode && (
          <div className={styles.bio}>
            <Paragraph>{nl2br(this.state.bio)}</Paragraph>
            {canEdit && (
              <Button
                transparent
                onClick={this.enableEditMode}
                className={styles.toggleEditButton}
              >
                {translate('/profileBio/editButton')}
              </Button>
            )}
          </div>
        )}
        {canEdit &&
          this.state.editMode && (
            <div className={styles.bioEditorWrapper}>
              <textarea
                className={styles.bioEditor}
                value={this.state.bio}
                onChange={this.handleChange}
              />
            </div>
          )}
        {canEdit &&
          this.state.editMode && (
            <div className={styles.bioEditorControls}>
              <Button
                rounded
                className={styles.bioSaveButton}
                onClick={this.handleSave}
              >
                {translate('/profileBio/saveButton')}
              </Button>
            </div>
          )}
      </div>
    );
  }
}
ProfileBio.displayName = 'ProfileBio';
ProfileBio.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string,
  canEdit: PropTypes.bool,
};
ProfileBio.defaultProps = {
  canEdit: false,
};

export default ProfileBio;
