import React, { useState, useCallback, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import { appendQueryParams } from 'utils/bundle-helper';
import { translate } from 'utils/translate';
import Heading, { H1 } from 'components/atoms/Heading';
import { plus } from 'components/atoms/Icon/icons';
import { LinkButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import * as styles from './ProductFilterSlider.scss';
import RangeSlider from './RangeSlider';
import MultiRangeSlider from './MultiRangeSlider';

const ProductFilterSlider = ({
  doUpdateUrl = () => {},
  name,
  url,
  filters,
  filterType,
  className,
  type,
  minLabel = '',
  maxLabel = '',
  filterGroupChar,
}) => {
  if (!filters || filters === undefined) {
    return null;
  }

  const isOrFilter = filterType.startsWith('Or');

  const allFilters = isOrFilter
    ? filters.map(f => ({ ...f, id: filterGroupChar + f.id }))
    : filters.map(f => ({ ...f, id: f.id.toString() }));

  let queryArray = (url.query[filterType] || '')
    .split(',')
    // split returns empty nodes for an empty string
    .filter(item => item !== '');

  const currentFilter = allFilters.filter(f => queryArray.includes(f.id));

  queryArray = queryArray.filter(
    item => !allFilters.map(f => f.id).includes(item),
  );

  const [val, setVal] = useState({ value: 0, label: '' });
  const [minVal, setMinVal] = useState({ value: 0, label: '' });
  const [maxVal, setMaxVal] = useState({ value: 0, label: '' });

  useLayoutEffect(() => {
    if (type === 'slider') {
      if (currentFilter.length > 0) {
        setVal({
          value: allFilters.findIndex(f => f.id === currentFilter[0].id),
          label: currentFilter[0].name,
        });
      } else
        setVal({
          value: 0,
          label: minLabel ? '' : allFilters[0].name,
        });
    }
    if (type === 'rangeSlider') {
      if (currentFilter.length > 0) {
        const indeces = currentFilter.map(f =>
          allFilters.findIndex(o => o.id === f.id),
        );
        setMinVal({ value: indeces[0], label: allFilters[indeces[0]].name });
        setMaxVal({
          value: indeces[indeces.length - 1],
          label: allFilters[indeces[indeces.length - 1]].name,
        });
      } else {
        setMinVal({ value: 0, label: allFilters[0].name });
        setMaxVal({
          value: allFilters.length - 1,
          label: allFilters[allFilters.length - 1].name,
        });
      }
    }
  }, [filters]);

  const setUrl = params => {
    const newUrl = appendQueryParams(
      url,
      {
        [filterType]: params.join(','),
      },
      true,
    );
    newUrl.noScrollReset = true;
    doUpdateUrl(newUrl);
  };

  const sliderValueChanged = (value, which) => {
    if (!which) {
      setVal({
        value,
        label: allFilters[value].name,
      });
      setUrl([...queryArray, allFilters[value].id]);
    } else if (which === 'min') {
      setMinVal({ value, label: allFilters[value].name });

      if (value === 0 && maxVal.value === allFilters.length - 1) {
        setUrl([...queryArray]);
        return;
      }

      const filtersForUrl = allFilters
        .slice(value, maxVal.value + 1)
        .map(f => f.id);

      setUrl([...queryArray, ...filtersForUrl]);
    } else if (which === 'max') {
      setMaxVal({ value, label: allFilters[value].name });

      if (value === allFilters.length - 1 && minVal.value === 0) {
        setUrl([...queryArray]);
        return;
      }

      const filtersForUrl = allFilters
        .slice(minVal.value, value + 1)
        .map(f => f.id);

      setUrl([...queryArray, ...filtersForUrl]);
    }
  };

  const sliderProps = {
    min: 0,
    max: filters.length - 1,
    value: val.value,
    label: val.label,
    step: 1,
    onChange: e => sliderValueChanged(e),
    minLabel,
    maxLabel,
  };

  const multiSliderProps = {
    minLabel: minVal.label,
    minValue: minVal.value,
    max: allFilters.length - 1,
    maxLabel: maxVal.label,
    maxValue: maxVal.value,
    onChange: sliderValueChanged,
  };

  const reset = () => {
    setVal({ value: 0, label: '' });
    setUrl(queryArray);
  };

  return (
    <section className={classnames(styles.root, className)}>
      <H1 size={Heading.sizes.heading4} className={styles.heading}>
        {name}
      </H1>
      {type === 'slider' && (
        <RangeSlider {...sliderProps}>
          {!!currentFilter.length && (
            <LinkButton onClick={reset} className={styles.resetButton}>
              <Icon icon={plus} />
              {translate('/pages/productListPage/reset')}
            </LinkButton>
          )}
        </RangeSlider>
      )}
      {type === 'rangeSlider' && <MultiRangeSlider {...multiSliderProps} />}
    </section>
  );
};
ProductFilterSlider.displayName = 'ProductFilterSlider';
ProductFilterSlider.propTypes = {};
ProductFilterSlider.defaultProps = {};

export default connect('doUpdateUrl', ProductFilterSlider);
