import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './VisuallyHidden.scss';

const VisuallyHidden = ({ children, as: Component = 'span' }) => (
  <Component className={styles.root}>{children}</Component>
);
VisuallyHidden.displayName = 'VisuallyHidden';
VisuallyHidden.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
};
VisuallyHidden.defaultProps = {};

export default VisuallyHidden;
