import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withColumnContext } from 'components/molecules/PageGrid';
import {
  propertyValueFor,
  fixReplacedScriptsInString,
  CustomPropTypes,
} from 'packages/inmeta-epi-react/utils/epi-react';
import { isBrowser } from 'packages/inmeta-epi-react/utils/device';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import useScriptInject from 'hooks/use-script-inject';
import * as styles from './WidgetBlock.scss';

const LoadableScriptSandbox = lazy(() =>
  import('components/utilities/ScriptSandbox'),
);

const facebookSdk = ({ facebookLanguage }) => `
  <script>
    ((d, s, id) => {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      var object = "//connect.facebook.net/${facebookLanguage}/all.js#xfbml=1";
      js.src = object;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  </script>
`;

const twitterSdk = () => `
  <script>
    function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0], p = /^http:/.test(d.location) ? 'http' : 'https';
      if (!d.getElementById(id)) {
          js = d.createElement(s);
          js.id = id;
          js.src = p + '://platform.twitter.com/widgets.js';
          fjs.parentNode.insertBefore(js, fjs);
      }
    }(document, 'script', 'twitter-wjs');
  </script>
`;

const getSDK = script => {
  if (script.includes('twitter')) {
    return twitterSdk();
  }
  if (script.includes('facebook')) {
    return facebookSdk({
      facebookLanguage: 'en_US',
    });
  }
  return '';
};

const widgetStyles = `
  <style>
    .instagram-media {
      min-width: unset !important;
    }
  </style>
`;

const WidgetBlock = ({ block }) => {
  // console.log("WidgetBlock", props);
  if (!isBrowser) {
    return null;
  }
  const script = fixReplacedScriptsInString(propertyValueFor(block.script));

  if (!propertyValueFor(block.disableIframe))
    return (
      <section className={styles.centerContent}>
        {global.localStorage &&
        global.localStorage.getItem('block.widget.disabled') === 'true' ? (
          '[WidgetBlock]'
        ) : (
          <Suspense fallback={<LoadingIndicator />}>
            <LoadableScriptSandbox
              content={`${getSDK(script)}${widgetStyles}${script}`}
            />
          </Suspense>
        )}
      </section>
    );

  useScriptInject(script);

  return null;
};
WidgetBlock.displayName = 'WidgetBlock';
WidgetBlock.propTypes = {
  block: PropTypes.shape({
    script: CustomPropTypes.EpiserverValue,
    disableIframe: CustomPropTypes.EpiserverValue,
  }),
};
WidgetBlock.defaultProps = {};

export default withColumnContext(WidgetBlock);
