import React from 'react';
import PropTypes from 'prop-types';
import { withColumnContext } from 'components/molecules/PageGrid';
import BlockWrapper from 'components/molecules/BlockWrapper';
import ScriptSandbox from 'components/utilities/ScriptSandbox';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import * as styles from './FlickrBlock.scss';

const sandboxStyle = `
  <style>
    .flickr-gallery {
      border-bottom: 1px solid #c7c7c7;
    }
    #flickr_badge_wrapper {
      width: 100%;
      text-align: left;
    }
    .flickr_badge_image {
      margin: 0px;
      display: inline;
    }
    .flickr_badge_image a {
      pointer-events: none
    }
    .flickr_badge_image img {
      margin: 7px;
    }
  </style>
`;

const FlickrBlock = props => {
  // console.log('FlickrBlock', props);
  const { convertedFlickrLink, block } = props;
  const readMoreLink = propertyValueFor(block.readMoreLink);
  return (
    <BlockWrapper
      block={{
        ...block,
        readMoreLinkTarget: '_blank',
      }}
      className={styles.flickrGallery}
    >
      <ScriptSandbox
        content={`${sandboxStyle}<script type="text/javascript" src=${convertedFlickrLink} width="0" height="0" alt=""></script><a href="${readMoreLink}" target="_blank" style="width: 100%;position:absolute;height: 100%;margin-bottom: -3em;left: 0;color:transparent;">${
          block.title
        }</a>`}
      />
      {/** DEVELOPER WARNING: The Flickr badge code is likely to be deprecated, and we recommend changing to a new method to embed photo as soon as possible.  See https://www.flickr.com/api for information on how to access Flickr data. Thanks. */}
    </BlockWrapper>
  );
};
FlickrBlock.displayName = 'FlickrBlock';
FlickrBlock.propTypes = {
  convertedFlickrLink: PropTypes.string,
  block: PropTypes.object,
};
FlickrBlock.defaultProps = {};

export default withColumnContext(FlickrBlock);
