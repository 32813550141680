import React from 'react';
import PropTypes from 'prop-types';

// based on https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f from @kitze

const ConditionalWrap = ({ condition = null, wrap, children }) =>
  condition === null || !!condition ? wrap(children) : children;
ConditionalWrap.displayName = 'ConditionalWrap';
ConditionalWrap.propTypes = {
  condition: PropTypes.bool,
  wrap: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};
ConditionalWrap.defaultProps = {};

export default ConditionalWrap;
