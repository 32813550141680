import React from 'react';
import Heading from 'components/atoms/Heading';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import { COLUMN_ASIDE, COLUMN_FOOTER } from 'components/molecules/PageGrid';
import * as styles from './BlockWrapper.scss';

const styleMap = {
  [COLUMN_ASIDE]: styles.asideTitle,
  [COLUMN_FOOTER]: styles.footerTitle,
};

const BlockWrapperTitle = ({ children, columnContext, style }) => {
  const titleStyle = style && style.color ? { color: style.color } : {};
  return (
    <PropertyFor
      property={children}
      component={Heading}
      componentProps={{
        level: columnContext === COLUMN_ASIDE ? 3 : 2,
        alt: true,
        bold: false,
        size: Heading.sizes.heading4,
        className: styleMap[columnContext] || styles.title,
        style: titleStyle,
      }}
    />
  );
};

export default BlockWrapperTitle;
