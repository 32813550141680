
export default function useWebchat(culture, siteSkin) {

  var exists = document.getElementById("magic-widget-launcher-parent");
  if (exists) return;

  var scriptElement = document.createElement("script");
  scriptElement.async = 1;
  scriptElement.src = "https://magicwidget.socialboards.com/magic-widget.js";
  document.body.appendChild(scriptElement);

  if (siteSkin !== 'VisitOslo') return;

   if(culture == 'no') {
    var o = {
      community: 'visitoslo',
      moduleMask: 6,
      initModule: 0,	
      widgetId: 2057,
      filters: [],
      intentId: null,
      messenger: {
          right: '60px',
          bottom: '120px',
          animationDirection: 'none',
          translate: '60px',
          home: {
              logoDimensions: { width: '140px' },
              chatLink: {
                 order: 1,
       startChatText: 'Start ny chat',
                 continueChatText: 'Fortsett chat'
              },
              faqLink: {
                 order: 0
              },
              formLink: {
                 order: 2
              }
          },
          faq: {
             areStickyTopicsFirst: false,
             faqCategoryId: null,
             faqName: 'visitoslo',
             tags: ''
          },
          form: {
             formName: 'norsk'
          },
          chat: {
             authenticationMask: 2,
             queueMessageBackgroundColor: '#ffffff',
             queueMessageFontSizeRem: '1.1rem',
             agentMessageBackgroundColor: '#F9C59E',
             botMessageBackgroundColor: '#F9C59E'
          }
      },
      launcher: {
          enabled: true,
          height: '60px',
          width: '60px',
          right: '60px',
          bottom: '60px',
  launchIcon: 'https://www.visitoslo.com/globalassets/bilder-oslo/visitoslo/chat-open.png',
  dismissIcon: 'https://www.visitoslo.com/globalassets/bilder-oslo/visitoslo/chat-close.png'
      }
  }
    // culture != 'no', default to english
  } else {
    var o = {
      community: 'visitoslo',
      moduleMask: 6,
      initModule: 0,	
      widgetId: 2060,
      filters: [],
      intentId: null,
      language: 'en',
      messenger: {
          right: '60px',
          bottom: '120px',
          animationDirection: 'none',
          translate: '60px',
          language: 'en',
          home: {
              logoDimensions: { width: '140px' },
              chatLink: {
                 order: 1,
                 startChatText: 'Chat with us',
                 continueChatText: 'Continue chat'
              },
              faqLink: {
                 order: 0,
                 text: 'Search our FAQs'
              },
              formLink: {
                 order: 2,
                 text: 'Send us an email'
              }
          },
          faq: {
             areStickyTopicsFirst: false,
             faqCategoryId: null,
             faqName: 'visitoslo',
             tags: ''
          },
          form: {
             formName: 'visitosloengelsk'
          },            chat: {
             authenticationMask: 2,
             queueMessageBackgroundColor: '#ffffff',
             queueMessageFontSizeRem: '1.1rem',
             agentMessageBackgroundColor: '#F9C59E',
             botMessageBackgroundColor: '#F9C59E'
          }
      },
      launcher: {
          enabled: true,
          height: '60px',
          width: '60px',
          right: '60px',
          bottom: '60px',
  launchIcon: 'https://www.visitoslo.com/globalassets/bilder-oslo/visitoslo/chat-open.png',
  dismissIcon: 'https://www.visitoslo.com/globalassets/bilder-oslo/visitoslo/chat-close.png'
      }
  }
  }
    window.magicWidgetAsyncInit = function() {
      magicWidget.initializeAsync(o)
      .then(function() {
          // initialization was successfull!
          console.log('Webchat initialized');
      })
      .catch(function(error) {
          // an error occured while initializing.
          console.log('Webchat failed to initialize', error);
      });
    }
}
