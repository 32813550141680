import React from 'react';
import { connect } from 'redux-bundler-react';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';

const filterTypes = {
  AREA: 'AreaIds',
  FACILITY: 'FacilityIds',
};

const PageFiltersContainer = ({ pageData, children }) => {
  const productFilters = (pageData.filters || []).filter(filter => {
    if (
      (propertyValueFor(pageData.currentPage.hideAreaFilter) &&
        filter.filterType === filterTypes.AREA) ||
      (propertyValueFor(pageData.currentPage.hideFacilityFilter) &&
        filter.filterType === filterTypes.FACILITY)
    ) {
      if (
        filter.filterType === filterTypes.FACILITY &&
        filter.pageId === pageData.currentPageId
      )
        return true;
      return false;
    }
    return true;
  });
  let showFilters = false;
  if (productFilters.length > 0) showFilters = true;
  if (!propertyValueFor(pageData.currentPage.hideCategorySelector))
    showFilters = true;
  if (!propertyValueFor(pageData.currentPage.hideLocationButton))
    showFilters = true;
  return children(
    showFilters,
    !propertyValueFor(pageData.currentPage.hideLocationButton),
    productFilters,
    propertyValueFor(pageData.currentPage.hideCategorySelector)
      ? []
      : pageData.categories,
    propertyValueFor(pageData.currentPage.showCapacityFilter),
  );
};

export default connect('selectPageData', PageFiltersContainer);
