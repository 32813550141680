import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from 'components/layouts/DefaultLayout';
import HeaderContainer from 'containers/organisms/HeaderContainer';
import FooterContainer from 'containers/organisms/FooterContainer';
import HelmetContainer from 'containers/HelmetContainer';
import GTMPageViewTrackerContainer from 'containers/GTMPageViewTrackerContainer';
import NotificationsToastContainer from 'containers/molecules/NotificationsToastContainer';
import useSiteSettings from 'hooks/use-site-settings';
import useWebchat from 'hooks/use-webchat';
import ErrorBoundary from 'packages/inmeta-epi-react/components/ErrorBoundary';

const DefaultLayoutContainer = ({ noSticky = false, children }) => {
  const { culture, siteSkin } = useSiteSettings();
  useWebchat(culture, siteSkin);
  return (
    <DefaultLayout
      header={<HeaderContainer noSticky={noSticky} />}
      footer={<FooterContainer />}
    >
      <HelmetContainer />
      <GTMPageViewTrackerContainer />
      <ErrorBoundary>{children}</ErrorBoundary>
      <NotificationsToastContainer max={3} />
    </DefaultLayout>
  );
};

export default DefaultLayoutContainer;
