import React from 'react';
import PropTypes from 'prop-types';
import { IS_BROWSER } from 'utils/environment';
let iframeResizerLib = () => {};
if (IS_BROWSER) {
  iframeResizerLib = require('iframe-resizer/js/iframeResizer');
}

class Iframe extends React.PureComponent {
  componentDidMount() {
    const { heightCalculationMethod } = this.props;
    this.resizeIframe(heightCalculationMethod);
  }

  componentWillUnmount() {
    if (this.frame && this.frame.iFrameResizer) {
      this.frame.iFrameResizer.close();
    }
  }

  resizeIframe = heightCalculationMethod => {
    const { frame } = this;
    if (!frame || !heightCalculationMethod) return;
    iframeResizerLib(
      {
        // log: true,
        heightCalculationMethod,
        checkOrigin: false,
        bodyMargin: 0,
      },
      frame,
    );
  };

  setRef = ref => {
    this.frame = ref;
  };

  render() {
    const {
      src,
      className,
      scrolling,
      frameBorder,
      autoresize,
      ...rest
    } = this.props;
    const props = {
      ...rest,
      src,
      frameBorder,
      className,
      scrolling: scrolling ? 1 : 0,
      ref: this.setRef,
    };
    return <iframe {...props} />;
  }
}
Iframe.displayName = 'Iframe';
Iframe.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  scrolling: PropTypes.bool,
  frameBorder: PropTypes.number,
  autoresize: PropTypes.bool,
};
Iframe.defaultProps = {
  className: null,
  scrolling: true,
  frameBorder: 0,
  autoresize: false,
};

export default Iframe;
