import {
  composeBundlesRaw,
  createCacheBundle,
  appTimeBundle,
  createReactorBundle,
  debugBundle,
} from 'redux-bundler';
import cache from '../utils/bundle-cache';

// import apiArgs from "./api-args-mock"; // we mock for prototype
import apiArgs from './api-args';
import user from './user';
import breakpoint from './breakpoint';
import notifications from './notifications';
import mainMenu from './mainMenu';
import settings from './settings';
import createUrlBundle from './create-url-bundle';
import createPageBundle from './create-page-bundle';
import pageProductsBundle from './page-products';

// bundle arrays are returned by functions to not load/initialize bundles prematurely
// common bundles
const getCommonBundles = () => [
  appTimeBundle,
  // onlineBundle,
  createReactorBundle(),
  debugBundle,
  createPageBundle(),
  pageProductsBundle,
  user,
  mainMenu,
  settings,
  breakpoint,
  notifications,
  apiArgs,
];
// server bundles
const getServerBundles = () => [
  ...getCommonBundles(),
  createUrlBundle({ inert: true }),
];
// client bundles
const getClientBundles = () => [
  ...getCommonBundles(),
  createUrlBundle({ inert: false }),
  createCacheBundle(cache.set),
];

export const getServerStore = state =>
  composeBundlesRaw(...getServerBundles())(state);

export const getClientStore = state =>
  composeBundlesRaw(...getClientBundles())(state);

export default getClientStore;
