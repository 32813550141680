import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  CustomPropTypes,
  propertyValueFor,
} from 'packages/inmeta-epi-react/utils/epi-react';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import MainElementDetector from 'components/utilities/MainElementDetector';
import { H2 } from 'components/atoms/Heading';
import * as styles from './StoryChapterBlock.scss';

const StoryChapterBlock = ({ block, className }) => {
  const { title, content } = block;

  return (
    <MainElementDetector
      data={{
        title: propertyValueFor(title),
      }}
      render={({ isCurrent }) => (
        <section
          className={classnames(className)}
        >
          <H2
            className={styles.title}
          >
            {propertyValueFor(title)}
          </H2>
          <PropertyFor
            property={content}
            className={classnames(styles.chapterContent)}
          />
        </section>
      )}
    />
  );
};

StoryChapterBlock.displayName = 'StoryChapterBlock';
StoryChapterBlock.propTypes = {
  block: PropTypes.shape({
    content: CustomPropTypes.EpiserverValue,
    title: CustomPropTypes.EpiserverValue,
  }),
  className: PropTypes.string,
};
StoryChapterBlock.defaultProps = {};

export default StoryChapterBlock;
