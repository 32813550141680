import React from 'react';
import {useTransition, animated, config as springConfig } from 'react-spring';

const TransitionElement = ({children, from, enter, leave, delay = 0, config = springConfig.stiff, className}) => {
  const transitions = useTransition(children, item => item, {
    from,
    enter,
    leave,
    delay,
    config,
  });
  return (
    <React.Fragment>
      {transitions.map(({ key, item, props }) => (
        item && <animated.div style={props} key={key} className={className}>{item}</animated.div>
      ))}
    </React.Fragment>
  );
};

export default TransitionElement;
