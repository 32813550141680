import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import { ratioPropType } from 'components/atoms/Block/Block';
import Image from 'components/atoms/Image';
import Flag from 'components/atoms/Flag';
import TeaserLink from 'components/molecules/TeaserLink';
import { Paragraph } from 'components/atoms/Text';
import shorten from 'utils/string/shorten';
import { BREAKPOINT_PHONE } from 'utils/breakpoint';
import { COLUMN_MAIN, useColumnContext } from 'components/molecules/PageGrid';
import BreakpointContainer from 'containers/BreakpointContainer';
import Icon from 'components/atoms/Icon';
import useThemeConfig from 'hooks/use-theme-config';
import Title from './Title';
import * as styles from './Teaser.scss';
import { arrowRightLong } from 'components/atoms/Icon/icons';

export const MODE_TEASER = 'MODE_TEASER';
export const MODE_FLAG = 'MODE_FLAG';
export const MODE_LINK = 'MODE_LINK';

const INTRO_LONG = 130;
const INTRO_SHORT = 90;
const INTRO_TINY = 65;
const INTRO_TINY_BOUNDARY = 55;
const INTRO_SHORT_BOUNDARY = 30;

export const trimIntro = (intro, title) =>
  shorten(
    intro,
    title.length > INTRO_TINY_BOUNDARY
      ? INTRO_TINY
      : title.length > INTRO_SHORT_BOUNDARY
      ? INTRO_SHORT
      : INTRO_LONG,
  );

export const Teaser = ({
  href,
  mode,
  description,
  intro,
  className,
  target,
  image,
  alt,
  children,
  renderAfterLink,
  columnCount = 2,
  megaMenuTeaser = false,
  color,
  invert = false,
}) => {
  if (mode === MODE_LINK) {
    return (
      <TeaserLink href={href} target={target}>
        {children}
      </TeaserLink>
    );
  }
  const columnContext = useColumnContext();
  const isMain = columnContext === COLUMN_MAIN;
  const { teaserIcon } = useThemeConfig();

  return (
    <BreakpointContainer
      render={breakpoint => {
        const isMobile = breakpoint === BREAKPOINT_PHONE;
        if (mode === MODE_FLAG && breakpoint > BREAKPOINT_PHONE) {
          return (
            <article
              className={classnames(
                styles.teaser,
                styles.flagTeaser,
                className,
                {
                  [styles.alt]: alt,
                  [styles.inverted]: invert,
                },
              )}
            >
              <Flag
                description={description}
                align="top"
                image={
                  <Link
                    href={href}
                    className={styles.link}
                    description={description}
                    target={target}
                  >
                    <Image
                      src={image.src}
                      alt={image.alt}
                      title={image.title}
                      copy={image.imageFileCopyright}
                      className={styles.image}
                      ratio={image.ratio || [2, 1]}
                      fit="cover"
                      cropDetails={image.cropDetails}
                      lazy={{ offset: 500, resize: true }}
                    />
                  </Link>
                }
              >
                <Link href={href} description={description} target={target}>
                  {children}
                  {!!teaserIcon && (
                    <Icon icon={teaserIcon} className={styles.teaserIcon} />
                  )}
                </Link>
                {renderAfterLink}
              </Flag>
            </article>
          );
        }

        return (
          <article
            className={classnames(styles.teaser, className, {
              [styles.alt]: alt,
              [styles.c2]: !isMobile && isMain && columnCount === 2,
              [styles.c3]: !isMobile && isMain && columnCount === 3,
              [styles.coloredContainerWrapper]: !!color && !!image,
              [styles.inverted]: invert,
            })}
          >
            <Link
              href={href}
              className={styles.link}
              description={description}
              target={target}
            >
              {!!image && (
                <Image
                  src={image.src}
                  alt={image.alt}
                  title={image.title}
                  className={classnames(styles.image, {
                    [styles.featuredImage]: !!megaMenuTeaser,
                  })}
                  ratio={image.ratio || [2, 1]}
                  fit={image.fit || 'cover'}
                  cropDetails={image.cropDetails}
                />
              )}
              <div
                className={styles.container}
                style={{ backgroundColor: !!image && color }}
              >
                {children}
                {intro}
                {!!teaserIcon && !color && (
                  <Icon icon={teaserIcon} className={styles.teaserIcon} />
                )}
                {color && (
                  <Icon className={styles.arrowIcon} icon={arrowRightLong} />
                )}
              </div>
            </Link>
            {!!renderAfterLink && (
              <div className={styles.afterLink}>{renderAfterLink}</div>
            )}
          </article>
        );
      }}
    />
  );
};
Teaser.displayName = 'Teaser';
Teaser.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    title: PropTypes.string,
    fit: PropTypes.string,
    copy: PropTypes.string,
    ratio: ratioPropType,
  }),
  description: PropTypes.string,
  intro: PropTypes.string,
  mode: PropTypes.string,
  alt: PropTypes.bool,
  columnCount: PropTypes.number,
  color: PropTypes.string,
  invert: PropTypes.bool,
};
Teaser.defaultProps = {
  mode: MODE_TEASER,
};

Teaser.Title = ({ children, truncate, gutterBottom, className }) => (
  <Paragraph
    className={classnames({
      [styles.introTitle]: !className,
      [styles.featuredTitleText]: className,
    })}
    gutterBottom={gutterBottom}
  >
    {truncate && children ? trimIntro(children, truncate) : children}
  </Paragraph>
);
Teaser.SmallText = ({ className, ...rest }) => (
  <Paragraph
    className={classnames(className, styles.smallText)}
    size={Paragraph.sizes.small}
    {...rest}
  />
);
Teaser.Intro = ({ children, truncate, gutterBottom }) => (
  <Paragraph className={styles.intro} gutterBottom={gutterBottom}>
    {truncate && children ? trimIntro(children, truncate) : children}
  </Paragraph>
);
Teaser.Readmore = ({ children }) => (
  <span className={classnames(styles.link, styles.readmore)}>{children}</span>
);

export default Teaser;
