import { useCallback, useState } from 'react';
import * as cookieUtils from 'utils/cookie';

export const useCookie = (key, defaultValue, path) => {
    const [cookieValue, setCookieValue] = useState(cookieUtils.getCookie(key, defaultValue));
    const setCookie = useCallback((value, numberOfDays) => {
        setCookieValue(value);
        cookieUtils.setCookie(key, value, numberOfDays, path);
    }, [key, path]);
    const deleteCookie = () => {
        setCookieValue(defaultValue);
        cookieUtils.deleteCookie(key, path);
    }
    return [cookieValue, setCookie, deleteCookie];
};