import { useReducer, useMemo, useCallback } from 'react';
import { useIsMounted } from './use-is-mounted';

const stateReducer = (
  state = { isLoading: false, error: null, data: null },
  action,
) => {
  switch (action.type) {
    case 'REQUEST':
      return { isLoading: true, error: null, data: null };
    case 'ERROR':
      return { isLoading: false, error: action.error, data: null };
    case 'SUCCESS':
      return { isLoading: false, error: null, data: action.data };
    default:
      return state;
  }
};

export const useFetch = request => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer(stateReducer);
  const fetch = useCallback(
    params => {
      dispatch({ type: 'REQUEST' });
      request(params)
        .then(data => {
          // console.log(data, isMounted.current);
          if (isMounted.current) {
            if (data.error || data.message) {
              dispatch({
                type: 'ERROR',
                error: data.error || data.message || 'An error occured',
              });
            } else {
              dispatch({
                type: 'SUCCESS',
                data,
              });
            }
          } else {
            dispatch({
              type: 'ERROR',
              error: 'Unmounted before fetch returned',
            });
          }
        })
        .catch(error => {
          if (isMounted.current) {
            dispatch({ type: 'ERROR', error });
          }
        });
    },
    [request],
  );
  return useMemo(
    () => ({
      fetch,
      ...state,
    }),
    [state, fetch],
  );
};
