import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { connect } from 'redux-bundler-react';
import { Hero } from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { chevronLeft } from 'components/atoms/Icon/icons';
import { useSpring, animated } from 'react-spring';
import isInEditMode from 'packages/inmeta-epi-react/utils/is-in-edit-mode';
import { translate } from 'utils/translate';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import Image from 'components/atoms/Image';
import * as styles from './HeroHeader.scss';

const HeroHeader = ({ title, image, hide, hasEffects, pageData }) => {
  const heroheader = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  useLayoutEffect(() => {
    function resizeHandler() {
      if (heroheader.current && heroheader.current.previousSibling)
        setHeaderHeight(
          (window.visualViewport
            ? window.visualViewport.height
            : window.innerHeight) -
            heroheader.current.previousSibling.offsetHeight,
        );
    }
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    if (window.visualViewport)
      window.visualViewport.addEventListener('resize', resizeHandler);
  }, []);

  const wrapperTransition = useSpring({
    to: hide
      ? [
          { opacity: 0 },
          {
            height: '0.1vh',
            position: 'absolute',
            width: '1%',
          },
        ]
      : [
          {
            height: `100vh`,
            position: 'fixed',
            width: '100%',
          },
          { opacity: 1 },
        ],
    config: key => key !== 'height' && { duration: 500 },
    immediate: key => key !== 'opacity',
  });

  const contentTransition = !hasEffects
    ? {
        height: '250px',
      }
    : useSpring({
        to: hide
          ? [{ transform: 'translate(0, 0px)', opacity: 0 }, { height: '1px' }]
          : [
              { height: '250px' },
              { transform: 'translate(0, 25px)', opacity: 1 },
            ],
        config: key => key !== 'height' && { duration: 400 },
        immediate: key => !hide && (key === 'height' || key === 'transform'),
      });
  return (
    <animated.div
      ref={heroheader}
      style={
        isInEditMode() || !hasEffects
          ? {
              opacity: 1,
              height: isInEditMode() ? '600px' : `${headerHeight}px`,
              top: '0px',
            }
          : wrapperTransition
      }
      className={classnames(styles.wrapper, {
        [styles.wrapperEffects]: hasEffects && !isInEditMode(),
      })}
    >
      <div
        className={styles.heroimage}
        style={{ backgroundImage: `url(${image.src})` }}
      >
        <Image
          {...image}
          copyClassName={classnames(styles.copyButton, {
            [styles.copyButtonNoEffects]: !hasEffects,
          })}
          isInvisible
          fit="none"
          src={`${image.src}?preset=ProductSliderThumbnails`}
        />
      </div>
      <animated.div
        className={styles.header}
        style={isInEditMode() ? null : contentTransition}
      >
        <Button
          className={styles.iconBack}
          transparent
          title={translate('/menu/goBack')}
          href={pageData.breadCrumbs[pageData.breadCrumbs.length >= 2 ? pageData.breadCrumbs.length - 2 : 0].url}
        >
          <Icon icon={chevronLeft} />
        </Button>
        <div className={styles.titleWrapper}>
          <PropertyFor
            property={title}
            component={Hero}
            componentProps={{ as: 'h1', className: styles.heroTitle }}
          />
        </div>
        <div className={styles.dummyBtn}></div>
      </animated.div>
    </animated.div>
  );
};

HeroHeader.displayName = 'HeroHeader';
HeroHeader.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.object.isRequired,
  hide: PropTypes.bool,
  hasEffects: PropTypes.bool.isRequired,
};
HeroHeader.defaultProps = {
  hide: false,
};

export default connect('selectPageData', HeroHeader);
