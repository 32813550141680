import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button, {IconButton} from 'components/atoms/Button';

import MenuToggler from 'components/utilities/MenuToggler';
import Navigation from 'components/molecules/Navigation';
import SearchFormContainer from 'containers/molecules/SearchFormContainer';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import {
  cross,
  hamburger,
  heartFilled,
  exploretheregion,
  macpro,
  arrowDown,
  chevronLeft
} from 'components/atoms/Icon/icons';
import NoBodyScroll from 'components/utilities/NoBodyScroll';
import TransitionElement from 'components/utilities/TransitionElement';
import RegionMenu2, { VARIANT_MOBILE } from 'components/organisms/RegionMenu2';
import { translate } from 'utils/translate';

import * as styles from './MobileHeader.scss';

const HeaderPropTypes = {
  logo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  secondaryLogo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  professionalsMenu: PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.array,
  }),
  languageLinks: PropTypes.array,
  quickSearchText: PropTypes.string,
  userMenu: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
    url: PropTypes.string,
  }),
  menuItems: PropTypes.array,
  regionMenu: PropTypes.object,
};

const MobileHeader = ({
  logo,
  secondaryLogo,
  professionalsMenu,
  languageLinks,
  quickSearchText,
  searchPageLink,
  userMenu,
  menuItems,
  regionMenu,
}) => (
  <div className={styles.root}>
    <header className={styles.header}>
      {!!logo.link && !!logo.mobileImage && (
        <a href={logo.link}>
          <img src={logo.mobileImage} className={styles.logo} alt="" />
        </a>
      )}
      {!!secondaryLogo.link && !!secondaryLogo.mobileImage && (
        <a href={secondaryLogo.link}>
          <img
            src={secondaryLogo.mobileImage}
            className={styles.subLogo}
            alt=""
          />
        </a>
      )}
    </header>
    {!!languageLinks && (
      <MenuToggler
        render={({ clickOpenerProps, isOpen }) => (
          <nav>
            <Button
              transparent
              className={classnames(styles.languageMenuButton, {
                [styles.isOpen]: isOpen,
              })}
              {...clickOpenerProps}
            >
              {languageLinks.reduce(
                (current, lang) =>
                  lang.isCurrentLanguage ? lang.name : current,
                null,
              )}
              <Icon className={styles.languageButtonIcon} icon={arrowDown} />
            </Button>
            {isOpen && (
              <ul className={styles.languageList}>
                {languageLinks.map(lang => (
                  <li key={lang.url} className={styles.item}>
                    <Button
                      transparent
                      href={lang.url}
                      className={classnames(styles.button, {
                        [styles.languageBarCurrent]: lang.isCurrentLanguage,
                      })}
                    >
                      {lang.name}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </nav>
        )}
      />
    )}
    {/* menu */}
    <MenuToggler
      render={({ clickOpenerProps, isOpen }) => (
        <nav>
          {isOpen ? <NoBodyScroll /> : null}
          <Button
            transparent
            className={styles.mainMenuButton}
            {...clickOpenerProps}
          >
            <Icon
              icon={isOpen ? cross : hamburger}
              className={styles.menuButton}
            />
          </Button>
          <TransitionElement from={{opacity: 0, transform: 'translateY(-10px)',}} enter={{opacity: 1, transform: 'translateY(0px)',}} leave={{opacity: 0, transform: 'translateY(-10px)',}} className={styles.mainMenu}>
            {isOpen && (
              <div className={styles.mainMenuInner}>
                <Navigation direction="vertical" className={styles.navigation}>
                  {menuItems.map(item => {
                    const items = item.children
                      ? item.children.filter(i => !!i.url)
                      : [];
                    return (
                      <Navigation.Item
                        key={item.url || item.name}
                        label={item.name}
                        url={item.url}
                        isCurrent={item.isCurrent}
                        wrapClassName={styles.wrap}
                        iconClassName={styles.icon}
                        linkClassName={styles.link}
                        menuLevel={1}
                        renderChildren={
                          !!items.length &&
                          (({ doClose, isOpen }) => 
                            <TransitionElement from={{opacity: 0, transform: 'translateX(-20px)',}} enter={{opacity: 1, transform: 'translateX(0px)',}} leave={{opacity: 0, transform: 'translateX(-20px)',}} className={styles.subMenu}>
                              {!!isOpen && (
                                <Navigation
                                  direction="vertical"
                                  className={styles.subMenuInner}
                                >
                                  <IconButton transparent full textAlign="left" iconBefore={chevronLeft} className={styles.backButton} onClick={() => doClose()}>
                                    {item.name}
                                  </IconButton>
                                  {items.map(subItem => {
                                    const subItems = subItem.children
                                      ? subItem.children.filter(i => !!i.url)
                                      : [];
                                    return (
                                      <Navigation.Item
                                        key={subItem.url || subItem.name}
                                        label={subItem.name}
                                        url={subItem.url}
                                        isCurrent={subItem.isCurrent}
                                        wrapClassName={styles.wrap}
                                        linkClassName={styles.link}
                                        iconClassName={styles.icon}
                                        menuLevel={2}
                                        renderChildren={
                                          !!subItems.length &&
                                          (({ doClose, isOpen }) =>
                                            <TransitionElement from={{opacity: 0, transform: 'translateX(-20px)',}} enter={{opacity: 1, transform: 'translateX(0px)',}} leave={{opacity: 0, transform: 'translateX(-20px)',}} className={styles.subMenu}>
                                              {!!isOpen && (
                                                <Navigation
                                                  direction="vertical"
                                                  className={styles.subMenuInner}
                                                >
                                                 <IconButton transparent full textAlign="left" iconBefore={chevronLeft} className={styles.backButton} onClick={() => doClose()}>
                                                    {subItem.name}
                                                  </IconButton>
                                                  {subItems.map(subSubItem => (
                                                    <Navigation.Item
                                                      key={
                                                        subSubItem.url
                                                      }
                                                      label={subSubItem.name}
                                                      url={subSubItem.url}
                                                      isCurrent={subSubItem.isCurrent}
                                                      wrapClassName={styles.wrap}
                                                      linkClassName={styles.link}
                                                      iconClassName={styles.icon}
                                                      menuLevel={3}
                                                    />
                                                  ))}
                                                </Navigation>
                                              )}
                                            </TransitionElement>
                                          )
                                        }
                                      />
                                    );
                                  })}
                                </Navigation>
                              )}
                            </TransitionElement>
                          )
                        }
                      />
                    );
                  })}
                  {!!professionalsMenu && !!professionalsMenu.label && (
                    <Navigation.Item
                      className={styles.item2}
                      wrapClassName={styles.wrap}
                      linkClassName={styles.link2}
                      label={professionalsMenu.label}
                      leftIcon={macpro}
                      leftIconProps={{
                        className: styles.menuItemIcon,
                      }}
                      menuLevel={1}
                      renderChildren={
                        !!professionalsMenu.items &&
                        (({ doClose, isOpen }) => 
                            <TransitionElement from={{opacity: 0, transform: 'translateX(-20px)',}} enter={{opacity: 1, transform: 'translateX(0px)',}} leave={{opacity: 0, transform: 'translateX(-20px)',}} className={styles.subMenu}>
                              {!!isOpen && (
                                <Navigation
                                  direction="vertical"
                                  className={styles.subMenuInner}
                                >
                                <IconButton transparent full textAlign="left" iconBefore={chevronLeft} className={styles.backButton} onClick={() => doClose()}>
                                  <Icon icon={macpro} className={styles.buttonIcon} />
                                  {professionalsMenu.label}
                                </IconButton>
                                {professionalsMenu.items.map(
                                  ({ linkText, url }) => (
                                    <Navigation.Item
                                      key={url}
                                      label={linkText}
                                      url={url}
                                      wrapClassName={styles.wrap}
                                      linkClassName={styles.link}
                                      menuLevel={2}
                                    />
                                  ),
                                )}
                              </Navigation>
                            )}
                          </TransitionElement>
                        )
                      }
                    />
                  )}
                  <Navigation.Item
                    className={styles.item2}
                    wrapClassName={styles.wrap}
                    linkClassName={styles.link2}
                    label={translate('/regionMenu/exploreTheRegion')}
                    leftIcon={exploretheregion}
                    leftIconProps={{
                      className: styles.menuItemIcon,
                    }}
                    menuLevel={1}
                    renderChildren={
                      !!regionMenu.menuPages &&
                      !!regionMenu.menuPages.length &&
                        (({ doClose, isOpen }) => 
                          <TransitionElement from={{opacity: 0, transform: 'translateX(-20px)',}} enter={{opacity: 1, transform: 'translateX(0px)',}} leave={{opacity: 0, transform: 'translateX(-20px)',}} className={styles.subMenu}>
                            {!!isOpen && (
                              <Navigation
                                direction="vertical"
                                className={styles.subMenuInner}
                              >
                              <IconButton transparent full textAlign="left" iconBefore={chevronLeft} className={styles.backButton} onClick={() => doClose()}>
                                <Icon icon={exploretheregion} className={styles.buttonIcon} />
                                {translate('/regionMenu/exploreTheRegion')}
                              </IconButton>
                              <RegionMenu2 variant={VARIANT_MOBILE} menuPages={regionMenu.menuPages} />
                            </Navigation>
                          )}
                        </TransitionElement>
                      )
                    }
                  />
                </Navigation>
                <SearchFormContainer
                  action={searchPageLink}
                  className={styles.searchForm}
                  inputClassName={styles.searchFormInput}
                  placeholder={quickSearchText}
                />
                <div className={styles.favorites}>
                  <Button
                    transparent
                    href={userMenu.url}
                    className={styles.myPageButton}
                  >
                    <div>
                      {userMenu.isLoggedIn ? (
                        <Image
                          src={userMenu.user.profilePictureUrl}
                          ratio={[1, 1]}
                          className={styles.avatar}
                          fit="cover"
                        />
                      ) : (
                        <Icon icon={heartFilled} className={styles.icon} />
                      )}
                    </div>
                    {translate('/menu/myFavorites')}
                  </Button>
                </div>
              </div>
            )}
          </TransitionElement>
        </nav>
      )}
    />
  </div>
);
MobileHeader.displayName = 'MobileHeader';
MobileHeader.propTypes = HeaderPropTypes;

export default MobileHeader;
