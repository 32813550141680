import React from "react";
import PageView from "components/organisms/PageView";
import DefaultLayoutContainerV2 from "containers/layouts/DefaultLayoutContainerV2";

const SubStartPageV2 = page => (
  <DefaultLayoutContainerV2>
    <PageView
      useFullscreenSlider
      slider={{ captionSettings: { fullHeight: true } }}
      useStartPageLayout
      isV2design
      {...page}
    />
  </DefaultLayoutContainerV2>
);

SubStartPageV2.displayName = "SubStartPageV2";
SubStartPageV2.propTypes = {};
SubStartPageV2.defaultProps = {};

export default SubStartPageV2;
