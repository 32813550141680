import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './Flag.scss';

const ALIGN_TOP = 'top';
const ALIGN_BOTTOM = 'bottom';
const ALIGN_MIDDLE = 'middle';

const Flag = ({
  as: Tag = 'div',
  isReverse,
  align = ALIGN_MIDDLE,
  image,
  children,
  className,
  ...rest
}) => {
  return (
    <Tag
      className={classnames(className, styles.flag, {
        [styles.flagRev]: isReverse,
        [styles.flagTop]: align === ALIGN_TOP,
        [styles.flagBottom]: align === ALIGN_BOTTOM,
      })}
      {...rest}
    >
      <div className={styles.flagImage}>{image}</div>
      <div className={styles.flagBody}>{children}</div>
    </Tag>
  );
};
Flag.displayName = 'Flag';
Flag.propTypes = {};
Flag.defaultProps = {};

export default Flag;
