import { createSelector } from 'redux-bundler';

export default {
  name: 'settings',
  getReducer: () => {
    const initialData = {
      disableBreadcrumbs: false,
      disableInspirationalView: false,
      footerSettings: {},
      cookieSettings: {},
      logoAndFaviconSettings: {},
      productPageLink: '/',
      searchPage: null,
      quickSearchText: null,
      siteSkin: null,
      knownUrls: {},
      siteStartPages: {},
      siteLanguages: [],
      professionalsMenu: {},
      regionMenu: { menuPages: [] },
    };

    return (state = initialData, { type, notification }) => state;
  },
  selectSettingsRaw: state => state.settings,
  selectSearchPageLink: createSelector(
    'selectSettingsRaw',
    settings => settings.searchPage,
  ),
  selectRegionMenu: createSelector(
    'selectSettingsRaw',
    settings => settings.regionMenu,
  ),
  selectFooterSettings: createSelector(
    'selectSettingsRaw',
    settings => settings.footerSettings,
  ),
  selectProductPageLink: createSelector(
    'selectSettingsRaw',
    settings => settings.productPageLink,
  ),
  selectCookieSettings: createSelector(
    'selectSettingsRaw',
    settings => settings.cookieSettings,
  ),
  selectKnownUrls: createSelector(
    'selectSettingsRaw',
    settings => settings.knownUrls,
  ),
  selectSiteLanguages: createSelector(
    'selectSettingsRaw',
    settings => settings.siteLanguages,
  ),
  selectFacebookAccessToken: createSelector(
    'selectSettingsRaw',
    settings => `${settings.facbookAppId}|${settings.facbookToken}`,
  ),
};
