import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'redux-bundler-react';

import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import RelatedProducts from 'components/organisms/RelatedProducts';
import BreadcrumbsContainer from 'containers/molecules/BreadcrumbsContainer';
import Container from 'components/atoms/Container';
import { PageGridSingleColumn } from 'components/molecules/PageGrid';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import { TitleXL } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import FavouriteButtonContainer from 'containers/molecules/FavouriteButtonContainer';
import { fakeColorOpacity } from 'utils/color';
import ShareButton from 'components/organisms/ShareButton';

// new startpage layout
import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';

import * as styles from './ArticlePage.scss';

const ArticlePage = page => {
  const {
    mainImage,
    heading,
    hideIntroductionText,
    introduction,
    url,
    colorTheme,
    mainContentAreaFull,
    mainContentAreaLeft,
    mainContentAreaRight,
    defaultAdsContentArea,
    mainBody,
    htmlBody,
    sliderImages,
    relatedProducts,
  } = page;

  const showShareButtons = true;
  const image = propertyValueFor(mainImage);
  const slides = propertyValueFor(sliderImages);
  const myFavoritesId = propertyValueFor(myFavoritesId);

  const topImage =
    image && image.url
      ? {
          src: image.url,
          alt: image.alternativeText,
          description: image.description,
          copy: image.credits,
        }
      : slides &&
        slides.length && {
          src: slides[0].slideUrl || slides[0].slideImage,
          copy: slides[0].imageFileCopyright,
          description: slides[0].imageFileDescription,
          alt: slides[0].altText,
          cropDetails: slides[0].cropDetails,
        };

  const themeColors =
    colorTheme && propertyValueFor(colorTheme)
      ? JSON.parse(propertyValueFor(colorTheme))
      : {};
  return (
    <DefaultLayoutContainerV2>
      <React.Fragment>
        <BreadcrumbsContainer />

        <Container wide noPadding className={classnames(styles.root)}>
          <PageGridSingleColumn
            wideMain
            main={
              <div>
                {topImage && (
                  <Container wide noPadding>
                    <Image
                      {...topImage}
                      src={`${topImage.src}?preset=ArticleHeaderImage`}
                    />
                  </Container>
                )}
                <Container
                  className={
                    !!topImage &&
                    propertyValueFor(heading) &&
                    styles.overlappingIntro
                  }
                >
                  <Container narrow>
                    <PropertyFor
                      property={heading}
                      component={TitleXL}
                      componentProps={{
                        as: 'h1',
                        className: styles.title,
                        underline: themeColors.Color || true,
                      }}
                    />
                    {!hideIntroductionText && (
                      <PropertyFor
                        property={introduction}
                        className={styles.introduction}
                        component={
                          !!introduction &&
                          typeof introduction.$c === 'string' &&
                          Paragraph
                        }
                        componentProps={{ size: Paragraph.sizes.large }}
                      />
                    )}

                    {!!showShareButtons && (
                      <div style={{ display: 'flex', margin: '1.5em 0' }}>
                        <ShareButton
                          title={propertyValueFor(heading)}
                          url={url.href}
                          noPadding
                        />
                        {!!myFavoritesId && (
                          <FavouriteButtonContainer
                            identifier={myFavoritesId}
                          />
                        )}
                      </div>
                    )}
                  </Container>
                </Container>
                <Container wide>
                  <React.Fragment>
                    <PropertyFor
                      property={mainBody}
                      className={classnames(styles.mainBody)}
                    />
                    <PropertyFor property={htmlBody} className={styles.html} />
                  </React.Fragment>

                  <PropertyFor
                    property={mainContentAreaFull}
                    className={styles.mainContentAreaFull}
                  />
                </Container>
              </div>
            }
            mainLeft={
              <PropertyFor
                property={mainContentAreaLeft}
                className={styles.mainContentAreaLeft}
              />
            }
            mainRight={
              <PropertyFor
                property={mainContentAreaRight}
                className={styles.mainContentAreaRight}
              />
            }
            ads={
              <PropertyFor
                property={defaultAdsContentArea}
                className={styles.adsContentArea}
                tagName="ul"
              />
            }
          />
          {!!(relatedProducts && relatedProducts.length) && (
            <div
              className={classnames(styles.after, {
                [styles.padded]: !!themeColors.Color,
              })}
              style={{
                backgroundColor: fakeColorOpacity(themeColors.Color, 0.55),
              }}
            >
              <RelatedProducts products={relatedProducts} />
            </div>
          )}
        </Container>
      </React.Fragment>
    </DefaultLayoutContainerV2>
  );
};
ArticlePage.displayName = 'ArticlePage';
ArticlePage.propTypes = {};
ArticlePage.defaultProps = {};

export default connect('selectRelatedProducts', ArticlePage);
