import React from 'react';
import classnames from 'classnames';
import Button from 'components/atoms/Button';
import * as styles from './ButtonFilter.scss';

const noop = () => {};

const ButtonFilter = ({
  title,
  links = [],
  onSelect,
  onHover = noop,
  selected,
}) =>
  links.length ? (
    <section className={styles.buttonFilter}>
      <h1 className={styles.title}>{title}</h1>
      <ul className={styles.buttonGroup}>
        {links.map(link => (
          <li key={link.name}>
            <Button
              className={classnames(styles.button, {
                [styles.selected]: selected === link,
              })}
              onClick={() => onSelect(link)}
              onMouseEnter={() => onHover(link.name)}
              onMouseLeave={() => onHover(null)}
              onFocus={() => onHover(link.name)}
              onBlur={() => onHover(null)}
            >
              {link.name}
            </Button>
          </li>
        ))}
      </ul>
    </section>
  ) : null;

export default ButtonFilter;
