import React from 'react';

import BlockWrapper from 'components/molecules/BlockWrapper';
import PropTypes from 'prop-types';
import {
  CustomPropTypes,
  propertyValueFor,
} from 'packages/inmeta-epi-react/utils/epi-react';
import Accordion from 'components/utilities/Accordion';
import * as styles from './AccordionBlock.scss';

export const AccordionBlock = ({ block }) => {
  const items = propertyValueFor(block.items).map(item => ({
    ...item,
    body: { $c: item.body },
  }));

  return (
    <BlockWrapper block={block} className={styles.root}>
      <Accordion items={items} />
    </BlockWrapper>
  );
};
AccordionBlock.displayName = 'AccordionBlock';
AccordionBlock.propTypes = {
  block: PropTypes.shape({
    title: CustomPropTypes.EpiserverValue,
    items: CustomPropTypes.EpiserverValue,
  }),
};
AccordionBlock.defaultProps = {};
export default AccordionBlock;
