import React from 'react';
import { connect } from 'redux-bundler-react';

const PageOtherListPagesContainer = ({ pageData, doUpdateUrl, children }) => {
  if (!pageData.otherListPages) {
    return null;
  }
  return children({ otherListPages: pageData.otherListPages, doUpdateUrl });
};

export default connect(
  'selectPageData',
  'doUpdateUrl',
  PageOtherListPagesContainer,
);
