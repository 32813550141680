import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Block from 'components/atoms/Block';
import Image from 'components/atoms/Image';
import LazyLoad from 'react-lazyload';
import { getVideoId, getVideoPlatform } from 'utils/utils';
import * as styles from './VideoPlayer.scss';
import ytLogo from '../../../assets/images/yt_icon_rgb.png';

const srcMap = {
  youtube: id => `https://www.youtube-nocookie.com/embed/${id}?rel=0`,
  vimeo: id => `https://player.vimeo.com/video/${id}?transparent=0`,
};

const srcThumb = {
  youtube: id => `http://img.youtube.com/vi/${id}/maxresdefault.jpg`,
};

const useVideoMeta = url =>
  useMemo(() => {
    const platform = getVideoPlatform(url);
    const id = getVideoId(url);
    const src = platform ? srcMap[platform](id) : null;
    const thumb = platform === 'youtube' ? srcThumb[platform](id) : null;
    return { platform, src, thumb };
  }, [url]);

const VideoPlayer = ({
  url,
  className,
  ratio,
  thumbnailUrl,
  disableLazy,
  fit,
}) => {
  const [showVideo, setShowVideo] = useState(
    platform === 'vimeo' || disableLazy,
  );
  const { platform, src, thumb } = useVideoMeta(url);
  if (platform) {
    if (showVideo) {
      return (
        <Block ratio={ratio} className={className}>
          <iframe
            title={`${platform} video: ${url}`}
            className={styles.videoIframe}
            src={src}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            role="presentation"
          />
        </Block>
      );
    }

    let thumbnail = thumb;
    if (thumbnailUrl) {
      thumbnail = thumbnailUrl;
    }
    return (
      <Block ratio={ratio} className={className}>
        <LazyLoad height={400} once>
          <button
            type="button"
            className={styles.videoLazy}
            onClick={() => setShowVideo(true)}
          >
            <img className={styles.videoLogo} src={ytLogo} alt="Logo Youtube" />
            <Image src={thumbnail} ratio={ratio} fit={fit} />
          </button>
        </LazyLoad>
      </Block>
    );
  }
  return null;
};
VideoPlayer.displayName = 'VideoPlayer';
VideoPlayer.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  ratio: PropTypes.any,
  thumbnailUrl: PropTypes.any,
  disableLazy: PropTypes.bool,
  fit: PropTypes.string,
};
VideoPlayer.defaultProps = {
  thumbnailUrl: null,
  disableLazy: false,
  fit: 'width',
};

export default VideoPlayer;
