import React from 'react';
import classnames from 'classnames';
import { connect } from 'redux-bundler-react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { magnifyer } from 'components/atoms/Icon/icons';
import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageSearchResultContainer from 'containers/organisms/PageSearchResultContainer';
import SiteSettingsContainer from 'containers/organisms/SiteSettingsContainer';
import PageView from 'components/organisms/PageView';
import TeaserList, { teaserConfig } from 'components/organisms/TeaserList';
import { SmallText } from 'components/atoms/Text';
import { translate } from 'utils/translate';
import * as constants from 'utils/constants';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { isUrlForSamePage } from 'utils/bundle-helper';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';


import * as styles from './SearchPage.scss';

const SearchPage = ({ doUpdateUrl = () => {}, ...page }) => {
  const currentPaginationNum = 0;
  const maxPaginationAmount = 10;
  const paginationStartNum = currentPaginationNum;

  const inputRef = React.useRef(null);
  const handleSubmit = React.useCallback(e => {
    e.preventDefault();

    doUpdateUrl(
      `${e.target.action}${e.target.action.includes('?') ? '&' : '?'}${
        inputRef.current.name
      }=${inputRef.current.value}`,
    );
  }, []);

  return (
    <DefaultLayoutContainerV2>
      <PageView isOneCol {...page}>
        {!!page.isLoading &&
          isUrlForSamePage(page.url, page.nextUrl) && (
            <React.Fragment>
              <LoadingIndicator background="rgba(255,255,255,0.4)" />
              <div className={styles.loadingContainer}>
                <div className={styles.spinner} />
              </div>
            </React.Fragment>
          )}
        {!page.isLoading &&
          <PageSearchResultContainer currentUrl={page.url}>
            {searchResult => (
              <React.Fragment>
                <SiteSettingsContainer>
                  {({ searchPage, quickSearchText }) => (
                    <form
                      method="get"
                      action={searchPage}
                      className={styles.searchForm}
                      onSubmit={handleSubmit}
                    >
                      <input
                        ref={inputRef}
                        type="search"
                        placeholder={quickSearchText}
                        defaultValue={searchResult.term}
                        name={constants.get('searchParam')}
                        className={styles.searchInput}
                      />
                      <Button
                        transparent
                        type="submit"
                        className={styles.searchButton}
                      >
                        <Icon icon={magnifyer} className={styles.icon} />
                      </Button>
                    </form>
                  )}
                </SiteSettingsContainer>
                {/* TODO: Fix translation issue. Shouldn't have to split a translated text to get the proper text */}
                {!searchResult.results.length && (
                  <SmallText>{translate('/pages/searchPage/noHits1')}</SmallText>
                )}
                {!!searchResult.results.length && (
                  <React.Fragment>
                    <SmallText>
                      {`${translate('/pages/searchPage/numHits1')} ${
                        searchResult.totalResults
                      } ${translate('/pages/searchPage/numHits2')}`}
                    </SmallText>
                    <TeaserList
                      className={styles.resultList}
                      items={searchResult.results}
                      config={teaserConfig.imageTitleAndIntroForListView}
                      showFavoriteButton
                    />
                    {!!searchResult.pages.length && (
                      <div className={styles.pagination}>
                        {searchResult.pages
                          .slice(
                            paginationStartNum,
                            paginationStartNum + maxPaginationAmount,
                          )
                          .map(p => (
                            <Button
                              key={p.number}
                              transparent
                              disabled={p.isCurrent}
                              className={classnames(styles.button, {
                                [styles.disabled]: p.isCurrent,
                              })}
                              href={p.href}
                            >
                              {p.number}
                            </Button>
                          ))}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </PageSearchResultContainer>
        }
      </PageView>
    </DefaultLayoutContainerV2>
  );
};
SearchPage.displayName = 'SectionPage';
SearchPage.propTypes = {};
SearchPage.defaultProps = {};

// export default SearchPage;
export default connect(
  'doUpdateUrl',
  SearchPage,
);
