import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './Breadcrumbs.scss';
import Icon from 'components/atoms/Icon';
import { house, chevronRight } from 'components/atoms/Icon/icons';

const Breadcrumbs = ({ className, crumbs = [] }) => {
  const [rootCrumb, ...restCrumbs] = crumbs;
  return crumbs.length ? (
    <ul className={classnames(styles.breadcrumbs, className)}>
      <li className={styles.crumb}>
        <a href={rootCrumb.url}>
          <Icon
            icon={house}
            className={styles.iconCircle}
            size={['1.5em', '1.5em']}
          />
        </a>
        <Icon icon={chevronRight} className={styles.separator} />
      </li>
      {restCrumbs.map((crumb, i) => (
        <li key={crumb.url || crumb.text} className={styles.crumb}>
          {crumb.url && i !== restCrumbs.length - 1 ? (
            <React.Fragment>
              <a href={crumb.url}>{crumb.text}</a>{' '}
              <Icon icon={chevronRight} className={styles.separator} />
            </React.Fragment>
          ) : (
            crumb.text
          )}
        </li>
      ))}
    </ul>
  ) : null;
};
Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
};
Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
