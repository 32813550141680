import React from 'react';
import classnames from 'classnames';
import { resolveComponent } from '../../utils/component-registry';
import { editorAttributesFor } from '../../utils/epi-react';
import isInEditMode from '../../utils/is-in-edit-mode';
// import * as styles from './content-area.scss';

// export type ContentAreaProps = {
//   tagName?: string;
//   className?: string;
//   items?: Array<any>;
//   propertyName?: string;
// };

export function contentAreaItems(propertyName, items) {
  return (items || []).filter(i => !!i).map((item, i) => {
    const Component = resolveComponent(item.componentName);
    const itemOpts =
      isInEditMode() && propertyName
        ? {
            'data-epi-block-id': item.$idForOnPageEditing,
            'data-epi-block-info': '{&quot;missingrenderer&quot;:false}',
          }
        : {};
    return (
      <div key={i} {...itemOpts}>
        <Component {...item} />
      </div>
    );
  });
}

const ContentArea = ({
  tagName, // ?: string;
  className, // ?: string;
  items, // ?: Array<any>;
  propertyName, // ?: string;
}) => {
  return React.createElement(
    tagName || 'div',
    editorAttributesFor(propertyName, className), // , styles.base)),
    contentAreaItems(propertyName, items),
  );
};

ContentArea.supportsPropertyForIntegration = true;

export default ContentArea;
