import qs from 'querystringify';
import { createSelector } from 'redux-bundler';
import dlv from 'dlv';
import removeLeading from 'utils/string/remove-leading';
import {
  HAS_WINDOW,
  initScrollPosition,
  saveScrollPosition,
  parseUrl,
} from '../utils/bundle-helper';

export const EVENT_LOAD = 'load';
export const EVENT_PUSH = 'push';
export const EVENT_REPLACE = 'replace';
export const EVENT_POP = 'pop';

// DONE: Change url from string to object (reverse urlObject -> url, url -> urlString (or href on object))
// DONE: Add "type" indicator ("replace", "push", "pop", "load")
// DONE: support "cosmetic" url change

// TODO: Update saveScrollPosition to merge state (because it overwrites the current state)
// TODO: Look into just resetting scrollPosition when the new page actually loads.. More like a proper browser navigation

const loc = (() => {
  if (!HAS_WINDOW) return {};
  return window.location;
})();
const defaults = {
  name: 'url',
  inert: !HAS_WINDOW,
  actionType: 'URL_UPDATED',
  handleScrollRestoration: true,
};

export default opts => {
  const config = Object.assign({}, defaults, opts);
  const { actionType } = config;

  // selectors
  const selectUrl = state => state[config.name];
  const selectUrlString = createSelector(
    selectUrl,
    urlObj => urlObj.href,
  );
  const selectQueryObject = createSelector(
    selectUrl,
    urlObj => urlObj.query,
  );
  const selectQueryString = createSelector(
    selectQueryObject,
    queryObject => qs.stringify(queryObject),
  );
  const selectPathname = createSelector(
    selectUrl,
    urlObj => urlObj.pathname,
  );
  const selectHash = createSelector(
    selectUrl,
    urlObj => removeLeading('#', urlObj.hash),
  );
  const selectHashObject = createSelector(
    selectHash,
    hash => qs.parse(hash),
  );

  // actions
  const doUpdateUrl = (newState, opts = { state: {}, event: EVENT_PUSH }) => ({
    dispatch,
    getState,
  }) => {
    let url = parseUrl(newState, newState.event || opts.event);
    dispatch({
      type: actionType,
      payload: { url: url },
    });
  };
  const doReplaceUrl = url => doUpdateUrl(url, { event: EVENT_REPLACE });
  const doUpdateQuery = (query, opts = { replace: true }) =>
    doUpdateUrl(Object.assign({}, selectUrl(), { query: qs.parse(query) }), {
      event: opts.replace ? EVENT_REPLACE : EVENT_PUSH,
    });
  const doUpdateHash = (hash, opts = { replace: true }) =>
    doUpdateUrl(Object.assign({}, selectUrl(), { hash: hash }), {
      event: opts.replace ? EVENT_REPLACE : EVENT_PUSH,
    });

  return {
    name: config.name,
    init: store => {
      if (config.inert) {
        return;
      }

      if (config.handleScrollRestoration) initScrollPosition();

      window.addEventListener('popstate', popstate => {
        store.doUpdateUrl(loc, {
          event: EVENT_POP,
        });
      });

      let lastState = store.selectUrl();

      store.subscribe(() => {
        const newState = store.selectUrl();
        if (lastState !== newState && newState.href !== loc.href) {
          try {
            if (config.handleScrollRestoration) saveScrollPosition();
            window.history[
              newState.event === EVENT_REPLACE ? 'replaceState' : 'pushState'
            ]({}, null, newState.href);
            if (!newState.noScrollReset) {
              window.scrollTo(0, 0);
            }
          } catch (e) {
            console.error(e);
          }
        }
        lastState = newState;
      });
    },
    getReducer: () => {
      const initialState = parseUrl(
        !config.inert && HAS_WINDOW
          ? dlv(window, 'INITIAL_DATA.model.url', loc.href)
          : '/',
        EVENT_LOAD,
      );

      return (state = initialState, { type, payload }) => {
        if (type === '@@redux/INIT' && typeof state === 'string') {
          return parseUrl(state, EVENT_LOAD);
        }
        if (type === actionType) {
          return parseUrl(payload.url || payload, payload.event || EVENT_LOAD);
        }
        return state;
      };
    },

    // actions
    doUpdateUrl,
    doReplaceUrl,
    doUpdateQuery,
    doUpdateHash,

    // selectors
    selectUrl,
    selectUrlString,
    selectQueryObject,
    selectQueryString,
    selectPathname,
    selectHash,
    selectHashObject,
  };
};
