import React from "react";
import PropTypes from "prop-types";
import * as styles from "./TwitterBlock.scss";
import { withColumnContext } from "components/molecules/PageGrid";
import ScriptSandbox from "components/utilities/ScriptSandbox"; 

const TwitterBlock = props => {
  // console.log('TwitterBlock', props);
  const {
    block
  } = props;
  if (block && block.alternateHref && block.alternateHref.$c) {
    return (
      <section>
          <div className={styles.tweetsBox}>
            <ScriptSandbox content={`<a class="twitter-timeline" href="${block.alternateHref.$c}?ref_src=twsrc%5Etfw">Tweets by VisitOSLO</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`} />
          </div>
      </section>
    );
  }
  return null;
};
TwitterBlock.displayName = "TwitterBlock";
TwitterBlock.propTypes = {};
TwitterBlock.defaultProps = {};

export default withColumnContext(TwitterBlock);
