import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './PageGrid.scss';
import Container from 'components/atoms/Container';

export const COLUMN_MAIN = 'main';
export const COLUMN_MAINLEFT = 'main-left';
export const COLUMN_MAINRIGHT = 'main-right';
export const COLUMN_ASIDE = 'aside';
export const COLUMN_ADS = 'ads';
export const COLUMN_FOOTER = 'footer';
export const ColumnContext = React.createContext(COLUMN_MAIN);

export const withColumnContext = Component =>
  class extends React.PureComponent {
    render() {
      return (
        <ColumnContext.Consumer>
          {context => <Component {...this.props} columnContext={context} />}
        </ColumnContext.Consumer>
      );
    }
  };
export const useColumnContext = () => useContext(ColumnContext);

/**
 * The main Page Grid.
 * Use it on every page that needs the 2/3 column grid
 */
const PageGrid = ({
  main,
  aside,
  mainLeft,
  mainRight,
  ads,
  oneCol,
  wideMain,
}) => (
  <React.Fragment>
    {!!wideMain && (
      <main className={styles.column} id="main-content">
        <ColumnContext.Provider value={COLUMN_MAIN}>
          {main}
        </ColumnContext.Provider>
      </main>
    )}
    <div
      className={classnames(styles.container, {
        [styles.wideMain]: wideMain,
      })}
    >
      <section
        className={classnames(styles.main, {
          [styles.oneCol]: oneCol,
        })}
      >
        {!wideMain && (
          <main className={styles.column} id="main-content">
            <ColumnContext.Provider value={COLUMN_MAIN}>
              {main}
            </ColumnContext.Provider>
          </main>
        )}
        <div className={styles.grid}>
          <div className={styles.column}>
            <ColumnContext.Provider value={COLUMN_MAINLEFT}>
              {mainLeft}
            </ColumnContext.Provider>
          </div>
          <div className={styles.column}>
            <ColumnContext.Provider value={COLUMN_MAINRIGHT}>
              {mainRight}
            </ColumnContext.Provider>
          </div>
        </div>
        {!!ads && (
          <ColumnContext.Provider value={COLUMN_ADS}>
            <div className={classnames(styles.ads, styles.column)}>{ads}</div>
          </ColumnContext.Provider>
        )}
      </section>
      {!oneCol && (
        <ColumnContext.Provider value={COLUMN_ASIDE}>
          <aside className={classnames(styles.aside, styles.column)}>
            {aside}
          </aside>
        </ColumnContext.Provider>
      )}
    </div>
  </React.Fragment>
);
PageGrid.displayName = 'PageGrid';
PageGrid.propTypes = {
  /**
   * content for main column
   */
  main: PropTypes.node,
  /**
   * content for left column in main, below main content
   */
  mainLeft: PropTypes.node,
  /**
   * content for right column in main, below main content
   */
  mainRight: PropTypes.node,
  /**
   * content for aside column
   */
  aside: PropTypes.node,
  /**
   * content for ads column
   */
  ads: PropTypes.node,
  /**
   * if true; aside columnn is dropped, even if aside content is provided
   */
  oneCol: PropTypes.bool,
  /**
   * if true; main column is rendered above the grid, filling 100% of the containing element. mainLeft, mainRight and aside forms a 3 column layout below.
   */
  wideMain: PropTypes.bool,
};
PageGrid.defaultProps = {
  oneCol: false,
  wideMain: false,
};

export const PageGridSingleColumn = ({
  main,
  mainLeft,
  mainRight,
  ads,
  wideMain,
}) => (
  <React.Fragment>
    {!!wideMain && (
      <main className={styles.column} id="main-content">
        <ColumnContext.Provider value={COLUMN_MAIN}>
          {main}
        </ColumnContext.Provider>
      </main>
    )}
    <div
      className={classnames(styles.container, {
        [styles.wideMain]: wideMain,
      })}
    >
      <section className={classnames(styles.main, styles.oneCol)}>
        {!wideMain && (
          <main className={styles.column} id="main-content">
            <ColumnContext.Provider value={COLUMN_MAIN}>
              {main}
            </ColumnContext.Provider>
          </main>
        )}
        <Container className={styles.grid}>
          <div className={styles.column}>
            <ColumnContext.Provider value={COLUMN_MAINLEFT}>
              {mainLeft}
            </ColumnContext.Provider>
          </div>
          <div className={styles.column}>
            <ColumnContext.Provider value={COLUMN_MAINRIGHT}>
              {mainRight}
            </ColumnContext.Provider>
          </div>
        </Container>
        {!!ads && (
          <ColumnContext.Provider value={COLUMN_ADS}>
            <Container className={classnames(styles.ads, styles.column)}>
              {ads}
            </Container>
          </ColumnContext.Provider>
        )}
      </section>
    </div>
  </React.Fragment>
);

export default PageGrid;
