import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withColumnContext } from 'components/molecules/PageGrid';
import BlockWrapper from 'components/molecules/BlockWrapper';
import Calendar from 'components/utilities/Calendar';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import getLocale from 'utils/getLocale';
import { appendQueryParams } from 'utils/bundle-helper';
import { getFormattedDate } from 'utils/dateTime';
import * as constants from 'utils/constants';
import { parseUrl } from 'utils/bundle-helper';

const CalendarBlock = ({ block, columnContext, ...props }) => {
  const locale = getLocale();
  const eventListPageUrl = parseUrl(propertyValueFor(block.eventListPage));
  const handleLinkForDate = useCallback(
    date =>
      appendQueryParams(eventListPageUrl, {
        [constants.get('fromDate')]: getFormattedDate(date, 'yyyy-MM-dd'),
        [constants.get('toDate')]: getFormattedDate(date, 'yyyy-MM-dd'),
      }).href,
  );
  return (
    <BlockWrapper block={block} columnContext={columnContext}>
      <Calendar
        locale={locale}
        weekStartsOn={Calendar.weekStartsOn.monday}
        getLinkForDate={handleLinkForDate}
        isBlock
      />
    </BlockWrapper>
  );
};
CalendarBlock.displayName = 'CalendarBlock';
CalendarBlock.propTypes = {};
CalendarBlock.defaultProps = {};

export default withColumnContext(CalendarBlock);
