import React from "react";
import PropTypes from "prop-types";

function Timer(callback, delay) {
  var timerId,
    start,
    remaining = delay;

  this.pause = function() {
    window.clearTimeout(timerId);
    remaining -= new Date() - start;
  };

  this.resume = function() {
    start = new Date();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, remaining);
  };

  this.resume();
}

class TimerComponent extends React.Component {
  start = (callback = this.props.callback, delay = this.props.delay) => {
    this.stop();
    if (callback && delay) {
      this.timer = new Timer(callback, delay);
    }
  };
  stop = () => {
    this.timer && this.timer.pause();
    this.timer = null;
  };
  pause = () => {
    this.timer && this.timer.pause();
  };
  resume = () => {
    this.timer && this.timer.resume();
  };
  componentDidMount() {
    if (this.props.autostart) {
      this.start();
    }
  }
  componentWillUnmount() {
    this.stop();
  }
  render() {
    const { start, resume, stop, pause } = this;
    return this.props.render({
      start,
      resume,
      stop,
      pause
    });
  }
}
TimerComponent.displayName = "Timer";
TimerComponent.propTypes = {
  callback: PropTypes.func,
  delay: PropTypes.number,
  render: PropTypes.func.isRequired,
  autostart: PropTypes.bool
};
TimerComponent.defaultProps = {
  autostart: false
};

export default TimerComponent;
