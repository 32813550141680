import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BlockWrapper from 'components/molecules/BlockWrapper';
import TeaserLink from 'components/molecules/TeaserLink';
import { COLUMN_FOOTER, useColumnContext } from 'components/molecules/PageGrid';
import useThemeConfig from 'hooks/use-theme-config';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import * as styles from './LinksBlock.scss';

// detect column context and do special rendering for footer version

const styleMap = {
  [COLUMN_FOOTER]: styles.footerLink,
};

const LinksBlock = ({ block }) => {
  const themeConfig = useThemeConfig();
  const columnContext = useColumnContext();
  const icon =
    themeConfig[
      columnContext === COLUMN_FOOTER
        ? 'footerLinksBlockIcon'
        : 'linksBlockIcon'
    ];
  const links = propertyValueFor(block.links);
  return (
    <BlockWrapper block={{ title: block.header }} columnContext={columnContext}>
      {links.map(link => (
        <TeaserLink
          key={`${link.linkText}_${link.url}`}
          href={link.url}
          className={classnames(styles.link, styleMap[columnContext])}
          title={link.linkTitle}
          icon={columnContext !== COLUMN_FOOTER ? icon : ""}
        >
          {link.linkText}
        </TeaserLink>
      ))}
    </BlockWrapper>
  );
};
LinksBlock.displayName = 'LinksBlock';
LinksBlock.propTypes = {};
LinksBlock.defaultProps = {};

export default LinksBlock;
