import React from 'react';
import PropTypes from 'prop-types';

import { H2 } from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Container from 'components/atoms/Container';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';

import * as styles from './MyPageSectionAccomodation.scss';

const MyPageSectionAccomodation = ({ items = [] }) => (
  <BreakpointContainer
    render={breakpoint => {
      const headingSize =
        breakpoint < BREAKPOINT_TABLET_PORTRAIT
          ? H2.sizes.large
          : H2.sizes.heading2;
      return (
        <Container noPadding>
          {items.map((article, i) => (
            <a key={article.url} href={article.url} className={styles.link}>
              <Image src={article.imageUrl} ratio={[16, 9]} fit="cover" cropDetails={article.cropDetails} />
              <div className={styles.wrap}>
                <H2 alt center className={styles.heading} size={headingSize}>
                  {article.title}
                </H2>
              </div>
            </a>
          ))}
        </Container>
      );
    }}
  />
);
MyPageSectionAccomodation.displayName = 'MyPageSectionAccomodation';
MyPageSectionAccomodation.propTypes = {};
MyPageSectionAccomodation.defaultProps = {};

export default MyPageSectionAccomodation;
