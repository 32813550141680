import React from "react";
import { Provider as ReduxProvider } from "redux-bundler-react";
import CurrentPage from "../containers/CurrentPage";
import { RootElement } from "../GlobalEvents";

export default ({ store }) => (
  <ReduxProvider store={store}>
    <RootElement>
      <CurrentPage />
    </RootElement>
  </ReduxProvider>
);
