import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './ProductFilterSlider.scss';

const MultiRangeSlider = ({
  className,
  children,
  max,
  min = 0,
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  onChange,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [lastChanged, setLastChanged] = useState('max');

  const range = useRef(null);

  const [mouseState, setMouseState] = useState(null);

  useEffect(() => {
    setMinVal(minValue);
    setMaxVal(maxValue + 1);
  }, [minValue, maxValue]);

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, maxVal]);

  const changeCallback = e => {
    if (e.target.name === 'min' && Number(e.target.value) <= maxValue) {
      setMinVal(Number(e.target.value));
      setLastChanged('min');
    }
    if (e.target.name === 'max' && Number(e.target.value) > minValue) {
      setMaxVal(Number(e.target.value));
      setLastChanged('max');
    }
  };

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      if (lastChanged === 'min') onChange(minVal, 'min');
      else onChange(maxVal - 1, 'max');
    }
  };

  useEffect(() => {
    if (mouseState === 'up') {
      if (lastChanged === 'min') onChange(minVal, 'min');
      else onChange(maxVal - 1, 'max');
    }
  }, [mouseState]);

  const handleTouchEnd = () => {
    if (lastChanged === 'min') onChange(minVal, 'min');
    else onChange(maxVal - 1, 'max');
  };

  const getPercent = value =>
    Math.round(((value - min) / (max + 1 - min)) * 100);

  const getFormattedLabel = (label, isMinimumLabel = true) => {
    const matches = label.match(/([0-9.,]+)(?:[\D.]*)([0-9,.]+)/);
    if (matches && matches.length > 2)
      return `${matches[isMinimumLabel ? 1 : 2]}`;
    return label;
  };
  return (
    <div
      className={classnames(
        styles.sliderWrapper,
        styles.multiSliderWrapper,
        className,
      )}
    >
      <input
        type="range"
        value={minVal}
        onChange={changeCallback}
        className={classnames(styles.thumb, styles.thumbLeft)}
        onKeyUp={handleKeyUp}
        onMouseDown={() => setMouseState('down')}
        onMouseUp={() => setMouseState('up')}
        onTouchEnd={handleTouchEnd}
        style={{ zIndex: minVal > max - 100 && '5' }}
        min={min}
        max={max + 1}
        name="min"
      />
      <input
        type="range"
        value={maxVal}
        onChange={changeCallback}
        className={classnames(styles.thumb, styles.thumbRight)}
        onKeyUp={handleKeyUp}
        onMouseDown={() => setMouseState('down')}
        onMouseUp={() => setMouseState('up')}
        onTouchEnd={handleTouchEnd}
        min={min}
        max={max + 1}
        name="max"
      />

      <div className={styles.multiRangeSlider}>
        <div className={styles.track} />
        <div ref={range} className={styles.range} />
        <div className={styles.sliderLeftVal}>
          {getFormattedLabel(minLabel, true)}
        </div>
        <div className={styles.sliderRightVal}>
          {getFormattedLabel(maxLabel, false)}
        </div>
      </div>

      {children}
    </div>
  );
};

MultiRangeSlider.displayName = 'MultiRangeSlider';
MultiRangeSlider.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.any,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
MultiRangeSlider.defaultProps = {};

export default MultiRangeSlider;
