import getUUID from "utils/uuid";
const notificationDefaults = {
  delay: 0
};

export default {
  name: "notifications",
  getReducer: () => {
    const initialData = [];

    return (state = initialData, { type, notification }) => {
      if (type === "NOTIFICATIONS_ADD") {
        return [
          ...state,
          { ...notificationDefaults, ...notification, uuid: getUUID() }
        ];
      }
      if (type === "NOTIFICATIONS_DISMISS") {
        return state.filter(item => item !== notification);
      }
      if (type === "NOTIFICATIONS_CLEAR") {
        return initialData;
      }
      return state;
    };
  },
  selectNotifications: state => state.notifications,

  /**
   * All hide/show logic for notifications must be handled at component level..
   * Because "pause on hover", "dismiss on click", "show x at a time", "hide after x seconds", etc relies too much on the interaction of the user/with the component(s)
   */
  doAddNotification: notification => ({
    type: "NOTIFICATIONS_ADD",
    notification
  }),
  doDismissNotification: notification => ({
    type: "NOTIFICATIONS_DISMISS",
    notification
  }),
  doClearNotifications: () => ({
    type: "NOTIFICATIONS_CLEAR"
  })
};
