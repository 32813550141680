import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sticky from 'components/utilities/Sticky';
import {
  chevronUp,
  chevronDown,
  heartFilled,
} from 'components/atoms/Icon/icons';
import Container from 'components/atoms/Container';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import Navigation from 'components/molecules/Navigation';
import SearchFormContainer from 'containers/molecules/SearchFormContainer';
import Topbar from 'components/organisms/Topbar';
import RegionMenu2 from 'components/organisms/RegionMenu2';
import MenuToggler from 'components/utilities/MenuToggler';
import ConditionalWrap from 'components/utilities/ConditionalWrap';
import { translate } from 'utils/translate';

import * as styles from './DesktopHeader.scss';

const HeaderPropTypes = {
  logo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  secondaryLogo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  professionalsMenu: PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.array,
  }),
  languageLinks: PropTypes.array,
  quickSearchText: PropTypes.string,
  userMenu: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
    url: PropTypes.string,
    logoutAction: PropTypes.func,
  }),
  menuItems: PropTypes.array,
  regionMenu: PropTypes.object,
  isSticky: PropTypes.bool,
  searchPageLink: PropTypes.any,
};

const DesktopHeader = ({
  logo,
  secondaryLogo,
  professionalsMenu,
  languageLinks,
  quickSearchText,
  searchPageLink,
  userMenu,
  menuItems,
  regionMenu,
  isSticky,
}) => {
  const submenuDirectionDividerCount = Math.floor(menuItems.length / 2);
  return (
    <div className={styles.root}>
      <Topbar>
        <MenuToggler
          closeOnOutsideClick
          render={({ isOpen, clickOpenerProps, ref }) => (
            <React.Fragment>
              <Topbar.Button
                {...clickOpenerProps}
                className={classnames(styles.regionMenuButton, {
                  [styles.isOpen]: isOpen,
                })}
                buttonClassName={styles.regionMenuButtonIcon}
                icon={isOpen ? chevronUp : chevronDown}
              >
                {translate('/regionMenu/exploreTheRegion')}
              </Topbar.Button>
              {isOpen && <RegionMenu2 {...regionMenu} innerRef={ref} />}
            </React.Fragment>
          )}
        />
        {!!professionalsMenu && !!professionalsMenu.label && (
          <Topbar.Menu
            buttonClassName={styles.professionalsMenuButton}
            label={professionalsMenu.label}
            items={professionalsMenu.items}
          />
        )}
        {userMenu.isLoggedIn ? (
          <Topbar.Menu
            className={styles.myPageButton}
            items={[
              {
                linkText: translate('/menu/logOut'),
                action: userMenu.logoutAction,
              },
            ]}
            href={userMenu.url}
            label={
              <React.Fragment>
                <Image
                  src={userMenu.user.profilePictureUrl}
                  ratio={[1, 1]}
                  className={styles.avatar}
                  fit="cover"
                />
                {translate('/menu/myFavorites')}
              </React.Fragment>
            }
          />
        ) : (
          <div className={styles.myPageButton}>
            <Topbar.Button href={userMenu.url}>
              <Icon icon={heartFilled} className={styles.icon} />
              {translate('/menu/myFavorites')}
            </Topbar.Button>
          </div>
        )}
      </Topbar>
      <header className={styles.header}>
        <Container className={styles.container} noPadding>
          {!!logo.link && !!logo.desktopImage && (
            <a href={logo.link}>
              <img src={logo.desktopImage} className={styles.logo} alt="" />
            </a>
          )}
          {!!secondaryLogo.link && !!secondaryLogo.desktopImage && (
            <a href={secondaryLogo.link}>
              <img
                src={secondaryLogo.desktopImage}
                className={styles.subLogo}
                alt=""
              />
            </a>
          )}
          <div className={styles.langSearchContainer}>
            {!!languageLinks && (
              <ul className={styles.languageList}>
                {languageLinks.map(lang => (
                  <li key={lang.url}>
                    <Button
                      href={lang.url}
                      transparent
                      className={classnames(styles.item, {
                        [styles.languageBarCurrent]: lang.isCurrentLanguage,
                      })}
                    >
                      {lang.name}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
            <SearchFormContainer
              action={searchPageLink}
              className={styles.searchForm}
              inputClassName={styles.searchFormInput}
              buttonClassName={styles.searchFormButton}
              placeholder={quickSearchText}
              showSearchButton
            />
          </div>
        </Container>
      </header>
      <ConditionalWrap
        condition={!!isSticky}
        wrap={children => (
          <Sticky
            stickyClassName={styles.isSticky}
            innerClassName={styles.stickyInner}
          >
            {children}
          </Sticky>
        )}
      >
        <nav className={styles.navigation}>
          <Container noPadding className={styles.navigationContainer}>
            <Navigation direction="horizontal">
              {menuItems.map((item, itemIndex) => {
                const subItems = !!item.children
                  ? item.children.filter(item => !!item.url)
                  : [];
                return (
                  <Navigation.Item
                    key={item.url || item.name}
                    label={item.name}
                    url={item.url}
                    isCurrent={item.isCurrent}
                    menuLevel={0}
                    showOnHover
                  >
                    {subItems.length && (
                      <Navigation
                        direction="vertical"
                        className={styles.subMenu}
                      >
                        {subItems.map(subItem => {
                          const subSubItems = !!subItem.children
                            ? subItem.children.filter(item => !!item.url)
                            : [];
                          return (
                            <Navigation.Item
                              className={styles.subItem}
                              key={subItem.url}
                              label={subItem.name}
                              url={subItem.url}
                              isCurrent={subItem.isCurrent}
                              menuLevel={1}
                              iconClassName={styles.subItemIcon}
                              showOnHover
                            >
                              {subSubItems.length && (
                                <Navigation
                                  direction="vertical"
                                  className={classnames(styles.subMenu, {
                                    [styles.subMenuLeft]:
                                      itemIndex >= submenuDirectionDividerCount,
                                    [styles.subMenuRight]:
                                      itemIndex < submenuDirectionDividerCount,
                                  })}
                                >
                                  {subSubItems.map(subSubItem => (
                                    <Navigation.Item
                                      className={styles.subItem}
                                      key={subSubItem.url}
                                      label={subSubItem.name}
                                      url={subSubItem.url}
                                      isCurrent={subSubItem.isCurrent}
                                      menuLevel={2}
                                    />
                                  ))}
                                </Navigation>
                              )}
                            </Navigation.Item>
                          );
                        })}
                      </Navigation>
                    )}
                  </Navigation.Item>
                );
              })}
            </Navigation>
          </Container>
        </nav>
      </ConditionalWrap>
    </div>
  );
};

DesktopHeader.displayName = 'DesktopHeader';
DesktopHeader.propTypes = HeaderPropTypes;

export default DesktopHeader;
