import { apiFetch, myPageApi, localMyPageApi } from 'server/api';

export default {
  name: 'api-args',
  // note that the store gets passed in here:
  getExtraArgs: store => {
    // defined here because we need to do stuff with store and the result
    const pageFetch = urlPath =>
      apiFetch(
        `${urlPath}${urlPath.indexOf('?') === -1 ? '?' : '&'}react`,
      ).then(({ userData, productResponse, ...data }) => {
        // extract userData here
        store.doUpdateUserData(userData);
        // extract productResponse here
        store.doSetPageProducts(urlPath, productResponse);
        return data;
      });

    return { apiFetch, pageFetch, myPageApi, localMyPageApi };
  },
};
