/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import qs from 'querystringify';
import * as constants from 'utils/constants';
import { HAS_DEBUG_FLAG, HAS_WINDOW, IS_BROWSER, IS_PROD } from './environment';
import raf from './raf';

export { HAS_DEBUG_FLAG, HAS_WINDOW, IS_BROWSER, IS_PROD, raf };
const fallback = func => {
  setTimeout(func, 0);
};
export const ric =
  IS_BROWSER && self.requestIdleCallback ? self.requestIdleCallback : fallback;
const defaultUrlObject = {
  href: '',
  path: '',
  hash: '',
  query: {},
  search: undefined,
  hiddenQuery: {},
  cosmetic: false,
  noScrollReset: false,
  event: 'push',
};

export const isIE11 =
  navigator &&
  navigator.userAgent &&
  !navigator.userAgent.match(/Trident.*rv:11\./);

// can dump this once IE 11 support is no longer necessary
export const isPassiveSupported = () => {
  let passiveSupported = false;
  try {
    const options = Object.defineProperty({}, 'passive', {
      get: () => {
        passiveSupported = true;
      },
    });
    window.addEventListener('test', options, options);
    window.removeEventListener('test', options, options);
  } catch (err) {
    passiveSupported = false;
  }
  return passiveSupported;
};

export const PASSIVE_EVENTS_SUPPORTED = isPassiveSupported();

export const startsWith = (string, searchString) =>
  string.substr(0, searchString.length) === searchString;

export const flattenExtractedToObject = extracted => {
  const result = {};
  for (const appName in extracted) {
    Object.assign(result, extracted[appName]);
  }
  return result;
};

export const flattenExtractedToArray = extracted => {
  const accum = [];
  for (const appName in extracted) {
    accum.push(...extracted[appName]);
  }
  return accum;
};

export const addGlobalListener = (
  eventName,
  handler,
  opts = { passive: false },
) => {
  if (IS_BROWSER) {
    if (opts.passive) {
      if (PASSIVE_EVENTS_SUPPORTED) {
        self.addEventListener(eventName, handler, { passive: true });
      } else {
        self.addEventListener(eventName, debounce(handler, 200), false);
      }
    } else {
      self.addEventListener(eventName, handler);
    }
  }
};

export const selectorNameToValueName = name => {
  const start = name[0] === 's' ? 6 : 5;
  return name[start].toLowerCase() + name.slice(start + 1);
};

export const debounce = (fn, wait) => {
  let timeout;
  function debounced(...args) {
    const ctx = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(ctx, args);
    }, wait);
  }
  debounced.cancel = () => {
    clearTimeout(timeout);
  };
  return debounced;
};

// see https://github.com/nuxt/nuxt.js/issues/2512 for reasons why this is needed
export const getScrollTop = () =>
  !IS_BROWSER
    ? 0
    : document.scrollingElement
    ? document.scrollingElement.scrollTop
    : Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop,
      );
export const getScrollLeft = () =>
  !IS_BROWSER
    ? 0
    : document.scrollingElement
    ? document.scrollingElement.scrollLeft
    : Math.max(
        window.pageXOffset,
        document.documentElement.scrollLeft,
        document.body.scrollLeft,
      );

export const saveScrollPosition = () => {
  if (!IS_BROWSER) {
    return;
  }
  history.replaceState(
    {
      height: document.body.offsetHeight,
      width: document.body.offsetWidth,
      y: getScrollTop(),
      x: getScrollLeft(),
    },
    '',
  );
};

export const restoreScrollPosition = () => {
  if (IS_BROWSER) {
    const { state } = history;
    if (state) {
      // we'll force it to our known height since the DOM rendering may
      // be async and the height may not be restored yet.
      const newStyle = `height: ${state.height}px; width: ${state.width}px;`;
      document.body.setAttribute('style', newStyle);
      window.scrollTo(state.x, state.y);
      ric(() => document.body.removeAttribute('style'));
    }
  }
};

export const initScrollPosition = () => {
  if (IS_BROWSER) {
    // turn off browser scroll restoration if available
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
    addGlobalListener('popstate', restoreScrollPosition);
    addGlobalListener('scroll', debounce(saveScrollPosition, 300), {
      passive: true,
    });
    saveScrollPosition();
  }
};

// moved from create-url-bundle2

export const isString = obj =>
  Object.prototype.toString.call(obj) === '[object String]';
export const isDefined = thing => typeof thing !== 'undefined';
export const ensureString = input =>
  isString(input) ? input : qs.stringify(input);
const IPRE = /^[0-9.]+$/;
export const parseSubdomains = (hostname, getBareHost) => {
  if (IPRE.test(hostname)) return [];
  const parts = hostname.split('.');
  if (getBareHost) {
    return parts.slice(-2).join('.');
  }
  return hostname.split('.').slice(0, -2);
};
export const removeLeading = (char, string) =>
  string.charAt(0) === char ? string.slice(1) : string;
export const ensureLeading = (char, string) => {
  if (string === char || string === '') {
    return '';
  }
  return string.charAt(0) !== char ? char + string : string;
};

export const localStorageData = {
  getItem: key => JSON.parse(localStorage.getItem(key)),
  setItem: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  removeItem: key => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

/**
 * url/history stuff
 */

export function cleanPath(path) {
  const transformedPath = path
    // .replace(/\/+$/, '') // Remove trailing slash
    .toLowerCase();
  return transformedPath === '' ? '/' : transformedPath; // Don't want to strip the root
}
// We cannot use `url instanceof Location` because location objects in IE
// are not instances of that constructor
function isLocation(url) {
  return 'hash' in url && 'pathname' in url && 'origin' in url;
}

const validUrlKeys = Object.keys(defaultUrlObject);
export function isUrlObject(url) {
  if (!url || typeof url !== 'object') {
    return false;
  }
  if ('path' in url && typeof url.path === 'string') {
    const otherKeys = Object.keys(url).filter(
      key => validUrlKeys.indexOf(key) === -1,
    );
    if (otherKeys.length === 0) {
      let validKeyTypes = true;
      if (url.query && typeof url.query !== 'object') {
        validKeyTypes = false;
      }
      if (url.hiddenQuery && typeof url.hiddenQuery !== 'object') {
        validKeyTypes = false;
      }
      return validKeyTypes;
    }
  }
  return false;
}

const reURLInformation = new RegExp(
  [
    '^(https?:)//', // protocol
    '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
    '(/{0,1}[^?#]*)', // pathname
    '(\\?[^#]*|)', // search
    '(#.*|)$', // hash
  ].join(''),
);
export function parseUrlStringToLocation(href) {
  const match = href.match(reURLInformation);
  return (
    match && {
      href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  );
}

export function parseUrl(
  url,
  event = 'push',
  cosmetic = false,
  noScrollReset = false,
) {
  let obj = { ...defaultUrlObject, event, cosmetic, noScrollReset };

  if (typeof url === 'string') {
    const parsed = parseUrlStringToLocation(
      url.charAt(0) === '/' ? `http://example.com${url}` : url,
    );
    obj = {
      ...obj,
      path: parsed.pathname,
      query: parsed.search ? qs.parse(parsed.search) : {},
      search: parsed.search,
      hash: parsed.hash || '',
    };
  } else if (url && isLocation(url)) {
    obj = {
      ...obj,
      path: url.pathname,
      query: qs.parse(url.search),
      search: url.search,
      hash: url.hash,
    };
  } else if (isUrlObject(url)) {
    // Url is already parsed but might not contain "query", "hash", "hiddenQuery", cosmetic, resetScroll and "event"
    obj = { ...obj, ...url };
  }

  // it's convenient to have a string representation of the (full) url
  obj.href = getFullUrl(urlToString(obj));

  return obj;
}

export function getFullUrl(path) {
  if (!window.location) {
    return path;
  }
  return `${location.protocol}//${location.hostname}${
    location.port && location.port !== '' ? `:${location.port}` : ''
  }${path}`;
}

export function urlToString(url, includeHiddenQuery = false) {
  let q = qs.stringify(url.query);
  if (includeHiddenQuery && url.hiddenQuery) {
    const hiddenQ = qs.stringify(url.hiddenQuery);
    q = (q === '' ? hiddenQ : `${q}&${hiddenQ}`).replace(/&$/, '');
  }
  return cleanPath(url.path) + (q === '' ? '' : `?${q}`);
}

export function areEqual(url1, url2, compareHiddenQuery = false) {
  let urlObject1 = null;
  let urlObject2 = null;
  if (typeof url1 === 'string') {
    urlObject1 = parseUrl(url1);
  } else {
    urlObject1 = url1;
  }
  if (typeof url2 === 'string') {
    urlObject2 = parseUrl(url2);
  } else {
    urlObject2 = url2;
  }
  if (urlObject1.path !== urlObject2.path) {
    return false;
  }
  if (qs.stringify(urlObject1.query) !== qs.stringify(urlObject2.query)) {
    return false;
  }
  if (
    compareHiddenQuery &&
    qs.stringify(urlObject1.hiddenQuery) !==
      qs.stringify(urlObject2.hiddenQuery)
  ) {
    return false;
  }
  return true;
}

export const appendQueryParams = (
  url,
  paramsObject,
  removeEmptyValues = false,
) => {
  let query = { ...url.query, ...paramsObject };
  if (removeEmptyValues) {
    query = Object.keys(query).reduce(
      (obj, key) => ({
        ...obj,
        ...(query[key] !== '' && { [key]: query[key] }),
      }),
      {},
    );
  }
  return parseUrl({ ...url, query });
};

export const isUrlForSamePage = (url1, url2) =>
  !!url1 &&
  !!url2 &&
  !!url1.path &&
  !!url2.path &&
  url1.path == url2.path &&
  url1.query[constants.get('productId')] ==
    url2.query[constants.get('productId')];
