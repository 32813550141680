import { START, END, MIDDLE, zones } from './constants';
import getRelativeBoundingClientRect from './getRelativeBoundingClientRect';
export default (anchorRect, containerRect) => {
  if (!anchorRect || !containerRect) {
    return [];
  }
  const relativeAnchorRect = getRelativeBoundingClientRect(
    anchorRect,
    containerRect,
  );

  // offset
  const ax = {
    [START]: relativeAnchorRect.left,
    [MIDDLE]: relativeAnchorRect.left + relativeAnchorRect.width / 2,
    [END]: relativeAnchorRect.right,
  };
  const ay = {
    [START]: relativeAnchorRect.top,
    [MIDDLE]: relativeAnchorRect.top + relativeAnchorRect.height / 2,
    [END]: relativeAnchorRect.bottom,
  };

  const dx = {
    [START]: ax => containerRect.width - ax,
    [MIDDLE]: ax =>
      Math.min(ax, containerRect.width - relativeAnchorRect.right) * 2 +
      relativeAnchorRect.width,
    [END]: ax => ax,
  };
  const dy = {
    [START]: ay => containerRect.height - ay,
    [MIDDLE]: ay =>
      Math.min(ay, containerRect.height - relativeAnchorRect.bottom) * 2 +
      relativeAnchorRect.height,
    [END]: ay => ay,
  };
  return zones.map(z => {
    let x = ax[z.ax];
    let y = ay[z.ay];
    let xWithin = x > 0 && x < containerRect.width;
    let yWithin = y > 0 && y < containerRect.height;
    return {
      ...z,
      x,
      y,
      isWithin: xWithin && yWithin,
      width: dx[z.dx](x),
      height: dy[z.dy](y),
    };
  });
};
