import { useEffect, useRef, useState, useCallback } from "react";
import { useResizeObserver } from "./use-resize-observer";

export const useSize = (nodeRef, active = true, onChange) => {
  const [size, setSize] = useState({});
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const onChangeRef = useRef(null);
  onChangeRef.current = onChange;
  const callback = useCallback(({ width, height }) => {
    const roundedWidth = Math.floor(width);
    const roundedHeight = Math.floor(height);
    if (
      roundedWidth !== widthRef.current ||
      roundedHeight !== heightRef.current
    ) {
      widthRef.current = roundedWidth;
      heightRef.current = roundedHeight;
      setSize({ width: roundedWidth, height: roundedHeight });
      if (onChangeRef.current) {
        onChangeRef.current({ width: roundedWidth, height: roundedHeight });
      }
    }
  }, []);
  useResizeObserver(nodeRef, callback, active);
  useEffect(() => {
    //console.log("fake resized");
    callback(nodeRef.current.getBoundingClientRect());
  }, [callback, nodeRef]);

  return size;
};
