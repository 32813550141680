import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import delve from 'packages/inmeta-epi-react/utils/delve';
import BlockWrapper from 'components/molecules/BlockWrapper';
import { teaserConfig } from 'components/organisms/TeaserList';
import { useColumnContext } from 'components/molecules/PageGrid';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

const TeaserList = React.lazy(() =>
  import(
    /* webpackChunkName: "teaserlist" */ /* webpackPreload: true */ 'components/organisms/TeaserList'
  ),
);

const LinkListBlock = ({ block: { header, linkBlocks } }) => {
  const columnContext = useColumnContext();
  const links = delve(propertyValueFor(linkBlocks), 'items', null);
  if (!links) {
    return null;
  }
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BlockWrapper block={{ title: header }} columnContext={columnContext}>
        {links.map(({ block }) => (
          <TeaserList
            config={teaserConfig.onlyLink}
            title={propertyValueFor(block.header)}
            items={
              propertyValueFor(block.links) &&
              propertyValueFor(block.links).map(({ linkText, ...item }) => ({
                ...item,
                title: linkText,
              }))
            }
          />
        ))}
      </BlockWrapper>
    </Suspense>
  );
};
LinkListBlock.displayName = 'LinkListBlock';
LinkListBlock.propTypes = {};
LinkListBlock.defaultProps = {};

export default LinkListBlock;
