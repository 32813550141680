import { useRef, useEffect, useCallback, useMemo } from "react";
import intervalTimer, { STATE_PAUSED, STATE_RUNNING } from "./interval-timer";

export default function useIntervalTimer() {
  const timerRef = useRef(null);
  const pause = useCallback(() => {
    if (timerRef.current) {
      timerRef.current.pause();
    }
  }, []);
  const stop = useCallback(() => {
    if (timerRef.current) {
      timerRef.current.stop();
    }
  }, []);
  const start = useCallback((callback, interval) => {
    if (timerRef.current) {
      timerRef.current.stop();
    }
    timerRef.current = new intervalTimer(callback, interval);
  }, []);
  const resume = useCallback(() => {
    if (timerRef.current) {
      timerRef.current.resume();
    }
  }, []);

  // always stop on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        timerRef.current.stop();
        timerRef.current = null;
      }
    };
  }, []);

  return useMemo(
    () => ({
      start,
      resume,
      stop,
      pause,
      isPaused: () =>
        timerRef.current && timerRef.current.state === STATE_PAUSED,
      isRunning: () =>
        timerRef.current && timerRef.current.state === STATE_RUNNING,
      id: Date.now()
    }),
    [start, resume, stop, pause]
  );
}
