import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuToggler from 'components/utilities/MenuToggler';
import Icon from 'components/atoms/Icon';
import {
  chevronDown,
  chevronUp,
  chevronLeft,
  chevronRight,
} from 'components/atoms/Icon/icons';
import { IS_BROWSER } from 'utils/bundle-helper';
import Button, {IconButton} from 'components/atoms/Button';
import * as styles from './Navigation.scss';

export const NavigationItem = ({
  label,
  url,
  isCurrent = false,
  children,
  showOnHover = false,
  className,
  linkClassName,
  iconClassName,
  wrapClassName,
  leftIcon,
  leftIconProps,
  menuLevel,
  renderChildren,
}) => {
  const openIcon = menuLevel > 0 ? chevronLeft : chevronUp;
  const closeIcon = menuLevel > 0 ? chevronRight : chevronDown;
  const hasChildren = !!children || !!renderChildren;
  const fullButton = !url && hasChildren;
  const shouldRender = !!url || !!hasChildren;
  if(!shouldRender) {
    return null;
  }
  return (
    <MenuToggler
      render={({
        hoverOpenerProps,
        clickOpenerProps,
        isOpen,
        ref,
        doClose,
        doOpen,
        doToggle,
      }) => (
        <li
          className={classnames(styles.item, className, {
            [styles.active]: isOpen,
            [styles.current]: isCurrent,
            [styles.hasChildren]: hasChildren,
          })}
          key={url || label}
          ref={ref}
          {...(showOnHover && hoverOpenerProps)}
        >
        {fullButton ? (
          <span className={classnames(styles.wrap, wrapClassName)}>
              <Button
                transparent
                className={classnames(styles.button, linkClassName)}
                {...clickOpenerProps}
                aria-label="Open menu"
              >
                <span className={styles.inner}>
                  {!!leftIcon && (
                    <Icon {...leftIconProps} offsetRight icon={leftIcon} />
                  )}
                  <span className={styles.label}>
                    {label}
                  </span>
                  <Icon icon={isOpen ? openIcon : closeIcon} />
                </span>
              </Button>
          </span>
        ) : (
          <span className={classnames(styles.wrap, wrapClassName)}>
            <a href={url} className={classnames(styles.link, linkClassName)}>
              {!!leftIcon && (
                <Icon {...leftIconProps} offsetRight icon={leftIcon} />
              )}
              {label}
            </a>
            {IS_BROWSER && hasChildren && (
              <Button
                transparent
                className={classnames(styles.linkIcon, iconClassName)}
                {...clickOpenerProps}
                aria-label="Open menu"
              >
                <Icon icon={isOpen ? openIcon : closeIcon} />
              </Button>
            )}
          </span>
          )}
          {renderChildren
            ? renderChildren({ isOpen, doClose, doOpen, doToggle }) : null}
          {!renderChildren && !!children && isOpen
            ? children : null}
        </li>
      )}
    />
  );
};
NavigationItem.propTypes = {
  label: PropTypes.any,
  url: PropTypes.string,
  isCurrent: PropTypes.bool,
  showOnHover: PropTypes.bool,
  children: PropTypes.node,
  leftIcon: PropTypes.string,
  leftIconProps: PropTypes.object,
  menuLevel: PropTypes.number,
  renderChildren: PropTypes.func,
};
NavigationItem.defaultProps = {
  isCurrent: false,
  showOnHover: false,
  leftIcon: null,
  menuLevel: 0,
};

export const Navigation = ({ direction = 'vertical', children, className }) => (
  <ul
    className={classnames(styles.navigation, className, {
      [styles.vertical]: direction === 'vertical',
      [styles.horizontal]: direction === 'horizontal',
    })}
  >
    {children}
  </ul>
);
Navigation.displayName = 'Navigation';
Navigation.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  children: PropTypes.node,
  className: PropTypes.string,
};
Navigation.defaultProps = {
  direction: 'vertical',
};

Navigation.Item = NavigationItem;

export default Navigation;
