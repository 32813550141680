import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dlv from 'dlv';

import Button from 'components/atoms/Button';
import Container from 'components/atoms/Container';
import Toggle from 'components/utilities/Toggle';
import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';
import CurrentSectionIndicator from 'components/molecules/CurrentSectionIndicator';
import MyPageSection from 'components/organisms/MyPageSection';

import MyPageSectionUpcomingEvents from 'components/organisms/MyPageSectionUpcomingEvents';
import MyPageSectionActivitiesAndAttractions from 'components/organisms/MyPageSectionActivitiesAndAttractions';
import MyPageSectionInspiration from 'components/organisms/MyPageSectionInspiration';
import MyPageSectionMap from 'components/organisms/MyPageSectionMap';
import MyPageSectionAccomodation from 'components/organisms/MyPageSectionAccomodation';
import MyPageSectionFoodAndDrink from 'components/organisms/MyPageSectionFoodAndDrink';

import useSiteSettings from 'hooks/use-site-settings';

import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';

import { translate } from 'utils/translate';

import {
  CATEGORY_ACCOMMODATION,
  CATEGORY_INSPIRATION,
  CATEGORY_EVENT,
  CATEGORY_LOCATION,
  CATEGORY_FOODANDDRINK,
  CATEGORY_OTHER,
  CATEGORY_DEFAULT,
  categoryMap,
} from './constants';

const categoryList = [
  CATEGORY_EVENT,
  CATEGORY_INSPIRATION,
  CATEGORY_ACCOMMODATION,
  CATEGORY_FOODANDDRINK,
  CATEGORY_LOCATION,
  CATEGORY_OTHER,
];

const renderCategory = {
  [CATEGORY_EVENT]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/events/title')}
      color="#ffffff"
    >
      <ToggleListItems
        items={events}
        count={5}
        render={MyPageSectionUpcomingEvents}
        toggleButton={ToggleButton}
      />
    </MyPageSection>
  ),
  [CATEGORY_INSPIRATION]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/inspiration/title')}
      color="#F4EDFC"
    >
      <ToggleListItems
        items={events}
        count={5}
        render={MyPageSectionInspiration}
        toggleButton={ToggleButton}
      />
    </MyPageSection>
  ),
  [CATEGORY_LOCATION]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/locations/title')}
      color="#EDF9FC"
    >
      <ToggleListItems
        items={events}
        count={5}
        render={MyPageSectionActivitiesAndAttractions}
        toggleButton={ToggleButton}
      />
    </MyPageSection>
  ),
  [CATEGORY_FOODANDDRINK]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/foodanddrinks/title')}
      color="#FFFFFF"
      titleBackground={events[0].imageUrl || null}
    >
      <ToggleListItems
        items={events}
        count={6}
        toggleButton={ToggleButton}
        render={MyPageSectionFoodAndDrink}
      />
    </MyPageSection>
  ),
  [CATEGORY_ACCOMMODATION]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/accommodations/title')}
      color="#EEFCED"
    >
      <ToggleListItems
        items={events}
        count={5}
        render={MyPageSectionAccomodation}
        toggleButton={ToggleButton}
      />
    </MyPageSection>
  ),
  [CATEGORY_OTHER]: events => (
    <MyPageSection
      title={translate('/pages/myPage/sections/other/title')}
      color="#FFFFFF"
    >
      <ToggleListItems
        items={events}
        count={4}
        render={MyPageSectionActivitiesAndAttractions}
        toggleButton={ToggleButton}
      />
    </MyPageSection>
  ),
};

const getCategory = article =>
  categoryMap[article.productType] || CATEGORY_DEFAULT;

const ToggleListItems = ({
  items,
  count = 0,
  toggleButton: ToggleButton,
  render: View,
}) => {
  const useToggle = count !== 0 && items.length > count;
  const useToggleButton = useToggle && !!ToggleButton;
  return !items.length ? null : (
    <Toggle
      on={!useToggle}
      render={({ on, toggle }) => (
        <React.Fragment>
          <View items={on ? items : items.slice(0, count)} />
          {useToggleButton && <ToggleButton toggle={toggle} on={on} />}
        </React.Fragment>
      )}
    />
  );
};

const ToggleButton = ({ toggle, on }) => (
  <Container centerText style={{ padding: '40px 0 0 0' }}>
    <Button ghost onClick={toggle}>
      {on
        ? translate('/pages/myPage/showLessButton')
        : translate('/pages/myPage/showMoreButton')}
    </Button>
  </Container>
);

const MyPageView = ({
  favoriteItems,
  profileHeader,
  profileBioSection,
  children,
  onClearFavorites,
  productPageUrl,
  showProfileHeader,
}) => {
  const { culture, siteSkin } = useSiteSettings();

  const fixedFavoriteItems = useMemo(
    () =>
      favoriteItems.filter(article => !!article.url).map(article => ({
        ...article,
        url: article.tellusId ? `${productPageUrl}${article.url}` : article.url,
      })),
    [favoriteItems],
  );
  const categorizedArticles = useMemo(
    () =>
      fixedFavoriteItems.reduce(
        (categories, article) => ({
          ...categories,
          [getCategory(article)]: [
            ...(categories[getCategory(article)] || []),
            article,
          ],
        }),
        categoryList.reduce(
          (obj, categoryName) => ({ ...obj, [categoryName]: [] }),
          {},
        ),
      ),
    [fixedFavoriteItems],
  );
  const mapFavoriteItems = fixedFavoriteItems.filter(
    item => !!item.geoLocation,
  );

  return (
    <DefaultLayoutContainerV2 noBreadcrumbs noSticky>
      {showProfileHeader && profileHeader}
      {profileBioSection}
      {children}
      {!!fixedFavoriteItems.length && (
        <React.Fragment>
          {Object.keys(categorizedArticles).map(
            category =>
              categorizedArticles[category].length
                ? renderCategory[category](categorizedArticles[category])
                : null,
          )}

          {!!mapFavoriteItems.length && (
            <MyPageSection
              title={translate('/pages/myPage/sections/map/title')}
              color="#ffffff"
            >
              <MyPageSectionMap items={mapFavoriteItems} />
            </MyPageSection>
          )}

          {!!onClearFavorites && (
            <Container centerText style={{ padding: '40px 0' }}>
              <Button ghost onClick={onClearFavorites}>
                {translate('/pages/myPage/clearFavoritesButton')}
              </Button>
            </Container>
          )}
          <BreakpointContainer
            gte={BREAKPOINT_TABLET_PORTRAIT}
            match={() => <CurrentSectionIndicator />}
          />
        </React.Fragment>
      )}
    </DefaultLayoutContainerV2>
  );
};
MyPageView.displayName = 'MyPageView';
MyPageView.propTypes = {
  favoriteItems: PropTypes.array,
  profileHeader: PropTypes.node,
  profileBioSection: PropTypes.node,
  children: PropTypes.node,
  onClearFavorites: PropTypes.func,
  productPageUrl: PropTypes.string,
  showProfileHeader: PropTypes.bool,
};
MyPageView.defaultProps = {
  favoriteItems: [],
};

export default MyPageView;
