import React from "react";
import PropTypes from "prop-types";
import { parseUrl, urlToString } from "../../utils/url";
import { getSizes, SizeType } from "./ImageSizes";
import preload, {
  hasOtherSizeLoaded,
  getBestCachedImageUrl,
  registerLoaded
} from "./preload";

// TODO: add a base64 loader to load svg image as base64
const noImage =
  "data:image/svg+xml;base64,PHN2ZyB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHZpZXdCb3g9IjAgMCA0ODAgNDgwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjMDAwIiBvcGFjaXR5PSIuMDc1Ij48L3JlY3Q+PGcgc3Ryb2tlPSIjZmZmIiBvcGFjaXR5PSIuMTc1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC01NzIuMzYpIiBzdHJva2Utd2lkdGg9IjVweCI+PHBhdGggZD0ibTAuNDk3NDggMTA1MS45YzQ3OS00NzkuMDQgNDc5LTQ3OS4wNCA0NzktNDc5LjA0Ii8+PHBhdGggZD0ibTAuNDk3NDggNTcyLjg2YzQ3OSA0NzkuMDQgNDc5IDQ3OS4wNCA0NzkgNDc5LjA0Ii8+PC9nPjwvc3ZnPg=="; //'<svg xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" viewBox="0 0 480 480" preserveAspectRatio="none"><rect width="100%" height="100%" fill="#000" opacity=".075"></rect><g stroke="#fff" opacity=".175" transform="translate(0 -572.36)" stroke-width="5px"><path d="m0.49748 1051.9c479-479.04 479-479.04 479-479.04"/><path d="m0.49748 572.86c479 479.04 479 479.04 479 479.04"/></g></svg>'; //require('styles/images/noimage.svg');

export function srcset(urlString, requestedSize = null, maxSize = null) {
  const srcset = [];
  const sizes = getSizes();
  Object.keys(sizes).forEach(size => {
    if (
      (!requestedSize || sizes[size] >= sizes[requestedSize]) &&
      (!maxSize || sizes[size] <= sizes[maxSize])
    ) {
      const url = parseUrl(urlString);
      url.query["w"] = sizes[size];
      url.query["h"] = sizes[size];
      url.query["mode"] = "max";
      srcset.push(urlToString(url) + " " + sizes[size] + "w");
    }
  });
  return srcset.join(", ");
}

export default class ImageFallback extends React.Component {
  static defaultProps = {
    size: "original"
  };
  constructor(props) {
    super(props);

    this.state = {
      useCachedImagePlaceHolder:
        props.preferSmallerIfCached && hasOtherSizeLoaded(props.src, props.size)
    };
  }
  onLoad(e) {
    const image = e.target;
    setCachedIfLoaded(image, this.props.src, this.props.size);
  }
  sizes() {
    const sizes = getSizes();
    if (this.props.size !== "original") {
      return sizes[this.props.size] + "px";
    } else {
      return null;
    }
  }
  srcSet() {
    if (this.props.size !== "original") {
      return srcset(this.props.src, this.props.size, this.props.maxSize);
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (this.state.useCachedImagePlaceHolder) {
      preload(this.props.src, this.props.size, this.srcSet()).then(() => {
        if (this.state.useCachedImagePlaceHolder) {
          this.setState({ useCachedImagePlaceHolder: false });
        }
      });
    }
  }
  render() {
    const newProps = Object.assign({}, this.props);
    delete newProps.size;
    delete newProps.maxSize;
    delete newProps.preferSmallerIfCached;

    const requestedImageSize = getSizes()[this.props.size];

    if (!this.props.src) {
      return (
        <img
          {...newProps}
          src={noImage}
          style={{
            maxWidth: requestedImageSize,
            maxHeight: requestedImageSize
          }}
        />
      );
    }

    if (this.state.useCachedImagePlaceHolder) {
      return (
        <img
          {...newProps}
          src={getBestCachedImageUrl(newProps.src)}
          onLoad={e => {
            const image = e.target;
            if (this.props.preferSmallerIfCached) {
              image.style[image.height > image.width ? "height" : "width"] =
                "100%";
            }
          }}
          style={{
            maxWidth: requestedImageSize,
            maxHeight: requestedImageSize
          }}
        />
      );
    } else {
      return (
        <img
          {...newProps}
          className={newProps.className}
          srcSet={this.srcSet()}
          sizes={this.sizes()}
          onLoad={e => this.onLoad(e)}
          ref={img =>
            img && setCachedIfLoaded(img, this.props.src, this.props.size)
          }
          onError={e => onError(e)}
        />
      );
    }
  }
}
ImageFallback.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  itemProp: PropTypes.string,
  size: PropTypes.string,
  preferSmallerIfCached: PropTypes.bool,
  maxSize: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.object
};

function setCachedIfLoaded(img, originalSrc, size) {
  // If it's `noImage`
  if (!originalSrc) {
    return;
  }

  if (imageHasLoaded(img)) {
    registerLoaded(originalSrc, size);
  }
}

function onError(e) {
  const img = e.target;
  img.src = noImage;
  img.srcset = "";
  img.sizes = "";
  img.style.background = "rgba(255,0,0,.125)";
}

function imageHasLoaded(img) {
  if (!img.complete) {
    return false;
  }

  if (typeof img.naturalWidth !== "undefined" && img.naturalWidth === 0) {
    return false;
  }

  // No other way of checking: assume it’s ok.
  return true;
}
