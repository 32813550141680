import React from 'react';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';
import PageView from 'components/organisms/PageView';

/**
 * This is `StartPage`, and this is a description for it
 */
const StartPageV2 = page => (
  <DefaultLayoutContainerV2>
    <PageView
      useFullscreenSlider
      useStartPageLayout
      noBreadcrumbs
      isV2design
      {...page}
    />
  </DefaultLayoutContainerV2>
);
StartPageV2.displayName = 'StartPageV2';
StartPageV2.propTypes = {};
StartPageV2.defaultProps = {};

export default StartPageV2;
