import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import Image from 'components/atoms/Image';
import Heading, { H1 } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import { trimIntro } from 'components/molecules/Teaser/Teaser';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import getRandomIntBetween from 'utils/getRandomIntBetween';

import FavouriteButtonContainer from 'containers/molecules/FavouriteButtonContainer';
import * as styles from './TeaserWall.scss';

const Masonry = React.lazy(() =>
  import(
    /* webpackChunkName: "masonry" */ /* webpackPreload: true */ 'components/organisms/Masonry'
  ),
);
const MasonryItem = React.lazy(() =>
  import(
    /* webpackChunkName: "masonry" */ /* webpackPreload: true */ 'components/organisms/Masonry/MasonryItem'
  ),
);

class TeaserWall extends React.Component {
  constructor(props) {
    super(props);
    this.sizeMap = {};
  }
  // 0,5,10
  addSize(item, i) {
    return (this.sizeMap[item.id] =
      getRandomIntBetween(0, 4) === 4 ? 'large' : 'small');
  }
  render() {
    const { items, showMunicipality, showFavoriteButton } = this.props;

    if (items.length <= 0) {
      return null;
    }

    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Masonry className={styles.masonry}>
          {items
            .filter(item => !!item.imageUrl)
            .map((item, i) => (
              <MasonryItem
                key={item.id}
                ratio={[4, 3]}
                large={
                  (this.sizeMap[item.id] || this.addSize(item, i)) === 'large'
                }
              >
                <article className={styles.wallItem}>
                  {showFavoriteButton && item.myFavoritesId && (
                    <FavouriteButtonContainer
                      identifier={item.myFavoritesId}
                      compact
                    />
                  )}
                  <Link href={item.url} className={styles.link}>
                    <Image
                      src={item.imageUrl}
                      className={styles.image}
                      ratio={[4, 3]}
                      fit="cover"
                      cropDetails={item.cropDetails}
                      lazy={{ offset: 300 }}
                    />
                    <div className={styles.textWrap}>
                      <H1
                        alt
                        size={Heading.sizes.heading5}
                        className={styles.title}
                      >
                        {item.title}
                      </H1>
                      {showMunicipality &&
                        item.address &&
                        item.address.postalName && (
                          <Paragraph
                            className={classnames(
                              styles.intro,
                              styles.additionalIntro,
                            )}
                          >
                            {item.address.postalName}
                          </Paragraph>
                        )}
                      {item.intro && (
                        <Paragraph className={styles.intro}>
                          {trimIntro(item.intro, item.title)}
                        </Paragraph>
                      )}
                    </div>
                  </Link>
                </article>
              </MasonryItem>
            ))}
        </Masonry>
      </Suspense>
    );
  }
}
TeaserWall.displayName = 'TeaserWall';
TeaserWall.propTypes = {
  showMunicipality: PropTypes.bool.isRequired,
  showFavoriteButton: PropTypes.bool,
};
TeaserWall.defaultProps = {
  showFavoriteButton: false,
};

export default TeaserWall;
