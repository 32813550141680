import React from 'react';
import PropTypes from 'prop-types';

import { Title } from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import { withColumnContext } from 'components/molecules/PageGrid';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import { median } from 'utils/utils';
import classnames from 'classnames';

import * as styles from './WeatherBlock.scss';

const Forecast = props => {
  const {
    days,
    settings = {
      maxAmount: 100,
      date: {
        locales: ['no-bok'],
        formate: {
          hour: '2-digit',
          minute: '2-digit',
        },
      },
    },
  } = props;

  return (
    <div className={styles.forecast}>
      {days &&
        days.map(day => (
          <div>
            <div className={styles.dateTitle}>
              <time dateTime={new Date(day.timeNodes[0].from)}>
                {new Date(day.timeNodes[0].from).toLocaleDateString(
                  settings.date.locales,
                )}
              </time>
            </div>
            <ul className={styles.weatherList}>
              {day.timeNodes.map(item => (
                <li className={styles.weatherItem}>
                  <p className={styles.weatherTitle}>
                    <time dateTime={new Date(item.from)}>
                      {new Date(item.from).toLocaleTimeString(
                        settings.date.locales,
                        settings.date.formate,
                      )}
                    </time>
                    <span> - </span>
                    <time dateTime={new Date(item.to)}>
                      {new Date(item.to).toLocaleTimeString(
                        settings.date.locales,
                        settings.date.formate,
                      )}
                    </time>
                  </p>
                  <Weather
                    symbol={item.symbolUrl && item.symbolUrl}
                    temperature={Math.trunc(
                      median([item.minTemperature, item.maxTemperature]),
                    )}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

Forecast.propTypes = {
  days: PropTypes.any,
};

const Weather = props => {
  const { symbol, temperature } = props;
  return (
    <div className={styles.weatherInfo}>
      {symbol && <Image src={symbol} className={styles.weatherImage} />}
      {temperature && (
        <strong className={styles.temperature}>
          {temperature}
          <sup>°</sup>
        </strong>
      )}
    </div>
  );
};

Weather.propTypes = {
  symbol: PropTypes.object,
  temperature: PropTypes.object,
};

const WeatherBlock = props => {
  const { block, forecast, columnContext } = props;

  const title = propertyValueFor(block.title);
  const bodyText = propertyValueFor(block.bodyText);
  const showDetails = propertyValueFor(block.showDetails);
  const readMoreLink = propertyValueFor(block.readMoreLink);
  const readMoreText = propertyValueFor(block.readMoreText);

  return !!forecast ? (
    <div
      className={classnames(styles.contentBox, styles.lTopGutter, {
        [styles.contentBoxWithDetails]: showDetails,
        [styles.contextMain]: columnContext === 'main',
        [styles.contextMainLeft]: columnContext === 'main-left',
        [styles.contextMainRight]: columnContext === 'main-right',
        [styles.contextAside]: columnContext === 'aside',
      })}
    >
      {title && (
        <Title className={styles.sectionSecondaryHeading}>{title}</Title>
      )}
      {bodyText && <p className={styles.weatherParagraph}>{bodyText}</p>}
      {showDetails ? (
        <Forecast days={forecast.days} />
      ) : (
        <Weather
          symbol={forecast && forecast.symbolUrl ? forecast.symbolUrl : ''}
          temperature={
            forecast && forecast.currentTemperature
              ? forecast.currentTemperature
              : ''
          }
        />
      )}
      {readMoreLink && (
        <a href={readMoreLink} className={styles.moreInfoLink}>
          {readMoreText}
        </a>
      )}
    </div>
  ) : null;
};
WeatherBlock.displayName = 'WeatherBlock';
WeatherBlock.propTypes = {
  block: PropTypes.any,
  forecast: PropTypes.any,
  columnContext: PropTypes.any,
};
WeatherBlock.defaultProps = {};

export default withColumnContext(WeatherBlock);
