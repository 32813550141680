import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'redux-bundler-react';
import FavouriteButton from 'components/molecules/FavouriteButton';

const FavouriteButtonContainer = ({
  userFavorites = [],
  identifier,
  doAddFavorite,
  doRemoveFavorite,
  className,
  isShort = false,
  compact = false,
  colorTheme = { color: null, hasWhiteFont: false },
}) => {
  const isFavorite = !!(
    userFavorites.includes(`${identifier}`) ||
    userFavorites.includes(identifier)
  ); // Also check for numerical favorite-ids
  return (
    <FavouriteButton
      className={className}
      isFavourite={isFavorite}
      isShort={isShort}
      compact={compact}
      onChange={() =>
        isFavorite ? doRemoveFavorite(identifier) : doAddFavorite(identifier)
      }
      colorTheme={colorTheme}
    />
  );
};

FavouriteButtonContainer.displayName = 'FavouriteButtonContainer';
FavouriteButtonContainer.propTypes = {
  userFavorites: PropTypes.array,
  identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  doAddFavorite: PropTypes.func,
  doRemoveFavorite: PropTypes.func,
  className: PropTypes.any,
  isShort: PropTypes.bool,
  compact: PropTypes.bool,
  colorTheme: PropTypes.object,
};

export default connect(
  'selectUserFavorites',
  'doAddFavorite',
  'doRemoveFavorite',
  FavouriteButtonContainer,
);
