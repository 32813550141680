import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { svgIcons, icomoonIcons } from 'utils/iconLookUp';
import * as styles from './Icon.scss';

const Icon = React.forwardRef(
  (
    {
      className,
      name,
      icon,
      title,
      spin,
      adjustCenter,
      offsetRight,
      offsetLeft,
      size = ['1em', '1em'],
      ...props
    },
    ref,
  ) => {
    if (name && svgIcons.includes(name))
      return (
        <svg
          aria-hidden="true"
          viewBox="0 0 1024 1024"
          width={size[0]}
          height={size[1]}
          ref={ref}
          {...props}
          className={classnames(styles.icon, className, {
            [styles.spin]: spin,
            [styles.adjustCenter]: adjustCenter,
            [styles.offsetRight]: offsetRight,
            [styles.offsetLeft]: offsetLeft,
          })}
        >
          {!!title && <title>{title}</title>}
          <use xlinkHref={`#${name}`} />
        </svg>
      );

    const resolvedIcon = name ? icomoonIcons?.[name] : icon;
    if (resolvedIcon)
      return (
        <svg
          viewBox="0 0 1024 1024"
          width={size[0]}
          height={size[1]}
          ref={ref}
          {...props}
          className={classnames(styles.icon, className, {
            [styles.spin]: spin,
            [styles.adjustCenter]: adjustCenter,
            [styles.offsetRight]: offsetRight,
            [styles.offsetLeft]: offsetLeft,
          })}
        >
          {!!title && <title>{title}</title>}
          <path d={resolvedIcon.path} fill="currentColor" />
        </svg>
      );
    console.warn(`No icon match found for: ${name} or ${icon}`);
    return null;
  },
);

export const iconPropType = PropTypes.shape({
  path: PropTypes.string.isRequired,
  name: PropTypes.string,
  source: PropTypes.string,
});

Icon.displayName = 'Icon';
Icon.propTypes = {
  className: PropTypes.string,
  icon: iconPropType,
  name: PropTypes.string,
  title: PropTypes.string,
  spin: PropTypes.bool,
  adjustCenter: PropTypes.bool,
  offsetRight: PropTypes.bool,
  offsetLeft: PropTypes.bool,
  size: PropTypes.array,
};
Icon.defaultProps = {
  spin: false,
  adjustCenter: false,
  offsetRight: false,
  offsetLeft: false,
};

export default Icon;
