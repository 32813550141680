import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { withColumnContext } from 'components/molecules/PageGrid';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

const Image = React.lazy(() =>
  import(
    /* webpackChunkName: "image" */ /* webpackPreload: true */ 'components/atoms/Image'
  ),
);

const ImageBlock = props => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Image src={props.src} />
    </Suspense>
  );
};
ImageBlock.displayName = 'ImageBlock';
ImageBlock.propTypes = {
  src: PropTypes.string
};
ImageBlock.defaultProps = {};

export default withColumnContext(ImageBlock);
