import React from 'react';
import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { isUrlForSamePage } from 'utils/bundle-helper';

const usePageChange = (url, onChange) => {
    const urlRef = useRef(null);
    // console.log("GTMPageViewTracker", props);
    useEffect(() => {
        if(!urlRef.current || !isUrlForSamePage(url, urlRef.current)) {
            urlRef.current = url;
            // script
            onChange(url);
        }
    }, [url, onChange])
}


const GTMPageViewTracker = props => {
    const {
        url,
        meta
    } = props;
    usePageChange(url, url => {
        global.dataLayer = global.dataLayer || [];
        global.dataLayer.push({
        'event': 'Pageview',
        'pagePath': url.href,
        'pageTitle': meta.title
        });
    });
    
    return null
};
GTMPageViewTracker.displayName = "GTMPageViewTracker";
GTMPageViewTracker.propTypes = {};
GTMPageViewTracker.defaultProps = {};


export default GTMPageViewTracker