import React from 'react';
import { connect } from 'redux-bundler-react';

const SiteSettingsContainer = ({ settingsRaw, children }) => {
  return children(settingsRaw);
};

export default connect(
  'selectSettingsRaw',
  SiteSettingsContainer,
);
