import React from 'react';
import { connect } from 'redux-bundler-react';
import classnames from 'classnames';
import { withColumnContext } from 'components/molecules/PageGrid';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import PropTypes from 'prop-types';
import { H2 } from 'components/atoms/Heading';

import * as styles from './FacilityMatrixBlock.scss';

const FacilityMatrixBlock = ({
  block,
  columnNames,
  facilityMatrixItems,
  productPageLink,
}) => {
  const bodyText = propertyValueFor(block.bodyText);
  const firstColumnHeading = propertyValueFor(block.firstColumnHeading);
  const title = propertyValueFor(block.title);
  const columns = propertyValueFor(facilityMatrixItems);

  return (
    <div
      id="FacilityMatrixContentBlock"
      className={classnames(styles.eventList, styles.facilityList)}
    >
      {title && <H2 className={styles.title}>{title}</H2>}
      {bodyText && <p>{bodyText}</p>}
      <div className={styles.swipeInfo}>
        {/* <%= TranslationKeys.Product.SwipeToScroll.GetString() %>  */}
        <span className={styles.iconScroll} />
      </div>
      <div className={styles.tableWrapper}>
        <div className={classnames(styles.scrollable, styles.doubleScroll)}>
          <table className={classnames(styles.table, styles.responsive)}>
            <thead>
              <tr style={{ height: '76px' }}>
                {/* <th>
                  <EPiServer:Property ID="Property2" CssClass="secondary-heading" PropertyName="<%# _(m => m.FirstColumnHeading) %>" runat="server" />
                </th> */}
                {columnNames &&
                  columnNames.map(column => (
                    <th key={column.id}>
                      <div className={styles.data}>{column.name}</div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {facilityMatrixItems &&
                facilityMatrixItems.map((facility, index) => (
                  <tr
                  // style={
                  //   columnHeights[index]
                  //     ? { height: `${columnHeights[index]}px` }
                  //     : null
                  // }
                  >
                    <td style={{ display: 'none' }}>
                      <a href={facility.productUrl}>{facility.productName}</a>
                    </td>
                    {facility.facilityItems.map(item => (
                      <td>
                        <div className={styles.data}>
                          <span>{item.comment}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pinned}>
          <table className={styles.table}>
            {/*ref={this.colRef}>*/}
            <thead>
              <tr style={{ height: '76px' }}>
                {columns &&
                  columns.map(item => (
                    <th style={{ display: 'none' }}>
                      <div className={styles.data}>
                        <span>{item.productName}</span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {facilityMatrixItems &&
                facilityMatrixItems.map(facility => (
                  <tr>
                    <td>
                      <a href={`${productPageLink}${facility.productUrl}`}>
                        {facility.productName}
                      </a>
                    </td>
                    {facility.facilityItems.map(item => (
                      <td style={{ display: 'none' }}>
                        <div className={styles.data}>
                          <span>{item.comment}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

FacilityMatrixBlock.displayName = 'FacilityMatrixBlock';
FacilityMatrixBlock.propTypes = {
  block: PropTypes.any,
  columnNames: PropTypes.array,
  facilityMatrixItems: PropTypes.array,
};
FacilityMatrixBlock.defaultProps = {};

export default connect(
  'selectProductPageLink',
  withColumnContext(FacilityMatrixBlock),
);
