import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './Container.scss';

export const getContainerProps = ({
  full,
  wide,
  narrow,
  relative,
  centerText,
  className,
  noPadding,
  style,
  ...props
}) => ({
  ...props,
  className: classnames(styles.container, className, {
    [styles.isFull]: full,
    [styles.isWide]: wide,
    [styles.isRelative]: relative,
    [styles.centerText]: centerText,
    [styles.noPadding]: noPadding,
    [styles.isNarrow]: narrow,
  }),
  style,
});

const Container = ({ children, ...props }) => (
  <div {...getContainerProps(props)}>{children}</div>
);
Container.displayName = 'Container';
Container.propTypes = {
  noPadding: PropTypes.bool,
  full: PropTypes.bool,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
  centerText: PropTypes.bool,
  relative: PropTypes.bool,
};
Container.defaultProps = {
  noPadding: false,
  full: false,
  wide: false,
  relative: false,
  narrow: false,
};

export default Container;
