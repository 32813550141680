import { createSelector } from 'redux-bundler';
import { createCache } from 'utils/cache';
import { PAGE_CACHE_HIT } from './create-page-bundle';
import { parseUrl } from '../utils/bundle-helper';

export const PAGE_PRODUCTS_CLEAR = 'PAGE_PRODUCTS_CLEAR';
export const PAGE_PRODUCTS_SET = 'PAGE_PRODUCTS_SET';
export const PAGE_PRODUCTS_FETCH_START = 'PAGE_PRODUCTS_FETCH_START';
export const PAGE_PRODUCTS_FETCH_SUCCESS = 'PAGE_PRODUCTS_FETCH_SUCCESS';
export const PAGE_PRODUCTS_FETCH_ERROR = 'PAGE_PRODUCTS_FETCH_ERROR';

export const cache = createCache(false);

function cacheKey(urlOrPath) {
  const url = parseUrl(urlOrPath);
  const { query } = url;
  // remove view from query
  delete query.view;
  return parseUrl({ ...url, query }).href;
}

const defaultProductResponse = {
  totalResults: 0,
  offset: 0,
  count: 0,
  products: [],
};
const initialState = {
  isFetching: false,
  hasMoreProducts: false,
  data: {
    ...defaultProductResponse,
  },
};

export const createPageProductsDataFromProductResponse = (
  path,
  productResponse = defaultProductResponse,
  mergeState = defaultProductResponse,
) =>
  cache.set(cacheKey(path), {
    totalResults: productResponse.totalResults,
    offset: productResponse.offset,
    count: productResponse.count,
    products: [
      ...(mergeState.products || []),
      ...(productResponse.products || []),
    ],
    hasMoreProducts:
      productResponse.offset + productResponse.count <
      productResponse.totalResults,
  });

let lastFetch;

export default {
  name: 'pageProducts',
  getReducer: () => (state = initialState, action) => {
    switch (action.type) {
      case PAGE_PRODUCTS_CLEAR:
        return { ...initialState };

      case PAGE_CACHE_HIT: {
        const data = cache.get(cacheKey(action.url));
        if (data) {
          return {
            ...initialState,
            data,
            isFetching: false,
          };
        }
        return { ...initialState };
      }

      case PAGE_PRODUCTS_SET: {
        return {
          ...initialState,
          data: createPageProductsDataFromProductResponse(
            action.path,
            action.productResponse,
          ),
          isFetching: false,
        };
      }
      case PAGE_PRODUCTS_FETCH_START: {
        return {
          ...state,
          isFetching: true,
          error: false,
        };
      }
      case PAGE_PRODUCTS_FETCH_SUCCESS: {
        return {
          ...state,
          data: createPageProductsDataFromProductResponse(
            action.path,
            action.productResponse,
            state.data,
          ),
          isFetching: false,
        };
      }
      case PAGE_PRODUCTS_FETCH_ERROR: {
        return {
          ...state,
          isFetching: false,
          error: action.error,
        };
      }

      default:
        return state;
    }
  },
  selectPageProductsRaw: state => state.pageProducts,
  selectPageProductsData: createSelector(
    'selectPageProductsRaw',
    productsRaw => productsRaw.data,
  ),
  selectPageProducts: createSelector(
    'selectPageProductsData',
    productsData => productsData.products,
  ),
  selectPageProductsOffset: createSelector(
    'selectPageProductsData',
    productsData => productsData.offset,
  ),

  doFetchMorePageProducts: () => ({ dispatch, apiFetch, store }) => {
    const { currentLanguage, currentPageId } = store.selectPageData();
    const { offset, count } = store.selectPageProductsData();
    const url = store.selectPageUrl();
    const timestamp = Date.now();
    lastFetch = timestamp;
    dispatch({ type: PAGE_PRODUCTS_FETCH_START });
    apiFetch(
      `/api/productlistapi/getproducts/?pageId=${currentPageId}&offset=${offset +
        count}&language=${currentLanguage}${
        url.query.AreaIds ? `&areaIds=${url.query.AreaIds}` : ''
      }`,
    ).then(data => {
      if (timestamp === lastFetch) {
        dispatch({
          type: PAGE_PRODUCTS_FETCH_SUCCESS,
          path: url.path,
          productResponse: data,
        });
      }
    });
  },

  doSetPageProducts: (path, productResponse) => ({ dispatch }) => {
    // only set if new url? ... maybe maybe
    dispatch({
      type: PAGE_PRODUCTS_SET,
      path,
      productResponse: {
        ...defaultProductResponse,
        ...productResponse,
      },
    });
  },
};
