import React from "react";
import PropTypes from "prop-types";
import { translate } from 'utils/translate';
import * as styles from "./SkipToMainContent.scss";

const SkipToMainContent = ({ targetId }) => (
    <a
    href={`#${targetId}`}
    data-reload
    className={styles.SkipToMainContent}
  >
    {translate('/global/skipToContent')}
  </a>
);

SkipToMainContent.displayName = "SkipToMainContent";
SkipToMainContent.propTypes = {
  targetId: PropTypes.string.isRequired,
};
SkipToMainContent.defaultProps = {
  targetId: "#main-content"
};

export default SkipToMainContent;
