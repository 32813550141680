import React, { useMemo, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button/Button';
import Container from 'components/atoms/Container/Container';
import { H2 } from 'components/atoms/Heading';
import Block, {
  ratioPropType,
  getRatioStyle,
} from 'components/atoms/Block/Block';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import { chevronLeft, chevronRight } from 'components/atoms/Icon/icons';
import { BREAKPOINT_PHONE, BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
import BreakpointContainer from 'containers/BreakpointContainer';
import ConditionalWrap from 'components/utilities/ConditionalWrap';
import VideoPlayer from 'components/utilities/VideoPlayer';
import useThemeConfig from 'hooks/use-theme-config';
import { stripHtml } from 'utils/utils';
import LoadingIndicator from 'components/utilities/LoadingIndicator';

import * as styles from './MediaSlider.scss';
import Indicator from 'packages/react-spring-slider/example/components/Indicator';
import { translate } from 'utils/translate';

const Slider = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/Slider'
  ),
);
const SlideTrack = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SlideTrack'
  ),
);
const AutoPlay = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/AutoPlay'
  ),
);
const SliderState = lazy(() =>
  import(
    /* webpackChunkName: "slider" */ /* webpackPreload: true */ 'packages/react-spring-slider/SliderState'
  ),
);

const getAutoPlayMilliSeconds = value => {
  if (value <= 0) {
    return 0;
  }
  if (value < 100) {
    // we asume values below 100 is seconds
    return value * 1000;
  }
  return value;
};

const SlideContent = ({
  slide,
  fullHeight = false,
  inline = false,
  fullWidth = false,
  useGradient = false,
  useStartPageLayout = false,
}) => (
  <Container
    className={classnames(styles.slideContentContainer, {
      [styles.fullHeight]: fullHeight,
      [styles.inline]: inline,
      [styles.startPageVO]: useStartPageLayout,
    })}
    full={fullWidth}
    noPadding
  >
    <div
      className={classnames(styles.slideContent, {
        [styles.gradientBackground]: useGradient,
        [styles.startPageVO]: useStartPageLayout,
      })}
    >
      {slide.slideTitle && (
        <BreakpointContainer
          render={breakpoint => {
            const isMobile = breakpoint === BREAKPOINT_PHONE;
            return (
              <H2
                className={styles.title}
                alt
                size={isMobile ? H2.sizes.heading6 : H2.sizes.heading3}
              >
                {slide.slideTitle}
              </H2>
            );
          }}
        />
      )}
      {slide.slideDescription && (
        <p className={styles.description}>
          {stripHtml(slide.slideDescription)}
        </p>
      )}
    </div>
  </Container>
);
SlideContent.propTypes = {
  slide: PropTypes.object.isRequired,
  fullHeight: PropTypes.bool,
  inline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  useGradient: PropTypes.bool,
  useStartPageLayout: PropTypes.bool,
};

const MediaSlider = ({
  slides,
  ratio,
  outsideContainerOverlay,
  inlineContent,
  className,
  autoPlay,
  height,
  fit,
  sliderSize,
  buttonsOutside,
  useGradient,
  useStartPageLayout,
}) => {
  const autoPlayMilliSeconds = getAutoPlayMilliSeconds(autoPlay);
  const themeConfig = useThemeConfig();
  const isFullWidthSlider = sliderSize === 'gigantic';
  const ratioStyle = useMemo(() => getRatioStyle(ratio), [ratio]);
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Slider hasBullets items={slides}>
        {!!autoPlayMilliSeconds && (
          <AutoPlay interval={autoPlayMilliSeconds} pauseOnHover />
        )}
        <BreakpointContainer
          render={breakpoint => (
            <>
              <div className={classnames(styles.root, className)}>
                <SlideTrack
                  direction="horizontal"
                  swipe={slides.length > 1}
                  infinite={slides.length > 1}
                  snap="center"
                  dragTreshold={
                    breakpoint >= BREAKPOINT_TABLET_PORTRAIT ? 200 : 100
                  }
                  height={height || 'item'}
                  className={styles.navTrack}
                  renderItem={({ item, isCurrent, getItemProps }) => (
                    <Container
                      {...getItemProps()}
                      noPadding
                      className={classnames({
                        [styles.largeSlide]: !isFullWidthSlider,
                        [styles.currentItem]: isCurrent,
                        [styles.fullWidthSlide]: isFullWidthSlider,
                      })}
                    >
                      {item.videoUrl ? (
                        <div
                          className={classnames(styles.wrapper, styles.video, {
                            [styles.isFullwidth]: isFullWidthSlider,
                          })}
                        >
                          <VideoPlayer ratio={ratio} url={item.videoUrl} />
                        </div>
                      ) : (
                        <ConditionalWrap
                          condition={!!item.slideUrl}
                          wrap={children => (
                            <Link
                              href={item.slideUrl}
                              target={item.newWindow ? '_blank' : null}
                              description={`${
                                !!item.slideTitle ? item.slideTitle + ':' : ''
                              }${item.slideUrl}`}
                            >
                              {children}
                            </Link>
                          )}
                        >
                          <Block
                            ratio={ratio}
                            contain
                            className={classnames(styles.wrapper, {
                              [styles.startPageVO]: useStartPageLayout,
                            })}
                          >
                            <Image
                              src={item.slideImage}
                              ratio={ratio}
                              fit={fit || (ratio ? 'cover' : 'none')}
                              draggable="false"
                              copy={item.imageFileCopyright}
                              description={item.imageFileDescription}
                              alt={item.altText}
                              cropDetails={item.cropDetails}
                            />
                            {!!inlineContent &&
                              (item.slideTitle || item.slideDescription) && (
                                <SlideContent
                                  inline
                                  fullHeight={false}
                                  slide={item}
                                  fullWidth={isFullWidthSlider}
                                  useGradient={useGradient}
                                  useStartPageLayout={useStartPageLayout}
                                />
                              )}
                          </Block>
                        </ConditionalWrap>
                      )}
                    </Container>
                  )}
                />
                {outsideContainerOverlay && !isFullWidthSlider && (
                  <div
                    className={classnames(styles.overlay, {
                      [styles.whiteOverlay]: buttonsOutside,
                    })}
                  />
                )}
                <SliderState>
                  {({ items, index, actions }) => {
                    const currentSlide = items[index];
                    return (
                      <React.Fragment>
                        {slides.length > 1 && (
                          <React.Fragment>
                            <Button
                              className={classnames(
                                styles.navButton,
                                styles.navButtonPrev,
                                { [styles.buttonsOutside]: buttonsOutside },
                              )}
                              onClick={actions.prev}
                              aria-label={translate(
                                '/product/sliderButtonPrevAria',
                              )}
                            >
                              <Icon icon={chevronLeft} />
                            </Button>

                            <Button
                              as={Button}
                              className={classnames(
                                styles.navButton,
                                styles.navButtonNext,
                                { [styles.buttonsOutside]: buttonsOutside },
                              )}
                              onClick={actions.next}
                              aria-label={translate(
                                '/product/sliderButtonNextAria',
                              )}
                            >
                              <Icon icon={chevronRight} />
                            </Button>
                          </React.Fragment>
                        )}
                        {!inlineContent &&
                          (currentSlide.slideTitle ||
                            currentSlide.slideDescription) && (
                            <SlideContent
                              fullHeight={
                                themeConfig.frontpageSliderFullHeightContent
                              }
                              slide={currentSlide}
                              fullWidth={isFullWidthSlider}
                            />
                          )}
                      </React.Fragment>
                    );
                  }}
                </SliderState>
                <Indicator className={'positionDotIndicator'} />
              </div>
            </>
          )}
        />
      </Slider>
    </Suspense>
  );
};

MediaSlider.displayName = 'MediaSlider';
MediaSlider.propTypes = {
  /** Set to number of ms or false */
  slides: PropTypes.array.isRequired,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  outsideContainerOverlay: PropTypes.bool,
  /** className for the root node */
  className: PropTypes.string,
  ratio: ratioPropType,
  inlineCaption: PropTypes.bool,
  height: PropTypes.string,
  fit: PropTypes.string,
  sliderSize: PropTypes.string,
  inlineContent: PropTypes.bool,
  buttonsOutside: PropTypes.bool,
  useGradient: PropTypes.bool,
  useStartPageLayout: PropTypes.bool,
};
MediaSlider.defaultProps = {
  autoPlay: false,
  outsideContainerOverlay: true,
  inlineCaption: false,
  buttonsOutside: false,
};

export default MediaSlider;
