import React from 'react';
import { Like } from 'react-facebook/module';
import { withColumnContext } from 'components/molecules/PageGrid';

const FacebookLikeBlock = () => <Like colorScheme="light" width="225px" />;
FacebookLikeBlock.displayName = 'FacebookLikeBlock';
FacebookLikeBlock.propTypes = {};
FacebookLikeBlock.defaultProps = {};

export default withColumnContext(FacebookLikeBlock);
