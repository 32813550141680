import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import { resolveComponent } from 'packages/inmeta-epi-react/utils/component-registry';
import FlexGrid from 'components/utilities/FlexGrid';
import BreakpointContainer from 'containers/BreakpointContainer';
import { BREAKPOINT_TABLET_LANDSCAPE } from 'utils/breakpoint';
import * as styles from './ColumnBlock.scss';

const ColumnBlock = ({ block }) => {
  const columns = propertyValueFor(block.columns).items;

  return (
    <BreakpointContainer
      render={breakpoint => (
        <FlexGrid
          className={styles.row}
          collapse={breakpoint < BREAKPOINT_TABLET_LANDSCAPE}
        >
          {columns.slice(0, 3).map(column => {
            const BlockComponent = resolveComponent(column.componentName);
            return (
              <FlexGrid.Column
                width={`${100 / columns.length}%`}
                className={styles.column}
                grow
                shrink
              >
                <BlockComponent {...column} />
              </FlexGrid.Column>
            );
          })}
        </FlexGrid>
      )}
    />
  );
};

ColumnBlock.displayName = 'ColumnBlock';
ColumnBlock.propTypes = {
  block: PropTypes.object,
};
ColumnBlock.defaultProps = {};

export default ColumnBlock;
