import React, { lazy, Suspense, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';
import PageFiltersContainer from 'containers/organisms/PageFiltersContainer';
import LocationButton from 'components/molecules/LocationButton';

import TeaserMap from 'components/molecules/TeaserMap';
import TeaserList, { teaserConfig } from 'components/organisms/TeaserList';
import TeaserWall from 'components/organisms/TeaserWall';

import Heading, { H1 } from 'components/atoms/Heading';
import Button, { IconButton } from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import {
  filter,
  grid,
  list,
  markerFill,
  plus,
} from 'components/atoms/Icon/icons';
import delve from 'packages/inmeta-epi-react/utils/delve';
import { translate } from 'utils/translate';
import getTeaserForProduct from 'utils/getTeaserForProduct';
import LoadingIndicator from 'components/utilities/LoadingIndicator';
import { appendQueryParams, isUrlForSamePage } from 'utils/bundle-helper';
import useSiteSettings from 'hooks/use-site-settings';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import { BREAKPOINT_PHONE, BREAKPOINT_TABLET_PORTRAIT } from 'utils/breakpoint';
import BreakpointContainer from 'containers/BreakpointContainer';
import ConditionalWrap from 'components/utilities/ConditionalWrap';
import Container from 'components/atoms/Container';
import Toggle from 'components/utilities/Toggle';
import FocusLock from 'components/utilities/FocusLock';
import Modal from 'components/utilities/Modal';
import VisuallyHidden from 'components/atoms/VisuallyHidden';
import ProductFilterSlider from 'components/organisms/ProductFilterSlider';
import Image from 'components/atoms/Image';

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';


import * as styles from './ProductListPage.scss';

const PageProductsContainer = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'containers/organisms/PageProductsContainer'
  ),
);
const ProductFilter = lazy(() =>
  import(
    /* webpackChunkName: "product" */ /* webpackPreload: true */ 'components/organisms/ProductFilter'
  ),
);

const VIEWMODE_WALL = 'wall';
const VIEWMODE_LIST = 'list';
const VIEWMODE_MAP = 'map';

const ViewModeButton = ({ name, icon, label, viewMode, url, breakpoint }) => {
  const forceReload = viewMode === VIEWMODE_MAP || name === VIEWMODE_MAP;
  const reloadProps = forceReload
    ? {
        'data-reload': true,
      }
    : {
        'data-cosmetic': true,
        'data-replace': true,
      };
  return (
    <Button
      href={
        appendQueryParams(url, {
          view: name,
        }).href
      }
      title={label}
      transparent
      disabled={viewMode === name}
      {...reloadProps}
      className={classnames(styles.viewModeButton, {
        [styles.viewModeButtonActive]: viewMode === name,
      })}
    >
      {icon}{' '}
      <ConditionalWrap
        condition={breakpoint <= BREAKPOINT_TABLET_PORTRAIT}
        wrap={children => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        {translate(`/pages/productListPage/${label.toLowerCase()}`)}
      </ConditionalWrap>
    </Button>
  );
};

const ProductListing = ({
  products,
  page,
  viewMode,
  showMunicipalityOnProductListingPage,
  culture,
}) => {
  const items = products.map(getTeaserForProduct(page.url.path));

  if (viewMode === VIEWMODE_MAP) {
    return <TeaserMap items={items} ratio={[4, 3]} showList fromGmapPage />;
  }

  return (
    <React.Fragment>
      {viewMode === VIEWMODE_LIST ? (
        <TeaserList
          config={teaserConfig.imageTitleIntroAndDetailsForListView}
          items={items}
          showMunicipality={showMunicipalityOnProductListingPage}
          showFavoriteButton
          culture={culture}
          className={styles.productItem}
        />
      ) : (
        <TeaserWall
          items={items}
          showMunicipality={showMunicipalityOnProductListingPage}
          showFavoriteButton
        />
      )}
    </React.Fragment>
  );
};

const ProductListPage = page => {
  const defaultView = propertyValueFor(page.defaultView) || VIEWMODE_WALL;

  const viewMode = delve(page.url, 'query.view', defaultView);
  const geolocationOK = !!navigator.geolocation;
  const {
    showMunicipalityOnProductListingPage,
    colorSettings,
    culture,
  } = useSiteSettings();

  const showMap = propertyValueFor(page.showMap);
  const disableWallView = propertyValueFor(page.disableInspirationalView);
  const disableListView = propertyValueFor(page.disableListView);

  const showIntroductionInCollageView = propertyValueFor(
    page.showIntroductionInCollageView,
  );
  const showMainBodyInCollageView = propertyValueFor(
    page.showMainBodyInCollageView,
  );

  let hideIntroductionText = false;
  if (viewMode === VIEWMODE_WALL && !showIntroductionInCollageView) {
    hideIntroductionText = true;
  }
  let hideMainBody = false;
  if (viewMode === VIEWMODE_WALL && !showMainBodyInCollageView) {
    hideMainBody = true;
  }

  const productColor =
    (page.productType &&
      propertyValueFor(page.productType) &&
      colorSettings.productColors[
        propertyValueFor(page.productType).toLowerCase()
      ]) ||
    colorSettings.productColors.others;

  const btnRef = useRef(null);
  return (
    <DefaultLayoutContainerV2>
      <PageView
        isOneCol
        isV2design
        {...page}
        hideIntroductionText={hideIntroductionText}
        hideMainBody={hideMainBody}
        color={productColor}
      >
        <Container wide>
          <BreakpointContainer
            render={breakpoint => (
              <React.Fragment>
                {/* FILTERS */}
                <div className={styles.filterContainer}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className={styles.viewModeContainer}>
                      <div
                        className={styles.viewModeButtonGroup}
                        style={{ textAlign: 'right' }}
                      >
                        {!!showMap && (
                          <ViewModeButton
                            url={page.url}
                            name={VIEWMODE_MAP}
                            icon={<Icon icon={markerFill} />}
                            label="Map"
                            viewMode={viewMode}
                            breakpoint={breakpoint}
                          />
                        )}
                        {!disableWallView && (
                          <ViewModeButton
                            url={page.url}
                            name={VIEWMODE_WALL}
                            icon={<Icon icon={grid} />}
                            label="Wall"
                            viewMode={viewMode}
                            breakpoint={breakpoint}
                          />
                        )}
                        {!disableListView && (
                          <ViewModeButton
                            url={page.url}
                            name={VIEWMODE_LIST}
                            icon={<Icon icon={list} />}
                            label="List"
                            viewMode={viewMode}
                            breakpoint={breakpoint}
                          />
                        )}
                      </div>
                    </div>
                    {breakpoint <= BREAKPOINT_TABLET_PORTRAIT && (
                      <PageFiltersContainer>
                        {(
                          showFilters,
                          showLocationButton,
                          filters,
                          categories,
                          showCapacityFilter
                        ) =>
                          showFilters && (
                            <Toggle
                              on={false}
                              render={({ on, setOn, setOff }) => (
                                <React.Fragment>
                                  <div className={styles.filterDropdown}>
                                    <IconButton
                                      transparent
                                      style={{
                                        background: `${productColor}66`,
                                      }}
                                      iconAfter={filter}
                                      onClick={() => {
                                        setOn();
                                        setTimeout(() => {
                                          if (btnRef.current)
                                            btnRef.current.focus();
                                        }, 100);
                                      }}
                                    >
                                      {translate(
                                        '/pages/productListPage/filters',
                                      )}
                                    </IconButton>
                                  </div>
                                  {on && (
                                    <FocusLock>
                                      <Modal
                                        fullscreen
                                        scrollLock
                                        closeBtn={
                                          <Button
                                            ref={btnRef}
                                            onClick={setOff}
                                            transparent
                                            className={styles.filterModalClose}
                                          >
                                            {translate(
                                              '/pages/productListPage/closeFilter',
                                            )}
                                            <Icon icon={plus} />
                                          </Button>
                                        }
                                      >
                                        <Modal.Body
                                          className={styles.filterModal}
                                          style={{
                                            background: `${productColor}66`,
                                          }}
                                        >
                                          <Filters
                                            filters={filters}
                                            productColor={productColor}
                                            page={page}
                                            breakpoint={breakpoint}
                                            viewMode={viewMode}
                                            geolocationOK={
                                              geolocationOK &&
                                              showLocationButton
                                            }
                                            categories={categories}
                                            showCapacityFilter={showCapacityFilter}
                                          />
                                        </Modal.Body>
                                      </Modal>
                                    </FocusLock>
                                  )}
                                </React.Fragment>
                              )}
                            />
                          )
                        }
                      </PageFiltersContainer>
                    )}
                  </div>
                </div>
                <div className={styles.grid}>
                  <div className={styles.column}>
                    {breakpoint > BREAKPOINT_TABLET_PORTRAIT && (
                      <PageFiltersContainer>
                        {(
                          showFilters,
                          showLocationButton,
                          filters,
                          categories,
                          showCapacityFilter,
                        ) =>
                          showFilters && (
                            <Filters
                              filters={filters}
                              productColor={productColor}
                              page={page}
                              breakpoint={breakpoint}
                              viewMode={viewMode}
                              geolocationOK={
                                geolocationOK && showLocationButton
                              }
                              categories={categories}
                              showCapacityFilter={showCapacityFilter}
                            />
                          )
                        }
                      </PageFiltersContainer>
                    )}
                  </div>
                  <div className={styles.column}>
                    {(showMap || !disableListView || !disableWallView) && (
                      <React.Fragment>
                        <Suspense fallback={<LoadingIndicator />}>
                          <PageProductsContainer>
                            {({
                              products,
                              isFetching,
                              hasMoreProducts,
                              doFetchMorePageProducts,
                            }) => (
                              <React.Fragment>
                                <ProductListing
                                  page={page}
                                  products={products}
                                  viewMode={viewMode}
                                  culture={culture}
                                  showMunicipalityOnProductListingPage={
                                    showMunicipalityOnProductListingPage
                                  }
                                />

                                {hasMoreProducts && (
                                  <div
                                    style={{
                                      padding: '2em 0',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {isFetching ? (
                                      <LoadingIndicator fixed={false} />
                                    ) : (
                                      <IconButton
                                        transparent
                                        onClick={doFetchMorePageProducts}
                                        iconBefore={plus}
                                        className={styles.showMoreButton}
                                      >
                                        {translate(
                                          '/pages/productListPage/showMore',
                                        )}
                                      </IconButton>
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </PageProductsContainer>
                        </Suspense>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          />
        </Container>
      </PageView>
    </DefaultLayoutContainerV2>
  );
};
ProductListPage.displayName = 'ProductListPage';
ProductListPage.propTypes = {};
ProductListPage.defaultProps = {};

export default ProductListPage;

const Filters = ({
  filters,
  productColor,
  page,
  breakpoint,
  viewMode,
  geolocationOK,
  categories,
  showCapacityFilter,
}) => (
  <div
    className={classnames({
      [styles.filterWrapper]: breakpoint > BREAKPOINT_TABLET_PORTRAIT,
    })}
    style={
      breakpoint > BREAKPOINT_TABLET_PORTRAIT
        ? {
            background: `${productColor}66`,
          }
        : {}
    }
  >
    {categories && categories.length > 1 && (
      <Suspense fallback={<LoadingIndicator />}>
        <ProductFilter
          url={page.url}
          toggleable={categories.length > 5}
          className={styles.filter}
          filters={categories.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
          })}
          dropdown={false}
          filterType="CategoryIds"
        />
      </Suspense>
    )}
    {filters.map((filter, index) => (
      <React.Fragment key={filter.name}>
        {filter && (
          <Suspense fallback={<LoadingIndicator />}>
            {filter.filterViewType === 'slider' ||
            filter.filterViewType === 'rangeSlider' ? (
              <ProductFilterSlider
                type={filter.filterViewType}
                url={page.url}
                className={styles.filterSlider}
                {...filter}
                filterGroupChar={String.fromCharCode(65 + index)}
              />
            ) : (
              <ProductFilter
                url={page.url}
                toggleable={filter.filters.length > 5}
                open={breakpoint > BREAKPOINT_PHONE || index === 0}
                className={styles.filter}
                {...filter}
                dropdown={false}
                filterGroupChar={String.fromCharCode(65 + index)}
              />
            )}
          </Suspense>
        )}
      </React.Fragment>
    ))}
    {/* capacity filters */}
    {showCapacityFilter && (
      <CapacityFilter url={page.url} />
    )}
    {/* Location button */}
    <div className={styles.viewModeLocation}>
      {geolocationOK && (
        <React.Fragment>
          {!!page.isLoading && isUrlForSamePage(page.url, page.nextUrl) && (
            <LoadingIndicator background="rgba(255,255,255,0.4)" />
          )}
          <LocationButton
            title={translate('/pages/productListPage/locationButtonText')}
            url={page.url}
          />
        </React.Fragment>
      )}
    </div>
    {!!page.isLoading && isUrlForSamePage(page.url, page.nextUrl) && (
      <LoadingIndicator background="rgba(255,255,255,0.4)" />
    )}
    <Image
      className={styles.filterImage}
      src={propertyValueFor(page.filterImage)}
    />
  </div>
);

const CapacityFilter = ({url}) => {
  // get current values (if any) from the url
  const [minValue, setMinValue] = useState(() => parseInt(url.query.capacityMin) || null); // get initial data from url
  const [maxValue, setMaxValue] = useState(() => parseInt(url.query.capacityMax) || null); // get initial data from url

  useEffect(() => {
    setMinValue(parseInt(url.query.capacityMin));
    setMaxValue(parseInt(url.query.capacityMax));
  }, [url.query.capacityMin, url.query.capacityMax]);

  const error = useMemo(() => {
    if(minValue !== "" && maxValue === "") {
      return translate('/pages/productListPage/capacityError/maxValueReqired');
    }
    if(minValue >= maxValue) {
      return translate('/pages/productListPage/capacityError/maxLargerThanMin');
    }
    return false;
  }, [minValue, maxValue]);

  const newUrl = useMemo(() => {
    return appendQueryParams(url, {
      capacityMin: minValue,
      capacityMax: maxValue
    }, true);
  }, [minValue, maxValue, url]);

  const onMinChange = useCallback((e) => {
    setMinValue(e.target.value * 1);
  }, []);
  const onMaxChange = useCallback((e) => {
    setMaxValue(e.target.value * 1);
  }, [minValue]);

  return (
    <section className={styles.capacityFilter}>
      <H1 size={Heading.sizes.heading4} className={styles.heading}>
        {translate('/pages/productListPage/capacity')}
      </H1>
      <div className={styles.fieldWrap}>
        <label className={styles.field}><span className={styles.text}>{translate('/pages/productListPage/capacityMin')}</span><input type="number" value={minValue} onChange={onMinChange} className={styles.input} /></label>
        <label className={styles.field}><span className={styles.text}>{translate('/pages/productListPage/capacityMax')}</span><input type="number" value={maxValue} onChange={onMaxChange} className={styles.input} /></label>
        <Button href={newUrl.href} data-no-scrolltop disabled={!!error} transparent className={styles.button}>{translate('/pages/productListPage/capacitySearch')}</Button>
      </div>
      {!!error && <p className={styles.error}>{error}</p>}
    </section>
  )
}
