// from https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// phone: 0 - 600
// tablet portrait: 600 - 900
// tablet landscape: 900 - 1200
// desktop: 1200 - 1800
// big desktop: 1800 +

// !! IMPORTANT!
// !! The list of breakpoints (not their sizes tho), are also defined in the backend (Scope/Device/Breakpoint.cs)
// !! And are used in Scope/Device/BreakpointDetector.cs
// !! Updates to names or number/meaning of numbers must also be replicated in Scope/Device/Breakpoint.cs

import cssBreakpoints from '../styles/exports/_breakpoints';
import { IS_BROWSER } from './environment';

// because scope uses Enums to handle breakpoints, we have to handle breakpoints by index
const breakpoints = [
  { name: 'phone', upperBoundary: cssBreakpoints.phone * 1 },
  {
    name: 'tabletportrait',
    upperBoundary: cssBreakpoints.tabletportrait * 1,
  },
  {
    name: 'tabletlandscape',
    upperBoundary: cssBreakpoints.tabletlandscape * 1,
  },
  { name: 'desktop', upperBoundary: cssBreakpoints.desktop * 1 },
  { name: 'bigdesktop', upperBoundary: Infinity },
];

// getBreakpointRangeIndexFromWidth
export const getBreakpointFromWidth = width =>
  breakpoints.reduce(
    (selectedIndex, breakpoint, index) =>
      selectedIndex === null && breakpoint.upperBoundary >= width
        ? index
        : selectedIndex,
    null,
  );
export const getBrowserBreakpoint = (fallback = 3) => {
  if (!IS_BROWSER) {
    return fallback;
  }
  const width = window.innerWidth; // changed back from document.body.offsetWidth; because the innerWidth matches the width the css reacts to // changed from window.innerWidth
  return getBreakpointFromWidth(width);
};
export const getBreakpointIndexFromName = name =>
  breakpoints.reduce(
    (selectedIndex, breakpoint, index) =>
      breakpoint.name === name ? index : selectedIndex,
    null,
  );

export const BREAKPOINT_PHONE = getBreakpointIndexFromName('phone');
export const BREAKPOINT_TABLET_PORTRAIT = getBreakpointIndexFromName(
  'tabletportrait',
);
export const BREAKPOINT_TABLET_LANDSCAPE = getBreakpointIndexFromName(
  'tabletlandscape',
);
export const BREAKPOINT_DESKTOP = getBreakpointIndexFromName('desktop');
export const BREAKPOINT_DESKTOP_BIG = getBreakpointIndexFromName('bigdesktop');
