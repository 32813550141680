import React from "react";
import { SliderState } from "../../SliderState";
import { forX } from "../../lib/for-x";

import "./Indicator.css";

const Indicator = ({ className = "", align = "center" }) => (
  <SliderState>
    {({ count, actions, index }) => (
      <ul className={`dot-indicator ${className} dot-indicator--${align}`}>
        {forX(count, i => (
          <li key={`key_${i}`} className="dot-indicator__dot">
            <button
              onClick={() => actions.goTo(i)}
              className={`dot-indicator__dot-button ${
                index === i ? "dot-indicator__dot-button--current" : ""
              }`}
            >
              {i}
            </button>
          </li>
        ))}
      </ul>
    )}
  </SliderState>
);

export default Indicator;
