import { add } from 'packages/inmeta-epi-react/utils/component-registry';

const propReq = require.context(
  'packages/inmeta-epi-react/components/PropertyTypes',
  true,
  /[A-Z].+\.jsx$/,
);
propReq.keys().forEach(key => {
  const componentName = key.replace(/^.+\/([^/]+).jsx$/, '$1');
  add('propertyTypes/' + componentName, propReq(key).default);
});
const pageReq = require.context(
  'episerver/pages',
  true,
  /\.\/[^/]+\/index\.jsx$/,
);
// const pageNames = [
//   'ArticlePage',
//   'ConferenceCalendarPage',
//   'EventListPage',
//   'GmapPage',
//   'LoadingPage',
//   'MyPage',
//   'MyPageContainer',
//   'NotFound404Page',
//   'NotFoundPage',
//   'ProductListPage',
//   'ProductPresentationPage', // !!
//   'SearchPage',
//   'StartPage',
//   'SubStartPage',
//   'TellusRegistrationPage',
// ];
pageReq.keys().forEach(key => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.jsx$/, '$1');
  // if (pageNames.includes(componentName)) {
  add('pages/' + componentName, pageReq(key).default);
  // }
});

const blockReq = require.context(
  'episerver/blocks',
  true,
  /\.\/[^/]+\/index\.jsx$/,
);
// const blockNames = [
//   'AdBlock',
//   'CalendarBlock',
//   'EditorBlock',
//   'FacebookLikeBlock',
//   'FacilityMatrixBlock',
//   'FeatureImage',
//   'FlickrBlock',
//   'FormsBlock',
//   'GalleryBlock',
//   'ImageBlock',
//   'LinkListBlock',
//   'LinksBlock',
//   'PageListBlock',
//   'SocialMediaLinkBlock',
//   'TeaserListBlock',
//   'TellusContentBlock',
//   'TellusEventListBlock',
//   'TellusSliderContentBlock',
//   'TwitterBlock',
//   'VideoBlock',
//   'WeatherBlock',
//   'WidgetBlock',
// ];
blockReq.keys().forEach(key => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.jsx$/, '$1');
  // if (blockNames.includes(componentName)) {
  add('blocks/' + componentName, blockReq(key).default);
  // }
});
