import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import { BREAKPOINT_TABLET_LANDSCAPE } from 'utils/breakpoint';

import BreakpointContainer from 'containers/BreakpointContainer';

import * as styles from './TeaserRowBlock.scss';
import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import BlockWrapper from 'components/molecules/BlockWrapper';

const ImageWrapper = ({ href, className, children }) => {
  return href ? (
    <Link href={href} isBlock className={className}>
      <figure>{children}</figure>
    </Link>
  ) : (
    <figure className={className}>{children}</figure>
  );
};

const TeaserRowBlock = ({ teaserItems, ingress, title, block }) => {
  const imgs = teaserItems.map(
    img =>
      img.imageUrl && {
        src: img.imageUrl,
        alt: img.title,
        description: img.title,
        showCaption: true,
        href: img.url,
        copy: img.copyright,
      },
  );
  const scaleImages = propertyValueFor(block.scaleWide);
  return (
    <div
      className={classnames(styles.TeaserRowBlock, {
        [styles.oneImage]: imgs.length === 1,
        [styles.twoImages]: imgs.length === 2,
        [styles.threeImages]: imgs.length === 3,
        [styles.fourImages]: imgs.length === 4,
        [styles.scaleImages]: scaleImages,
      })}
    >
      <h2 className={styles.heading}>{title}</h2>
      <p>{ingress}</p>
      <BreakpointContainer
        render={breakpoint => {
          if (breakpoint > BREAKPOINT_TABLET_LANDSCAPE) {
            return (
              <div className={styles.container}>
                {imgs.map(img => (
                  <ImageWrapper
                    key={img.src}
                    href={img.href}
                    className={styles.imageItem}
                  >
                    <Image
                      ratio={imgs.length === 1 ? [] : [1, 1]}
                      fit={imgs.length === 1 ? 'none' : 'cover'}
                      className={styles.image}
                      {...img}
                    />
                    {!!img.description && (
                      <figcaption className={styles.caption}>
                        <span>{img.description}</span>
                      </figcaption>
                    )}
                  </ImageWrapper>
                ))}
              </div>
            );
          }
          if (imgs.length !== 1)
            return (
              <div className={styles.container}>
                {imgs.map(img => (
                  <ImageWrapper
                    href={img.href}
                    figure
                    key={img.src}
                    className={styles.imageItem}
                  >
                    <Image
                      className={styles.image}
                      {...img}
                      ratio={[1, 1]}
                      fit="cover"
                    />
                    {!!img.description && (
                      <figcaption className={styles.caption}>
                        <span>{img.description}</span>
                      </figcaption>
                    )}
                  </ImageWrapper>
                ))}
              </div>
            );
          return (
            <div className={styles.container}>
              {imgs.map(img => (
                <ImageWrapper
                  href={img.href}
                  key={img.src}
                  className={styles.imageItem}
                >
                  <Image className={styles.image} {...img} />
                  {!!img.description && (
                    <figcaption className={styles.caption}>
                      <span>{img.description}</span>
                    </figcaption>
                  )}
                </ImageWrapper>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

TeaserRowBlock.displayName = 'TeaserRowBlock';
TeaserRowBlock.propTypes = {
  images: PropTypes.any,
  emphaziseBlock: PropTypes.any,
  fullWidth: PropTypes.any,
  block: PropTypes.shape({
    titleText: PropTypes.any,
    ingress: PropTypes.any,
  }),
};
TeaserRowBlock.defaultProps = {};

export default TeaserRowBlock;
