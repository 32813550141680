import React from "react";

import DefaultLayoutContainer from "containers/layouts/DefaultLayoutContainer";
import PageView from "components/organisms/PageView";

import DefaultLayoutContainerV2 from 'containers/layouts/DefaultLayoutContainerV2';


const SectionPage = page => {
  return (
    <DefaultLayoutContainerV2>
      <PageView
                  useFullscreenSlider
                  useStartPageLayout
                  noBreadcrumbs
                  isV2design 
      {...page} />
    </DefaultLayoutContainerV2>
  );
};
SectionPage.displayName = "SectionPage";
SectionPage.propTypes = {};
SectionPage.defaultProps = {};

export default SectionPage;
