import React from "react";
import PropTypes from "prop-types";
import * as styles from "./FlexGrid.scss";

const GridContext = React.createContext({ gutter: 0, collapse: false });

const Column = ({
  as: Tag,
  width,
  grow,
  shrink,
  fill,
  children,
  first,
  last,
  style,
  ...props
}) => (
  <GridContext.Consumer>
    {gridConfig => (
      <Tag
        style={{
          ...style,
          ...(!gridConfig.collapse && {
            flexShrink: `${fill || shrink ? 1 : 0}`,
            flexGrow: `${fill || grow ? 1 : 0}`,
            flexBasis: fill
              ? "auto"
              : width
                ? first ? width : `calc(${width} - ${gridConfig.gutter})`
                : "auto",
            marginLeft: first ? 0 : gridConfig.gutter
          })
        }}
        {...props}
      >
        {children}
      </Tag>
    )}
  </GridContext.Consumer>
);
Column.display = "Column";
Column.propTypes = {
  as: PropTypes.string,
  children: PropTypes.any,
  width: PropTypes.string,
  grow: PropTypes.bool,
  shrink: PropTypes.bool,
  fill: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  style: PropTypes.object
};
Column.defaultProps = {
  as: "div",
  fill: false,
  grow: false,
  shrink: false,
  first: false,
  last: false,
  style: {}
};

const FlexGrid = ({ as: Tag, gutter, children, collapse, ...props }) => {
  const childCount = React.Children.count(children);
  return (
    <GridContext.Provider value={{ gutter, collapse }}>
      <Tag
        style={{
          ...(!collapse && {
            width: "100%",
            display: "flex"
          })
        }}
        {...props}
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            ...child.props,
            first: index === 0,
            last: index === childCount - 1
          })
        )}
      </Tag>
    </GridContext.Provider>
  );
};
FlexGrid.displayName = "FlexGrid";
FlexGrid.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  gutter: PropTypes.string,
  collapse: PropTypes.bool
};
FlexGrid.defaultProps = {
  as: "div",
  gutter: "0px",
  collapse: false
};
FlexGrid.Column = Column;

export default FlexGrid;
