import React from 'react';
import { isBrowser } from './utils/device';

const listeners = {};
// This component is needed to bind events to the most outer
// component but still use Reacts event system. If we were to
// bind directly to `document` you won't be able to stop the
// propgation to those handlers from inside React, since React
// only binds one handler to `document`.
export const RootElement = ({ children, className }) => (
  <div
    className={className}
    onClick={triggerEvent}
    onKeyDown={triggerEvent}
    onTouchStart={triggerEvent}
    onTouchEnd={triggerEvent}
  >
    {children}
  </div>
);

export function on(event, callback) {
  const events = event.split(' ');
  events.forEach(eventName => {
    if (!listeners[eventName]) {
      listeners[eventName] = [];
    }

    listeners[eventName].push(callback);
    handleWindowSubscription(eventName);
  });

  // We return a function here that you can call to unscribe to the event.
  // This instead of exporting an `off` function where you have to pass
  // the same function you passed to `on` which makes it impossible to
  // pass an inline function to `on`.
  return () =>
    events.forEach(eventName => {
      listeners[eventName] = listeners[eventName].filter(
        listener => listener !== callback,
      );
      handleWindowSubscription(eventName);
    });
}

export function scrollPosition(newPosition = undefined) {
  if (!isBrowser) return newPosition || 0;
  if (newPosition !== undefined) {
    document.body.scrollTop = newPosition;
    document.documentElement.scrollTop = newPosition;
  }
  return Math.max(document.body.scrollTop, document.documentElement.scrollTop);
}

export function one(event, callback) {
  const unsubscribe = on(event, e => {
    callback(e);
    unsubscribe();
  });
}

function hasSubscribers(eventName) {
  return listeners[eventName] && listeners[eventName].length > 0;
}

function triggerEvent(e) {
  if (!hasSubscribers(e.type)) {
    return;
  }
  listeners[e.type].forEach(listener => {
    listener(e);
  });
}

const throttling = [];

// Some events than can fire at a high rate and execute computationally
// expensive operations such as DOM modifications. Those events should
// be throttled, such as `scroll` and `resize`.
function throttleEvent(e) {
  if (!hasSubscribers(e.type) || throttling.indexOf(e.type) !== -1) {
    return;
  }
  throttling.push(e.type);
  window.requestAnimationFrame(() => {
    triggerEvent(e);
    throttling.splice(throttling.indexOf(e.type), 1);
  });
}

const windowEvents = {
  resize: throttleEvent,
  scroll: throttleEvent,
  // We need `touchmove` on the window because you can't swipe out
  // the drawer/mini cart
  touchmove: throttleEvent,
};

// Pass `window` events defined in `windowEvents` to this module. Should be
// invoked when `listeners` has been manipulated
function handleWindowSubscription(eventName) {
  if (isBrowser && windowEvents[eventName]) {
    if (hasSubscribers(eventName)) {
      window.addEventListener(eventName, windowEvents[eventName]);
    } else {
      window.removeEventListener(eventName, windowEvents[eventName]);
    }
  }
}
