import React, {
  useMemo,
  useContext,
  useRef,
  useLayoutEffect,
  useReducer,
} from 'react';
import {
  reducer,
  getInitialState,
  ACTION_NEXT,
  ACTION_PREV,
  ACTION_GOTO,
  ACTION_SET_ITEMS,
  ACTION_TRANSITION_START,
  ACTION_TRANSITION_END,
  ACTION_HOVER_START,
  ACTION_HOVER_END,
} from './lib/state';
import { useValueChanged } from './lib/use-value-changed';

const SliderContext = React.createContext(null);
export const useSliderState = () => {
  const state = useContext(SliderContext);
  if (state === null) throw Error('Missing <Slider>;');
  return state;
};

export const Slider = ({ initialIndex, index, onChange, items, children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    { initialIndex, index, items },
    getInitialState,
  );
  const actions = useRef({
    next: () => dispatch({ type: ACTION_NEXT }),
    prev: () => dispatch({ type: ACTION_PREV }),
    goTo: i => dispatch({ type: ACTION_GOTO, index: i }), // NEED GO TO :(
    transitionStart: () => dispatch({ type: ACTION_TRANSITION_START }),
    transitionEnd: (i, increment) =>
      dispatch({ type: ACTION_TRANSITION_END, index: i, increment }),
    hoverStart: () => dispatch({ type: ACTION_HOVER_START }),
    hoverEnd: () => dispatch({ type: ACTION_HOVER_END }),
  });
  useLayoutEffect(() => {
    if (items !== state.items) {
      dispatch({ type: ACTION_SET_ITEMS, items });
    }
  }, [items, state]);
  useLayoutEffect(() => {
    if (index !== undefined && index !== state.index) {
      dispatch({ type: ACTION_GOTO, index });
    }
  }, [index, state]);

  useValueChanged(state.index, onChange);

  const value = useMemo(
    () => ({
      ...state,
      count: state.items.length,
      index: index !== undefined ? index : state.index,
      isFirst: state.index === 0,
      isLast: state.index === state.items.length - 1,
      actions: actions.current,
    }),
    [state, actions, index],
  );

  return useMemo(
    () => (
      <SliderContext.Provider value={value}>{children}</SliderContext.Provider>
    ),
    [value, children],
  );
};

export default Slider;
