// set "breakpoint" cookie on breakpoint change.. (window resize event)
import { createSelector } from 'redux-bundler';
import { on } from 'packages/inmeta-epi-react/GlobalEvents';
import { getBrowserBreakpoint } from 'utils/breakpoint';
import { IS_BROWSER } from 'utils/bundle-helper';

export default {
  name: 'breakpoint',
  getReducer: () => {
    const initialData = getBrowserBreakpoint();

    return (state = initialData, { type, payload }) => {
      if (type === 'BREAKPOINT_SET') {
        return payload;
      }
      if (type === '@@INIT') {
        if (state !== initialData) {
          console.warn(
            `guessed breakpoint "${state}", real breakpoint "${initialData}"`,
          );
          return initialData;
        }
      }
      return state;
    };
  },
  selectBreakpoint: state => state.breakpoint,
  doSetBreakpoint: breakpoint => ({
    type: 'BREAKPOINT_SET',
    payload: breakpoint,
  }),
  reactNewBreakpoint: createSelector(
    'selectBreakpoint',
    breakpoint => {
      if (IS_BROWSER) {
        // set cookie for breakpoint
        // name: breakpoint, domain: current, path: /, expires: 1 year
        document.cookie = `breakpoint=${breakpoint};path=/;max-age=${60 *
          60 *
          24 *
          365};`;
      }
    },
  ),
  init: store => {
    const resizeHandler = getResizeHandler(store);
    on('resize', resizeHandler);
  },
};

// utils
const getResizeHandler = store => () => {
  const newBreakpoint = getBrowserBreakpoint();
  if (newBreakpoint !== store.selectBreakpoint()) {
    store.doSetBreakpoint(newBreakpoint);
  }
};
