import React from 'react';

import DefaultLayoutContainer from 'containers/layouts/DefaultLayoutContainer';
import PageView from 'components/organisms/PageView';

/**
 * This is `StartPage`, and this is a description for it
 */
const StartPage = page => (
  <DefaultLayoutContainer>
    <PageView useFullscreenSlider noBreadcrumbs {...page} />
  </DefaultLayoutContainer>
);

StartPage.displayName = 'StartPage';
StartPage.propTypes = {};
StartPage.defaultProps = {};

export default StartPage;
