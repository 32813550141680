import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BreadcrumbsContainer from 'containers/molecules/BreadcrumbsContainer';
import FavouriteButtonContainer from 'containers/molecules/FavouriteButtonContainer';
import ShareButton from 'components/organisms/ShareButton';

import Container from 'components/atoms/Container';
import { TitleXL } from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import PageGrid from 'components/molecules/PageGrid';
import { PageGridSingleColumn } from 'components/molecules/PageGrid/PageGrid';
import MediaSlider from 'components/molecules/MediaSlider';

import { propertyValueFor } from 'packages/inmeta-epi-react/utils/epi-react';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';

import * as styles from './PageView.scss';

// eslint-disable-next-line import/no-mutable-exports
let PageView = props => {
  const slides = propertyValueFor(props.sliderImages);
  const myFavoritesId = propertyValueFor(props.myFavoritesId);
  const sliderTimeInterval = propertyValueFor(props.sliderTimeInterval);
  const sliderSize = propertyValueFor(props.sliderSize);
  const useGradient = propertyValueFor(props.useGradient);
  const grids = { standard: PageGrid, v2: PageGridSingleColumn };
  const Grid = props.isV2design ? grids['v2'] : grids['standard'];

  return (
    <React.Fragment>
      {props.useFullscreenSlider && !!(slides && slides.length) && (
        <MediaSlider
          ratio={props.useStartPageLayout ? [21, 7] : [28, 11]}
          autoPlay={false}
          slides={slides}
          className={styles.fullScreenSlider}
          inlineContent
          sliderSize={sliderSize}
          useGradient={useGradient}
          useStartPageLayout={props.useStartPageLayout}
        />
      )}
      {(!props.noBreadcrumbs || !props.useStartPageLayout) && <BreadcrumbsContainer />}
      <Container
        className={classnames(
          styles.container,
          (!!props.noBreadcrumbs || props.useStartPageLayout) && styles.noBreadcrumbs,
          props.useStartPageLayout && styles.maxWidthCentered,
        )}
        wide={props.isV2design}
      >
        <Grid
          oneCol={props.isOneCol}
          wideMain={props.isWideMain}
          main={
            <div
              className={classnames({
                [styles.v2design]: props.isV2design,
                [styles.articlePage]: props.isArticlePage,
              })}
            >
              {(props.isV2design && !props.useStartPageLayout) && (
                <Container>
                  <PropertyFor
                    property={props.heading}
                    component={TitleXL}
                    componentProps={{
                      as: 'h1',
                      className: styles.title,
                      underline: props.color || true,
                    }}
                  />
                  {!props.hideIntroductionText && (
                    <PropertyFor
                      property={props.introduction}
                      className={styles.introduction}
                      component={
                        !!props.introduction &&
                        typeof props.introduction.$c === 'string' &&
                        Paragraph
                      }
                      componentProps={{ size: Paragraph.sizes.large }}
                    />
                  )}
                </Container>
              )}
              {(props.isV2design && props.useStartPageLayout) && (
                <Container>
                  <PropertyFor
                    property={props.heading}
                    component={TitleXL}
                    componentProps={{
                      as: 'h1',
                      className: styles.titleVOStartPage,
                      underline: false,
                    }}
                  />
                  {!props.hideIntroductionText && (
                    <PropertyFor
                      property={props.introduction}
                      className={styles.introductionVOStartPage}
                      component={
                        !!props.introduction &&
                        typeof props.introduction.$c === 'string' &&
                        Paragraph
                      }
                      componentProps={{ size: Paragraph.sizes.large }}
                    />
                  )}
                </Container>
              )}

              {!props.useFullscreenSlider && !!(slides && slides.length) && (
                <MediaSlider
                  autoPlay={sliderTimeInterval}
                  slides={slides}
                  ratio={[2, 1]}
                  inlineContent
                  className={styles.sliderSmall}
                  buttonsOutside
                />
              )}
              <Container>
                {!!props.mainImage && (
                  <Image
                    src={`${propertyValueFor(props.mainImage)}`}
                    ratio={[2, 1]}
                    fit="cover"
                  />
                )}

                {!props.isV2design && (
                  <PropertyFor
                    property={props.heading}
                    component={TitleXL}
                    componentProps={{
                      as: 'h1',
                      className: styles.title,
                      underline: props.color || true,
                    }}
                  />
                )}
                {!props.hideIntroductionText && !props.isV2design && (
                  <PropertyFor
                    property={props.introduction}
                    className={styles.introduction}
                    component={
                      !!props.introduction &&
                      typeof props.introduction.$c === 'string' &&
                      Paragraph
                    }
                    componentProps={{ size: Paragraph.sizes.large }}
                  />
                )}

                {!!props.showShareButtons && (
                  <div style={{ display: 'flex', margin: '1.5em 0' }}>
                    <ShareButton
                      title={propertyValueFor(props.heading)}
                      url={props.url.href}
                      noPadding
                    />
                    {!!myFavoritesId && (
                      <FavouriteButtonContainer identifier={myFavoritesId} />
                    )}
                  </div>
                )}

                {!props.hideMainBody && (
                  <React.Fragment>
                    <PropertyFor
                      property={props.mainBody}
                      className={classnames(styles.mainBody)}
                    />
                    <PropertyFor
                      property={props.htmlBody}
                      className={styles.html}
                    />
                  </React.Fragment>
                )}
              </Container>
              <Container wide={props.isV2design}>{props.children}</Container>
              <Container full={props.useStartPageLayout}>
                <PropertyFor
                  property={props.mainContentAreaFull}
                  className={classnames({
                    [styles.startPageV2]: props.useStartPageLayout
                  },
                  styles.mainContentAreaFull
                  )}
                />
              </Container>
            </div>
          }
          mainLeft={
            <PropertyFor
              property={props.mainContentAreaLeft}
              className={styles.mainContentAreaLeft}
            />
          }
          mainRight={
            <PropertyFor
              property={props.mainContentAreaRight}
              className={styles.mainContentAreaRight}
            />
          }
          aside={
            !props.useStartPageLayout && (
            <PropertyFor
              property={props.rightContentArea}
              className={styles.rightContentArea}
            />
          )}
          ads={
            <PropertyFor
              property={props.defaultAdsContentArea}
              className={styles.adsContentArea}
              tagName="ul"
            />
          }
        />
        <div
          className={classnames(styles.after, {
            [styles.v2design]: props.isV2design,
            [styles.articlePage]: props.isArticlePage,
          })}
        >
          {props.renderAfter || null}
        </div>
      </Container>
    </React.Fragment>
  );
};
PageView.displayName = 'PageView';
PageView.propTypes = {
  // stuff from pages
  url: PropTypes.object,
  mainImage: PropTypes.any,
  slider: PropTypes.any,
  sliderImages: PropTypes.any,
  heading: PropTypes.any,
  mainBody: PropTypes.any,
  htmlBody: PropTypes.any,
  introduction: PropTypes.any,
  myFavoritesId: PropTypes.any,
  content: PropTypes.any,
  // custom content
  children: PropTypes.any,
  // contentAreas
  mainContentAreaFull: PropTypes.any,
  mainContentAreaLeft: PropTypes.any,
  mainContentAreaRight: PropTypes.any,
  rightContentArea: PropTypes.any,
  adsContentArea: PropTypes.any,
  // config booleans
  showShareButtons: PropTypes.bool,
  isOneCol: PropTypes.bool,
  isWideMain: PropTypes.bool,
  useFullscreenSlider: PropTypes.bool,
  noBreadcrumbs: PropTypes.bool,
  // new startpage content
  useStartPageLayout: PropTypes.bool,
  // TODO: Add bool prop for toggeling fullpageSlider (if it exists)
};

PageView.defaultProps = {};

PageView = React.memo(PageView);
export default PageView;
