import React from 'react';

// internal registry
let registry = {};

// NotFoundComponent is used when
let NotFoundComponent = props => {
  return <div>Placeholder for {props.componentPath}</div>;
};

let resolveFunction = (componentPath, fallback) => {
  let Component = registry[componentPath] || null;
  if (!Component) {
    console.log(componentPath + ' not found in componentRegistry');
    Component = fallback || NotFoundComponent || null;
  }
  return Component;
};

export const resolveComponent = (componentPath, fallback) => {
  return resolveFunction(componentPath, fallback);
};

export const add = (path, ComponentRef) => {
  registry[path] = ComponentRef;
};
export const set = mappedComponents => {
  // only set if mappedComponents is an object.. (doubles as a reset with set())
  registry = typeof mappedComponents === 'object' ? mappedComponents : {};
};
export const setNotFoundComponent = ComponentRef => {
  NotFoundComponent = ComponentRef;
};

export const registerResolver = resolver => {
  resolveFunction = resolver;
};

export const logRegistry = () => {
  console.log(registry);
};
