import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mitt from 'mitt';
import { IS_BROWSER } from 'utils/environment';

let stack = [];
const emitter = mitt();
emitter.on('updated', newStack => {
  stack = newStack;
  if (IS_BROWSER) {
    document.body.style.background = stack.length
      ? stack[stack.length - 1].color
      : null;
  }
});
const add = color => {
  const obj = { color };
  const newStack = [].concat(stack, [obj]);
  emitter.emit('updated', newStack);

  return {
    remove: () => {
      emitter.emit('updated', stack.filter(colorObj => colorObj !== obj));
    },
    update: newColor => {
      emitter.emit(
        'updated',
        stack.map(colorObj => {
          if (colorObj === obj) {
            colorObj.color = newColor;
          }
          return colorObj;
        }),
      );
    },
  };
};
const BodyBackgroundColor = ({ color }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) {
      ref.current = add(color);
    } else {
      ref.current.update(color);
    }
  }, [color]);
  useEffect(() => {
    return () => ref.current.remove();
  }, []);
  return null;
};

/* 
class BodyBackgroundColor extends React.Component {
  componentWillReceiveProps(newProps) {
    if (newProps.color !== this.props.color) {
      this.foo.update(newProps.color);
    }
  }
  componentDidMount() {
    this.foo = add(this.props.color);
  }
  componentWillUnmount() {
    this.foo.remove();
  }
  render() {
    return null;
  }
}
*/
BodyBackgroundColor.displayName = 'BodyBackgroundColor';
BodyBackgroundColor.propTypes = {
  color: PropTypes.string,
};
BodyBackgroundColor.defaultProps = {};

export default BodyBackgroundColor;
