/**
 *
 * inject script to facilitate iframe resizing
 * https://github.com/davidjbradshaw/iframe-resizer
 *
 * based on https://github.com/zeroasterisk/react-iframe-resizer-super, simplified for usage as a ScriptSandbox
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { IS_BROWSER } from 'utils/environment';
let iframeResizerLib = () => {};
if (IS_BROWSER) {
  iframeResizerLib = require('iframe-resizer/js/iframeResizer');
}

class ScriptSandbox extends React.Component {
  componentDidMount() {
    // can't update until we have a mounted iframe
    this.updateIframe(this.props.content);
    this.resizeIframe(this.props);
  }

  componentWillUnmount() {
    if (this.frame && this.frame.iFrameResizer) {
      this.frame.iFrameResizer.close();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // can replace content if we got new props
    if (nextProps.content !== this.props.content) {
      this.updateIframe(nextProps.content);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  updateIframe = content => {
    // do we have content to inject (content or children)
    if (!content) return;

    // get frame to inject into
    const frame = this.frame;
    if (!frame) return;

    // verify frame document access
    // Due to browser security, this will fail with the following error
    //   Uncaught DOMException: Failed to read the 'contentDocument' property from 'HTMLIFrameElement':
    //   Blocked a frame with origin "http://<hostname>" from accessing a cross-origin frame.
    // resolve this by loading documents from the same domain name,
    // or injecting HTML `content` vs. loading via `src`
    const doc = frame.contentDocument;
    if (!doc) return;

    // if content isn't a string, don't update
    if (typeof content !== 'string') return;

    //   we could send this in via REACT dangerously set HTML
    //   but we are in an iframe anyway, already a red-headed step-child.
    // <!DOCTYPE html> ADDED TO SOLVE BODY HEIGHT
    // moved script tag to doc.write because it's easier than injecting on load
    doc.open();
    doc.write(
      `<!DOCTYPE html><html><head><script src="${this.props.iframeResizerUrl}"></script></head><body>${content}</body></html>`,
    );
    doc.close();
  };

  resizeIframe = props => {
    const frame = this.frame;
    if (!frame) return;
    iframeResizerLib(
      {
        // log: true,
        heightCalculationMethod: props.heightCalculationMethod,
        checkOrigin: false,
        bodyMargin: 0,
      },
      frame,
    );
  };

  setRef = ref => (this.frame = ref);

  render() {
    const { id, frameBorder, className, style, title, content } = this.props;
    return (
      <iframe
        ref={this.setRef}
        id={id}
        frameBorder={frameBorder}
        className={className}
        style={style}
        title={title}
        role="none"
      />
    );
  }
}
ScriptSandbox.propTypes = {
  /** content of HTML to load in the iframe */
  content: PropTypes.string.isRequired,
  id: PropTypes.string,
  frameBorder: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  iframeResizerUrl: PropTypes.string,
  heightCalculationMethod: PropTypes.string,
};
ScriptSandbox.defaultProps = {
  heightCalculationMethod: 'lowestElement', // performs the best in our tests with regards to position:absolute/fixed and content that might resize down (like embeds)
  frameBorder: 0,
  style: {
    width: '100%',
    minHeight: 0,
  },
  iframeResizerUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.16/iframeResizer.contentWindow.min.js',
};

export default ScriptSandbox;
