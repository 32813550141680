import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button, { IconButton } from 'components/atoms/Button';

import MenuToggler from 'components/utilities/MenuToggler';
import NavigationV2 from 'components/molecules/NavigationV2';
import SearchFormContainerV2 from 'containers/molecules/SearchFormContainerV2';
import Icon from 'components/atoms/Icon';
import {
  chevronLeft,
  language,
  people,
  info,
  magnifyer,
  exploretheregion,
  macpro,
} from 'components/atoms/Icon/icons';
import NoBodyScroll from 'components/utilities/NoBodyScroll';
import TransitionElement from 'components/utilities/TransitionElement';
import RegionMenu2, { VARIANT_MOBILE } from 'components/organisms/RegionMenu2';
import { translate } from 'utils/translate';
import { iconLookup } from 'utils/iconLookUp';
import { newShade } from 'utils/hex';
import * as styles from './MobileHeaderV2.scss';

const HeaderPropTypes = {
  logo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  secondaryLogo: PropTypes.shape({
    link: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
  }),
  professionalsMenu: PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.array,
  }),
  languageLinks: PropTypes.array,
  quickSearchText: PropTypes.string,
  userMenu: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
    url: PropTypes.string,
  }),
  regionMenu: PropTypes.object,
};

const MobileHeaderV2 = ({
  logo,
  professionalsMenu,
  languageLinks,
  quickSearchText,
  searchPageLink,
  userMenu,
  regionMenu,
}) => {
  const [searchActive, setSearchActive] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const megaMenu = INITIAL_DATA?.megaMenu;

  function resetStates() {
    setSearchActive(false);
    setMenuOpen(false);
  }

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        {!!logo.link && !!logo.mobileImage && (
          <a href={logo.link} className={styles.logoLink}>
            <img src={logo.mobileImage} alt={translate('/menu/MenuLogoAlt')} />
          </a>
        )}
      </header>

      <div className={styles.bottomBar}>
        <MenuToggler
          render={({ clickOpenerProps, isOpen }) => (
            <nav>
              {isOpen ? <NoBodyScroll /> : null}
              {/* Bottom bar buttons */}
              {megaMenu.menuItems
                .filter(item => item.showInMobileMenu)
                .slice(0, 3)
                .map(item => {
                  const iconBoxColor =
                    item.colorTheme && JSON.parse(item.colorTheme);
                  const color = iconBoxColor ? iconBoxColor.Color : '#ffffff';

                  return (
                    item.showInMobileMenu && (
                      <a
                        href={item.url}
                        className={styles.bottomBarButton}
                        aria-label={item.name}
                      >
                        <div
                          className={styles.iconWrapper}
                          style={{ backgroundColor: color }}
                        >
                          <Icon
                            name={iconLookup[item.icon] || info.name}
                            className={styles.menuButton}
                          />
                        </div>
                        <span>{item.name}</span>
                      </a>
                    )
                  );
                })}

              {/* Bottom bar search */}
              <Button
                transparent
                className={styles.bottomBarButton}
                aria-label={
                  searchActive
                    ? `${translate('/global/close')} ${translate(
                        '/search/searchButton',
                      ).toLowerCase()}`
                    : translate('/search/searchButton')
                }
                onClick={() => {
                  resetStates();
                  setSearchActive(!searchActive);
                }}
              >
                {searchActive ? <NoBodyScroll /> : null}
                <div
                  className={classnames(
                    styles.iconWrapper,
                    styles.searchButton,
                  )}
                >
                  {searchActive ? (
                    <div className={styles.closeSearch}>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    <Icon icon={magnifyer} className={styles.menuButton} />
                  )}
                </div>
                <span>
                  {searchActive
                    ? `${translate('/global/close')} ${translate(
                        '/search/searchButton',
                      ).toLowerCase()}`
                    : translate('/search/searchButton')}
                </span>
              </Button>

              {searchActive && (
                <div className={styles.searchWrapper}>
                  <div className={styles.searchHeader}>
                    <a href={logo.link} className={styles.logoLink}>
                      <img
                        src={logo.mobileImage}
                        alt={translate('/menu/MenuLogoAlt')}
                      />
                    </a>
                  </div>
                  <SearchFormContainerV2
                    action={searchPageLink}
                    className={styles.searchForm}
                    inputClassName={styles.searchFormInput}
                    placeholder={quickSearchText}
                    showSearchButton
                    showSecondarySearchButton
                  />
                </div>
              )}

              {/* Menu */}
              <Button
                transparent
                aria-label={
                  menuOpen
                    ? `${translate('/global/close')} ${translate(
                        '/menu/menu',
                      ).toLowerCase()}`
                    : translate('/menu/openMenuAria')
                }
                className={classnames(
                  styles.mainMenuButton,
                  styles.bottomBarButton,
                )}
                {...clickOpenerProps}
              >
                {/* Animate menu icon from hamburger to cross and back */}
                <div className={styles.iconWrapper}>
                  <div
                    className={classnames(styles.menuIcon, {
                      [styles.menuOpen]: menuOpen,
                    })}
                    onClick={() => {
                      resetStates();
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>

                <span>
                  {menuOpen
                    ? `${translate('/global/close')} ${translate(
                        '/menu/menu',
                      ).toLowerCase()}`
                    : translate('/menu/menu')}
                </span>
              </Button>

              <TransitionElement
                from={{
                  opacity: 1,
                  transform: 'translateX(100%)',
                  transition: 'all 0.1s linear',
                }}
                enter={{ opacity: 1, transform: 'translateX(0px)' }}
                leave={{
                  opacity: 1,
                  transform: 'translateX(100%)',
                  transition: 'all 0.1s linear',
                }}
                className={styles.mainMenu}
              >
                {menuOpen && <NoBodyScroll /> && (
                  <div className={styles.mainMenuInner}>
                    <div className={styles.menuHeader}>
                      <a href={logo.link} className={styles.logoLink}>
                        <img
                          src={logo.mobileImage}
                          alt={translate('/menu/MenuLogoAlt')}
                        />
                      </a>
                    </div>

                    {/* Language */}
                    {!!languageLinks && !searchActive && (
                      <MenuToggler
                        render={({ clickOpenerProps, isOpen }) => (
                          <Button
                            transparent
                            aria-label={translate('/menu/lang')}
                            className={styles.languageMenuButton}
                            {...clickOpenerProps}
                          >
                            <Icon
                              className={styles.languageButtonIcon}
                              icon={language}
                            />
                            {languageLinks.reduce(
                              (current, lang) =>
                                lang.isCurrentLanguage ? lang.name : current,
                              null,
                            )}
                            {isOpen && (
                              <ul className={styles.languageList}>
                                {languageLinks.map(lang => (
                                  <li key={lang.url} className={styles.item}>
                                    <Button
                                      transparent
                                      aria-label={lang.name}
                                      href={lang.url}
                                      className={classnames(styles.button, {
                                        [styles.languageBarCurrent]:
                                          lang.isCurrentLanguage,
                                      })}
                                    >
                                      {lang.name}
                                    </Button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </Button>
                        )}
                      />
                    )}

                    <NavigationV2
                      direction="vertical"
                      className={styles.navigation}
                    >
                      {megaMenu.menuItems.map(item => {
                        const items = item.children
                          ? item.children.filter(i => !!i.url)
                          : [];
                        const menuColorTheme =
                          item.colorTheme && JSON.parse(item.colorTheme);
                        const color = menuColorTheme
                          ? menuColorTheme.Color
                          : '#ffffff';

                        const style = {};
                        if (color) {
                          style['--backgroundColor'] = newShade(color, -15);
                        }

                        return (
                          <NavigationV2.Item
                            key={item.url || item.name}
                            label={item.name}
                            url={item.url}
                            icon={item.icon}
                            isCurrent={item.isCurrent}
                            wrapClassName={styles.wrap}
                            iconClassName={styles.icon}
                            linkClassName={styles.link}
                            menuColor={color}
                            menuLevel={1}
                            renderChildren={
                              !!items.length &&
                              (({ doClose, isOpen }) => (
                                <TransitionElement
                                  from={{
                                    opacity: 1,
                                    transform: 'translateX(100%)',
                                    transition: 'all 0.1s linear',
                                  }}
                                  enter={{
                                    opacity: 1,
                                    transform: 'translateX(0px)',
                                  }}
                                  leave={{
                                    opacity: 1,
                                    transform: 'translateX(100%)',
                                    transition: 'all 0.1s linear',
                                  }}
                                  className={styles.subMenu}
                                >
                                  {!!isOpen && (
                                    <NavigationV2
                                      direction="vertical"
                                      className={styles.subMenuInner}
                                    >
                                      <div
                                        className={styles.subMenuWrapper}
                                        style={{
                                          backgroundColor: color || '#DAEEF4',
                                        }}
                                      >
                                        <div className={styles.subMenuTitle}>
                                          <IconButton
                                            transparent
                                            full
                                            textAlign="left"
                                            iconBefore={chevronLeft}
                                            className={styles.backButton}
                                            onClick={() => doClose()}
                                          />
                                          <a href={item.url ? item.url : null}>
                                            <Icon
                                              name={
                                                iconLookup[item.icon] || info
                                              }
                                            />
                                            {item.name}
                                          </a>
                                        </div>
                                        <div
                                          className={styles.subMenuItemsWrapper}
                                        >
                                          {items.map(subItem => {
                                            const subItems = subItem.children
                                              ? subItem.children.filter(
                                                  i => !!i.url,
                                                )
                                              : [];

                                            return (
                                              <NavigationV2.Item
                                                key={
                                                  subItem.url || subItem.name
                                                }
                                                label={subItem.name}
                                                url={subItem.url}
                                                isCurrent={subItem.isCurrent}
                                                className={styles.subItem}
                                                wrapClassName={styles.wrap}
                                                linkClassName={classnames(
                                                  styles.link,
                                                  styles.linkSubitems,
                                                )}
                                                iconClassName={styles.icon}
                                                menuLevel={2}
                                                renderChildren={
                                                  !!subItems.length &&
                                                  (({ doClose, isOpen }) => (
                                                    <TransitionElement
                                                      from={{
                                                        opacity: 1,
                                                        transform:
                                                          'translateX(100%)',
                                                        transition:
                                                          'all 0.1s linear',
                                                      }}
                                                      enter={{
                                                        opacity: 1,
                                                        transform:
                                                          'translateX(0px)',
                                                      }}
                                                      leave={{
                                                        opacity: 1,
                                                        transform:
                                                          'translateX(100%)',
                                                        transition:
                                                          'all 0.1s linear',
                                                      }}
                                                      className={classnames(
                                                        styles.subMenu,
                                                        styles.subSubmenu,
                                                      )}
                                                    >
                                                      {!!isOpen && (
                                                        <NavigationV2
                                                          direction="vertical"
                                                          className={
                                                            styles.subMenuInner
                                                          }
                                                        >
                                                          <div
                                                            className={
                                                              styles.subMenuWrapper
                                                            }
                                                            style={{
                                                              backgroundColor:
                                                                style[
                                                                  '--backgroundColor'
                                                                ],
                                                            }}
                                                          >
                                                            <div
                                                              className={
                                                                styles.subMenuTitle
                                                              }
                                                            >
                                                              <IconButton
                                                                transparent
                                                                full
                                                                textAlign="left"
                                                                iconBefore={
                                                                  chevronLeft
                                                                }
                                                                className={
                                                                  styles.backButton
                                                                }
                                                                onClick={() =>
                                                                  doClose()
                                                                }
                                                              />
                                                              <a
                                                                href={
                                                                  subItem.url
                                                                }
                                                                className={
                                                                  styles.noIcon
                                                                }
                                                              >
                                                                {subItem.name}
                                                              </a>
                                                            </div>
                                                            <div
                                                              className={
                                                                styles.subMenuItemsWrapper
                                                              }
                                                            >
                                                              {subItems.map(
                                                                subSubItem => (
                                                                  <NavigationV2.Item
                                                                    key={
                                                                      subSubItem.url
                                                                    }
                                                                    label={
                                                                      subSubItem.name
                                                                    }
                                                                    url={
                                                                      subSubItem.url
                                                                    }
                                                                    isCurrent={
                                                                      subSubItem.isCurrent
                                                                    }
                                                                    wrapClassName={
                                                                      styles.wrap
                                                                    }
                                                                    linkClassName={classnames(
                                                                      styles.link,
                                                                      styles.linkSubitems,
                                                                    )}
                                                                    iconClassName={
                                                                      styles.icon
                                                                    }
                                                                    menuLevel={
                                                                      3
                                                                    }
                                                                  />
                                                                ),
                                                              )}
                                                            </div>
                                                          </div>
                                                        </NavigationV2>
                                                      )}
                                                    </TransitionElement>
                                                  ))
                                                }
                                              />
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </NavigationV2>
                                  )}
                                </TransitionElement>
                              ))
                            }
                          />
                        );
                      })}

                      {/* Explore the region */}
                      <div className={styles.constantMenuItemsWrapper}>
                        <NavigationV2.Item
                          className={styles.item}
                          wrapClassName={styles.innerWrap}
                          linkClassName={styles.link}
                          label={translate('/regionMenu/exploreTheRegion')}
                          menuLevel={1}
                          noMenuIconChevron
                          renderChildren={
                            !!regionMenu.menuPages &&
                            !!regionMenu.menuPages.length &&
                            (({ doClose, isOpen }) => (
                              <TransitionElement
                                from={{
                                  opacity: 1,
                                  transform: 'translateX(100%)',
                                  transition: 'all 0.1s linear',
                                }}
                                enter={{
                                  opacity: 1,
                                  transform: 'translateX(0px)',
                                }}
                                leave={{
                                  opacity: 1,
                                  transform: 'translateX(100%)',
                                  transition: 'all 0.1s linear',
                                }}
                                className={styles.subMenu}
                              >
                                {!!isOpen && (
                                  <NavigationV2
                                    direction="vertical"
                                    className={styles.subMenuInner}
                                  >
                                    <div
                                      className={classnames(
                                        styles.subMenuWrapper,
                                        styles.regionWrapper,
                                      )}
                                    >
                                      <div className={styles.subMenuTitle}>
                                        <IconButton
                                          transparent
                                          full
                                          textAlign="left"
                                          iconBefore={chevronLeft}
                                          className={styles.backButton}
                                          onClick={() => doClose()}
                                        />
                                        <span>
                                          <Icon
                                            icon={exploretheregion}
                                            className={styles.buttonIcon}
                                          />
                                          {translate(
                                            '/regionMenu/exploreTheRegion',
                                          )}
                                        </span>
                                      </div>
                                      <div className={styles.regionMenu}>
                                        <RegionMenu2
                                          variant={VARIANT_MOBILE}
                                          menuPages={regionMenu.menuPages}
                                        />
                                      </div>
                                    </div>
                                  </NavigationV2>
                                )}
                              </TransitionElement>
                            ))
                          }
                        />
                      </div>

                      {/* Professionals menu */}
                      <div className={styles.constantMenuItemsWrapper}>
                        <NavigationV2.Item
                          className={styles.item}
                          wrapClassName={styles.innerWrap}
                          linkClassName={styles.link}
                          label={professionalsMenu.label}
                          menuLevel={1}
                          noMenuIconChevron
                          renderChildren={
                            !!professionalsMenu.items &&
                            (({ doClose, isOpen }) => (
                              <TransitionElement
                                from={{
                                  opacity: 1,
                                  transform: 'translateX(100%)',
                                  transition: 'all 0.1s linear',
                                }}
                                enter={{
                                  opacity: 1,
                                  transform: 'translateX(0px)',
                                }}
                                leave={{
                                  opacity: 1,
                                  transform: 'translateX(100%)',
                                  transition: 'all 0.1s linear',
                                }}
                                className={styles.subMenu}
                              >
                                {!!isOpen && (
                                  <NavigationV2
                                    direction="vertical"
                                    className={styles.subMenuInner}
                                  >
                                    <div className={styles.subMenuWrapper}>
                                      <div className={styles.subMenuTitle}>
                                        <IconButton
                                          transparent
                                          full
                                          textAlign="left"
                                          iconBefore={chevronLeft}
                                          className={styles.backButton}
                                          onClick={() => doClose()}
                                        />
                                        <span>
                                          <Icon
                                            icon={macpro}
                                            className={styles.buttonIcon}
                                          />
                                          {professionalsMenu.label}
                                        </span>
                                      </div>
                                      <div
                                        className={styles.subMenuItemsWrapper}
                                      >
                                        {professionalsMenu.items.map(
                                          ({ linkText, url }) => (
                                            <NavigationV2.Item
                                              key={url}
                                              label={linkText}
                                              url={url}
                                              wrapClassName={styles.wrap}
                                              linkClassName={classnames(
                                                styles.link,
                                                styles.linkSubitems,
                                                styles.professionalsMenu,
                                              )}
                                              iconClassName={styles.icon}
                                              menuLevel={2}
                                            />
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  </NavigationV2>
                                )}
                              </TransitionElement>
                            ))
                          }
                        />
                      </div>

                      {/* Favorites */}

                      <div className={styles.constantMenuItemsWrapper}>
                        <a
                          href={userMenu.url}
                          className={classnames(
                            styles.item,
                            styles.myPageButton,
                          )}
                          aria-label={translate('/menu/myFavorites')}
                        >
                          <Icon icon={people} className={styles.icon} />

                          <span>{translate('/menu/myFavorites')}</span>
                        </a>
                      </div>
                    </NavigationV2>
                  </div>
                )}
              </TransitionElement>
            </nav>
          )}
        />
      </div>
    </div>
  );
};
MobileHeaderV2.displayName = 'MobileHeaderV2';
MobileHeaderV2.propTypes = HeaderPropTypes;

export default MobileHeaderV2;
