// Hook
import { useState, useEffect } from 'react';
const isMatch = (event, target) => {
  const key = event.key || event.keyCode;
  // if array
  if (Array.isArray(target)) {
    return target.includes(key);
  }
  return key === target;
};

export const KEY_ESC = ['Escape', 'Esc', 27];

export default function(targetKey, onPress, listen = true) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // Add event listeners
  useEffect(
    () => {
      if (listen) {
        // If pressed key is our target key then set to true
        const downHandler = event => {
          if (isMatch(event, targetKey)) {
            setKeyPressed(true);
            if (onPress) {
              onPress();
            }
          }
        };

        // If released key is our target key then set to false
        const upHandler = event => {
          if (isMatch(event, targetKey)) {
            setKeyPressed(false);
          }
        };
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
          window.removeEventListener('keydown', downHandler);
          window.removeEventListener('keyup', upHandler);
        };
      }
    },
    [onPress, targetKey, listen],
  ); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
