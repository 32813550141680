import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './TableList.scss';

// Maybe just replace it with a proper table? Must be easier..

// TODO: Expand with "scrollable" for overflow-x on small screens
export const Table = ({ children, className, zebra, caption }) => (
  <table
    className={classnames(className, styles.table, {
      [styles.zebra]: zebra,
    })}
  >
    {caption && <caption className={styles.caption}>{caption}</caption>}
    <tbody>{children}</tbody>
  </table>
);

export const Row = ({
  children,
  highlightOnHover = false,
  selected = false,
  verticalCenter = false,
  blockRow = false,
  className,
}) => (
  <tr
    className={classnames(className, styles.row, {
      [styles.blockRow]: blockRow,
      [styles.highlightOnHover]: highlightOnHover,
      [styles.selected]: selected,
      [styles.verticalCenter]: verticalCenter,
    })}
  >
    {children}
  </tr>
);
export const Column = ({
  children,
  className,
  highlight = false,
  min = false,
  grow = false,
  noWrap = false,
  noSpacing = false,
  verticalCenter = true,
  width,
  colSpan,
  rowSpan,
  heading,
  align = 'left',
}) => {
  const Tag = heading ? 'th' : 'td';
  return (
    <Tag
      className={classnames(className, styles.column, {
        [styles.highlight]: highlight,
        [styles.min]: min,
        [styles.grow]: grow,
        [styles.noWrap]: noWrap,
        [styles.noSpacing]: noSpacing,
        [styles.verticalCenter]: verticalCenter,
        [styles.alignEnd]: align === 'right',
      })}
      style={{ ...(width && { width }) }}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {children}
    </Tag>
  );
};

export default Table;
