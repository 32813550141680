import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading, { H2 } from 'components/atoms/Heading';
import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import {
  clock,
  chevronDown,
  chevronRight,
  chevronLeft,
  location,
} from 'components/atoms/Icon/icons';
import EventTicketInfo from 'components/molecules/EventTicketInfo';
import Table, { Column } from 'components/molecules/TableList';
import Image from 'components/atoms/Image';
import { IconButton } from 'components/atoms/Button';
import Toggle from 'components/utilities/Toggle';
import TicketAvailabilityFetcher from 'components/utilities/TicketAvailabilityFetcher';
import getProductUrl from 'utils/getProductUrl';
import { getUrlParameter } from 'utils/getUrlParameter';
import { translate } from 'utils/translate';
import { getFullUrl, appendQueryParams, parseUrl } from 'utils/bundle-helper';
import useSiteSettings from 'hooks/use-site-settings';
import { getDateFromISO, getFormattedDate } from 'utils/dateTime';
import shorten from 'utils/string/shorten';
import FavouriteButtonContainer from 'containers/molecules/FavouriteButtonContainer';

import * as styles from './EventList.scss';

const groupByKey = key => (groups, item) => {
  const itemKey = item[key];
  return {
    ...groups,
    [itemKey]: [...(groups[itemKey] || []), ...[item]],
  };
};
const groupFunctions = {
  month: groupByKey('month'),
  day: groupByKey('day'),
};
const sortFunctions = {
  noFromTime: (a, b) => {
    if (!a.fromTime) return -1;
    return 0;
  },
  timestamp: (a, b) => {
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    return 0;
  },
};

const groupProducts = (groupBy, products) => {
  if (!groupFunctions[groupBy]) {
    return [{ title: null, products }];
  }
  const groupedObject = products.reduce(groupFunctions[groupBy], {});
  return Object.keys(groupedObject).map(key => ({
    title: key,
    products: groupedObject[key],
  }));
};

const EventTitleDesc = ({ title, desc }) => {
  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const [availableLines, setAvailableLines] = useState(0);

  useEffect(() => {
    function heightHandler() {
      document.fonts.ready.then(() => {
        if (titleRef.current) {
          setAvailableLines(
            Math.floor(
              (wrapperRef.current.offsetHeight -
                titleRef.current.offsetHeight) /
                30,
            ),
          );
        }
      });
    }
    heightHandler();
    window.addEventListener('resize', heightHandler);

    return () => {
      window.removeEventListener('resize', heightHandler);
    };
  }, []);

  const lineClamp = {};
  if (availableLines) {
    lineClamp['--lines'] = availableLines;
  }

  return (
    <div ref={wrapperRef} className={styles.textWrapper}>
      <h3 ref={titleRef} className={styles.title}>
        {title}
      </h3>
      {!!desc && (
        <p className={styles.description} style={lineClamp}>
          {desc}
        </p>
      )}
    </div>
  );
};

const EventTime = ({ event }) => {
  const eventStartFullDate = event.openingHours[0].startDay;
  const eventEndFullDate = event.openingHours[0].endDay;

  const showStartMonth =
    getFormattedDate(eventStartFullDate, 'MMM') !==
      getFormattedDate(eventEndFullDate, 'MMM') ||
    getFormattedDate(eventStartFullDate, 'yyyy') !==
      getFormattedDate(eventEndFullDate, 'yyyy');

  const showYear =
    getFormattedDate(eventStartFullDate, 'yyyy') !==
    getFormattedDate(eventEndFullDate, 'yyyy');

  return (
    <div className={styles.time}>
      <span>
        <Icon icon={clock} />
        <span className={styles.timeAndDateWrapper}>
          {event.fromTime ? (
            <React.Fragment>
              {getFormattedDate(event.fromTime, 'p')}

              {!!event.toTime &&
                event.fromTime !== event.toTime &&
                getFormattedDate(event.toTime, '–p')}
            </React.Fragment>
          ) : !!eventEndFullDate && eventStartFullDate !== eventEndFullDate ? (
            <React.Fragment>
              <span>
                {getFormattedDate(eventStartFullDate, 'dd')}
                {'.'}
                {showStartMonth && (
                  <React.Fragment>
                    {' '}
                    {getFormattedDate(eventStartFullDate, 'MMM')}
                  </React.Fragment>
                )}
                {showYear && (
                  <React.Fragment>
                    {' '}
                    {getFormattedDate(eventStartFullDate, 'yyyy')}
                  </React.Fragment>
                )}
                {'–'}
              </span>
              <span>
                {getFormattedDate(eventEndFullDate, 'dd')}.{' '}
                {getFormattedDate(eventEndFullDate, 'MMM')}
                {showYear && (
                  <React.Fragment>
                    {' '}
                    {getFormattedDate(eventEndFullDate, 'yyyy')}
                  </React.Fragment>
                )}
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {event.nameOfStartDay} {getFormattedDate(event.fromDate, 'dd')}
              {'. '}
              {getFormattedDate(event.fromDate, 'MMM')}
            </React.Fragment>
          )}
        </span>
      </span>
    </div>
  );
};

const dateColumnVariations = {
  time: product => (
    <Column
      noSpacing
      highlight={!!product.fromTime}
      width={60}
      className={classnames(styles.date, styles.onlyTime)}
    >
      {!!product.fromTime && (
        <span className={styles.time}>
          <Icon icon={clock} className={styles.timeIcon} />
          {getFormattedDate(product.fromTime, 'p')}
        </span>
      )}
    </Column>
  ),

  dateTime: product => (
    <Column noSpacing highlight width={80} className={styles.date}>
      <span className={styles.weekday}>
        {getFormattedDate(product.fromDate, 'eeee')}
      </span>
      <br />
      <strong>{getFormattedDate(product.fromDate, 'dd')}</strong>
      <span> {getFormattedDate(product.fromDate, 'MMM')}</span>
      {!!product.fromTime && (
        <span className={styles.time}>
          <Icon icon={clock} className={styles.timeIcon} />
          {getFormattedDate(product.fromTime, 'p')}
        </span>
      )}
    </Column>
  ),

  duration: product => (
    <Column noSpacing highlight min noWrap className={classnames(styles.date)}>
      {product.nameOfStartDay}
      {product.fromDate !== product.toDate && (
        <span>–{product.nameOfEndDay}</span>
      )}
      <br />
      <strong>{getFormattedDate(product.fromDate, 'dd')}</strong>
      {getFormattedDate(product.fromDate, ' MMM yyyy')}
      {!!product.toDate && product.fromDate !== product.toDate && (
        <React.Fragment>
          –<strong>{getFormattedDate(product.toDate, 'dd')}</strong>
          {getFormattedDate(product.toDate, ' MMM yyyy')}
        </React.Fragment>
      )}
      {!!product.fromTime && (
        <span className={styles.time}>
          <Icon icon={clock} className={styles.timeIcon} />
          {getFormattedDate(product.fromTime, 'p')}
          {!!product.toTime &&
            product.fromTime !== product.toTime &&
            getFormattedDate(product.toTime, '–p')}
        </span>
      )}
    </Column>
  ),
};

const makeProductUrl = product => {
  if (product.startTime) {
    return appendQueryParams(parseUrl(getFullUrl(product.url)), {
      startDate: product.fromDate,
      startTime: getFormattedDate(product.fromTime, 'HH:mm'),
    }).href;
  }
  return appendQueryParams(parseUrl(getFullUrl(product.url)), {
    startDate: product.fromDate,
  }).href;
};

const EventListItems = ({
  groupTitle,
  products,
  ticketInfo,
  eventColor,
  showAreaTag,
}) => {
  const { culture } = useSiteSettings();

  const style = {};
  if (eventColor) {
    style['--dateOverlay'] = `${eventColor}33`;
  }

  return (
    <div className={styles.eventGroup}>
      <H2 size={Heading.sizes.heading3} className={styles.eventGroupTitle}>
        {alterGroupTitleByCulture(groupTitle, culture)}
      </H2>

      <div
        className={classnames(
          styles.cardGrid,
          products.length === 1 && styles.halfWidth,
        )}
      >
        {products.map((product, i) => (
          <React.Fragment key={product.key}>
            <div className={styles.card}>
              <a
                href={`${makeProductUrl(product)}&show=openinghours`}
                className={styles.eventLink}
              >
                <div className={styles.imageContainer}>
                  <Image
                    src={product.imageUrl}
                    ratio={[3, 2]}
                    fit="cover"
                    cropDetails={product.cropDetails}
                    className={styles.image}
                    description={product.imageName}
                    copy={product.imageCopyright}
                  />
                  <div
                    className={classnames(
                      styles.dateOverlay,
                      !eventColor && styles.defaultColor,
                    )}
                    style={style}
                  >
                    <div className={styles.date}>
                      <span>
                        {getFormattedDate(product.fromDate, 'd')}
                        {'.'}
                      </span>
                      <span>
                        {getFormattedDate(product.fromDate, 'MMM').replace(
                          '.',
                          '',
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.tags}>
                  {!showAreaTag ? (
                    product.categories
                      .slice(0, 2)
                      .map((tag, i) => <span key={i}>{tag}</span>)
                  ) : (
                    <span key={i}>{product.place}</span>
                  )}
                </div>
                {product.description ? (
                  <EventTitleDesc
                    title={product.title}
                    desc={product.description}
                  />
                ) : (
                  <h3 className={styles.title}>{product.title}</h3>
                )}
              </a>
              <div className={styles.cardBottom}>
                <EventTime event={product} />
                <div className={styles.place}>
                  <span>
                    <Icon icon={location} />
                    {product.intro}
                  </span>
                </div>
                <EventTicketInfo
                  isLoading={ticketInfo.isLoading}
                  externalData={ticketInfo.ticketData[i]}
                  product={product}
                  eventCardView
                />
              </div>
              <FavouriteButtonContainer
                compact
                identifier={product.myFavoritesId}
                className={styles.favoritesButton}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const alterGroupTitleByCulture = (title, culture) => {
  let out = title;
  if (culture == 'it') {
    out = title.split(' ');
    return `${out[0]}, ${out[1]} ${out[2]} ${out[3]}`;
  }
  if (culture == 'de') {
    out = title.split(',');
    // return `${out[0]}, ${out[1]} ${out[2]}`;
    return `${out[0]}, ${out[1]}`;
  }
  return out;
};

const toParamDateFormat = date => {
  const y = `${date.getFullYear()}`;
  const m = `0${date.getMonth() + 1}`.slice(-2);
  const d = `0${date.getDate()}`.slice(-2);
  return `${y}-${m}-${d}`;
};
const calendarPathByDate = date =>
  `${(window.location.origin,
  window.location.pathname)}?FromDate=${toParamDateFormat(
    date,
  )}&ToDate=${toParamDateFormat(date)}`;

const EventDateNavigator = ({ style, className }) => {
  const fromDate = getUrlParameter('FromDate');
  const toDate = getUrlParameter('ToDate');
  if (!fromDate || !toDate) return null;
  if (fromDate === toDate) {
    const prevDate = new Date(fromDate);
    prevDate.setDate(prevDate.getDate() - 1);
    const nextDate = new Date(toDate);
    nextDate.setDate(nextDate.getDate() + 1);
    return (
      <div
        style={style}
        className={classnames(styles.quickDateNavigator, className)}
      >
        <Link
          className={styles.quickDateLink}
          href={calendarPathByDate(prevDate)}
        >
          <Icon icon={chevronLeft} className={styles.quickDateLinkIcon} />
          <span className={styles.quickDateLinkText}>
            {translate('/pages/eventListPage/previousDay')}
          </span>
        </Link>
        <Link
          className={styles.quickDateLink}
          href={calendarPathByDate(nextDate)}
        >
          <Icon icon={chevronRight} className={styles.quickDateLinkIcon} />
          <span className={styles.quickDateLinkText}>
            {translate('/pages/eventListPage/nextDay')}
          </span>
        </Link>
      </div>
    );
  }
  return null;
};
EventDateNavigator.propTypes = {
  style: PropTypes.any,
  className: PropTypes.any,
};

const EventList = React.memo(
  ({
    dateVariant,
    products,
    showProductLink,
    groupBy,
    dayFormat,
    monthFormat,
    channel,
    render,
    showInitial,
    highlighted,
    title,
    eventColor,
    showAreaTag,
  }) => {
    const { productPageLink, tellusTicketChannelName } = useSiteSettings();
    const transformedProducts = useMemo(
      () =>
        products
          ? products.map(product => {
              const eventDate = getDateFromISO(
                product.fromDate,
                product.fromTime,
              );
              const timestamp = getFormattedDate(eventDate, 't');
              const day = getFormattedDate(product.fromDate, dayFormat);
              const month = getFormattedDate(product.fromDate, monthFormat);
              const key = `${product.id}_${getFormattedDate(
                eventDate,
                "yyyy-MM-dd'T'HH:mm",
              )}`;
              const url = getProductUrl(product.url, productPageLink);
              const description = product.description
                ? product.description.longPlainText
                  ? shorten(
                      product.description.longPlainText.replace(
                        /[\r\n]+/gm,
                        '',
                      ),
                      240,
                    )
                  : product.description.shortPlainText
                : null;
              const categories = product.categories.filter(
                (name, index, self) =>
                  index === self.findIndex(cat => cat === name),
              );

              return {
                ...product,
                eventDate,
                key,
                day,
                month,
                timestamp,
                url,
                description,
                categories,
              };
            })
          : null,
      [products, dayFormat, monthFormat, productPageLink],
    );

    const sortedProducts = transformedProducts
      ? transformedProducts.sort(sortFunctions.timestamp)
      : [];

    if (!products || !products.length) {
      return (
        <p className={styles.noResult}>
          {translate('/pages/eventListPage/noResult')}
        </p>
      );
    }

    if (!groupBy) {
      if (render) {
        return render(sortedProducts);
      }
      const ticketAvailabilityFetcherKey = `${sortedProducts[0].fromDate}–${sortedProducts[0].toDate}`;

      return sortedProducts ? (
        <TicketAvailabilityFetcher
          products={sortedProducts}
          channel={channel || tellusTicketChannelName}
          key={ticketAvailabilityFetcherKey}
        >
          {({ isLoading, ticketInfo }) => (
            <>
              {isLoading ? (
                <div>Loading events...</div>
              ) : (
                <Toggle
                  on={!showInitial || sortedProducts.length <= showInitial}
                  render={({ on, setOn }) => (
                    <React.Fragment>
                      <Table caption={title}>
                        <EventListItems
                          products={
                            on
                              ? sortedProducts
                              : sortedProducts.slice(0, showInitial)
                          }
                          dateVariant={dateVariant}
                          ticketInfo={ticketInfo}
                          showProductLink={showProductLink}
                          highlighted={highlighted}
                          eventColor={eventColor}
                        />
                      </Table>
                      {!on && (
                        <p className={styles.showMore}>
                          <IconButton
                            onClick={setOn}
                            iconAfter={chevronDown}
                            className={styles.showMoreDates}
                          >
                            {translate('/product/showMoreDates')}
                          </IconButton>
                        </p>
                      )}
                    </React.Fragment>
                  )}
                />
              )}
            </>
          )}
        </TicketAvailabilityFetcher>
      ) : (
        <p>Please reload page...</p>
      );
    }

    // grouped products
    const groupedProducts = groupProducts(groupBy, sortedProducts);

    if (render) {
      return render(groupedProducts);
    }

    return (
      <div className={styles.events}>
        {groupedProducts.map((group, groupIndex) => {
          const groupedProductsFetcherKey = `${groupedProducts[groupIndex].products[0].fromDate}–${groupedProducts[groupIndex].products[0].toDate}`;
          return sortedProducts ? (
            <TicketAvailabilityFetcher
              products={group.products}
              channel={channel || tellusTicketChannelName}
              key={groupedProductsFetcherKey}
            >
              {ticketInfo => (
                <EventListItems
                  groupTitle={group.title}
                  products={group.products}
                  ticketInfo={ticketInfo}
                  eventColor={eventColor}
                  showAreaTag={showAreaTag}
                />
              )}
            </TicketAvailabilityFetcher>
          ) : (
            <p>Loading products...</p>
          );
        })}
      </div>
    );
  },
);
EventList.displayName = 'EventList';
EventList.propTypes = {
  showImages: PropTypes.bool,
  dateVariant: PropTypes.oneOf(Object.keys(dateColumnVariations)),
  products: PropTypes.array.isRequired,
  showProductLink: PropTypes.bool,
  groupBy: PropTypes.string,
  dayFormat: PropTypes.string,
  monthFormat: PropTypes.string,
  channel: PropTypes.string,
  render: PropTypes.func,
  showAreaTag: PropTypes.bool,
};
EventList.defaultProps = {
  showImages: false,
  dateVariant: 'dateTime',
  showProductLink: true,
  groupBy: null,
  dayFormat: 'PPPP',
  monthFormat: 'MMMM yyyy',
  render: null,
  showAreaTag: false,
};

export default EventList;
