import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading, { H1 } from 'components/atoms/Heading';
import * as styles from './Teaser.scss';

const Title = ({ className, small, children, hasWhiteFont }) => (
  <H1
    size={small ? Heading.sizes.normal : Heading.sizes.heading5}
    className={classnames(className, styles.title, {
      [styles.white]: hasWhiteFont,
    })}
    gutterBottom
    bold
  >
    {children}
  </H1>
);

export default Title;
