import React from 'react';
import { isString } from './bundle-helper';

export const nl2br = text =>
  isString(text)
    ? text
        .split(/(?:\r\n|\r|\n)/)
        .reduce(
          (res, frag, i, arr) => [
            ...res,
            frag,
            ...(i < arr.length - 1 ? [React.createElement('br')] : []),
          ],
          [],
        )
    : text;

