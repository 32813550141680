import React from 'react';
import PropTypes from 'prop-types';
import Text, { createTextVariation } from 'components/atoms/Text';

const getValidLevel = level => (level > 6 ? 6 : level < 1 ? 1 : level);

const levelTagMap = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};
const levelSizeMap = {
  1: Text.sizes.heading1,
  2: Text.sizes.heading2,
  3: Text.sizes.heading3,
  4: Text.sizes.heading4,
  5: Text.sizes.heading5,
  6: Text.sizes.heading6,
};

export const Heading = createTextVariation(
  'Heading',
  ({ level = 3, ...props }, ref) => {
    const validLevel = getValidLevel(level);
    return (
      <Text
        as={levelTagMap[validLevel]}
        size={levelSizeMap[validLevel]}
        {...props}
        ref={ref}
      />
    );
  },
);
export const H1 = createTextVariation('H1', (props, ref) => (
  <Heading level={1} {...props} ref={ref} />
));
export const H2 = createTextVariation('H2', (props, ref) => (
  <Heading level={2} {...props} ref={ref} />
));
export const H3 = createTextVariation('H3', (props, ref) => (
  <Heading level={3} {...props} ref={ref} />
));
export const H4 = createTextVariation('H4', (props, ref) => (
  <Heading level={4} {...props} ref={ref} />
));
export const H5 = createTextVariation('H5', (props, ref) => (
  <Heading level={5} {...props} ref={ref} />
));
export const H6 = createTextVariation('H6', (props, ref) => (
  <Heading level={6} {...props} ref={ref} />
));

export const Title = createTextVariation('Title', (props, ref) => (
  <H1 size={Text.sizes.title} alt {...props} ref={ref} />
));

export const TitleXL = createTextVariation('TitleXL', (props, ref) => (
  <H1 size={Text.sizes.titleXL} alt {...props} ref={ref} />
));

export const Hero = createTextVariation('Hero', (props, ref) => (
  <H1 size={Text.sizes.hero} alt {...props} ref={ref} />
));

export default Heading;
