import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  format,
  startOfMonth,
  startOfWeek,
  startOfDay,
  lastDayOfMonth,
  eachDayOfInterval,
  isWithinInterval,
  getWeek,
  isSameDay,
  eachWeekOfInterval,
  endOfWeek,
  addMonths,
} from 'date-fns/esm';

const useMonthNavigation = (startDate, onMonthChange) => {
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(startDate));
  useEffect(() => {
    if (onMonthChange) {
      onMonthChange(currentMonth);
    }
  }, [currentMonth, onMonthChange]);
  const nextMonth = useCallback(
    () => setCurrentMonth(addMonths(currentMonth, 1)),
    [currentMonth],
  );
  const prevMonth = useCallback(
    () => setCurrentMonth(addMonths(currentMonth, -1)),
    [currentMonth],
  );
  return {
    currentMonth,
    nextMonth,
    prevMonth,
  };
};

const useCalendar = (
  locale,
  weekStartsOn,
  startDate,
  dateRange,
  onMonthChange,
  calendarEntries = {},
) => {
  const today = startOfDay(new Date());
  const formatOptions = useMemo(
    () => ({
      locale,
      weekStartsOn,
      awareOfUnicodeTokens: true,
    }),
    [locale, weekStartsOn],
  );
  const { currentMonth, nextMonth, prevMonth } = useMonthNavigation(
    startDate || today,
    onMonthChange,
  );
  const weeks = useMemo(
    () =>
      eachWeekOfInterval(
        {
          start: startOfWeek(currentMonth, formatOptions),
          end: lastDayOfMonth(currentMonth, formatOptions),
        },
        formatOptions,
      ).map(weekStartDate => ({
        weekNumber: getWeek(weekStartDate, formatOptions),
        days: eachDayOfInterval(
          {
            start: weekStartDate,
            end: endOfWeek(weekStartDate, formatOptions),
          },
          formatOptions,
        ).map(day => {
          const timestamp = format(day, 't');
          return {
            date: day,
            isInCurrentMonth: isSameDay(startOfMonth(day), currentMonth),
            isToday: isSameDay(startOfDay(day), today),
            isInDateRange:
              !!dateRange &&
              !isSameDay(dateRange.from, dateRange.to) &&
              isWithinInterval(day, {
                start: startOfDay(dateRange.from),
                end: startOfDay(dateRange.to),
              }),
            timestamp,
            ...(calendarEntries[timestamp] || {}),
          };
        }),
      })),
    [today, currentMonth, formatOptions, calendarEntries],
  );

  return {
    today,
    formatOptions,
    currentMonth,
    weeks,
    nextMonth,
    prevMonth,
  };
};

export default useCalendar;
