import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./Link.scss";

const Link = ({ href, description, isBlock, children, className, target, onClick, ...rest }) => (
  <a
    href={href}
    className={classnames(className, { [styles.isBlock]: isBlock })}
    title={description}
    target={target}
    onClick={onClick}
    {...rest}
  >
    {children}
  </a>
);

Link.displayName = "Link";
Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  description: PropTypes.string,
  isBlock: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  target: PropTypes.string,
};
Link.defaultProps = {
  isBlock: false
};

export default Link;
