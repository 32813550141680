export const STATE_IDLE = 0;
export const STATE_RUNNING = 1;
export const STATE_PAUSED = 2;
export const STATE_STOPPED = 3;

export default function intervalTimer(callback, interval) {
  var timerId,
    startTime,
    remaining = interval,
    state = STATE_IDLE;

  var run = function(timeout) {
    startTime = new Date();
    state = STATE_RUNNING;

    window.clearTimeout(timerId);
    timerId = window.setTimeout(__callback, timeout);
  };
  var __callback = function() {
    if (state !== STATE_RUNNING) return;
    // run new BEFORE callback.. this matters in cases where the callback stops the interval
    run(interval);
    callback();
  };

  this.pause = function() {
    if (state !== STATE_RUNNING) return;

    window.clearTimeout(timerId);
    remaining = remaining - (new Date() - startTime);
    state = STATE_PAUSED;
  };
  this.stop = function() {
    window.clearTimeout(timerId);
    state = STATE_STOPPED;
  };

  this.resume = function() {
    if (state !== STATE_PAUSED) return;
    run(remaining);
  };
  this.state = state;

  run(interval);
}
