import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';
import { chevronUp, chevronDown } from 'components/atoms/Icon/icons';
import Container from 'components/atoms/Container';
import Button, { LinkButton } from 'components/atoms/Button';
import MenuToggler from 'components/utilities/MenuToggler';
import * as styles from './Topbar.scss';

const Topbar = ({ children }) => (
  <div className={styles.root}>
    <Container className={styles.inner}>{children}</Container>
  </div>
);
Topbar.Button = ({ className, children, onClick, href, icon, buttonClassName }) => (
  <Button
    transparent
    className={classnames(styles.button, className, { [styles.noIcon]: !icon })}
    onClick={onClick}
    href={href}
  >
    {!!children && <span className={styles.buttonText}>{children}</span>}
    {!!icon && (
      <i className={classnames(styles.buttonIcon, buttonClassName)}>
        <Icon icon={icon} />
      </i>
    )}
  </Button>
);
Topbar.Menu = ({ className, buttonClassName, label, items, href }) => (
  <MenuToggler
    closeOnOutsideClick
    closeOnEsc
    render={({ isOpen, clickOpenerProps, ref }) => (
      <div className={classnames(styles.menuRoot, className)}>
        {!href && (
          <Topbar.Button
            className={buttonClassName}
            icon={isOpen ? chevronUp : chevronDown}
            {...clickOpenerProps}
          >
            {label}
          </Topbar.Button>
        )}
        {!!href && (
          <React.Fragment>
            <Topbar.Button href={href} className={buttonClassName}>
              {label}
            </Topbar.Button>
            <Topbar.Button
              icon={isOpen ? chevronUp : chevronDown}
              className={buttonClassName}
              {...clickOpenerProps}
            />
          </React.Fragment>
        )}
        {isOpen && (
          <ul className={classnames(styles.menu, styles.isOpen)} ref={ref}>
            {!!items &&
              items.map(({ linkText, url, action }) => (
                <li key={url || linkText} className={styles.menuItem}>
                  {!action ? (
                    <a
                      href={url}
                      className={styles.menuLink}
                      onClick={() => {
                        window.location.href = url;
                      }}
                    >
                      {linkText}
                    </a>
                  ) : (
                    <Button
                      transparent
                      onClick={action}
                      className={styles.menuLink}
                    >
                      {linkText}
                    </Button>
                  )}
                </li>
              ))}
          </ul>
        )}
      </div>
    )}
  />
);
Topbar.displayName = 'Topbar';
Topbar.propTypes = {};
Topbar.defaultProps = {};

export default Topbar;
